import {Deserializable} from './interfaces/deserializable.interface';
import {User} from './user.model';
import {UserData} from "./user-data.model";

export class AgedissErrorBody implements Deserializable{
  erreur : any;
  user ?: UserData;
  date ?: Date;

  deserialize(input: any): this {
    return Object.assign(this,input);
  }
}
