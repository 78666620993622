<div *ngIf="loading" class="spinner"></div>
<div *ngIf="!loading">
  <div class="row m-0">
    <div class="col-md-12 pt-3">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{grcData?.destinataire_nom | uppercase}}">{{'Nom' | translate }} :</strong>
        <span> {{grcData?.destinataire_nom | uppercase}}</span>
      </p>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-md-12">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{grcData?.destinataire_adresse | uppercase}} {{grcData?.destinataire_code_postal}} {{grcData?.destinataire_ville | uppercase}}">{{'Adresse' | translate }} :</strong>
        <span>
          {{grcData?.destinataire_adresse | uppercase}} {{grcData?.destinataire_code_postal}} {{grcData?.destinataire_ville | uppercase}}
        </span>
      </p>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-md-12">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{grcData?.destinataire_email}}">{{'Email' | translate }} :</strong>
        <span> {{grcData?.destinataire_email}}</span>
      </p>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-md-6">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{grcData?.destinataire_tel_fixe}}">{{'Fixe' | translate }} :</strong>
        <span> {{grcData?.destinataire_tel_fixe}}</span>
      </p>
    </div>
    <div class="col-md-6">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{grcData?.destinataire_tel_portable}}">{{'Mobile' | translate }} :</strong>
        <span> {{grcData?.destinataire_tel_portable}}</span>
      </p>
    </div>
  </div>
</div>
