import {Component, Input, OnInit} from '@angular/core';
import {Grc} from '../../../shared/models/api/grc.model';
import {GrcData} from "../../../shared/models/api/grc-data.model";

@Component({
  selector: 'app-destinataire-gestion-relation-clients',
  templateUrl: './destinataire-gestion-relation-clients.component.html',
  styleUrls: ['./destinataire-gestion-relation-clients.component.scss']
})
export class DestinataireGestionRelationClientsComponent implements OnInit {

  @Input() grc: GrcData;
  @Input() loading: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
