import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import Swal from "sweetalert2";
import {Subscription} from 'rxjs';
import {Article} from '../../../../../shared/models/api/article.model';
import {ArticleService} from '../../../../../shared/services/api/article.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-visualisation-article',
  templateUrl: './visualisation-article.component.html',
  styleUrls: ['./visualisation-article.component.scss']
})
export class VisualisationArticleComponent implements OnInit {

  @Input() articleId: number;

  @Output() canceled: EventEmitter<void> = new EventEmitter();

  public article: Article;
  public loadingTableColis: boolean = false;
  public subscriptions: Subscription[] = [];
  public loading = false;
  public displayedColumns: string[] = ['codeBarre']

  constructor(private translate: TranslateService,
              private articleSvc: ArticleService) {
  }

  ngOnInit() {
    this.loading = true;
    this.loadingTableColis = true;
    this.subscriptions.push(this.articleSvc.getArticle(this.articleId).subscribe(
      (article) => {
        this.article = article;
        this.article.colis = article.colis;
        this.loading = false;
        this.loadingTableColis = false;
      }, (error) => {
        this.canceled.emit();
        Swal.fire({
          title: this.translate.instant('Désolé'),
          text: this.translate.instant('Une erreur est survenue'),
          type: 'error',
          showCancelButton: false,
        });
        this.loading = false;
        this.loadingTableColis = false;
      }));
  }

  cancel() {
    this.canceled.emit();
  }

}
