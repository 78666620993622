<div *ngIf="view.hasDroit('LIBRAIRIE_MENU_VIDEO')">

    <div class="row m-0 padding">
        <h1 class="page-header m-auto text-center padding-h1">{{'Mes vidéos' | translate }}</h1>
    </div>

    <div class="row m-0 padding-table" *ngIf="view.hasDroit('LIBRAIRIE_VIDEO_CREATE')">
      <div class="col-12 p-0 col-lg-4 col-xl-2">
        <button class="form-control btn btn-green btn-block font-weight-bold shadow"
                (click)="openModalAddNewVideo(modal_file_uploader)">{{'Déposer une vidéo' | translate }}
        </button>
      </div>
    </div>

    <div class="row m-0 padding-table">
        <div class="col-12 m-0 alert alert-light fade show shadow">
            <app-filter-video (submitFilter)="onSubmitFilter($event)" (loadFilter)="onLoadFilter($event)"></app-filter-video>
        </div>
    </div>

    <div class="row m-0 padding-table" tourAnchor="tableau.videos">
        <div class="col-12 p-0">
            <panel noButton="true" bodyClass="no-padding panel">
                <div class="row" header><h5 class="p-t-5 text-agediss">{{'Mes vidéos' | translate }}</h5></div>
                <div>
                    <app-table-ged [piecesJointes]="datas"
                                   [showInfosBtn]="true"
                                   [loading]="isLoadingTableVideo"
                                   [isVideo]="true"
                                   [showDownloadFileBtn]="view.hasDroit('LIBRAIRIE_VIDEO_DOWNLOAD')"
                                   [showViewFileBtn]="view.hasDroit('LIBRAIRIE_VIDEO_SHOW')"
                                   [showDeleteFileBtn]="view.hasDroit('LIBRAIRIE_VIDEO_DELETE')"
                                   (onSelectFile)="openModalVisualisationVideo(modal_visualisation_video,$event)"
                                   [pageSize]="10" [pageSizeOptions]="[5, 10, 20]">
                    </app-table-ged>
                </div>
            </panel>
        </div>
    </div>

</div>

<!--modal upload video-->
<ng-template #modal_file_uploader let-modal>
    <app-file-uploader [showHeader]="true" [isVideo]="true" (canceled)="cancel()" (uploaded)="onUploaded()"></app-file-uploader>
</ng-template>
<!--fin de modal upload video-->

<!-- modal visualisation vidéo -->
<ng-template #modal_visualisation_video let-modal>
    <app-file-preview-ged [imageGed]="data?.images" [isVideo]="true" (canceled)="modal.close($event)"></app-file-preview-ged>
</ng-template>

<!--<ng-template #modal_visualisation_video let-modal>-->
<!--  <app-file-preview-ged [piecesJointesGed]="datas" [isVideo]="true"-->
<!--                        (canceled)="modal.close($event)"></app-file-preview-ged>-->
<!--</ng-template>-->
<!-- fin de modal visualisation vidéo -->
