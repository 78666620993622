import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Droit} from '../../../models/api/droit.model';

@Injectable({
  providedIn: 'root'
})
export class DroitService {

  urlRessource = environment.url_api_espace_client + '/sso-droits';

  constructor(private http: HttpClient) {
  }


  getDroits(): Observable<Droit> {
    return this.http
      .get<Droit>(`${this.urlRessource}`)
      .pipe(map((observe) => {
        return new Droit().deserialize(observe);
      }))
  }

}
