import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SignatureLivraison} from '../../../../shared/models/api/ged/signature-livraison.model';
import Swal from 'sweetalert2';
import {PieceJointeService} from '../../../../shared/services/api/piece-jointe.service';
import {PieceJointe} from '../../../../shared/models/api/piece-jointe.model';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ContestationService} from '../../../../shared/services/api/contestation.service';
import {ConversationService} from '../../../../shared/services/api/conversation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Commande} from "../../../../shared/models/api/commande.model";
import {CommandeService} from "../../../../shared/services/api/commande.service";
import {FilterMultiCritereModel} from "../../../../shared/models/filters/filterMultiCritere.model";
import {View} from "../../../../shared/models/view.model";
import {AuthenticationService} from "../../../../shared/services/authentication.service";

@Component({
  selector: 'app-signature-livraison',
  templateUrl: './signature-livraison.component.html',
  styleUrls: ['./signature-livraison.component.scss']
})
export class SignatureLivraisonComponent implements OnInit {

  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  @Input() commande: Commande;
  @Input() commandeId: string;
  @Input() codeSociete: string;
  @Input() numeroClient: string;
  @Input() showViewBlBtn: boolean = false;
  @Input() origine: string;
  @Input() reference: string | undefined;
  public pieceJointe: PieceJointe;
  public loadingPage = false;
  public activModal: NgbModalRef;
  public files: PieceJointe[];
  public view: View;

  public signatureLivraison: SignatureLivraison;
  public loading: boolean = false;
  public loadingMap: boolean = false;
  public lat: number;
  public lng: number;
  public styles = [
    {
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#f5f5f5'
        }
      ]
    },
    {
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#616161'
        }
      ]
    },
    {
      'elementType': 'labels.text.stroke',
      'stylers': [
        {
          'color': '#f5f5f5'
        }
      ]
    },
    {
      'featureType': 'administrative.land_parcel',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#bdbdbd'
        }
      ]
    },
    {
      'featureType': 'poi',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#eeeeee'
        }
      ]
    },
    {
      'featureType': 'poi',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#757575'
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#e5e5e5'
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#9e9e9e'
        }
      ]
    },
    {
      'featureType': 'road',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#ffffff'
        }
      ]
    },
    {
      'featureType': 'road.arterial',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#757575'
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#dadada'
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#616161'
        }
      ]
    },
    {
      'featureType': 'road.local',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#9e9e9e'
        }
      ]
    },
    {
      'featureType': 'transit.line',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#e5e5e5'
        }
      ]
    },
    {
      'featureType': 'transit.station',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#eeeeee'
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#c9c9c9'
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#9e9e9e'
        }
      ]
    }
  ];

  @Output() canceled: EventEmitter<void> = new EventEmitter();

  constructor(private translate: TranslateService,
              private authSvc: AuthenticationService,
              private commandeSvc: CommandeService,
              private contestationSvc: ContestationService,
              private conversationSvc: ConversationService,
              private routeActive: ActivatedRoute,
              private router: Router,
              private fileSvc: PieceJointeService,
              private modalSvc: NgbModal) {
  }

  ngOnInit() {
    this.authSvc.currentView.subscribe(x => this.view = x);
    this.loadDataSignatureLivraiosn();
  }

  loadDataSignatureLivraiosn() {
    this.loading = true;
    this.loadingMap = true;
    this.filter.societe = this.codeSociete;
    this.filter.client = this.numeroClient;
    let value = this.commandeId;
    if (this.origine == 'wexlog') {
      value = this.reference;
    }
    this.commandeSvc.getSignatureLivraison(value, this.filter).subscribe(
      (signature) => {
        this.signatureLivraison = signature;
        this.lat = Number.parseFloat(this.signatureLivraison.latitude);
        this.lng = Number.parseFloat(this.signatureLivraison.longitude);
        this.loading = false;
        this.loadingMap = false;
      }, (error) => {
        this.canceled.emit();
        Swal.fire({
          title: this.translate.instant('Désolé'),
          text: this.translate.instant('Une erreur est survenue'),
          type: 'error',
          showCancelButton: false,
        });
        this.loading = false;
        this.loadingMap = false;
      }
    );
  }

  openModalVisualisationBlEmarge(modal) {
    this.activModal = this.modalSvc.open(modal, {
      size: 'lg',
      keyboard: false
    });
  }

  cancel() {
    this.canceled.emit();
  }

}
