import {Deserializable} from '../../interfaces/deserializable.interface';
import {PieceJointeGed} from "../ged/piece-jointe-ged.model";
import {WexlogGrcData} from "./wexlog-grc-data.model";

export class WexlogConversationGrc implements Deserializable {

  public id?: string;
  public ffumsg_id?: string;
  public objet?: string;
  public commentaire?: string;
  public message_visible_type: string;
  public statut_message_type?: string;
  public created_at: Date | string;
  public created_by?: string;
  public creator_id?: string;
  public grc: WexlogGrcData;


  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
