<div *ngIf="view.hasDroit('LITIGE_MENU_CONTESTATION')">
  <div class="row m-0 padding">
    <h1 class="page-header m-auto text-center padding-h1">{{'Mes contestations' | translate }}</h1>
  </div>

  <div class="row m-0 padding-table">
    <div class="col-12 m-0 alert alert-light fade show panel">
      <app-filter-contestation (submitFilter)="onSubmit($event)" (onLoad)="onLoadFilter($event)"></app-filter-contestation>
    </div>
  </div>

  <div class="row m-0 padding-table" tourAnchor="tableau.contestations">
    <div class="col-12 p-0">
      <panel noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-12"><h5 class="p-t-5 text-agediss">{{'Mes contestations' | translate }} : <span *ngIf="!loadingNbContestations" >{{nbData}}</span> <i *ngIf="loadingNbContestations" class='fas fa-spin fa-circle-notch'></i></h5></div>
        </div>
        <div>
          <app-table-contestation [data]="contestationData" [length]="nbData" [pageSize]="pageSize" (paginatorEvent)="onLoadDataByEvent($event)" [pageSizeOptions]="pageSizeOption" [showInfosBtn]="true" [loading]="loadingTableContestations">
          </app-table-contestation>
        </div>
      </panel>
    </div>
  </div>
</div>

