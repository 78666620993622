import {Component, Input, OnInit} from '@angular/core';
import {Commande} from "../../../shared/models/api/commande.model";
import {CommandeData} from "../../../shared/models/api/commande-data.model";


@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {

  @Input() commande: CommandeData;

  constructor() {
  }

  ngOnInit() {
    if(!this.commande) {
      throw new Error('Le composent information attend un objet de type commande.')
    }
  }

}
