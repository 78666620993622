import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Article} from '../../models/api/article.model';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  urlRessource = environment.url_api_espace_client + '/articles';

  constructor(private http: HttpClient) {
  }

  getArticle(articleId: number): Observable<Article> {
    return this.http
      .get<Article>(`${this.urlRessource}/${articleId}`)
      .pipe(map((article) => {
        return new Article().deserialize(article);
      }));
  }
}
