import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {Article} from '../../../../../shared/models/api/article.model';
import {Commande} from "../../../../../shared/models/api/commande.model";


@Component({
  selector: 'app-visualisation-article-livraison',
  templateUrl: './visualisation-article-livraison.component.html',
  styleUrls: ['./visualisation-article-livraison.component.scss']
})
export class VisualisationArticleLivraisonComponent implements OnInit {

  @Input() articleId: number;

  @Output() canceled: EventEmitter<void> = new EventEmitter();

  @Input() public article: Article;
  public commande: Commande;
  public loadingTableColis: boolean = false;
  public loadingTableEvenement: boolean = false;
  public subscriptions: Subscription[] = [];
  public loading = false;
  displayedColumns: string[] = ['codeBarre'];

  constructor() {
  }

  ngOnInit() {
  }

  cancel() {
    this.canceled.emit();
  }

}
