import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../../../../shared/models/user.model';
import {forkJoin, Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../../../../shared/services/api/sso/user.service';
import Swal from 'sweetalert2';
import {Client} from '../../../../../shared/models/api/client.model';
import {Role} from '../../../../../shared/models/api/role.model';
import {DroitService} from '../../../../../shared/services/api/sso/droits.service';
import {TranslateService} from '@ngx-translate/core';
import {UserData} from "../../../../../shared/models/user-data.model";
import {GrcService} from "../../../../../shared/services/api/grc.service";
import {SousCompteSsoService} from "../../../../../shared/services/api/sso/sous-compte-sso.service";

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Output() submited: EventEmitter<User> = new EventEmitter();
  @Input() username: string;
  @Input() userId: number;

  public user: UserData;
  public role: Role;
  public tree;
  public placeholder: string = 'Choisir';

  //tableau
  public subscriptions: Subscription[] = [];
  public dataClients: Client[];
  public clients: Client;
  public selectedDroits: number[] = [];
  public droitsGlobal: any [];
  public droitsGlobalChecked: any [];
  public droitsChecked: any [];
  public tab: any [];

  // loading
  public loading: boolean = false;
  public loadingBtn: boolean = false;
  public loadingClient: boolean = false;
  public loadingDroit: boolean = false;
  public disabled: boolean = false;
  public test: boolean = false;
  public check: boolean = false;

  //form
  public editUserForm: FormGroup;
  public editAllNotificationForm: FormGroup;
  public formSubmit = false;

  constructor(private translate: TranslateService,
              private toastr: ToastrService,
              private clientSvc: SousCompteSsoService,
              private grcSvc: GrcService,
              private droitSvc: DroitService,
              private userSvc: UserService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.loading = true;
    this.subscriptions.push(this.userSvc.getDataUserByUserId(this.userId).subscribe(
      (user) => {
        this.user = user;
        this.subscriptions.push(this.userSvc.getDataRoleByUser(this.user.id).subscribe(
          (role) => {
            this.role = role[0];
            this.initForm();
          }));
      }));
  }

  initForm() {
    this.subscriptions.push(
      forkJoin([
        this.clientSvc.getSousComptes(),
        this.droitSvc.getDroits()
      ]).subscribe(
        response => {
          this.dataClients = response[0];
          this.tree = response[1];
          if (this.tree.code == 2102) {
            Swal
              .fire({
                title: this.translate.instant('Désolé'),
                text: this.translate.instant("La liste des droits n'est pas disponible !")
              })
              .then(
                () => {
                  this.cancel();
                }
              )
          }
          this.droitsGlobal = [];
          this.droitsGlobalChecked = [];
          this.droitsChecked = [];
          this.getCheckRights();
          this.getRecursiveRight(this.tree);
          this.checkAllClients();
          this.loadingClient = false;
          this.loadingDroit = false;
          this.editUserForm = this.fb.group({
            firstname: [this.user.firstname, Validators.required],
            lastname: [this.user.lastname, Validators.required],
            email: [this.user.email, [Validators.required, Validators.email]],
            phone: [this.user.tel, Validators.required],
            fonction: [this.user.fonction, Validators.required],
            clients: [this.role.clients.map(val => {
              return val.id;
            })],
          });
          this.loading = false;
        },
        () => {
          Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('Une erreur est survenue')
          })
            .then(
              () => {
                this.cancel();
              }
            );
        }
      )
    );
  }

  initFormNotification(email) {
    this.editAllNotificationForm = new FormGroup({
      username: new FormControl(this.username, Validators.required),
      email: new FormControl(email, [Validators.required, Validators.email]),
    });
  }

  getCheckRights() {
    Object.keys(this.role.droits).forEach((key) => {
      let item = this.role.droits[key];
      if (item != null) {
        this.droitsChecked[item.id] = true;
        this.selectedDroits.push(item.id);
      }
    });
  }

  getRecursiveRight(tree) {
    Object.keys(tree).forEach((key) => {
      let item = tree[key];
      if (item != null) {
        if (item.global_context != undefined) {
          if (this.droitsGlobal[item.global_context] == undefined) {
            this.droitsGlobal[item.global_context] = [];
          }
          this.droitsGlobal[item.global_context].push(item.droitId);
          if(this.droitsChecked[item.droitId] === true) {
            this.droitsGlobalChecked[item.global_context] = true;
            this.droitsGlobalChecked[item.droitId] = true;
          } else {
            this.droitsGlobalChecked[item.global_context] = false;
            this.droitsGlobalChecked[item.droitId] = false;
          }
        } else {
          this.getRecursiveRight(item.childrens);
        }
      }
    });
  }

  selectDroit(e, item, libelle, context) {
    if (item.value.libelle == libelle) {
      if (this.droitsGlobal[libelle + '_' + context] != undefined) {
        this.droitsGlobal[libelle + '_' + context].forEach(val => {
          if (e.target.checked) {
            this.selectedDroits.push(val);
          } else {
            this.selectedDroits.splice(this.selectedDroits.findIndex(value => value == val), 1);
          }
        });
      }
    }
  }

  allClients() {
    this.disabled = !this.disabled;
    if (this.disabled == false) {
      this.placeholder = this.translate.instant('Choisir');
    } else {
      this.placeholder = this.translate.instant('Tous les clients sont sélectionnés');
      this.editUserForm.get('clients').reset();
    }
  }

  checkAllClients() {
    this.dataClients.map(val => {
      if (this.role.clients.find((d) => {
        return d.id == val.id;
      })) {
        this.disabled = false;
        this.placeholder = this.translate.instant('Choisir');
      } else {
        this.disabled = true;
        this.placeholder = this.translate.instant('Tous les clients sont sélectionnés');
      }
    });
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.selectedDroits.push(parseInt(e.target.value));
    } else {
      this.selectedDroits.splice(this.selectedDroits.findIndex(val => val == parseInt(e.target.value)), 1);
    }
  }

  updateFormParamNotification() {
    this.initFormNotification(this.editUserForm.get('email').value)
    const sub1 = this.grcSvc.updateEmailNotificationGrcByUsername(this.username, this.editAllNotificationForm.value).subscribe(
      (notification) => {
        sub1.unsubscribe();
        this.loadingBtn = false;
        this.formSubmit = false;
        this.toastr.success(
          this.translate.instant('Les paramètres des notifications GRC ont été enregistrés'),
          this.translate.instant('Notifications GRC'),
          {progressBar: true}
        );
        this.submited.emit(notification);
      },
      () => {
        this.loadingBtn = false;
        this.formSubmit = false;
        Swal.fire({
          title: this.translate.instant('Désolé'),
          text: this.translate.instant('Une erreur est survenue'),
          type: 'error',
          showCancelButton: false,
        })
      }
    );
  }

  updateFormUser() {
    this.formSubmit = true;
    if (this.editUserForm.invalid) {
      return;
    }
    let values = this.editUserForm.value;
    values.droits = this.selectedDroits;
    this.loadingBtn = true;
    const sub = this.userSvc.updateUser(this.userId, this.editUserForm.value).subscribe(
      (user) => {
        this.updateFormParamNotification();
        sub.unsubscribe();
        this.loadingBtn = false;
        this.formSubmit = false;
        this.toastr.info(
          this.translate.instant('Les informations de l\'utilisateur ont été modifiées'),
          this.translate.instant('Informations'),
          {progressBar: true}
        );
        this.submited.emit(user);
      },
      () => {
        this.loadingBtn = false;
        this.formSubmit = false;
        Swal.fire({
          title: this.translate.instant('Désolé'),
          text: this.translate.instant('Une erreur est survenue'),
          type: 'error',
          showCancelButton: false,
        })
      }
    );
  }

  cancel() {
    this.canceled.emit();
  }

  get f() {
    return this.editUserForm.controls;
  }

}
