import {Injectable} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {map} from "rxjs/operators";
import {HttpUtil} from "../../../utils/http.util";
import {Notif} from "../../../models/api/notif.model";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  urlRessource = environment.url_api_espace_client + '/notifications';
  onLoadSubject = new Subject<boolean>();
  $onLoad: Observable<boolean> = new Observable();

  onCompteurSubject = new Subject<number>();
  $onLoadCompteur: Observable<number> = new Observable();


  constructor(private http: HttpClient) {
    this.$onLoad = this.onLoadSubject.asObservable();
    this.$onLoadCompteur = this.onCompteurSubject.asObservable();
  }

  getNotifications(filter: any = null): Observable<Notif> {
    return this.http
      .get<Notif>(`${this.urlRessource}`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((notif) => {
        return new Notif().deserialize(notif);
      }));
  }

  /**
   *
   * @param id
   */
  checkNotif(id: number): Observable<any> {
    return this.http.put(`${this.urlRessource}/${id}/check`, {});
  }

  /**
   *
   * @param notificationsId
   */
  checkAllNotif(notificationsId: number[]): Observable<any> {
    return this.http.put(`${this.urlRessource}/check-all`, {notificationsId});
  }

  /**
   *
   * @param id
   */
  removeNotif(id: number): Observable<any> {
    return this.http.delete(`${this.urlRessource}/${id}/user-delete`,{});
  }

  /**
   *
   * @param notificationsId
   */
  removeAllNotif(notificationsId): Observable<any> {
    return this.http.request('delete', `${this.urlRessource}/user-delete-all`, { body: { notificationsId } })
  }

}
