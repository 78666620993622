<div *ngIf="view.hasDroit('COMMANDE_MENU_SUIVI-LIVRAISON')">
  <div class="row m-0 padding">
    <h1 class="page-header m-auto text-center padding-h1">{{'Mon suivi de livraison' | translate }}</h1>
  </div>

  <div class="row m-0 padding-table">
    <div class="col-12 m-0 alert alert-light fade show shadow" tourAnchor="panel.date.suivi.livraisons">
      <app-filter-suivi-livraison (submitFilter)="onSubmit($event)"
                                  (onLoad)="onLoadFilter($event)"></app-filter-suivi-livraison>
    </div>
  </div>

  <div class="row m-0 padding-table" tourAnchor="tableau.suivi.livraisons">
    <div class="col-12 p-0">
      <panel noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-12"><h5 class="p-t-5 text-agediss">{{'Mon suivi de livraison' | translate }} </h5></div>
        </div>
        <div>
          <app-table-suivi-de-livraison [data]="suiviLivraisonData" [showInfosBtn]="true" [pageSize]="5"
                                        [pageSizeOptions]="[5, 10, 20]"
                                        [loading]="loadingTableCommande"></app-table-suivi-de-livraison>
        </div>
      </panel>
    </div>
  </div>
</div>
