<div *ngIf="loading" class="spinner"></div>
<div *ngIf="!loading">
  <div class="row m-0 pt-4">
    <div class="offset-md-1 col-md-5 offset-0 col-12 px-2">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{currentUser?.lastname}}">{{'Nom' | translate }} :</strong>
        <span> {{currentUser?.lastname}}</span>
      </p>
    </div>
    <div class="col-md-6 col-12 px-2">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{currentUser?.firstname}}">{{'Prénom' | translate }} :</strong>
        <span> {{currentUser?.firstname}}</span>
      </p>
    </div>
  </div>
  <div class="row m-0">
    <div class="offset-md-1 col-md-5 offset-0 col-12 px-2">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{currentUser?.username}}">{{'Identifiant' | translate }} :</strong>
        <span> {{currentUser?.username}}</span>
      </p>
    </div>
    <div class="col-md-6 col-12 px-2">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{currentUser?.email | lowercase}}">{{'Email' | translate }} :</strong>
        <span> {{currentUser?.email | lowercase}}</span>
      </p>
    </div>
  </div>
  <div class="row m-0">
    <div class="offset-md-1 col-md-5 offset-0 col-12 px-2">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{currentUser?.tel}}">{{'Téléphone' | translate }} :</strong>
        <span> {{currentUser?.tel}}</span>
      </p>
    </div>
    <div class="col-md-6 col-12 px-2">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{currentUser?.fonction}}">{{'Fonction' | translate }} :</strong>
        <span> {{currentUser?.fonction}}</span>
      </p>
    </div>
  </div>
  <div class="row m-0 py-3">
    <div class="offset-sm-1 col-sm-5 offset-2 col-8 p-5">
      <button *ngIf="showViewEditDataBtn" class="btn btn-radius btn-agediss btn-block text-truncate shadow"
              (click)="selectEditData(this.currentUser.id, this.currentUser.username)">{{'Modifier les informations' | translate }}
      </button>
    </div>
    <div class="offset-sm-0 col-sm-5 offset-2 col-8 p-5">
      <button *ngIf="showViewEditPasswordBtn" class="btn btn-radius btn-agediss btn-block text-truncate shadow"
              (click)="selectEditPassword(this.currentUser.id, this.currentUser.username)">{{'Modifier le mot de passe' | translate }}
      </button>
    </div>
  </div>
</div>
