<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       matSortActive="created_at" matSortDirection="desc"
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>N°</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      <a [routerLink]="['/','contestations',element.id]" class="btn btn-icon btn-info">
        {{element?.id}}
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="numero_avis">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Bon de livraison' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.litige?.numero_avis}} </td>
  </ng-container>
  <ng-container matColumnDef="numero_commande">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Commande' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.litige?.numero_commande}} </td>
  </ng-container>
  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Date' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.created_at  | date:'dd-MM-yyyy'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataire_nom">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Destinataire' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.litige?.destinataire_nom | titlecase}} </td>
  </ng-container>
  <ng-container matColumnDef="client">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Client' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.litige?.client?.libelle | titlecase}} </td>
  </ng-container>
  <ng-container matColumnDef="statut">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Statut' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle text-white">
      <ng-template #statutContestation>
        <div class="p-0">
          <span *ngIf="element?.statut?.code === 'CON_0'">{{'Contestation à traiter par Agediss' | translate }}</span>
          <span *ngIf="element?.statut?.code === 'CON_1'">{{'Contestation acceptée par Agediss' | translate }}</span>
          <span *ngIf="element?.statut?.code === 'CON_2'">{{'Contestation refusée par Agediss' | translate }}</span>
        </div>
      </ng-template>
      <div placement="left"
           container="body"
           [ngbPopover]="statutContestation" triggers="mouseenter:mouseleave"
           class="label {{ element?.statut.libelle | statutLitigePipe: element?.statut.code}}">
        {{element?.statut?.libelle | titlecase }}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="traite_par">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Traité par' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{ element?.traite_par?.fullname | titlecase}} </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef class="text-center align-middle">
      <button *ngIf="showAddButton" class="btn btn-agediss btn-sm"
              placement="left"
              container="body"
              ngbTooltip="{{'Nouvelle contestation' | translate }}"
              (click)="selectAddContestation()">
        <i class="fa fa-plus"></i>
      </button>
    </th>
    <td class="text-center align-middle" mat-cell *matCellDef="let element">
      <button *ngIf="showViewBtn" ngbTooltip="{{'Visualisation de la contestation' | translate }}"
              placement="left"
              container="body"
              class="btn btn-sm btn-agediss"
              [routerLink]="['/','contestations',element.id]">
        <i class="fa fa-eye"></i>
      </button>
      <button *ngIf="showInfosBtn && this.innerWidth < 1150"
              ngbTooltip="{{'Informations complémentaires' | translate }}"
              placement="left"
              container="body"
              class="btn btn-sm btn-agediss ml-1"
              (click)="expandedElement = expandedElement === element ? null : element">
        <i class="fas fa-angle-double-down"></i>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Aucune contestation' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">

      <div class="table-detail"
           *ngIf="this.innerWidth < 511"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Commande' | translate }} : </strong><span>{{element?.litige?.numero_commande}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="(this.innerWidth < 790 && this.innerWidth > 767) || this.innerWidth < 600"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Destinataire' | translate }} : </strong><span>{{element?.litige?.destinataire_nom | titlecase}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1040"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date' | translate }} : </strong><span>{{element?.created_at  | date:'dd-MM-yyyy'}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth <= 1050"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Client' | translate }} : </strong><span>{{element?.litige?.client?.libelle | titlecase}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 400"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Statut' | translate }} : </strong>
          <ng-template #statutContestation>
            <div class="p-0">
              <span *ngIf="element?.statut?.code === 'CON_0'">{{'Contestation à traiter par Agediss' | translate }}</span>
              <span *ngIf="element?.statut?.code === 'CON_1'">{{'Contestation acceptée par Agediss' | translate }}</span>
              <span *ngIf="element?.statut?.code === 'CON_2'">{{'Contestation refusée par Agediss' | translate }}</span>
            </div>
          </ng-template>
          <div placement="top-left"
               container="body"
               [ngbPopover]="statutContestation" triggers="mouseenter:mouseleave"
               class="label {{ element?.statut.libelle | statutLitigePipe: element?.statut.code}}">
            {{element?.statut?.libelle | titlecase }}
          </div>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth <= 1200"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Traité par' | translate }} : </strong><span>{{element?.traite_par?.fullname | titlecase}}</span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>

<mat-paginator [length]="length"  [pageIndex]="pageIndex" (page)="emit($event)" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
               showFirstLastButtons></mat-paginator>
