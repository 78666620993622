import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FieldParametersModel} from '../../../../shared/models/filters/field-parameters.model';
import {FilterMultiCritereModel} from '../../../../shared/models/filters/filterMultiCritere.model';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {FormfilterHelper} from '../../../../shared/helpers/Formfilter.helper';
import {Client} from '../../../../shared/models/api/client.model';
import {StatutService} from '../../../../shared/services/api/statut.service';
import {StatutGrc} from '../../../../shared/models/api/statut-grc.model';
import {DatePickerMinDateComponent} from '../../../shared/date-picker/date-picker-min-date/date-picker-min-date.component';
import {DatePickerMaxDateComponent} from '../../../shared/date-picker/date-picker-max-date/date-picker-max-date.component';
import {FilterService} from '../../../../shared/services/filter.service';
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {SousCompteSsoService} from "../../../../shared/services/api/sso/sous-compte-sso.service";

@Component({
  selector: 'app-filter-grc',
  templateUrl: './filter-grc.component.html',
  styleUrls: ['./filter-grc.component.scss']
})
export class FilterGrcComponent implements OnInit, OnDestroy {

  @ViewChild('inputMinDate') inputMinDate: DatePickerMinDateComponent;
  @ViewChild('inputMaxDate') inputMaxDate: DatePickerMaxDateComponent;

  @Input() displayInput: FieldParametersModel[] = [];

  @Output() submitFilter = new EventEmitter<FilterMultiCritereModel>();
  @Output() onLoad = new EventEmitter<FilterMultiCritereModel>();

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public hiddenInput = [];
  public statuts: StatutGrc[];
  public statut: StatutGrc;
  public clients: Client[];
  public client: Client;
  public subscriptions: Subscription[] = [];

  public filterForm = new FormGroup({
    client: new FormControl(),
    statut: new FormControl(),
    minDate: new FormControl(),
    maxDate: new FormControl(),
    createdAt: new FormControl(),
    search: new FormControl()
  });

  public loadStatut: boolean = false;
  public loadClient: boolean = false;
  public minDate;
  public maxDate;

  constructor(private translate: TranslateService,
              private statutSvc: StatutService,
              private clientSvc: SousCompteSsoService,
              private toastr: ToastrService,
              public filterSvc: FilterService) {
  }

  ngOnInit() {
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.loadClient = true;
      this.loadStatut = true;
      this.loadDataSousCompte();
      this.loadStatutData();
    }))
    this.onInitFilter();
  }

  onInitFilter() {
    this.loadClient = true;
    this.loadStatut = true;
    FormfilterHelper.hydrateForm(this.displayInput, this.filterForm);
    this.hiddenInput = FormfilterHelper.extractHiddenInput(this.displayInput);
    this.loadDataSousCompte();
    this.loadStatutData();
    this.getValueLocalStorage();
    this.onLoad.emit(this.filterForm.getRawValue());
  }

  /**
   * Chargement des sous-comptes
   */
  loadDataSousCompte() {
    this.subscriptions.push(this.clientSvc.getSousComptes().subscribe(value => {
        this.clients = value;
        this.loadClient = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des sous-comptes n'est pas disponible", {application: this.translate.instant('GRC')}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadClient = false;
      }
    ));
  }

  /**
   * Chargement des statuts
   */
  loadStatutData() {
    this.subscriptions.push(this.statutSvc.getStatutsGrc().subscribe(value => {
      this.statuts = value;
      this.loadStatut = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des statuts n'est pas disponible", { application: this.translate.instant('GRC')}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadStatut = false;
      }
    ));
  }

  /**
   * On récupère les valeurs du localstorage
   */
  getValueLocalStorage() {
    this.filterForm.get('search').setValue(localStorage.getItem('grcSearchFilter'));
    this.filterSvc.onCheckValueLocalStorage(this.filterForm,'grcClientFilter', 'client');
    this.filterSvc.onCheckValueLocalStorage(this.filterForm,'grcStatutFilter', 'statut');
    this.filterSvc.convertDateLocalstorageForUrl(this.filterForm,  'minDate', 'grcMinDateFilter');
    this.filterSvc.convertDateLocalstorageForUrl(this.filterForm, 'maxDate', 'grcMaxDateFilter');
    this.onDateLocalstorage('grcMinDateFilter', 'grcMaxDateFilter');
  }

  /**
   *   on affiche la date enregistrée dans le localstorage, dans l'input
   */
  onDateLocalstorage(minDateFilterName: string, maxDateFilterName: string) {
    if (localStorage.getItem(minDateFilterName) != null && localStorage.getItem(maxDateFilterName) == null) {
      if (!this.minDate && !this.maxDate) {
        this.minDate = this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem(minDateFilterName));
        this.maxDate = null;
      }
    } else if (localStorage.getItem(minDateFilterName) != null && localStorage.getItem(maxDateFilterName) != null) {
      if (!this.minDate && !this.maxDate) {
        this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem(minDateFilterName));
        this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem(maxDateFilterName));
      }
    }
  }

  onSubmit() {
    //récupère les infos du filtre;
    if (this.inputMinDate.date == null) {
      this.filterForm.get('minDate').reset();
    }
    if (this.inputMaxDate.date == null) {
      this.filterForm.get('maxDate').reset();
    }
    this.filterSvc.onSaveInLocalstorage(this.filterForm,'grcSearchFilter', 'search');
    this.filterSvc.onSaveInLocalstorage(this.filterForm,'grcClientFilter', 'client');
    this.filterSvc.onSaveInLocalstorage(this.filterForm,'grcStatutFilter', 'statut');
    this.filterSvc.onSaveInLocalstorage(this.filterForm,'grcMinDateFilter', 'minDate');
    this.filterSvc.onSaveInLocalstorage(this.filterForm,'grcMaxDateFilter', 'maxDate');
    this.submitFilter.emit(this.filterForm.getRawValue());
  }

  public onSelectAllStatut() {
    const selected = this.statuts.map(item => item.code);
    this.filterForm.get('statut').patchValue(selected);
  }

  reset(): void {
    this.filterForm.reset();
    this.inputMinDate.reset();
    this.inputMaxDate.reset();
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
