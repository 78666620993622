import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Facture} from "../../models/api/facture.model";
import {HttpUtil} from '../../utils/http.util';
import {FactureLitige} from "../../models/api/facture-litige.models";
import {FactureData} from '../../models/api/facture-data.model';
import {PieceJointe} from "../../models/api/piece-jointe.model";

@Injectable({
  providedIn: 'root'
})
export class  FactureService {

  urlRessource = environment.url_api_espace_client + "/factures";

  constructor(private http: HttpClient) {}

  getFactures(filter: any = null): Observable<Facture> {
    return this.http
      .get<Facture[]>(`${this.urlRessource}`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((facture) => {
        return new Facture().deserialize(facture);
      }));
  }


  getFacture(factureId: number): Observable<FactureData> {
    return this.http
      .get<FactureData>(`${this.urlRessource}/${factureId}`)
      .pipe(map((facture) => {
        return new FactureData().deserialize(facture);
      }));
  }

  getPieceJointeComptable(filter: any = null): Observable<PieceJointe> {
    return this.http
        .get<PieceJointe[]>(`${this.urlRessource}-comptable`)
        .pipe(map((pieceJointe) => {
          return new PieceJointe().deserialize(pieceJointe);
        }));
  }

}
