<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="modal-header">
    <h4 class="modal-title  w-100 text-center">Paramètres des notifications Grc
    </h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="editAllNotificationForm">
      <div class="row m-0 pt-2">
        <div class="col-md-12 px-0 px-lg-3">
          <p class="text-truncate p-0">
            <strong>{{'Email' | translate }} :</strong>
            <span> {{email | lowercase}}</span>
          </p>
        </div>
      </div>
      <div class="row m-0 pt-2">
        <div class="col-md-12 px-0 px-lg-3">
          <div class="col-12 p-0">
            <label class="d-block">{{"Réponse GRC d'Agediss" | translate }}
              <div class="input-group">
                <ng-select class="ng-select filter"
                           [ngClass]="{'is-invalid':editAllNotificationForm.get('activerDemande').invalid && (editAllNotificationForm.get('activerDemande').dirty || formSubmit) }"
                           [items]="statuts"
                           [selectOnTab]="true"
                           bindValue="code"
                           bindLabel="libelle"
                           [clearable]="true"
                           placeholder="{{'Choisir' | translate }}"
                           formControlName="activerDemande">
                </ng-select>
                <div
                  *ngIf="editAllNotificationForm.get('activerDemande').invalid && (editAllNotificationForm.get('activerDemande').dirty || formSubmit)"
                  class="invalid-feedback">
                  {{'Veuillez sélectionner un paramétrage global !' | translate }}
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="row m-0 pt-2">
        <div class="col-md-12 px-0 px-lg-3">
          <div class="col-12 p-0">
            <label class="d-block">{{"Demande GRC d'Agediss" | translate }}
              <div class="input-group">
                <ng-select class="ng-select filter"
                           [ngClass]="{'is-invalid':editAllNotificationForm.get('activerMessage').invalid && (editAllNotificationForm.get('activerMessage').dirty || formSubmit) }"
                           [items]="statuts"
                           [selectOnTab]="true"
                           bindValue="code"
                           bindLabel="libelle"
                           [clearable]="true"
                           placeholder="{{'Choisir' | translate }}"
                           formControlName="activerMessage">
                </ng-select>
                <div
                  *ngIf="editAllNotificationForm.get('activerMessage').invalid && (editAllNotificationForm.get('activerMessage').dirty || formSubmit)"
                  class="invalid-feedback">
                  {{'Veuillez sélectionner un paramétrage global !' | translate }}
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="row m-0">
    <div class="col-12 modal-footer text-center">
      <button [ladda]="loadingBtn" data-style="zoom-in" class="btn btn-agediss" (click)="updateFormParamNotification()">
        {{'Enregistrer' | translate }}
      </button>
      <button class="btn bg-black-lighter text-white m-r-10" (click)="cancel()">{{'Fermer' | translate }}</button>
    </div>
  </div>
</div>
