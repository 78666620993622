import * as XLSX from 'xlsx';

const EXCEL_EXTENSION = '.xlsx';

export class TableUtil {
  static exportTableToExcel(tableId: string, name?: string) {
    const targetTableElm = document.getElementById(tableId);
    const wb = XLSX.utils.table_to_book(targetTableElm, {
      sheet: name
    } as XLSX.Table2SheetOpts);
    XLSX.writeFile(wb, name + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  static exportArrayToExcel(arr: any[], name?: string) {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(arr);
    XLSX.utils.book_append_sheet(wb, ws, name);
    XLSX.writeFile(wb, name + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
