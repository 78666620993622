<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="modal-header bg-modal">
    <h4 class="modal-title  w-100 text-center">{{"Historique des colis" | translate }} n° {{evenement.id}}</h4>
  </div>
  <div class="modal-body bg-modal">
    <app-table-evenement-colis [data]="evenement.colis"
                               [showViewBtn]="view.hasDroit('COMMANDE_PIECE-JOINTE_SHOW')"
                               (onSelectFile)="openModalVisualisationFile(modal_visualisation_file, $event)"></app-table-evenement-colis>
  </div>
</div>
<div class="modal-footer p-5">
  <button class="btn bg-black-lighter text-white m-r-10" (click)="cancel()">{{'Fermer' | translate }}</button>
</div>

<ng-template #modal_visualisation_file let-modal>
  <app-file-preview-ged  [piecesJointesGed]="evenementColis.doc" [doc]="true" (canceled)="modal.close($event)"></app-file-preview-ged>
</ng-template>
