import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Deserializable} from '../interfaces/deserializable.interface';
import {Client} from './client.model';
import {User} from '../user.model';
import {Statut} from './statut.model';
import {Flux} from './flux.model';
import {MotifDetail} from './motif-detail.model';
import {Origine} from './origine.model';
import {Responsable} from './responsable.model';
import {InstructionRetour} from './instruction-retour.model';
import {TypeApproche} from './type-approche.model';
import {Plateforme} from './plateforme.model';
import {FactureLitige} from './facture-litige.models';
import {FactureData} from "./facture-data.model";
import {CommandeData} from "./commande-data.model";
import {UserData} from "../user-data.model";

export class LitigeData implements Deserializable {

  public id?: number;
  public commande_data: CommandeData;
  public client?: Client;
  public plateforme?: Plateforme;
  public traite_par?: UserData;
  public statut?: Statut;
  public flux?: Flux;
  public motif_detail?: MotifDetail;
  public origine?: Origine;
  public type_approche?: TypeApproche;
  public facture_data?: FactureData[];
  public factureLitige?: FactureLitige;
  public numero_avis: number;
  public numero_commande?: string;
  public destinataire_nom?: string;
  public destinataire_adresse?: string;
  public destinataire_code_postal?: string;
  public destinataire_ville?: string;
  public destinataire_tel_fixe?: string;
  public destinataire_tel_portable?: string;
  public volume_total?: number;
  public poidsTotal?: number;
  public date_rdv?: Date | NgbDateStruct;
  public entite?: number;
  public note?: string;
  public created_at?: string | Date;
  public is_reparable: boolean = false;
  public montant_reparation: number ;
  public is_dedomagement: boolean = false;
  public montant_dedomagement: number;
  public has_assurance: boolean = false;
  public montant_assurance: number;
  public montant_reclamation: number;
  public montant_valide: number;
  public montant_limit_responsabilite: number;
  public date_litige: string | Date;
  public commentaire_statut: string;
  public instruction_retour?: InstructionRetour;
  public statut_frais_transport?: Statut;
  public statut_facturation?: Statut;
  public montant_facture: number;
  public detail_ligne: string;
  public acteurs: Responsable[];
  public date_prescrit: string | Date;


  deserialize(input: any): this {
    Object.assign(this, input);
    this.client = (input.client) ? new Client().deserialize(input.client) : null;
    this.traite_par = (input.traite_par) ? new UserData().deserialize(input.traite_par) : null;
    this.flux = (input.flux) ? new Flux().deserialize(input.flux) : null;
    this.statut = (input.statut) ? new Statut().deserialize(input.statut) : null;
    this.factureLitige = (input.factureLitige) ? new FactureLitige().deserialize(input.factureLitige) : null;
    this.origine = (input.origine) ? new Origine().deserialize(input.origine) : null;
    this.type_approche = (input.type_approche) ? new TypeApproche().deserialize(input.type_approche) : null;
    this.motif_detail = (input.motif_detail) ? new MotifDetail().deserialize(input.motif_detail) : null;
    this.instruction_retour = (input.instruction_retour) ? new InstructionRetour().deserialize(input.instruction_retour): null;

    return this;
  }
}
