import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'activiteRetourPipe'})
export class ActiviteRetourPipe implements PipeTransform {
  transform(value: string, exponent?: number): string {
    switch(value) {
      case 'RVK':
        return 'RVK';
      case 'RVL':
        return 'RVL';
      case 'RVR':
        return 'RVR';
      case 'RVS':
        return 'RVS';
      case 'RVF':
        return 'RVF';
      case 'RVT':
        return 'RVT';
      case 'RVM':
        return 'RVM';
    }
  }
}

@Pipe({name: 'activiteReprisePipe'})
export class ActiviteReprisePipe implements PipeTransform {
  transform(value: string, exponent?: number): string {
    switch(value) {
      case 'RE':
        return 'RE';
    }
  }
}

@Pipe({name: 'activiteLivraisonPipe'})
export class ActiviteLivraisonPipe implements PipeTransform {
  transform(value: string, exponent?: number): string {
    switch(value) {
      case 'LI':
        return 'LI';
      case 'LV1':
        return 'LV1';
      case 'PAG':
        return 'PAG';
      case 'L1E':
        return 'L1E';
      case 'L2E':
        return 'L2E';
      case 'L2P':
        return 'L2P';
      case 'LPR':
        return 'LPR';
    }
  }
}
