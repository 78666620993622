<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">
  <div class="row m-0">
    <div class="col-md-6 col-sm-6 col-12 p-10">
      <p class="text-truncate">
      <strong placement="right"
              container="body"
              ngbTooltip="{{contestationData.statut.libelle}}">{{'Statut' | translate }} :</strong>
        <span> {{contestationData.statut.libelle}}</span>
      </p>
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{contestationData.traite_par?.getFullname()}}">{{'Traité par' | translate }} :</strong>
        <span> {{contestationData.traite_par?.getFullname()}}</span>
      </p>
    </div>
    <div class="offset-md-0 col-md-6 offset-sm-0 col-sm-6 offset-2 col-8 p-10">
      <a *ngIf="showViewLitigeBtn" class="btn btn-radius btn-agediss btn-block text-truncate shadow mt-2"
         [routerLink]="['/','litiges',contestationData.litige.id]">{{'Visualisation du litige' | translate }}
      </a>
    </div>
  </div>
  <div class="p-10" *ngIf="contestationData.commentaire_statut">
      <p>
        <strong>Message : </strong>
        {{contestationData.commentaire_statut}}
      </p>
  </div>
</div>
