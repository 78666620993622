import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Article} from '../../models/api/article.model';
import {map} from 'rxjs/operators';
import {Prestation} from '../../models/api/prestation.model';
import {Evenement} from '../../models/api/evenement.model';
import {HttpUtil} from '../../utils/http.util';
import {Timeline} from '../../models/api/timeline.model';
import {Litige} from '../../models/api/litige.model';
import {SignatureLivraison} from '../../models/api/ged/signature-livraison.model';
import {Grc} from '../../models/api/grc.model';
import {Commande} from "../../models/api/commande.model";
import {PieceJointeGed} from "../../models/api/ged/piece-jointe-ged.model";
import {SuiviLivraisonData} from "../../models/api/suivi-livraison-data.model";
import {CommandeData} from "../../models/api/commande-data.model";

@Injectable({
  providedIn: 'root'
})
export class CommandeService {

  urlRessource = environment.url_api_espace_client + '/commandes';
  urlRessourceGrc = environment.url_api_espace_client + '/demandes-grc';

  constructor(private http: HttpClient) {
  }

  getLivraison(id: string): Observable<CommandeData> {
    return this.http
      .get<CommandeData>(`${this.urlRessource}/${id}`)
      .pipe(map((commande) => {
        return new CommandeData().deserialize(commande);
      }));
  }

  getLivraisons(filter: any = null): Observable<Commande> {
    return this.http
      .get<Commande>(`${this.urlRessource}`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((commande) => {
        return new Commande().deserialize(commande);
      }));
  }

  // getLivraisonsTodayDelivery(filter: any = null): Observable<SuiviLivraison> {
  //   return this.http
  //     .get<SuiviLivraison>(`${this.urlRessource}/livraison`, {params: HttpUtil.convertObjectToHttpParams(filter)})
  //     .pipe(map((commande) => {
  //       return new SuiviLivraison().deserialize(commande);
  //     }));
  // }

  getLivraisonsTodayDelivery(filter: any = null): Observable<SuiviLivraisonData[]> {
    return this.http
      .get<SuiviLivraisonData[]>(`${this.urlRessource}/livraison`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((commandes) => commandes.map((commande) => {
        return new SuiviLivraisonData().deserialize(commande);
      })));
  }

  getArticles(numeroAvis: string): Observable<Article[]> {
    return this.http
      .get<Article[]>(`${this.urlRessource}/${numeroAvis}/articles`)
      .pipe(map((articles) => articles.map((article) => {
        return new Article().deserialize(article);
      })));
  }

  getPrestations(numeroAvis: string): Observable<Prestation[]> {
    return this.http
      .get<Prestation[]>(`${this.urlRessource}/${numeroAvis}/prestations`)
      .pipe(map((prestations) => prestations.map((prestation) => {
        return new Prestation().deserialize(prestation);
      })));
  }

  getPieceJointes(commandeId: string): Observable<PieceJointeGed[]> {
    return this.http
      .get<PieceJointeGed[]>(`${this.urlRessource}/${commandeId}/piece-jointes`)
      .pipe(map((pieceJointes) => pieceJointes.map((pieceJointes) => {
        return new PieceJointeGed().deserialize(pieceJointes);
      })));
  }

  getPieceJointesByEvent(numeroAvis: string, eventId: number): Observable<PieceJointeGed[]> {
    return this.http
      .get<PieceJointeGed[]>(`${this.urlRessource}/${numeroAvis}/piece-jointes-evenements/${eventId}`)
      .pipe(map((pieceJointes) => pieceJointes.map((pieceJointes) => {
        return new PieceJointeGed().deserialize(pieceJointes);
      })));
  }

  getEvenementRdv(numeroAvis: string): Observable<Evenement> {
    return this.http
      .get<Evenement>(`${this.urlRessource}/${numeroAvis}/evenement-rdv`)
      .pipe(map((evenement) => {
        return new Evenement().deserialize(evenement);
      }));
  }

  getEvenements(numeroAvis: string): Observable<Evenement[]> {
    return this.http
      .get<Evenement[]>(`${this.urlRessource}/${numeroAvis}/evenements`)
      .pipe(map((evenements) => evenements.map((evenement) => {
        return new Evenement().deserialize(evenement);
      })));
  }

  getTimeline(numeroAvis: string): Observable<Timeline[]> {
    return this.http
      .get<Timeline[]>(`${this.urlRessource}/${numeroAvis}/statuts`)
      .pipe(map((timelines) => timelines.map((timeline) => {
        return new Timeline().deserialize(timeline);
      })));
  }

  getLitigesByNumeroAvis(filter: any = null, numeroAvis: string): Observable<Litige> {
    return this.http
      .get<Litige[]>(`${this.urlRessource}/numeroAvis/${numeroAvis}`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((litige) => {
        return new Litige().deserialize(litige);
      }));
  }

  getSignatureLivraison(numeroAvis: string, filter: any = null): Observable<SignatureLivraison> {
    return this.http
      .get<SignatureLivraison>(`${this.urlRessource}/${numeroAvis}/signature-livraison`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((signatureLivraison) => {
        return new SignatureLivraison().deserialize(signatureLivraison);
      }));
  }

  getDemandesGrcByNumeroAvis(filter: any = null, numeroAvis: string): Observable<Grc> {
    return this.http
      .get<Grc>(`${this.urlRessourceGrc}/${numeroAvis}/demandes-grc`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((grc) => {
        return new Grc().deserialize(grc);
      }));
  }

}
