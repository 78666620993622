import {Deserializable} from '../interfaces/deserializable.interface';
import {Commande} from "./commande.model";

export class Timeline implements Deserializable {

  public id?: number;
  public commande: Commande;
  public libelle?: string;
  public plateforme_code?: string;
  public type?: string;
  public flux?: string;
  public etat?: string;
  public dernier_evenement?: string;
  public latitude?: string;
  public longitude?: string;
  public date_evenement?: Date | string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
