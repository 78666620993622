import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {Client} from '../../../shared/models/api/client.model';
import {Facture} from '../../../shared/models/api/facture.model';
import {PieceJointe} from '../../../shared/models/api/piece-jointe.model';
import {FactureService} from '../../../shared/services/api/facture.service';
import {PieceJointeService} from '../../../shared/services/api/piece-jointe.service';
import {View} from '../../../shared/models/view.model';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {PageEvent} from '@angular/material/paginator';
import {PaginationService} from '../../../shared/services/pagination.service';
import {FactureData} from '../../../shared/models/api/facture-data.model';
import {PieceJointeData} from '../../../shared/models/api/piece-jointe-data.model';
import {PieceJointeGed} from '../../../shared/models/api/ged/piece-jointe-ged.model';

@Component({
  selector: 'app-mes-factures-page',
  templateUrl: './mes-factures.html',
  styleUrls: ['./mes-factures.scss']
})
export class MesFacturesPage implements OnInit, OnDestroy {

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  public client: Client;
  public facture: FactureData;
  public pieceJointeData: PieceJointeData;
  public piecesJointesGed: PieceJointeGed[];
  public activModal: NgbModalRef;
  public view: View;

  // Tableau
  public factures: Facture[];
  public factureData: FactureData[];
  public pieceJointesData: PieceJointeData[];
  public subscriptions: Subscription[] = [];
  public pieceJointes: PieceJointe[];
  public pageSizeOption = [5, 10, 20];

  // number
  public pageSize = 5;
  public nbData = 0;
  public nbPieceJointes = 0;
  public selectFacture: number = null;

  // loading
  public loadingPage = false;
  public loadingNbData = false;
  public loadingNbPieceJointeData = false;
  public loadingTableFacture = false;
  public loadingTableFactureAttente = false;
  public loadingFile = false;
  public showImage: boolean;
  public urlPreview: string;
  public currentType: number =  null;
  public availableFormat = [
    'image/png',
    'image/jpeg',
    'application/pdf'
  ];

  constructor(private translate: TranslateService,
              private factureSvc: FactureService,
              private modalSvc: NgbModal,
              private pieceJointeSvc: PieceJointeService,
              private paginationSvc: PaginationService,
              private authSvc: AuthenticationService) {
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize);
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {
    if (this.view.hasDroit('LITIGE_MENU_FACTURE')) {
      this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
        this.loadDataFactures();
        this.loadDataFile();
      }));
      this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
      this.loadDataFactures();
      this.loadDataFile();
    }
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize);

    this.loadDataFactures();
    this.loadDataFile();
  }

  /**
   * On récupère toutes les factures liès au compte client
   */
  loadDataFactures() {
    this.loadingTableFacture = true;
    this.loadingNbData = true;
    this.factureData = [];
    this.nbData = 0;
    this.subscriptions.push(this.factureSvc.getFactures(this.filter).subscribe((facture) => {
      this.factureData = facture.data;
      this.nbData = facture.nb;
      this.loadingTableFacture = false;
      this.loadingNbData = false;
      },
      () => {
        this.loadingTableFacture = false;
        this.loadingNbData = false;
      }
    ));
  }

  onLoadDataByEvent(event: PageEvent) {
    this.filter.limit = event.pageSize;
    this.filter.offset = event.pageIndex ? event.pageIndex * event.pageSize : 0;
    this.loadDataFactures();
  }

  openModalShowFacture(modalName, facture: FactureData) {
    this.facture = facture;
    this.selectFacture = this.facture.id;
    this.pieceJointeData = this.facture.pieceJointe;
    this.activModal = this.modalSvc.open(modalName, {
      size: 'xl', keyboard: false
    });
    this.showPieceJointe(this.pieceJointeData.id);
  }

  loadDataFile() {
    this.loadingTableFactureAttente = true;
    this.loadingNbPieceJointeData = true;
    this.pieceJointesData = [];
    this.nbPieceJointes = 0;
    this.subscriptions.push(this.factureSvc.getPieceJointeComptable().subscribe(value => {
      this.pieceJointesData = value.data;
      this.nbPieceJointes = value.nb;
      this.loadingTableFactureAttente = false;
      this.loadingNbPieceJointeData = false;
      },
      () => {
        this.loadingTableFactureAttente = false;
        this.loadingNbPieceJointeData = false;
      }
    ));
  }

  openModalVisualisationFile(modal, value: { id: number, index: number, filename: string, path: string }) {
    this.pieceJointeData = new PieceJointeData().deserialize({id: value.id, libelle: value.filename, path: value.path});
    this.activModal = this.modalSvc.open(modal, {
      size: 'xl',
    });
    this.showPieceJointe(value.id);
  }

  showPieceJointe(pieceJointeId) {
    this.loadingFile = true;
    if (this.pieceJointeData.path.startsWith('../var/')) {
      const sub1 = this.pieceJointeSvc.getFilePreview(pieceJointeId).subscribe(
        (urlPreview) => {
          this.currentType =  this.availableFormat.indexOf(urlPreview.type);
          if (this.availableFormat.indexOf(urlPreview.type) >= 0) {
            this.showImage = true;
          }
          this.urlPreview = window.URL.createObjectURL(urlPreview);
          this.loadingFile = false;
          sub1.unsubscribe();
        },
        () => {
          Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('Une erreur est survenue'),
            showCancelButton: false}
          ).then(() => {
            this.activModal.close();
            this.loadingFile = false;
            sub1.unsubscribe();
          });
        }
      );
    } else {
      const sub = this.pieceJointeSvc.getFilePreviewGed(pieceJointeId).subscribe(
        (piecesJointesGed) => {
          this.piecesJointesGed = piecesJointesGed;
          this.loadingFile = false;
          sub.unsubscribe();
        },
        () => {
          Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('Une erreur est survenue'),
            showCancelButton: false}
          ).then(() => {
            this.activModal.close();
            this.loadingFile = false;
            sub.unsubscribe();
          });

        }
      );
    }
  }

  onSubmit(filter = null) {
    // récupère les infos du filtre
    if (filter) {
      this.filter = filter;
    }
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize);
    this.loadDataFactures();
  }

  onLoadFilter(filter = null) {
    // récupère les infos du filtre
    if (filter) {
      this.filter = {...filter, ...this.filter};
    }
    this.loadDataFactures();
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
