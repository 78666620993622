<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="modal-header bg-modal">
    <h4 class="modal-title  w-100 text-center">{{'Preuve de livraison' | translate }}</h4>
  </div>
  <div class="modal-body bg-modal">
    <div class="row m-0 py-3">
      <div class="col-12 col-lg-6 my-2">
        <panel variants="danger" title="{{'Informations' | translate }}"
               noButton="true" bodyClass="no-padding" class="text-center">
          <div class="text-left">
            <div class="text-center py-3" *ngIf="signatureLivraison.numero_avis === null" style="min-height: 121px">
              {{'Aucune information' | translate }}
            </div>
            <div class="py-3" *ngIf="signatureLivraison.numero_avis !== null" style="min-height: 121px">
              <p class="text-truncate">
                <strong placement="right"
                        container="body"
                        ngbTooltip="{{signatureLivraison.numero_avis}}">{{'Bon de livraison' | translate }}
                  :</strong>
                <span> {{signatureLivraison.numero_avis}}</span>
              </p>
              <p class="text-truncate">
                <strong placement="right"
                        container="body"
                        ngbTooltip="{{signatureLivraison.date | date :'dd-MM-yyyy HH:mm:ss'}}">{{'Date' | translate }}
                  :</strong>
                <span> {{signatureLivraison.date | date :'dd-MM-yyyy HH:mm:ss'}}</span>
              </p>
              <p class="text-truncate">
                <strong placement="right"
                        container="body"
                        ngbTooltip="{{signatureLivraison.creneau_debut}}">{{'Début de créneau' | translate }}
                  :</strong>
                <span> {{signatureLivraison.creneau_debut}}</span>
              </p>
              <p class="text-truncate">
                <strong placement="right"
                        container="body"
                        ngbTooltip="{{signatureLivraison.creneau_fin}}">{{'Fin de créneau' | translate }}
                  :</strong>
                <span> {{signatureLivraison.creneau_fin}}</span>
              </p>
            </div>
          </div>
        </panel>
      </div>
      <div class="col-12 col-lg-6 my-2">
        <panel variants="danger"
               title="{{'Documents' | translate }}" noButton="true"
               bodyClass="no-padding" class="text-center">
          <div class="offset-2 col-8 py-3" style="min-height: 121px">
            <div class="pb-2" *ngIf="signatureLivraison.bl_emarge == null && this.signatureLivraison.signature == null">
              {{'Aucun document' | translate }}
            </div>
            <div class="pb-2" *ngIf="signatureLivraison.bl_emarge == null && this.signatureLivraison.signature != null">
              {{'Aucun Bl émargé de disponible' | translate }}
            </div>
            <div class="pb-2" *ngIf="signatureLivraison.bl_emarge != null && this.signatureLivraison.signature == null">
              {{'Aucune signature de disponible' | translate }}
            </div>
            <button *ngIf="showViewBlBtn && signatureLivraison.bl_emarge != null" class="btn btn-radius btn-agediss btn-block text-truncate shadow"
                    placement="top"
                    container="body"
                    ngbTooltip="{{'Visualisation du Bl émargé' | translate }}"
                    (click)="openModalVisualisationBlEmarge(modal_visualisation_bl_emarge)">
              {{'Visualisation du Bl émargé' | translate }}
            </button>
            <button *ngIf="showViewBlBtn && signatureLivraison.signature != null" class="btn btn-radius btn-agediss btn-block text-truncate shadow"
                    placement="top"
                    container="body"
                    ngbTooltip="{{'Visualisation de la signature' | translate }}"
                    (click)="openModalVisualisationBlEmarge(modal_visualisation_signature)">
              {{'Visualisation de la signature' | translate }}
            </button>
          </div>
        </panel>
      </div>
    </div>
    <div class="row m-0 py-3">
      <div class="col-12">
        <panel variants="danger" title="{{'Localisation' | translate }}"
               noButton="true" bodyClass="no-padding" class="text-center">
          <div class="text-center py-3" *ngIf="signatureLivraison.latitude === null">
            {{'Aucune localisation' | translate }}
          </div>
          <div class="p-0" *ngIf="signatureLivraison.latitude !== null">
            <agm-map *ngIf="lng" [latitude]="lat" [zoom]="15" [longitude]="lng" [mapTypeId]="'roadmap'"
                     [styles]="styles">
              <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
            </agm-map>
          </div>
        </panel>
      </div>
    </div>
  </div>
  <div class="modal-footer p-5 bg-modal">
    <button class="btn bg-black-lighter text-white m-r-10" (click)="cancel()">{{'Fermer' | translate }}</button>
  </div>
</div>

<ng-template #modal_visualisation_bl_emarge let-modal>
  <app-file-preview-ged [imageGed]="signatureLivraison.bl_emarge" [documents]="true" [showBtnDownload]="view.hasDroit('COMMANDE_PIECE-JOINTE_DOWNLOAD')" [numeroClient]="numeroClient" [societe]="societe" [commandeId]="commandeId" [blEmarge]="true" (canceled)="modal.close($event)"></app-file-preview-ged>
</ng-template>

<ng-template #modal_visualisation_signature let-modal>
  <app-file-preview-ged [piecesJointesGed]="signatureLivraison.signature" [doc]="true" (canceled)="modal.close($event)"></app-file-preview-ged>
</ng-template>
