import {Deserializable} from '../interfaces/deserializable.interface';
import {Colis} from './colis.model';
import {Evenement} from './evenement.model';
import {Commande} from "./commande.model";

export class Article implements Deserializable {

  public id: number;
  public commande: Commande;
  public colis: Colis[];
  public evenements: Evenement[];
  public poids?: number;
  public volume?: number;
  public reference?: string;
  public created_at?: Date | string;
  public updated_at?: Date | string;
  public libelle?: string;
  public nb_colis?: number;
  public pending?: boolean;

  constructor() {
    this.pending = false;
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
