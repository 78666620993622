<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       matSortActive="id" matSortDirection="desc"
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="other">
    <th mat-header-cell *matHeaderCellDef style="width: 20px">
      <ng-template #legendPopover>
        <div class="p-0">
          <ul class="list-group list-group-flush p-0">
            <li class="list-group-item f-s-10 p-0">{{'Demande créée par' | translate }}</li>
            <li class="list-group-item f-s-10 p-0">
              <i class='fas fa-circle color-grc'></i> {{'Agediss' | translate }}
            </li>
            <li class="list-group-item f-s-10 p-0">
              <i class='fas fa-circle color-do'></i> {{view.libelle}}
            </li>
          </ul>
        </div>
      </ng-template>
      <span placement="top"
            container="body"
            [ngbPopover]="legendPopover" triggers="mouseenter:mouseleave">
        <i class="fas fa-question-circle cursor-pointer text-agediss"></i>
      </span>
    </th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      <i class="fas fa-circle" [style.color]="element.demandeur_origine === 'GRC' ? '#dc354580' : '#007bff80'"></i>
    </td>
  </ng-container>
<!--  <ng-container matColumnDef="derniere_reponse">-->
<!--    <th mat-header-cell *matHeaderCellDef style="width: 20px">-->
<!--      <ng-template #legendPopover>-->
<!--        <div class="p-0">-->
<!--          <ul class="list-group list-group-flush p-0">-->
<!--            <li class="list-group-item f-s-10 p-0">{{'Dernière réponse' | translate }}</li>-->
<!--            <li class="list-group-item f-s-10 p-0">-->
<!--              <i class='fas fa-circle color-grc'></i> {{'Agediss' | translate }}-->
<!--            </li>-->
<!--            <li class="list-group-item f-s-10 p-0">-->
<!--              <i class='fas fa-circle color-do'></i> {{view.libelle}}-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </ng-template>-->
<!--      <span placement="top"-->
<!--            container="body"-->
<!--            [ngbPopover]="legendPopover" triggers="mouseenter:mouseleave">-->
<!--        <i class="fas fa-question-circle cursor-pointer text-agediss"></i>-->
<!--      </span>-->
<!--    </th>-->
<!--    <td mat-cell *matCellDef="let element" class="align-middle">-->
<!--      <i class="fas fa-circle" [style.color]="(element.demandeur_origine === 'GRC' && element.sacodm.startsWith('R')) || (element.demandeur_origine !== 'GRC' && element.sacodm.startsWith('DM'))  ? '#007bff80' : '#dc354580'"></i>-->
<!--    </td>-->
<!--  </ng-container>-->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{'Id' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      <a [routerLink]="['/','gestion-relation-clients', element.id]" [queryParams]="{numeroAvis: element.numero_avis}"
         class="btn btn-icon btn-info">
        {{element?.id}}
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="numero_avis">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Bon de livraison' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      <a [routerLink]="['/','livraisons', element.numero_avis]" class="btn btn-icon btn-danger">
        {{element?.numero_avis}}
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="numero_commande">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 250px">
      {{'Commande' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" class="align-middle">
        {{element?.numero_commande}}
    </td>
  </ng-container>
  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Date' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.created_at}}
    </td>
  </ng-container>
  <ng-container matColumnDef="login">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Identifiant' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.login | titlecase }}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataire_nom">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Destinataire' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.destinataire_nom | titlecase }}
    </td>
  </ng-container>
  <ng-container matColumnDef="statut">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Statut' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      <ng-template #statutGrc>
        <div class="p-0">
          <span *ngIf="element?.libelle_statut === 'En Traitement'">{{'Agediss doit vous répondre' | translate }}</span>
          <span *ngIf="element?.libelle_statut === 'Clos'">{{'Vous pouvez archiver cette conversation' | translate }}</span>
          <span *ngIf="element?.libelle_statut === 'ClOS'">{{'Vous pouvez archiver cette conversation' | translate }}</span>
          <span *ngIf="element?.libelle_statut === 'Info'">{{'Agediss attend une réponse ou Agediss doit vous répondre' | translate }}</span>
          <span *ngIf="element?.libelle_statut === 'Archivé'">{{'La conversation est archivée' | translate }}</span>
          <span *ngIf="element?.libelle_statut === 'A traiter'">{{'Agediss attend une réponse' | translate }}</span>
        </div>
      </ng-template>
      <div placement="left"
           container="body"
           [ngbPopover]="statutGrc" triggers="mouseenter:mouseleave"
           class="label {{ element?.libelle_statut | statutGrcPipe}}">
        {{element?.libelle_statut | titlecase}}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="objet">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Objet' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle text-truncate" style="max-width: 180px">
      {{element?.objet}}
    </td>
  </ng-container>
  <ng-container matColumnDef="commentaire">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%">{{'Commentaire' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle text-truncate" style="max-width: 200px">
      {{element?.commentaire}}
    </td>
  </ng-container>
  <ng-container matColumnDef="updated_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Dernière maj' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.updated_at }}
    </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef></th>
    <td class="text-center align-middle" mat-cell *matCellDef="let element">
      <button *ngIf="showDeleteDemandGrcBtn && element.code_statut === 'ATTENTE_GRC' &&  element.code_maj === 'OK'"
              [ladda]="element.pending"
              data-style="zoom-in"
              ngbTooltip="{{'Supprimer la demande GRC' | translate }}"
              placement="left"
              container="body"
              class="btn btn-agediss btn-sm btn-rounded ml-1 mr-1"
              (click)="delete(element.id)">
        <i class="fa fa-trash"></i></button>
      <button *ngIf="showInfosBtn && this.innerWidth < 1300"
              ngbTooltip="{{'Informations complémentaires' | translate }}"
              placement="left"
              container="body"
              class="button btn btn-rounded-arrow btn-sm btn-agediss mr-1"
              (click)="expandedElement = expandedElement === element ? null : element">
        <i class="fas fa-angle-double-down"></i>
      </button>
    </td>
  </ng-container>


  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Aucune demande GRC' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">

      <div class="table-detail"
           *ngIf="this.innerWidth < 400"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Bon de livraison' | translate }} : </strong><span>{{element?.numero_avis }}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 500"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Commande' | translate }} : </strong><span>{{element?.numero_commande }}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 900"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Statut' | translate }} : </strong>
          <span>
            <ng-template #statutGrc>
              <div class="p-0">
                <span
                  *ngIf="element?.libelle_statut === 'En Traitement'">{{'Agediss doit vous répondre' | translate }}</span>
                <span *ngIf="element?.libelle_statut === 'Clos'">{{'Vous pouvez archiver cette conversation' | translate }}</span>
                <span *ngIf="element?.libelle_statut === 'ClOS'">{{'Vous pouvez archiver cette conversation' | translate }}</span>
                <span *ngIf="element?.libelle_statut === 'Info'">{{'Agediss attend une réponse ou Agediss doit vous répondre' | translate }}</span>
                <span *ngIf="element?.libelle_statut === 'Archivé'">{{'La conversation est archivée' | translate }}</span>
                <span *ngIf="element?.libelle_statut === 'A traiter'">{{'Agediss attend une réponse' | translate }}</span>
              </div>
            </ng-template>
            <div placement="top-left"
                 container="body"
                 [ngbPopover]="statutGrc" triggers="mouseenter:mouseleave"
                 class="label {{ element?.libelle_statut | statutGrcPipe}}">
              {{element?.libelle_statut  | titlecase }}
            </div>
          </span>
        </div>
      </div>

      <div class="table-detail"
           *ngIf="this.innerWidth < 1050"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Identifiant' | translate }} : </strong><span>{{element?.login | titlecase}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1300"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date' | translate }} : </strong><span>{{element?.created_at}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1450"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Destinataire' | translate }} : </strong><span>{{element?.destinataire_nom | titlecase }}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1700"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Objet' | translate }} : </strong><span>{{element?.objet }}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1850"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Dernière maj' | translate }} : </strong><span>{{element?.updated_at}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 2150"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Commentaire' | translate }} : </strong><span>{{element?.commentaire}}</span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']"
      [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>

<mat-paginator [length]="length"  [pageIndex]="pageIndex" (page)="emit($event)" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
               showFirstLastButtons></mat-paginator>

