<div *ngIf="view.hasDroit('LITIGE_MENU_NOUVELLE-FACTURE')">
  <div class="row m-0 padding">
    <h1 class="page-header m-auto text-center padding-h1">{{'Nouvelle facture' | translate }}</h1>
  </div>

  <div class="row m-0 padding-table" tourAnchor="tableau.nouvelle.facture">
    <div class="col-12 p-0">
      <panel noButton="true" title="{{'Nouvelle facture' | translate }}" bodyClass="panel no-padding">
        <div style="min-height: 250px; margin: 10px 0 0 0;">
          <app-file-uploader [showHeader]="false" [idCompteClient]="view.contextId" [isNouvelleFacture]="true"
                             [showReturnButton]="false"
                             (uploaded)="onUploaded()"></app-file-uploader>
        </div>
      </panel>
    </div>
  </div>
</div>
