import {Component, Input, OnInit} from '@angular/core';
import {Stats} from '../../../shared/models/api/stats.model';

@Component({
  selector: 'app-livraisons-stats',
  templateUrl: './livraisons-stats.component.html',
  styleUrls: ['./livraisons-stats.component.scss']
})
export class LivraisonsStatsComponent implements OnInit {

  @Input() stats: Stats;
  @Input() multi = [];
  @Input() loadingData: boolean = false;
  @Input() loadingCharts: boolean = false;

  view: any[];

  colorScheme = {
    domain: ['#ffffff']
  };

  // options for the chart
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showGridLines = false;
  showLegend = false;
  showXAxisLabel = true;
  showYAxisLabel = true;
  timeline = true;

  onSelect(event) {
    console.log(event);
  }

  constructor() { }

  ngOnInit() {

  }
}
