<table id="anomalies-table" mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="numero_avis">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">
      {{'BL' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle">
      <button (click)="openLivraison(modal_livraison, element.numero_avis, false)" class="btn btn-icon btn-info">
        {{element?.numero_avis}}
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="numero_commande">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Commande' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.numero_commande}}
    </td>
  </ng-container>
  <ng-container matColumnDef="numero_commande_2">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Commande bis' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.numero_commande_2}}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataire_nom">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Destinataire' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.destinataire_nom | titlecase }}
    </td>
  </ng-container>
  <ng-container matColumnDef="phone">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Téléphone' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.mobile || element?.phone | titlecase }}
    </td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Email' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.email | titlecase }}
    </td>
  </ng-container>
  <ng-container matColumnDef="activite">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Activité' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.nom_activite | translate | titlecase }}
    </td>
  </ng-container>
  <ng-container matColumnDef="date_maj">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Date' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.date_maj | date}}
    </td>
  </ng-container>
  <ng-container matColumnDef="events">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      <span placement="top"
            container="body"
            [ngbPopover]="" triggers="mouseenter:mouseleave"
            (click)="openModal(modal_events, element.numero_avis)">
        <i class="fas fa-question-circle cursor-pointer text-agediss"></i>
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="statut">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Statut' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      <div class="btn btn-icon statut" (click)="openModal(modal_grc, element.numero_avis)" [ngClass]="{
              'bg-orange' : element && element.statut === 'PLATEFORME',
              'bg-indigo' : element && element.statut === 'LIVRAISON',
              'bg-red' : element && element.statut === 'PBTELMAIL',
              'bg-grey-darker' : element && element.statut === 'ANNULATION',
              'bg-lime' : element && element.statut === 'HUB',
              'bg-yellow-darker' : element && element.statut === 'ABSENTCONSUMER'
           }">
        <span *ngIf="element.statut === 'HUB'">{{'Réception HUB' | translate}}</span>
        <span *ngIf="element.statut === 'PLATEFORME'">{{'Réception plateforme' | translate}}</span>
        <span *ngIf="element.statut === 'LIVRAISON'">{{'Livraison' | translate}}</span>
        <span *ngIf="element.statut === 'ANNULATION'">{{'Annulation' | translate}}</span>
        <span *ngIf="element.statut === 'PBTELMAIL'">{{'Adresse/Tel' | translate}}</span>
        <span *ngIf="element.statut === 'ABSENTCONSUMER'">{{'Consommateur absent' | translate}}</span>
      </div>
    </td>
  </ng-container>


  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef></th>
    <td class="text-center align-middle" mat-cell *matCellDef="let element">
    </td>
  </ng-container>
  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
        innerHTML="{{'Aucune livraison' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
        innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <tr mat-footer-row *matFooterRowDef="['noData']"
      [hidden]="(dataSource && dataSource.data.length > 0) || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
</table>

<!--modal commande -->
<ng-template #modal_livraison let-modal>
  <div class="modal-body modal-height text-center livraison-modal-dialog">
    <app-livraison-page [numeroAvis]="livraisonNumeroAvis" [startTabIndex]="startTabIndex"></app-livraison-page>
  </div>
</ng-template>

<!-- modal Nouvelle demande GRC -->
<ng-template #modal_grc let-modal>
  <app-nouvelle-demande [grcNumeroAvis]="livraisonNumeroAvis"  (submited)="modal.close($event)"
                        (canceled)="modal.close($event)"></app-nouvelle-demande>
</ng-template>

<!-- modal évènements commande -->
<ng-template #modal_events let-modal>
  <app-evenement-content [commandeNumeroAvis]="livraisonNumeroAvis" ></app-evenement-content>
</ng-template>
