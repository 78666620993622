import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'statutLitigePipe'})
export class StatutLitigePipe implements PipeTransform {
  transform(value: string, exponent?: string): string {
    switch(exponent) {
      case 'LIT_2':
      case 'LIT_0':
      case 'CON_0':
      case 'JUS_0':
      case 'FAC_4':
        return 'label-primary';

      case 'LIT_3':
      case 'LIT_5':
      case 'CON_1':
      case 'FAC_1':
      case 'FAC_2':
      case 'FAC_3':
      case 'JUS_1':
        return 'label-green';

      case 'LIT_1':
      case 'CON_2':
      case 'JUS_2':
        return 'label-danger';

      case 'FAC_0':
      case 'LIT_6':
        return 'label-secondary';

      case '':
      case null:
        return 'label-secondary';
    }
  }
}
