import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import Swal from 'sweetalert2';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {GrcService} from '../../../../shared/services/api/grc.service';
import {View} from '../../../../shared/models/view.model';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {PieceJointeService} from '../../../../shared/services/api/piece-jointe.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Grc} from '../../../../shared/models/api/grc.model';
import {TypeDemandeService} from '../../../../shared/services/api/typeDemande.service';
import {TypeDemande} from '../../../../shared/models/api/type-demande.model';
import {FileItem, FileUploader} from 'ng2-file-upload';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../../environments/environment';
import {convertBytes, urlWithProtocol} from '../../../../shared/utils/util';
import {UserData} from '../../../../shared/models/user-data.model';
import {AppEvent, EventQueueService} from '../../../../shared/services/event-queue.service';

@Component({
  selector: 'app-nouvelle-demande',
  templateUrl: './nouvelle-demande.component.html',
  styleUrls: ['./nouvelle-demande.component.scss']
})
export class NouvelleDemandeComponent implements OnInit {

  @Output() submited: EventEmitter<Grc> = new EventEmitter<Grc>();
  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Output() uploaded = new EventEmitter<boolean>();

  @Input() commandeId: number;
  @Input() grcNumeroAvis: string;
  @Input() filterName: string;

  public loading = false;
  public loadingTable = false;
  public loadingTypeDemande = false;
  public loadingSubmitBtn = false;
  public laddaSentButton = false;
  public disabledButton = false;
  public showFileUploader = false;
  public formSubmit: boolean;
  public URL: string = null;

  public defaultExtension: string[] = ['.pdf', '.jpg'];
  public subscriptions: Subscription[] = [];
  public typeDemandes: TypeDemande[];

  public activModal: NgbModalRef;
  public currentUser: UserData;
  public innerWidth = window.innerWidth;
  public view: View;

  public demandeGrcForm: FormGroup;
  public uploader: FileUploader;

  constructor(private translate: TranslateService,
              private grcSvc: GrcService,
              private typeDemandeSvc: TypeDemandeService,
              private fileSvc: PieceJointeService,
              private authSvc: AuthenticationService,
              private modalSvc: NgbModal,
              private toastr: ToastrService,
              private eventQueue: EventQueueService) {
  }

  ngOnInit() {
    this.routeApiUploader();
    this.handleEventUploader();
    this.authSvc.currentView.subscribe(x => this.view = x);
    this.authSvc.currentUser.subscribe(x => this.currentUser = x);
    this.initForm();
  }

  initForm() {
    this.onloadTypeDemandes();
    this.demandeGrcForm = new FormGroup({
      username: new FormControl(this.currentUser.username),
      typeDemandes: new FormControl(null, Validators.required),
      message: new FormControl('', Validators.required),
    });
  }

  onloadTypeDemandes() {
    this.loadingTypeDemande = true;
    this.subscriptions.push(this.typeDemandeSvc.getTypeDemandesClient().subscribe(value => {
      this.typeDemandes = value;
      this.loadingTypeDemande = false;
      },
      () => {
        this.toastr.error(
          this.translate.instant("La liste des type de demandes n'est pas disponible", { application: this.translate.instant('GRC')}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadingTypeDemande = false;
      }
    ));
  }

  handleEventUploader() {
    // Apres avoir ajouter le fichier dans la queue de l'uploader
    this.uploader.onAfterAddingFile = (item: FileItem | any) => {
      const name = item.file.name;
      const split = name.split('.');
      item.formData.extention = '.' + split[split.length - 1];
      item.formData.name = name.replace(item.formData.extention, '');

      // controle l'extension du fichier (pdf, jpg)
      if (this.defaultExtension.indexOf(item.formData.extention) < 0) {
        Swal.fire({
          title: this.translate.instant('Attention !'),
          text: this.translate.instant('L\'extension {{ extension }} n\'est pas acceptée', {extension: item.formData.extention}) + '. ' + this.translate.instant('Liste des extensions acceptées: {{ liste }}', {liste: this.defaultExtension}),
          type: 'warning'
        }).then(() => {
          item.remove();
        });
      }
      // controle la taille du fichier
      if (item.file.size > environment.size_max_file) {
        Swal.fire({
          title: this.translate.instant('Attention !'),
          text: this.translate.instant('Le fichier {{ name }} est trop volumineux', {name: item.formData.name}) + '. ' + this.translate.instant('Il ne doit pas être supérieur à {{ size }}', {size: convertBytes(environment.size_max_file)}),
          type: 'warning'
        }).then(() => {
          item.remove();
        });
      }
    };

    // Avant l'uploade vers le serveur
    this.uploader.onBeforeUploadItem = (fileItem: FileItem | any) => {
      this.laddaSentButton = true;
      this.disabledButton = true;
      fileItem.isError = true;
      fileItem.file.name = fileItem.formData.name + fileItem.formData.extention;
      fileItem.withCredentials = false;
      return false;
    };

    this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
      if (fileItem.formData.name) {
        form.append('filename', fileItem.formData.name);
      }
    };

    this.uploader.onCompleteAll = () => {
      if (this.uploader.queue.length === 0) {
        this.uploaded.emit(true);
        this.submited.emit(JSON.parse(this.uploader.options.additionalParameter.grc));
        this.loadingSubmitBtn = false;
        this.formSubmit = false;
        this.toastr.success(
          this.translate.instant("Nouvelle demande GRC enregistrée"),
          this.translate.instant("Informations"),
          {progressBar: true}
        );
      }
      this.laddaSentButton = false;
    };

    // uploader reussi
    this.uploader.onSuccessItem = ((item: FileItem): any => {
      this.removeFileFromQueue(item.alias);
    });

    // erreur dans n'importe quels evenement ci-dessus.
    this.uploader.onErrorItem = ((item: FileItem, response: string): any => {
      item.progress = 0;
      const defaultMsg = this.translate.instant("Le fichier {{ value }} a rencontré un problème lors de l'envoi, merci de rééssayer ultérieurement", {value: item.file.name});
      try {
        const rep = JSON.parse(response);
        if (rep.code) {
          switch (rep.code) {
            case 2101 :
              Swal.fire({
                title: this.translate.instant('Attention !'),
                text: this.translate.instant("Vous devez choisir un type pour le fichier {{ value }}", {value: item.formData.name}),
                type: 'warning'
              }).then(() => {
                this.cancel();
              });
              break;
            case 2700 :
              Swal.fire({
                title: this.translate.instant('Attention !'),
                text: this.translate.instant("Vous devez choisir un type pour le fichier {{ value }}", {value: item.formData.name}),
                type: 'warning'
              }).then(() => {
                this.cancel();
              });
              break;
            default:
              Swal.fire({
                title: this.translate.instant('Désolé'),
                text: defaultMsg,
                type: 'error'
              }).then(() => {
                this.cancel();
              });
          }
        } else {
          Swal.fire({
            title: this.translate.instant('Désolé'),
            text: defaultMsg,
            type: 'error'
          }).then(() => {
            this.cancel();
          });
        }
      } catch (e) {
        Swal.fire({
          title: this.translate.instant('Désolé'),
          text: defaultMsg,
          type: 'error'
        }).then(() => {
          this.cancel();
        });
      }
      this.disabledButton = false;
      this.laddaSentButton = false;

      item.isCancel = false;
      item.isReady = false;
      item.isUploaded = false;
    });
  }

  removeFileFromQueue(label: string) {
    for (let i = 0; i < this.uploader.queue.length; i++) {
      if (this.uploader.queue[i].alias === label) {
        this.uploader.queue[i].remove();
        return;
      }
    }
  }

  routeApiUploader() {
    if (this.grcNumeroAvis != undefined || this.grcNumeroAvis != null) {
      this.URL = urlWithProtocol(environment.url_api_espace_client) + '/demandes-grc/' + this.grcNumeroAvis + '/upload';
    }
    if (!this.grcNumeroAvis) {
      throw new Error('Impossible de définir l\'url pour uploader les pièces jointes.');
    }
    this.uploader = new FileUploader({url: this.URL});
    this.authSvc.getToken().subscribe(token => {
      this.uploader.authToken = 'Bearer ' + token.token;
    });
  }

  sentAllFile(grc) {
      this.uploader.options.additionalParameter = {
        grc: JSON.stringify(grc)
      };
      this.uploader.uploadAll();
  }

  submitForm() {
    this.formSubmit = true;
    if (this.demandeGrcForm.invalid) {
      return;
    }

    this.loadingSubmitBtn = true;
    const sub1 = this.grcSvc.addDemande(this.grcNumeroAvis, this.demandeGrcForm.value).subscribe(
      (grc) => {
        if (this.uploader.queue.length > 0) {
          this.sentAllFile(grc);
        } else {
          this.submited.emit(grc);
          this.loadingSubmitBtn = false;
          this.formSubmit = false;
        }
        sub1.unsubscribe();
        this.eventQueue.dispatch(new AppEvent(AppEvent.EventType.GRC_MESSAGE_ADDED, {}));
      },
      () => {
        this.loadingSubmitBtn = false;
        this.formSubmit = false;
        Swal.fire({
          title: this.translate.instant('Désolé'),
          text: this.translate.instant('Une erreur est survenue'),
          showCancelButton: false
        }).then(() => {
            sub1.unsubscribe();
            this.cancel();
          }
        );
      }
    );
  }

  toggle() {
    this.showFileUploader = !this.showFileUploader;
  }

  cancel() {
    this.canceled.emit();
  }

}
