import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {Subscription} from "rxjs";
import {View} from "../../../../shared/models/view.model";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "../../../../shared/services/authentication.service";
import {ToastrService} from "ngx-toastr";
import {DatatableHelper} from "../../../../shared/helpers/datatable.helper";
import Swal from "sweetalert2";
import {WexlogGrcData} from "../../../../shared/models/api/wexlog/wexlog-grc-data.model";
import {GrcWexlogService} from "../../../../shared/services/api/wexlog/grc-wexlog.service";
import {UserData} from "../../../../shared/models/user-data.model";
import {FilterMultiCritereModel} from "../../../../shared/models/filters/filterMultiCritere.model";
import {tableAnimation} from "../../../../animations";
import {MatSort, Sort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-table-grc-by-commande-wexlog',
  templateUrl: './table-grc-by-commande-wexlog.component.html',
  styleUrls: ['./table-grc-by-commande-wexlog.component.scss'],
  animations: [tableAnimation],
})
export class TableGrcByCommandeWexlogComponent implements OnInit {

  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  expandedElement: WexlogGrcData | null;

  @Output() onSelectFile: EventEmitter<WexlogGrcData> = new EventEmitter<WexlogGrcData>();
  @Output() onSorted: EventEmitter<Sort> = new EventEmitter();
  @Output() selectShowMessage: EventEmitter<{ id: number }> = new EventEmitter<{ id: number }>();
  @Output() selectShowPieceJointe: EventEmitter<{ id: number }> = new EventEmitter<{ id: number }>();
  @Output() selectConversation: EventEmitter<WexlogGrcData> = new EventEmitter<WexlogGrcData>();
  @Output() deletedGrc: EventEmitter<number> = new EventEmitter<number>();

  @Input() displayedColumns: string[] = [
    'voir',
    'id',
    'ord_id',
    'numero_commande',
    'description',
    'created_at',
    'updated_at',
    'created_by',
    'statut',
    'options'
  ];
  @Input() data: WexlogGrcData[];
  @Input() grc: WexlogGrcData;
  @Input() loading = false;
  @Input() hiddenColumns: string[] = [];
  @Input() showViewMessageBtn: boolean = false;
  @Input() showFileBtn: boolean = false;
  @Input() showDeleteDemandGrcBtn: boolean = false;
  @Input() showInfosBtn: boolean = false;
  @Input() length: number;
  @Input() pageSize: number = 10;
  @Input() pageSizeOptions: number[] = [5, 10, 20];

  @Output() paginatorEvent: EventEmitter<PageEvent> = new EventEmitter<PageEvent>()

  public innerWidth: number;
  public dataSource: any;
  public sub: Subscription;
  public view: View;
  public currentUser: UserData;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private translate: TranslateService,
    private grcWexlogSvc: GrcWexlogService,
    private authSvc: AuthenticationService,
    private toastr: ToastrService) {
    this.authSvc.currentUser.subscribe(x => this.currentUser = x);
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {
    this.onResizeTable();
    this.dataSource = new MatTableDataSource<WexlogGrcData>(this.data);
    this.dataSource.paginator = this.paginator;
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1);
        }
      });
    }
  }

  emit($event: PageEvent) {
    return this.paginatorEvent.emit($event);
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  selectFile(grc: WexlogGrcData) {
    this.onSelectFile.emit(grc);
  };

  /**
   * Permet d'ordonner les colones.
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  delete(id: string) {
    let grc = this.data.find(x => {
      return x.id == id;
    });
    let index = this.data.indexOf(grc);

    Swal.fire({
      title: this.translate.instant('Attention !'),
      text: this.translate.instant("Êtes-vous sûr de vouloir supprimer le message {{ value }} ?", { value: this.dataSource.data[index].id}),
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: this.translate.instant('NON'),
      confirmButtonText: this.translate.instant('OUI'),

    }).then((result) => {
      if (result.value) {
        //affiche le chargement
        this.dataSource.data[index].pending = true;
        this.filter.username = this.currentUser.username
        //service pour supprimer le fichier
        this.sub = this.grcWexlogSvc.deleteDemandeGrc(id, this.filter).subscribe(
          (grc) => {
            this.grc = grc;
            this.toastr.info(
              this.translate.instant("La demande GRC a été supprimée"),
            );
            this.dataSource.data[index].pending = false;
            this.dataSource.data.splice(index, 1);
            this.dataSource = new MatTableDataSource(this.dataSource.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.deletedGrc.emit(1);
          },
          () => {
            this.dataSource.data[index].pending = false;
            this.toastr.error(
              this.translate.instant("La demande GRC n'a pas pu être supprimée"),
            );
          },
        );
      }

    });
  }

  onSelectConversation(grc: WexlogGrcData) {
    this.selectConversation.emit(grc);
  };

  selectShowFile(id: number) {
    this.selectShowPieceJointe.emit({id});
  };

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 410) {
      this.displayedColumns = [
        'voir',
        'id',
        'options'
      ];
    } else if (this.innerWidth > 410 && this.innerWidth <= 540) {
      this.displayedColumns = [
        'voir',
        'id',
        'ord_id',
        'options'
      ];
    } else if (this.innerWidth > 540 && this.innerWidth <= 970) {
      this.displayedColumns = [
        'voir',
        'id',
        'ord_id',
        'numero_commande',
        'options'
      ];
    } else if (this.innerWidth > 970 && this.innerWidth <= 1095) {
      this.displayedColumns = [
        'voir',
        'id',
        'ord_id',
        'numero_commande',
        'description',
        'options'
      ];
    } else if (this.innerWidth > 1095 && this.innerWidth <= 1360) {
      this.displayedColumns = [
        'voir',
        'id',
        'ord_id',
        'numero_commande',
        'description',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 1360 && this.innerWidth <= 1516) {
      this.displayedColumns = [
        'voir',
        'id',
        'ord_id',
        'numero_commande',
        'description',
        'created_by',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 1516 && this.innerWidth <= 1700) {
      this.displayedColumns = [
        'voir',
        'id',
        'ord_id',
        'numero_commande',
        'description',
        'created_at',
        'created_by',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 1700) {
      this.displayedColumns = [
        'voir',
        'id',
        'ord_id',
        'numero_commande',
        'description',
        'created_at',
        'updated_at',
        'created_by',
        'statut',
        'options'
      ];
    }
  }
}
