import { Injectable } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Medias} from '../../../models/api/medias.model';

@Injectable({
  providedIn: 'root'
})
export class MediasService {

  urlRessource= environment.url_api_espace_client + '/medias';

  constructor(private http: HttpClient) {}

  getMedias(numeroAvis: string): Observable<Medias[]> {
    return this.http
      .get<Medias[]>(`${this.urlRessource}/${numeroAvis}`)
      .pipe(map((medias) => medias.map((media) => {
        return new Medias().deserialize(media);
      })))
  }

}
