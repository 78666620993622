import {Deserializable} from '../interfaces/deserializable.interface';
import {Commande} from "./commande.model";

export class Medias implements Deserializable {

  public id?: number;
  public commande: Commande;
  public destinataire?: string;
  public type?: string;
  public date_envoie?: Date | string;
  public etat_envoie?: string;
  public message?: string;
  public created_at?: Date | string;
  public updated_at?: Date | string;

  deserialize(input: any): this {
    return Object.assign(this, input);

  }

}
