import {Component, Input, OnInit} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ContestationService} from '../../../../shared/services/api/contestation.service';
import {Subscription} from 'rxjs';
import Swal from "sweetalert2";
import {PieceJointeService} from '../../../../shared/services/api/piece-jointe.service';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {View} from '../../../../shared/models/view.model';
import {TranslateService} from '@ngx-translate/core';
import {PieceJointeData} from "../../../../shared/models/api/piece-jointe-data.model";
import {ContestationData} from "../../../../shared/models/api/contestation-data.model";
import {PieceJointeGed} from "../../../../shared/models/api/ged/piece-jointe-ged.model";


@Component({
  selector: 'app-contestation-content',
  templateUrl: './contestation-content.html',
  styleUrls: ['./contestation-content.scss']
})
export class ContestationContent implements OnInit {

  @Input() contestationData: ContestationData;

  public pieceJointeData: PieceJointeData;
  public loadPreview: boolean = false;
  public pieceJointeSub: Subscription;

  public loadingPage = false;
  public loadingTableFile: boolean = false;
  public activModal: NgbModalRef;
  public piecesJointes: PieceJointeData[];
  public pieceJointe: PieceJointeData;
  public piecesJointesGed: PieceJointeGed[];
  public view: View;
  public loadingFile: boolean = false;
  public showImage: boolean;
  public urlPreview: string;
  public currentType : number =  null;
  public availableFormat = [
    'image/png',
    'image/jpeg',
    'application/pdf'
  ];


  constructor(private translate: TranslateService,
              private contestationSvc: ContestationService,
              private fileSvc: PieceJointeService,
              private modalSvc: NgbModal,
              private authSvc: AuthenticationService) { }

  ngOnInit() {
    this.authSvc.currentView.subscribe((view) => {
      this.view = view
    })
    this.getPiecesJointe();
  }

  openModalUpload(modal) {
    this.activModal = this.modalSvc.open(modal, {
      size: 'xl', keyboard: false
    });
  }

  onUploaded() {
    this.getPiecesJointe();
    this.activModal.close();
  }

  getPiecesJointe() {
    this.loadingTableFile = true;
    this.piecesJointes = [];
    this.pieceJointeSub = this.contestationSvc.getPieceJointes(this.contestationData.id).subscribe(
      (files) => {
        this.piecesJointes = files.data;
        this.loadingTableFile = false;
      },
      () => {
        this.loadingTableFile = false;
      }
    );
  }

  openModalVisualisationFile(modal, value: { id: number, filename: string, path: string }) {
    this.pieceJointe = new PieceJointeData().deserialize({id: value.id, libelle: value.filename, path: value.path});
    this.loadPreview = true;
    this.activModal = this.modalSvc.open(modal, {
      size: 'xl',
    });
    this.showPieceJointe(value.id)
  }

  showPieceJointe(pieceJointeId) {
    this.loadingFile = true;
    if(this.pieceJointe.path.startsWith('../var/')) {
      const sub1 = this.fileSvc.getFilePreview(pieceJointeId).subscribe(
        (urlPreview) => {
          this.currentType =  this.availableFormat.indexOf(urlPreview.type);
          if (this.availableFormat.indexOf(urlPreview.type) >= 0) {
            this.showImage = true;
          }
          this.urlPreview = window.URL.createObjectURL(urlPreview);
          this.loadingFile = false;
          sub1.unsubscribe()
        },
        () => {
          Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('Une erreur est survenue'),
            showCancelButton: false}
          ).then(() => {
            this.activModal.close();
            this.loadingFile = false;
            sub1.unsubscribe();
          });
        }
      );
    } else {
      const sub = this.fileSvc.getFilePreviewGed(pieceJointeId).subscribe(
        (piecesJointesGed) => {
          this.piecesJointesGed = piecesJointesGed
          this.loadingFile = false;
          sub.unsubscribe()
        },
        () => {
          Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('Une erreur est survenue'),
            showCancelButton: false}
          ).then(() => {
            this.activModal.close();
            this.loadingFile = false;
            sub.unsubscribe();
          });

        }
      );
    }

  }

}
