<div class="row m-0 padding-tab">
  <div class="col-sm-12 py-4 m-0">
    <panel variants="danger" title="{{'Média' | translate}}" noButton="true" bodyClass="no-padding panel">
      <div>
        <app-table-media [data]="medias" [loading]="loadingTableMessage" [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"
                         [showViewBtn]="view.hasDroit('COMMANDE_MEDIA_SHOW')" [showInfosBtn]="true"
                         (selectMessage)="openModalVisualisationMessage(modal_visualisation_message, $event)"></app-table-media>
      </div>
    </panel>
  </div>
  <div class="col-sm-12 py-4 m-0">
    <panel noButton="true" bodyClass="no-padding panel">
      <div class="row" header>
        <div class="col-12"><h5 class="p-t-5 text-agediss">{{'Mes Documents' | translate }}</h5></div>
      </div>
      <div>
        <app-table-ged [piecesJointes]="pieceJointes"
                       [showInfosBtn]="true"
                       [loading]="loadingTableGed"
                       [showDownloadFileBtn]="view.hasDroit('COMMANDE_PIECE-JOINTE_DOWNLOAD')"
                       [showViewFileBtn]="view.hasDroit('COMMANDE_PIECE-JOINTE_SHOW')"
                       [showDeleteFileBtn]="false"
                       [showEditFileBtn]="false"
                       (onSelectFile)="openModalVisualisationFile(modal_visualisation_file,$event)"
                       [pageSize]="10" [pageSizeOptions]="[5, 10, 20]">
        </app-table-ged>
      </div>
    </panel>
  </div>
</div>

<ng-template #modal_visualisation_message let-modal>
  <app-visualisation-message [media]="media" (canceled)="modal.close($event)"></app-visualisation-message>
</ng-template>

<ng-template #modal_visualisation_file let-modal>
  <app-file-preview-ged [imageGed]="pieceJointe.images" [documents]="true" (canceled)="modal.close($event)"></app-file-preview-ged>
</ng-template>
