import {Deserializable} from '../../interfaces/deserializable.interface';
import {WexlogConversationGrc} from "./wexlog-conversation-grc.model";
import {PieceJointeGed} from "../ged/piece-jointe-ged.model";

export class WexlogGrcData implements Deserializable {

  public id?: string;
  public description?: string;
  public created_at?: Date | string;
  public created_by?: string;
  public updated_at?: Date | string;
  public updated_by?: string;
  public ord_id?: string;
  public numero_commande?: string;
  public numero_commande_1?: string;
  public numero_commande_2?: string;
  public numero_commande_3?: string;
  public objet?: string;
  public commentaire?: string;
  public commentaire_2?: string;
  public destinataire_nom?: string;
  public destinataire_tel_fixe?: number;
  public destinataire_tel_portable?: number;
  public destinataire_email?: string;
  public destinataire_adresse?: string;
  public destinataire_adresse2?: string;
  public destinataire_code_postal?: string;
  public destinataire_ville?: string;
  public destinataire_pays?: string;
  public nb_colis?: number;
  public poids_total?: number;
  public volume_total?: number;
  public libelle_statut?: string;
  public creator_id?: string;
  public numero_client?: string;
  public cse_id?: string;
  public raison_type?: string;
  public priority_type?: string;
  public creator_type?: string;
  public assigned_id?: string;
  public cfu_satisfaction_index?: string;
  public created_date_from?: string;
  public created_date_to?: string;
  public created_date_operation?: string;
  public assigned_date?: string;
  public closed_date?: string;
  public closed_by?: string;
  public resolved_date?: string;
  public resolved_by?: string;
  public assigned_by?: string;
  public free_search_text?: string;
  public deleted_date?: Date | string;
  public deleted_by?: string;
  public deletable?: boolean;
  public messages?: WexlogConversationGrc[]
  public pending: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this
  }

}
