import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Facture} from '../../../../shared/models/api/facture.model';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {tableAnimation} from '../../../../animations';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {FactureData} from '../../../../shared/models/api/facture-data.model';

@Component({
  selector: 'app-table-facture-a-traitees',
  templateUrl: './table-facture-a-traitees.component.html',
  styleUrls: ['./table-facture-a-traitees.component.scss'],
  animations: [tableAnimation],
})
export class TableFactureATraiteesComponent implements OnInit {

  expandedElement: Facture | null;

  @Input() data: FactureData[];
  @Input() showViewBtn = false;
  @Input() showInfosBtn = false;

  @Input() loading = false;
  @Input() height = null;
  @Input() hiddenColumns: string[] = [];

  @Input() length: number;
  @Input() pageSize = 10;
  @Input() pageIndex: number;
  @Input() pageEvent: PageEvent;
  @Input() pageSizeOptions: number[] = [5, 10, 20];

  @Output() onSorted: EventEmitter<Sort> = new EventEmitter();
  @Output() selectShowPieceJointe: EventEmitter<{pieceJointeId: number, factureId: number}>
    = new EventEmitter<{pieceJointeId: number, factureId: number}>();
  @Output() selectFacture: EventEmitter<FactureData> = new EventEmitter<FactureData>();
  @Output() paginatorEvent: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  public innerWidth: number;
  public dataSource: MatTableDataSource<FactureData>;
  public displayedColumns = [
    'id',
    'date',
    'type_facture',
    'montant',
    'statut',
    'options'
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.onResizeTable();
  }

  emit($event: PageEvent) {
    return this.paginatorEvent.emit($event);
  }

  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
      this.dataSource.sort = this.sort;
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
        this.dataSource.sort = this.sort;
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }

  }

  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

  selectShowPieceJointeId(pieceJointeId: number, factureId: number) {
    this.selectShowPieceJointe.emit({pieceJointeId, factureId});
  }

  onSelectFacture(data: FactureData) {
    this.selectFacture.emit(data);
  }

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 390) {
      this.displayedColumns = [
        'id',
        'options'
      ];
    } else if (this.innerWidth > 390 && this.innerWidth < 482) {
      this.displayedColumns = [
        'id',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 482 && this.innerWidth <= 884) {
      this.displayedColumns = [
        'id',
        'date',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 884 && this.innerWidth <= 1100) {
      this.displayedColumns = [
        'id',
        'date',
        'montant',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 1100) {
      this.displayedColumns = [
        'id',
        'date',
        'type_facture',
        'montant',
        'statut',
        'options',
      ];
    }
  }

}
