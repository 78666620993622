import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {tableAnimation} from '../../../../animations';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {Medias} from '../../../../shared/models/api/medias.model';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";


@Component({
  selector: 'app-table-media',
  templateUrl: './table-media.component.html',
  styleUrls: ['./table-media.component.scss'],
  animations: [tableAnimation],
})
export class TableMediaComponent implements OnInit, OnChanges {

  expandedElement: Medias | null;

  @Output() selectMessage: EventEmitter<Medias> = new EventEmitter<Medias>();

  @Input() hiddenColumns: string[] = [];
  @Input() loading = false;
  @Input() showViewBtn: boolean = false;
  @Input() showInfosBtn: boolean = false;
  @Input() data: Medias[];
  @Input() length: number;
  @Input() pageSize: number = 10;
  @Input() total: number;
  @Input() pageIndex: number;
  @Input() pageEvent: PageEvent;
  @Input() pageSizeOptions: number[] = [5, 10, 20];
  @Input() displayedColumns: string[] = [
    'type',
    'destinataire',
    'date_envoie',
    'etat_envoie',
    'message',
    'options',
  ];

  public innerWidth: number;
  public dataSource: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.dataSource = new MatTableDataSource<Medias>(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.onResizeTable()
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  onSelectMessage(medias: Medias) {
    this.selectMessage.emit(medias);
  };
  /**
   * Permet d'ordonner les colones.
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 600) {
      this.displayedColumns = [
        'type',
        'message',
        'options',
      ];
    }
    if (this.innerWidth > 600 && this.innerWidth <= 690) {
      this.displayedColumns = [
        'type',
        'destinataire',
        'message',
        'options',
      ];
    } else if (this.innerWidth > 680 && this.innerWidth <= 800) {
      this.displayedColumns = [
        'type',
        'destinataire',
        'date_envoie',
        'message',
        'options',
      ];
    } else if (this.innerWidth > 800 && this.innerWidth <= 991) {
      this.displayedColumns = [
        'type',
        'destinataire',
        'date_envoie',
        'etat_envoie',
        'message',
      ];
    } else if (this.innerWidth > 991 && this.innerWidth <= 1070) {
      this.displayedColumns = [
        'type',
        'destinataire',
        'message',
        'options',
      ];
    } else if (this.innerWidth > 1100 && this.innerWidth <= 1170) {
      this.displayedColumns = [
        'type',
        'destinataire',
        'date_envoie',
        'message',
        'options',
      ];
    } else if (this.innerWidth > 1170) {
      this.displayedColumns = [
        'type',
        'destinataire',
        'date_envoie',
        'etat_envoie',
        'message',
      ];
    }
  }

}
