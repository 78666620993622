import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs";
import {Evenement} from "../../../../shared/models/api/evenement.model";
import {SignatureLivraison} from "../../../../shared/models/api/ged/signature-livraison.model";
import {PieceJointeGed} from "../../../../shared/models/api/ged/piece-jointe-ged.model";
import {ImageGed} from "../../../../shared/models/api/ged/image-ged.model";
import {GrcData} from "../../../../shared/models/api/grc-data.model";

@Component({
  selector: 'app-file-preview-ged',
  templateUrl: './file-preview-ged.component.html',
  styleUrls: ['./file-preview-ged.component.scss']
})
export class FilePreviewGedComponent implements OnInit {

  @Input() evenement: Evenement = null;
  @Input() signatureLivraison: SignatureLivraison = null;
  @Input() piecesJointesGed: PieceJointeGed[] = null;
  @Input() pieceJointe: PieceJointeGed = null;
  @Input() imageGed: ImageGed[] = null;
  @Input() grc: GrcData = null;
  @Input() docId: number;
  @Input() commandeId: string;
  @Input() isVideo: Boolean = false;
  @Input() documents: Boolean = false;
  @Input() doc: Boolean = false;
  @Input() conversationDocuments: Boolean = false;
  @Input() blEmarge: Boolean = false;
  @Input() signature: Boolean = false;
  @Input() event: Boolean = false;
  @Input() showBtnDownload: Boolean = false;

  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Input() numeroClient: string;
  @Input() societe: string;

  public urlPreview: string | void;
  public loading: boolean = false;
  public loadingPage: boolean = false;
  public currentType: number = null;
  public subscriptions: Subscription[] = [];
  public availableFormat: string[] = [
    'image/png',
    'image/jpeg',
    'application/pdf'
  ];

  constructor() {
  }

  ngOnInit() {
  }

  cancel() {
    this.canceled.emit();
  }
}
