<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="modal-header" *ngIf="!loadingSubmitBtn">
    <h4
      class="modal-title  w-100 text-center">{{'Nouvelle demande GRC pour le Bl' | translate }} : {{referenceCommande}}</h4>
  </div>
  <div class="modal-body" style="min-height: 150px" *ngIf="!loadingSubmitBtn">

    <!-- debut formulaire de création de demande GRC -->

    <form [formGroup]="demandeGrcWexlogForm">
      <div class="row m-0">
        <div class="col-12 px-0 pt-3">
          <div class="col-12 p-0">
            <label
              class="d-block d-lg-none">{{'Type de texte' | translate }}</label>
          </div>
          <div class="input-group-prepend">
             <span
               class="input-group-text input-group-border-right d-none d-lg-flex align-items-center ">
              {{'Type de texte' | translate }}
            </span>
            <ng-select class="ng-select filter"
                       [ngClass]="{'is-invalid':demandeGrcWexlogForm.get('typeTextMessage').invalid && (demandeGrcWexlogForm.get('typeTextMessage').dirty || formSubmit) }"
                       [items]="typeTextMessages | sort: 'objet'"
                       [selectOnTab]="true"
                       (change)="onChangeTextMessage($event) && disableEnableInput('message',demandeGrcWexlogForm.get('typeTextMessage').value)"
                       bindValue="objet"
                       bindLabel="objet"
                       [loading]="loadingTypeTextMessage"
                       [clearable]="true"
                       placeholder="{{'Choisir' | translate }}"
                       formControlName="typeTextMessage">
            </ng-select>
            <div
              *ngIf="demandeGrcWexlogForm.get('typeTextMessage').invalid && (demandeGrcWexlogForm.get('typeTextMessage').dirty || formSubmit)"
              class="text-center invalid-tooltip">
              {{'Veuillez saisir un type de texte !' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="row m-0 pt-2">
        <div class="col-lg-12 col-md-12 p-0">
          <div class="row m-0">
            <div class="col-5 col-md-6 p-0">
              <label class="col-lg-12 col-md-12 col-sm-12 p-0">{{'Votre message' | translate }}
                :</label>
            </div>
            <div class="col-7 col-md-6 d-flex justify-content-end"
                 [ngClass]="{'text-danger': numberOfCharactersMessage <= 10,'text-dark': numberOfCharactersMessage > 10}">
              {{numberOfCharactersMessage}} {{'caractères restants' | translate }}
            </div>
          </div>
          <div class="row m-0">
            <textarea rows="7"  [readOnly]="isReadOnly" [maxlength]="'maxNumberOfCharactersMessage'"
                      (keyup)="onKeyUpInfos(maxNumberOfCharactersMessage, $event)"
                      class="form-control form-control-filter" formControlName="message"
                      placeholder="{{'Sélectionnez d\'abord un type de texte' | translate }}"
                      [ngClass]="{'is-invalid': demandeGrcWexlogForm.get('message').invalid  && (demandeGrcWexlogForm.get('message').dirty || formSubmit)}">
            </textarea>
            <div class="text-center invalid-tooltip"
                 *ngIf="demandeGrcWexlogForm.get('message').invalid  && (demandeGrcWexlogForm.get('message').dirty || formSubmit)">
              {{'Veuillez saisir un message !' | translate}}
            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- fin formulaire de création de demande GRC -->

  </div>

  <!-- footer -->

  <div class="modal-footer p-5" *ngIf="!loadingSubmitBtn">
    <button class="btn btn-agediss text-white" [disabled]="disabledButton" [ladda]="loadingSubmitBtn" data-style="zoom-in"
            (click)="onSubmit()">{{'Envoyer' | translate }}
    </button>
    <button class="btn  bg-black-lighter text-white" (click)="cancel()">{{'Fermer' | translate }}</button>
  </div>

  <!-- Fin footer -->

  <!-- debut loading submit form -->

  <div class="modal-body" style="height: 500px" *ngIf="loadingSubmitBtn">
    <div class="col-12 text-center" style="margin: 80px auto">
      <span class="fas fa-cog fa-spin text-agediss" style="font-size: 40px;"></span>
    </div>
    <div class="col-12 text-center">
      <span class="f-s-20">{{'Envoi de la réponse GRC en cours' | translate }}</span>
    </div>
  </div>

  <!-- fin loading submit form -->
</div>
