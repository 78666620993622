<div class="modal-header">
  <h4 class="modal-title  w-100 text-center">{{'Visualisation du message' | translate }}</h4>
</div>
<div class="modal-body">
  <div class="row m-0">
    <div class="col-12">
      <p><strong>{{'Type' | translate }} : </strong>{{ media.type}}</p>
      <p><strong>{{'Destinataire' | translate }} : </strong>{{ media.destinataire}}</p>
      <p><strong>{{"Date d'envoi" | translate }} : </strong>{{ media.date_envoie}}
      </p>
      <p><strong>{{"Etat d'envoi" | translate }} : </strong>{{ media.etat_envoie}}
      </p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <label for="messages">{{"Visualisation du message" | translate }}
        <div class="input-group">
          <div id="messages" class="form-control-filter" [innerHTML]="media.message"></div>
        </div>
      </label>
    </div>
  </div>
</div>
<div class="modal-footer p-5">
  <button class="btn bg-black-lighter text-white m-r-10" (click)="cancel()">{{'Fermer' | translate }}</button>
</div>

