import {Deserializable} from '../../interfaces/deserializable.interface';

export class WexlogTypeTextMessageGrc implements Deserializable {

  public id?: string;
  public objet?: string;
  public commentaire?: string;
  public statut_role_type?: string;
  public statut_text_type?: string;
  public statut_message_type?: string;
  public raison_type?: string;
  public message_visible_type?: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
