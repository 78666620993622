import {Deserializable} from '../interfaces/deserializable.interface';

export class Activite implements Deserializable{

  public idactivite: number;
  public created_at: Date;
  public updated_at: Date;
  public code_activite?: string;
  public nom_activite?: string;
  public destination?: string;
  public type?: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
