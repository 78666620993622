<div class="row m-0 py-3">
  <div class="col-lg-3 col-md-3 col-sm-6 col-6 p-0">
    <label class="col-12">{{'Date' | translate }}
      <input type="text"
             readonly [value]=" litigeData?.date_litige | date : 'dd/MM/yyy'"
             class="input form-control form-control-filter text-truncate">
    </label>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6 col-6 p-0">
    <label class="col-12">{{'Origine' | translate }}
      <input type="text"
             readonly [value]="litigeData?.origine?.libelle"
             class="input form-control form-control-filter text-truncate">
    </label>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6 col-6 p-0">
    <label class="col-12">{{'Flux' | translate }}
      <input type="text"
             readonly [value]="litigeData?.flux?.libelle"
             class="input form-control form-control-filter text-truncate">
    </label>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-6 col-6 p-0">
    <label class="col-12">{{'Statut' | translate }}
      <input type="text"
             placement="top"
             container="body"
             ngbTooltip="{{litigeData?.statut?.libelle}}"
             readonly [value]="litigeData?.statut?.libelle"
             class="input form-control form-control-filter text-truncate">
    </label>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6 col-6 p-0">
    <label class="col-12">{{'Motif' | translate }}
      <input type="text"
             placement="top"
             container="body"
             ngbTooltip="{{litigeData?.motif_detail?.motif?.libelle}}"
             readonly [value]="litigeData?.motif_detail?.motif?.libelle"
             class="input form-control form-control-filter text-truncate">
    </label>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6 col-6 p-0">
    <label class="col-12">{{'Motif détaillé' | translate }}
      <input type="text"
             placement="top"
             container="body"
             ngbTooltip="{{litigeData?.motif_detail?.libelle}}"
             readonly [value]="litigeData?.motif_detail?.libelle"
             class="input form-control form-control-filter text-truncate">
    </label>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6 col-6 p-0">
    <label class="col-12 text-truncate">{{'Montant validé' | translate }}
      <div class="input-group">
        <input type="text"
               readonly [value]="litigeData.montant_valide !== undefined ? litigeData.montant_valide : 0"
               class="input form-control form-control-filter text-truncate">
        <span class="input-group-append">
          <span class="input-group-text input-group-border-left"> € </span>
        </span>
      </div>
    </label>
  </div>
  <div class="col-12 p-0">
    <label class="col-12 m-0 text-truncate">{{'Commentaire' | translate }}
      <textarea readonly cols="30" rows="3"
                class="textarea form-control form-control-filter scrollbar scrollbar-black bordered-black square thin">
        {{litigeData.commentaire_statut}}
      </textarea>
    </label>
  </div>
</div>
