import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-contestation-conversation-content',
  templateUrl: './contestation-conversation-content.html',
  styleUrls: ['./contestation-conversation-content.scss']
})
export class ContestationConversationContent implements OnInit {

  @Input() conversationId: number;
  constructor() { }

  ngOnInit() {
  }

}
