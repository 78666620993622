import {Deserializable} from '../../interfaces/deserializable.interface';
import {ImageGed} from "./image-ged.model";

export class PieceJointeGed implements Deserializable {

  public id: number | string;
  public images?: ImageGed[];
  public filename: string;
  public categorie: string;
  public id_piece_jointe: string;
  public id_categorie: string;
  public numero_client: string;
  public type_document: string;
  public nom: string;
  public id_activite: string;
  public client: string;
  public recepisse: string;
  public index: string;
  public cab: string;
  public code_anomalie: string;
  public code_cause: string;
  public date?: Date| string;
  public heure: Date | string;
  public code_image: string;
  public codeImage: string;
  public societe: string;
  public SOCIETE: string;
  public type_image: string;
  public downloadable: boolean;
  public watchable: boolean;
  public deletable: boolean;
  public pending: boolean;

  constructor(){
    this.downloadable = true;
    this.watchable = true;
    this.deletable = true;
    this.pending = false;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
