import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserData} from "../../../../shared/models/user-data.model";

@Component({
  selector: 'app-informations-personnelles',
  templateUrl: './informations-personnelles.component.html',
  styleUrls: ['./informations-personnelles.component.scss']
})
export class InformationsPersonnellesComponent implements OnInit {

  @Input() currentUser: UserData;
  @Input() loading = false;
  @Input() showViewEditDataBtn: boolean = false;
  @Input() showViewEditPasswordBtn: boolean = false;
  @Input() showViewCreateParamMailGrcBtn: boolean = false;

  @Output() selectEditDataByUser: EventEmitter<{id: number, username: string}> = new EventEmitter<{id: number, username: string}>();
  @Output() selectEditPasswordByUser: EventEmitter<{id: number, username: string}> = new EventEmitter<{id: number, username: string}>();


  constructor() { }

  ngOnInit() {
  }

  selectEditData(id: number, username: string) {
    this.selectEditDataByUser.emit({id, username});
  };

  selectEditPassword(id: number, username: string) {
    this.selectEditPasswordByUser.emit({id, username});
  };

}
