<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="modal-header bg-modal">
    <h4 class="modal-title  w-100 text-center">{{"Géolocalisation de l'évènement" | translate }}
      - {{ libelle }}</h4>
  </div>
  <div class="modal-body bg-modal">
    <div class="row m-0" *ngIf="preuveDeLivraions">
      <div class="col-12 col-lg-6 my-2">
        <div class="text-center py-3" *ngIf="commandeId === null" style="min-height: 121px">
          {{'Aucune information' | translate }}
        </div>
        <div class="py-3" *ngIf="commandeId !== null" style="min-height: 121px">
          <p class="text-truncate">
            <strong placement="right"
                    container="body"
                    ngbTooltip="{{commandeId}}">{{'Bon de livraison' | translate }}
              :</strong>
            <span> {{commandeId}}</span>
          </p>
          <p class="text-truncate" *ngIf="evenementRdv.date_evenements != null">
            <strong placement="right"
                    container="body"
                    ngbTooltip="{{evenementRdv.date_evenements | date :'dd-MM-yyyy'}}">{{'Date' | translate }}
              :</strong>
            <span> {{evenementRdv.date_evenements | date :'dd-MM-yyyy'}}</span>
          </p>
          <p class="text-truncate" *ngIf="evenementRdv.creneau_debut != null">
            <strong placement="right"
                    container="body"
                    ngbTooltip="{{evenementRdv.creneau_debut}}">{{'Début de créneau' | translate }}
              :</strong>
            <span> {{evenementRdv.creneau_debut}}</span>
          </p>
          <p class="text-truncate" *ngIf="evenementRdv.creneau_fin != null">
            <strong placement="right"
                    container="body"
                    ngbTooltip="{{evenementRdv.creneau_fin}}">{{'Fin de créneau' | translate }}
              :</strong>
            <span> {{evenementRdv.creneau_fin}}</span>
          </p>
        </div>
      </div>
      <div class="col-12 col-lg-6 my-2">
        <div class="col-12 py-3" style="min-height: 121px">
          <div *ngIf="showDocumentBtn" style="height: 60px" class="row col-12">
            <span class="spinner-small"></span>
          </div>
          <div *ngIf="!showDocumentBtn">
            <div *ngIf="!showblEmargeBtn" class="alert alert-danger text-center py-2" role="alert">
              {{'Aucun Bl émargé de disponible' | translate }}
            </div>
            <div *ngIf="!showSignatureBtn" class="alert alert-danger text-center py-2" role="alert">
              {{'Aucune signature de disponible' | translate }}
            </div>
            <button *ngIf="showblEmargeBtn"
                    class="btn btn-radius btn-agediss btn-block text-truncate shadow"
                    placement="top"
                    container="body"
                    ngbTooltip="{{'Visualisation du Bl émargé' | translate }}"
                    (click)="openModalVisualisationDocument(modal_visualisation_bl_emarge)">
              {{'Visualisation du Bl émargé' | translate }}
            </button>
            <button *ngIf="showSignatureBtn"
                    class="btn btn-radius btn-agediss btn-block text-truncate shadow"
                    placement="top"
                    container="body"
                    ngbTooltip="{{'Visualisation de la signature' | translate }}"
                    (click)="openModalVisualisationDocument(modal_visualisation_signature)">
              {{'Visualisation de la signature' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0">
      <div class="col-12">
        <div class="p-0">
          <agm-map *ngIf="lng" [latitude]="lat" [zoom]="15" [longitude]="lng" [mapTypeId]="'roadmap'"
                   [styles]="styles">
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
          </agm-map>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer p-5 bg-modal">
    <button class="btn bg-black-lighter text-white m-r-10" (click)="cancel()">{{'Fermer' | translate }}</button>
  </div>
</div>

<ng-template #modal_visualisation_bl_emarge let-modal>
  <app-file-preview-ged [imageGed]="blEmarge" [documents]="true"
                        [showBtnDownload]="view.hasDroit('COMMANDE_PIECE-JOINTE_DOWNLOAD')"
                        [numeroClient]="numeroClient" [societe]="codeSociete" [commandeId]="commandeId"
                        [blEmarge]="true" (canceled)="modal.close($event)"></app-file-preview-ged>
</ng-template>

<ng-template #modal_visualisation_signature let-modal>
  <app-file-preview-ged [piecesJointesGed]="signature" [doc]="true"
                        (canceled)="modal.close($event)"></app-file-preview-ged>
</ng-template>
