import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';
import {Timeline} from '../../shared/models/api/timeline.model';
import {Subscription} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';
import {CommandeService} from "../../shared/services/api/commande.service";
import {CommandeData} from "../../shared/models/api/commande-data.model";
import {PaginationService} from "../../shared/services/pagination.service";
import {PageEvent} from "@angular/material/paginator";
import {View} from "../../shared/models/view.model";
import {AuthenticationService} from "../../shared/services/authentication.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-retours',
  templateUrl: './retours.html',
  styleUrls: ['./retours.scss']
})
export class RetoursPage implements OnInit, OnDestroy {

  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public view: View;

  //string
  public flux = 'retour';

  //Tableau
  public commandesData: CommandeData[];
  public timelines: Timeline[];
  public subscriptions: Subscription[] = [];
  public pageSizeOption = [5, 10, 20];

  //number
  public pageSize = 10;
  public nbData: number = 0;

  //loading
  public loadingTableRetour = false;
  public loadingNbRetour = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private translate: TranslateService,
              private paginationSvc: PaginationService,
              private authSvc: AuthenticationService,
              private router: Router,
              private commandeSvc: CommandeService) {
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {
    if (this.view.hasDroit('COMMANDE_MENU_RETOUR')) {
      this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
        this.loadDataLivraisons();
      }))
    } else {
      this.router.navigate(['/', 'home']);
    }
  }

  onSubmit(filter = null) {
    //récupère les infos du filtre
    if (filter) {
      this.filter = filter;
    }
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
    this.loadDataLivraisons();
  }


  onLoadFilter(filter = null) {
    //récupère les infos du filtre
    if (filter) {
      this.filter = {...filter, ...this.filter};
    }
    this.loadDataLivraisons();
  }

  /**
   * On récupère toutes les commandes liès au compte client
   */
  loadDataLivraisons() {
    this.loadingTableRetour = true;
    this.loadingNbRetour = true;
    this.filter.flux = this.flux;
    this.commandesData = [];
    this.nbData = 0;
    this.subscriptions.push(this.commandeSvc.getLivraisons(this.filter).subscribe((commande) => {
        this.commandesData = commande.data;
        this.nbData = commande.nb;
        this.commandesData.forEach((value, index) => {
          let progessStatut = this.progressBar(value);
          this.commandesData[index]['progressBar'] = progessStatut['progressBar'];
          this.commandesData[index]['color'] = progessStatut['color'];
          this.commandesData[index]['statut'] = progessStatut['statut'];
          this.commandesData[index]['date'] = progessStatut['date'];
        });
        this.loadingTableRetour = false;
        this.loadingNbRetour = false;
      },
      error => {
        this.loadingTableRetour = false;
        this.loadingNbRetour = false;
      }));
  }

  onLoadDataByEvent(event: PageEvent) {
    this.paginationSvc.onPaginationEvent(this.filter, event)
    this.loadDataLivraisons();
  }

  progressBar(value) {
    let tab = [];

    if ((!value.solde) && (!value.reception_plateforme_ok || value.reception_plateforme_ok === 'AT') && (!value.depart_plateforme_ok)) {
      tab['statut'] = this.translate.instant('En attente de reception - Agence régionale');
      tab['color'] = 'bg-secondary';
    } else if ((!value.solde) && (value.reception_plateforme_ok === 'OK') && (!value.depart_plateforme_ok)) {
      tab['statut'] = this.translate.instant('En attente de préparation - Agence régionale');
      tab['color'] = 'bg-warning';
    } else if ((value.reception_plateforme_ok === 'NK')) {
      tab['statut'] = this.translate.instant('Anomalie lors de la réception - Agence régionale');
      tab['color'] = 'bg-danger';
    } else if ((value.reception_plateforme_ok === 'OK') && (value.depart_plateforme_ok === 'NK')) {
      tab['statut'] = this.translate.instant('Anomalie lors du chargement - Agence régionale');
      tab['color'] = 'bg-danger';
    } else if ((!value.solde) && (value.reception_plateforme_ok === 'OK' || !value.reception_plateforme_ok) && (value.depart_plateforme_ok === 'OK') && ((!!value.plateforme_transit_code) && (value.reception_hub_ok === 'AT' || !value.reception_hub_ok))) {
      tab['statut'] = this.translate.instant('En attente de réception - Dépot national');
      tab['color'] = 'bg-secondary';
    } else if ((!value.solde) && (value.reception_plateforme_ok === 'OK' || !value.reception_plateforme_ok) && (value.depart_plateforme_ok === 'OK') && ((!!value.plateforme_transit_code) && (value.reception_hub_ok === 'OK') && (!value.depart_hub_ok || value.depart_hub_ok === 'AT')) && (!value.livraison_ok)) {
      tab['statut'] = this.translate.instant('En attente de livraison - Dépot national');
      tab['color'] = 'bg-warning';
    } else if ((value.reception_plateforme_ok === 'OK' || !value.reception_plateforme_ok) && (value.depart_plateforme_ok === 'OK') && ((!!value.plateforme_transit_code) && (value.reception_hub_ok === 'NK'))) {
      tab['statut'] = this.translate.instant('Anomalie lors de la réception - Dépot national');
      tab['color'] = 'bg-danger';
    } else if ((value.reception_plateforme_ok === 'OK' || !value.reception_plateforme_ok) && (value.depart_plateforme_ok === 'OK') && (value.reception_hub_ok === 'OK') && ((!!value.plateforme_transit_code) && (value.depart_hub_ok === 'NK')) && (!value.livraison_ok)) {
      tab['statut'] = this.translate.instant('Anomalie lors du chargement - Dépot national');
      tab['color'] = 'bg-danger';
    } else if ((!value.solde) && (value.reception_plateforme_ok === 'OK' || !value.reception_plateforme_ok) && (value.depart_plateforme_ok === 'OK') && ((!value.plateforme_transit_code) || ((value.reception_hub_ok === 'OK') && (value.depart_hub_ok === 'OK'))) && (!value.livraison_ok)) {
      tab['statut'] = this.translate.instant('Livraison en cours');
      tab['color'] = 'bg-warning';
    } else if ((value.solde === 'OK') && (value.livraison_ok === 'OK')) {
      tab['statut'] = this.translate.instant('Livré');
      tab['color'] = 'bg-green';
    } else if ((value.livraison_ok === 'NK')) {
      tab['statut'] = this.translate.instant('Anomalie lors de la livraison');
      tab['color'] = 'bg-danger';
    } else if ((!value.solde) && (((value.reception_plateforme_ok === 'OK') && (value.depart_plateforme_ok === 'AT') && (!value.plateforme_transit_code)) || ((value.reception_hub_ok === 'OK') && (value.depart_hub_ok === 'AT') && (!!value.plateforme_transit_code))) && (value.livraison_ok === 'AT')) {
      tab['statut'] = this.translate.instant('Avis de passage');
      tab['color'] = 'bg-warning';
    } else if ((value.solde === 'OK') && (!value.livraison_ok)) {
      tab['statut'] = this.translate.instant('Soldé - annulé');
      tab['color'] = 'bg-danger';
    }
    return tab;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
