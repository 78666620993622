import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import Swal from "sweetalert2";
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../../../../shared/services/authentication.service';
import {UserService} from '../../../../../shared/services/api/sso/user.service';
import {User} from '../../../../../shared/models/user.model';
import {TranslateService} from '@ngx-translate/core';
import {UserData} from "../../../../../shared/models/user-data.model";

@Component({
  selector: 'app-edit-password-user',
  templateUrl: './edit-password-user.component.html',
  styleUrls: ['./edit-password-user.component.scss']
})
export class EditPasswordUserComponent implements OnInit {

  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Output() submited: EventEmitter<void> = new EventEmitter();
  @Input() username: string;
  @Input() userId: number;

  public currentUser: UserData;
  public subscriptions: Subscription[] = [];

  // loading
  public loading: Boolean = false;
  public loadingBtn: Boolean = false;

  //form
  public passwordForm: FormGroup;
  public formSubmit = false;

  constructor(private translate: TranslateService, private fb: FormBuilder, private toastr: ToastrService, private authSvc: AuthenticationService, private userSvc: UserService) { }

  ngOnInit() {
    this.loading = true;
    this.authSvc.currentUser.subscribe(x => this.currentUser = x);
    this.subscriptions.push(this.userSvc.getDataUserByUserId(this.userId).subscribe(
      (user) => {
        this.currentUser = user;
        this.initForm();
      }));
  }

  initForm() {
    this.passwordForm = this.fb.group({
      username: [this.currentUser.username, Validators.required],
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required]
      }, {validator: this.MustMatch('newPassword', 'confirmNewPassword')});
    this.loading = false;
  }

  onSubmitResetPassword() {
    this.formSubmit = true;
    if (this.passwordForm.invalid) {
      return;
    }
    this.loadingBtn = true;
    const sub1 = this.userSvc.resetPassword(this.username, this.passwordForm.value).subscribe(
      next => {
        sub1.unsubscribe();
        this.loadingBtn = false;
        this.formSubmit = false;
        this.loadingBtn = false;
        this.toastr.info(
          this.translate.instant("Modifications de mot de passe enregistrés"),
          this.translate.instant("Informations"),
          {progressBar: true}
        );
        this.submited.emit(next);
      },
      error => {
        this.loadingBtn = false;
        this.formSubmit = false;
        Swal.fire({
          title: this.translate.instant('Désolé'),
          text: this.translate.instant('Une erreur est survenue'),
          type: 'error',
          showCancelButton: false,
        })
      }
    );
  }

  cancel() {
    this.canceled.emit();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.passwordForm.controls;
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }}

}
