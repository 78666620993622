<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">
  <div class="row m-0 py-2 px-2">
    <h1
      class="page-header text-center m-auto padding-h1">{{'Historique des demandes GRC' | translate }}</h1>
  </div>
  <div class="row m-0 padding-tab">
    <div class="col-md-12 col-lg-6 pt-4 m-0">
      <panel variants="danger" title="{{'Destinataire' | translate }}" noButton="true"
             bodyClass="no-padding panel"
             class="text-center bg-primary shadow">
        <div class="scrollbar scrollbar-black bordered-black square thin overflow-scroll text-left"
             style="min-height: 140px">
          <app-destinataire-gestion-relation-clients-wexlog [grcData]="grcWexlog"
                                                            [loading]="loadingInfosGrc"></app-destinataire-gestion-relation-clients-wexlog>
        </div>
      </panel>
    </div>
    <div class="col-md-12 col-lg-6 pt-4 m-0">
      <panel variants="danger" title="{{'Informations de la commande' | translate }}"
             noButton="true" bodyClass="no-padding panel"
             class="text-center">
        <div class="scrollbar scrollbar-black bordered-black square thin overflow-scroll text-left py-3"
             style="min-height: 140px">
          <app-infos-commande-wexlog [grcData]="grcWexlog" [loading]="loadingInfosGrc"
                                     [showViewCommandeBtn]="view.hasDroit('COMMANDE_SHOW')"></app-infos-commande-wexlog>
        </div>
      </panel>
    </div>
  </div>
  <div class="row m-0 padding-tab">
    <div class="col-sm-12 py-4 m-0">
      <panel noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-xl-10 col-sm-8 col-12 title-table"><h5 class="p-t-5 text-agediss">{{'Gestion relation client' | translate }}</h5></div>
          <div *ngIf="view.hasDroit('GRC_CREATE') && view.origine === 'wexlog' && view.contextId === '3891'"
               class="offset-xl-0 col-xl-2 offset-sm-0 col-sm-4 offset-2 col-8">
            <button class="btn btn-radius btn-agediss btn-block text-truncate shadow"
                    (click)="openModalNouvelleDemande(modal_nouvelle_demande_wexlog)"
            >{{'Nouvelle demande GRC' | translate }}
            </button>
          </div>
        </div>
        <div>
          <app-table-grc-by-commande-wexlog [data]="grcDataWexlog"
                                            [loading]="loadingTableGrcWewlog"
                                            [showInfosBtn]="true"
                                            [showFileBtn]="view.hasDroit('GRC_PIECE-JOINTE_SHOW')"
                                            [showDeleteDemandGrcBtn]="view.hasDroit('GRC_DELETE')"
                                            [showViewMessageBtn]="view.hasDroit('GRC_CONVERSATION_SHOW')"
                                            (selectConversation)="openModalvisualisationMessages(modal_visualisation_messages_wexlog, $event)"
                                            (onSelectFile)="openModalVisualisationFile(modal_visualisation_file, $event)"
                                            [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"></app-table-grc-by-commande-wexlog>
        </div>
      </panel>
    </div>
  </div>
</div>

<!-- modal visualisation messages -->
<ng-template #modal_visualisation_messages_wexlog let-modal>
  <app-visualisation-demande-grc-wexlog [wexlogGrcData]="grcWexlog" [loading]="loading"
                                 (canceled)="modal.close($event)"></app-visualisation-demande-grc-wexlog>
</ng-template>
<!-- fin de modal visualisation messages -->

<!-- modal visualisation file -->
<ng-template #modal_visualisation_file let-modal>
  <app-file-preview-ged [piecesJointesGed]="grcWexlog.doc" [doc]="true" (canceled)="modal.close($event)"></app-file-preview-ged>
</ng-template>
<!-- fin de modal visualisation file -->

<!-- modal nouvelle demande-grc-wexlog -->
<ng-template #modal_nouvelle_demande_wexlog let-modal>
  <app-nouvelle-demande-grc-wexlog [referenceCommande]="grcWexlog.ord_id" [origine]="'wexlog'" [numeroClient]="grcWexlog.numero_client" (submitted)="modal.close($event)"  (canceled)="modal.close()"></app-nouvelle-demande-grc-wexlog>
</ng-template>
<!-- fin de modal nouvelle demande-grc-wexlog -->
