import {Deserializable} from '../interfaces/deserializable.interface';

export class SuiviLivraisonData implements Deserializable {

  public id?: number;
  public numero_avis?: number;
  public numero_commande?: string;
  public activite_nom?: string;
  public activite_libelle?: string;
  public activite_detail_libelle?: string;
  public numero_commande_2?: string;
  public reference_commande?: string;
  public destinataire_nom?: string;
  public destinataire_code_postal?: string;
  public destinataire_ville?: string;
  public destinataire_pays?: string;
  public reception_statut?: string;
  public reception_evenement?: string;
  public reception_date_evenement: Date | string;
  public chargement_statut?: string;
  public chargment_evenement?: string;
  public chargement_date_evenement?: Date | string;
  public livraison_statut?: string;
  public livraison_evenement?: string;
  public livraison_date_evenement?: Date | string;
  public pending: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this
  }

}
