import {Component, Input, OnInit} from '@angular/core';
import {WexlogGrcData} from "../../../shared/models/api/wexlog/wexlog-grc-data.model";

@Component({
  selector: 'app-destinataire-gestion-relation-clients-wexlog',
  templateUrl: './destinataire-gestion-relation-clients-wexlog.component.html',
  styleUrls: ['./destinataire-gestion-relation-clients-wexlog.component.scss']
})
export class DestinataireGestionRelationClientsWexlogComponent implements OnInit {
  @Input() grcData: WexlogGrcData;
  @Input() loading = false;
  public loadingPage = false;

  constructor() { }

  ngOnInit() {
    if(!this.grcData) {
      throw new Error('Le composent destinataire attend un objet de type WexlogGrcData.')
    }
  }

}
