<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">
  <div class="row m-0 px-2" *ngIf="view.origine === 'tradiss'">
    <div class="col-sm-12 py-4 m-0">
      <panel noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-xl-10 col-sm-8 col-12 title-table"><h5
            class="pt-2 text-agediss m-0">{{'Historique des demandes GRC' | translate}} : <span
            *ngIf="!loadingNbGrc">{{nbData}}</span> <i *ngIf="loadingNbGrc" class='fas fa-spin fa-circle-notch'></i></h5></div>
          <div *ngIf="view.hasDroit('GRC_CREATE')"
               class="offset-xl-0 col-xl-2 offset-sm-0 col-sm-4 offset-2 col-8">
            <button class="btn btn-radius btn-agediss btn-block text-truncate shadow"
                    (click)="openModalNouvelleDemande(modal_nouvelle_demande)"
            >Nouvelle demande
            </button>
          </div>
        </div>
        <div>
          <app-table-grc-by-commande [data]="grcsData"
                                     [length]="nbData" [pageSize]="pageSize"
                                     (paginatorEvent)="onLoadDataGrcByEvent($event)" [pageSizeOptions]="pageSizeOption"
                                     [loading]="loadingTableGrc" [showInfosBtn]="true"
                                     (selectConversation)="openModalvisualisationMessages(modal_visualisation_messages, $event)"
                                     (onSelectFile)="openModalVisualisationFile(modal_visualisation_file, $event)"
                                     (deletedGrc)="onDeleteGrc($event)"
                                     [showDeleteDemandGrcBtn]="view.hasDroit('GRC_DELETE')"
                                     [showViewMessageBtn]="view.hasDroit('GRC_CONVERSATION_SHOW')"
                                     [showFileBtn]="view.hasDroit('GRC_PIECE-JOINTE_SHOW')">
          </app-table-grc-by-commande>
        </div>
      </panel>
    </div>
  </div>
  <div class="row m-0 px-2" *ngIf="view.origine === 'wexlog'">
    <div class="col-sm-12 py-4 m-0">
      <panel noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-xl-10 col-sm-8 col-12 title-table"><h5 class="p-t-5 text-agediss">{{'Mes demandes GRC' | translate }}</h5></div>
          <div *ngIf="view.hasDroit('GRC_CREATE') && view.origine === 'wexlog' && commande.reference_commande !== null && view.contextId === '3891'"
               class="offset-xl-0 col-xl-2 offset-sm-0 col-sm-4 offset-2 col-8">
            <button class="btn btn-radius btn-agediss btn-block text-truncate shadow"
                    (click)="openModalNouvelleDemandeWexlog(modal_nouvelle_demande_wexlog)"
            >{{'Nouvelle demande GRC' | translate }}
            </button>
          </div>
        </div>
        <div *ngIf="commande.reference_commande === null" style="height: 50px"
             class="text-center py-2">{{"La GRC n'est pas disponible pour cette commande" | translate }}</div>
        <div *ngIf="commande.reference_commande !== null">
          <app-table-grc-by-commande-wexlog [data]="grcDataWexlog"
                                            [loading]="loadingTableGrcWewlog"
                                            [showInfosBtn]="true"
                                            [showFileBtn]="view.hasDroit('GRC_PIECE-JOINTE_SHOW')"
                                            [showDeleteDemandGrcBtn]="view.hasDroit('GRC_DELETE')"
                                            [showViewMessageBtn]="view.hasDroit('GRC_CONVERSATION_SHOW')"
                                            (selectConversation)="openModalvisualisationMessagesWexlog(modal_visualisation_messages_wexlog, $event)"
                                            (onSelectFile)="openModalVisualisationFile(modal_visualisation_file_wexlog, $event)"
                                            (deletedGrc)="onDeleteGrc($event)"
                                            [pageSize]="5"
                                            [pageSizeOptions]="[5, 10, 20]"></app-table-grc-by-commande-wexlog>
        </div>
      </panel>
    </div>
  </div>

  <!-- modal Nouvelle demande -->
  <ng-template #modal_nouvelle_demande let-modal>
    <app-nouvelle-demande [grcNumeroAvis]="commande.numero_avis" (submited)="onNewMessageSubmited($event)"
                          (canceled)="modal.close($event)"></app-nouvelle-demande>
  </ng-template>
  <!-- fin de modal Nouvelle demande -->

  <!-- modal visualisation messages -->
  <ng-template #modal_visualisation_messages let-modal>
    <app-visualisation-demande-grc [grcData]="grcData" (submited)="modal.close($event)"
                                   (canceled)="modal.close($event)"></app-visualisation-demande-grc>
  </ng-template>
  <!-- fin de modal visualisation messages -->

  <ng-template #modal_visualisation_file let-modal>
    <app-file-preview-ged [piecesJointesGed]="grcData.doc" [doc]="true"
                          (canceled)="modal.close($event)"></app-file-preview-ged>
  </ng-template>


  <!-- modal visualisation messages wexlog-->
  <ng-template #modal_visualisation_messages_wexlog let-modal>
    <app-visualisation-demande-grc-wexlog [wexlogGrcData]="grcWexlog" [loading]="loading"
                                          (canceled)="modal.close($event)"></app-visualisation-demande-grc-wexlog>
  </ng-template>
  <!-- fin de modal visualisation messages wexlog-->

  <!-- modal visualisation file wexlog-->
  <ng-template #modal_visualisation_file_wexlog let-modal>
    <app-file-preview-ged [piecesJointesGed]="grcWexlog.doc" [doc]="true"
                          (canceled)="modal.close($event)"></app-file-preview-ged>
  </ng-template>
  <!-- fin de modal visualisation file -->

  <!-- modal nouvelle demande-grc-wexlog -->
  <ng-template #modal_nouvelle_demande_wexlog let-modal>
    <app-nouvelle-demande-grc-wexlog [referenceCommande]="commande.reference_commande" [origine]="'tradiss'"
                                     [numeroClient]="commande.numero_client" (submitted)="modal.close($event)"
                                     (canceled)="modal.close()"></app-nouvelle-demande-grc-wexlog>
  </ng-template>
  <!-- fin de modal nouvelle demande-grc-wexlog -->
