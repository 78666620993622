import {Injectable} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import 'rxjs-compat/add/operator/do';
import Swal from 'sweetalert2';
import {LogService} from '../services/log.service';
import {AgedissError} from '../models/agediss-error.model';
import {Router} from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService,
    private logSvc : LogService,
    private router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {



    return next.handle(request)
      .pipe(
        catchError(err => {

          // si on est en présence d'une erreur de type AgedissError c'est que l'erreur vient du controle de l'api (Interceptor)
          if(err.error instanceof AgedissError){
            this.logSvc.logError(err.error);
            return next.handle(request);
          }

            switch (err.status) {
              case 400 :
                HttpErrorInterceptor.handleStatus400(err, request, next);
                break;
              case 404 :
                HttpErrorInterceptor.handleStatus404(err, request, next);
                break;
              case 401 :
                this.handleStatus401(err);
                break;
              case 500 :
                this.handleStatus500(err, request, next);
                break;
            }

            return next.handle(request);
          }
        )
      );
  }

  private static handleStatus404(err: any, request: HttpRequest<any>, next: HttpHandler) {

    switch (err.error.code) {
      case 2602 :
        Swal.fire({text: `l'url api suivante ${err.url} n'existe pas`, titleText: 'Attention', type: 'warning'});
        break;// l'url n'existe pas
      case 2102 :
        return next.handle(request);

      default:return next.handle(request);
    }
  }


  private  handleStatus401(err: any) {
    switch (err.error.code) {
      case 2400 :
        alert("Vous n'avez pas accès à l'application Espace-client, vous allez être redirigé vers la liste des applications");
        return of(this.authenticationService.redirecToSso());
      default:
        return of(this.authenticationService.logout());
    }
  }

  private static handleStatus400(err: any, request: HttpRequest<any>, next: HttpHandler) {
    switch (err.error.code) {
      case 2100 :
        return next.handle(request); // TODO voir si on peut généralisé l'interception des erreurs avec le code 2100 "erreur saisie formulaire"
      default:return next.handle(request);
    }
  }

  private  handleStatus500(err: any, request: HttpRequest<any>, next: HttpHandler) {
    switch (err.error.code) {
      case 2100 :
        return next.handle(request);
      case 1 :
        this.router.navigate(['/','error']);
        return next.handle(request);
      default:
        return next.handle(request);
    }
  }
}
