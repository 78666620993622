<div class="row m-0 py-3">
  <div class="col-xl-6 col-lg-3 col-6 p-0">
    <label class="col-12">{{'Assurance' | translate }}
      <div class="input-group">
        <span class="input-group-prepend">
         <span class="input-group-text text-white"
               [ngClass]="{'bg-dark': !litigeData.has_assurance, 'bg-red-agediss': litigeData.has_assurance}"> {{litigeData.has_assurance | ouiNon}}
         </span>
        </span>
        <input type="text" readonly [value]="litigeData?.montant_assurance || null"
               class="input form-control form-control-filter">
        <span class="input-group-append"><span class="input-group-text input-group-border-left"> € </span></span>
      </div>
    </label>
  </div>
  <div class="col-xl-6 col-lg-3 col-6 p-0">
    <label class="col-12">{{'Réparable' | translate }}
      <div class="input-group">
        <div class="input-group-prepend">
                    <span class="input-group-text text-white"
                          [ngClass]="{'bg-dark': !litigeData.is_reparable, 'bg-red-agediss': litigeData.is_reparable}">{{litigeData.is_reparable | ouiNon}}</span>
        </div>
        <input type="text" readonly [value]="litigeData?.montant_reparation || null"
               class="input form-control form-control-filter">
        <span class="input-group-append">
            <span class="input-group-text input-group-border-left"> € </span>
          </span>
      </div>
    </label>
  </div>
  <div class="col-xl-6 col-lg-3 col-6 p-0">
    <label class="col-12">{{'Dédommagement' | translate }}
      <div class="input-group">
        <div class="input-group-prepend">
              <span class="input-group-text text-white"
                    [ngClass]="{'bg-dark': !litigeData.is_dedomagement, 'bg-red-agediss': litigeData.is_dedomagement}">{{litigeData.is_dedomagement | ouiNon}}
              </span>
        </div>
        <input type="text" readonly [value]="litigeData?.montant_dedomagement || null"
               class="input form-control form-control-filter">
        <span class="input-group-append">
            <span class="input-group-text input-group-border-left"> € </span>
          </span>
      </div>
    </label>
  </div>
  <div class="col-xl-6 col-lg-3 col-6 p-0">
    <label class="col-12 text-truncate">{{'Montant réclamation' | translate }}
      <div class="input-group">
        <input type="text"
               readonly [value]="litigeData.montant_reclamation !== undefined ? litigeData.montant_reclamation : 0"
               class="input form-control form-control-filter">
        <span class="input-group-append">
            <span class="input-group-text input-group-border-left"> € </span>
          </span>
      </div>
    </label>
  </div>
</div>
