<div *ngIf="view.hasDroit('EXTRACTION_MENU_EXTRACTION')">
  <div class="row m-0 padding">
    <h1 class="page-header m-auto text-center padding-h1">{{'Mes extractions' | translate }}</h1>
  </div>

  <div class="row m-0 padding-table" tourAnchor="tableau.extractions">
    <div class="col-12 m-0 alert alert-light fade show shadow">
      <app-filter-extraction [showTableExtraction]="showTableExtraction"
                             (submitFilter)="onSubmit($event)"></app-filter-extraction>
    </div>
  </div>

  <div class="row m-0 padding-table"
       *ngIf="showTableExtraction && view.hasDroit('EXTRACTION_DOWNLOAD') && nbData > nbDataMax">
    <div class="col-12 alert alert-danger text-center py-2" role="alert">
      <div>
        {{"La quantité d'information est trop importante pour effectuer une extraction excel" | translate }}
      </div>
      <div>
        {{"Vous pouvez réduire votre intervalle de date de saisie et effectuer votre extraction en plusieurs fois par exemple" | translate }}
      </div>
    </div>
  </div>

  <div class="row m-0 padding-table" *ngIf="showTableExtraction">
    <div class="col-12 p-0">
      <panel noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-xl-10 col-sm-8 col-12 title-table"><h5
            class="p-t-5 text-agediss">{{'Mes extractions' | translate }} : <span
            *ngIf="!loadingNbExtraction">{{nbData}}</span> <i *ngIf="loadingNbExtraction"
                                                              class='fas fa-spin fa-circle-notch'></i></h5>
          </div>
          <div *ngIf="view.hasDroit('EXTRACTION_DOWNLOAD') && nbData < nbDataMax"
               class="offset-xl-0 col-xl-2 offset-sm-0 col-sm-4 offset-2 col-8">
            <button class="btn btn-radius btn-agediss btn-block text-truncate shadow" [ladda]="loadingExtractionBtn"
                    data-style="zoom-in"
                    (click)="exportArray()">{{'Extraire' | translate }}
            </button>
          </div>
          <div *ngIf="view.hasDroit('EXTRACTION_DOWNLOAD') && nbData > nbDataMax"
               class="offset-xl-0 col-xl-2 offset-sm-0 col-sm-4 offset-2 col-8">
            <div class="btn btn-radius glow text-white btn-block text-truncate shadow">
              {{"Extraction Excel trop importante" | translate }}
            </div>
          </div>
        </div>
        <div>
          <app-table-extractions [data]="extractionsData" [length]="nbData" [pageSize]="pageSize"
                                 (paginatorEvent)="onLoadDataByEvent($event)" [pageSizeOptions]="pageSizeOption"
                                 [showInfosBtn]="true" [loading]="loadingTableExtraction"></app-table-extractions>
        </div>
      </panel>
    </div>
  </div>
</div>

