import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../shared/services/api/sso/user.service';
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {UserData} from "../../shared/models/user-data.model";
import {FilterMultiCritereModel} from "../../shared/models/filters/filterMultiCritere.model";
import {PaginationService} from "../../shared/services/pagination.service";
import {PageEvent} from "@angular/material/paginator";
import {GrcService} from "../../shared/services/api/grc.service";
import {NotificationGrcData} from "../../shared/models/api/notification-grc-data.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import Swal from "sweetalert2";
import {ToastrService} from "ngx-toastr";
import {User} from "../../shared/models/user.model";
import {View} from "../../shared/models/view.model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-parametres',
  templateUrl: './parametres.html',
  styleUrls: ['./parametres.scss']
})
export class ParametresPage implements OnInit, OnDestroy {

  @Output() submited: EventEmitter<User> = new EventEmitter();

  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public user: UserData;
  public activModal: NgbModalRef;
  public subscription: Subscription;
  public currentUser: UserData;
  public selectUsername: string = null;
  public selectUserId: number;
  public selectClient: string = null;
  public selectEmail: string = null;
  public selectId: number = null;
  public notifLength: number = null;
  public notificationGrcData: NotificationGrcData;
  public addParamMailGrcForm: FormGroup;
  public formSubmit = false;
  public view: View

  // tableau
  public notificationsGrcData: NotificationGrcData[];
  public pageSizeOption = [5, 10, 20];
  public subscriptions: Subscription[] = [];
  public tbNumeroClient: [] = [];

  // number
  public pageSize = 5;
  public nbData: number = 0;

  // loading
  public loading = false;
  public loadingBtn: boolean = false;
  public loadingTableNotification = false;
  public loadingNbNotification = false;

  @Output() canceled: EventEmitter<void> = new EventEmitter();

  constructor(private translate: TranslateService,
              private grcSvc: GrcService,
              private userSvc: UserService,
              private authSvc: AuthenticationService,
              private paginationSvc: PaginationService,
              private toastr: ToastrService,
              private router: Router,
              private modalSvc: NgbModal) {
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
    this.authSvc.currentView.subscribe(x => this.view = x);
    this.authSvc.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    if (this.view.hasDroit('UTILISATEUR_MENU_PARAMETRE')) {
      this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
        this.loadDataUser();
        if(this.view.hasDroit('GRC_PARAMETRE-NOTIFICATION-MAIL_SHOW') && this.currentUser.organisation.name === 'Client' && this.view.origine === 'tradiss') {
          this.loadDataNotification()
        }
      }))
      this.loadDataUser();
      if(this.view.hasDroit('GRC_PARAMETRE-NOTIFICATION-MAIL_SHOW') && this.currentUser.organisation.name === 'Client' && this.view.origine === 'tradiss') {
        this.loadDataNotification()
      }
    } else {
      this.router.navigate(['/', 'home']);
    }
  }

  loadDataUser() {
    console.log(this.currentUser)
    this.loading = true;
    this.subscriptions.push(this.userSvc.getDataUserByUserId(this.currentUser.id).subscribe((user) => {
      this.user = user;
      this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    ));
  }

  loadDataNotification() {
    this.loadingTableNotification = true;
    this.loadingNbNotification = true;
    this.filter.username = this.currentUser.username;
    this.filter.context = 'WITHSUBCONTEXTID';
    this.notificationsGrcData = [];
    this.nbData = 0;
    this.subscriptions.push(this.grcSvc.getNotificationGrc(this.filter).subscribe((notification) => {
      this.notificationsGrcData = notification.data;
      this.notifLength = notification.data.length
      this.nbData = notification.nb;
      this.loadingTableNotification = false;
      this.loadingNbNotification = false;
      },
      (error) => {
        this.loadingTableNotification = false;
        this.loadingNbNotification = false;
      }
    ));
  }

  onLoadDataByEvent(event: PageEvent) {
    this.paginationSvc.onPaginationEvent(this.filter, event)
    this.loadDataNotification();
  }

  /**
   * On édit les informations de l'utilisateur
   * @param modalName
   * @param value
   */
  openModalEditDataUser(modalName, value: { id: number, username: string }) {
    this.selectUsername = value.username;
    this.selectUserId = value.id;
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg', keyboard: false
    });
    this.activModal.result.then(() => {
      this.loadDataUser();
      if(this.view.hasDroit('GRC_PARAMETRE-NOTIFICATION-MAIL_SHOW') && this.currentUser.organisation.name === 'Client' && this.view.origine === 'tradiss') {
        this.loadDataNotification()
      }
    });
  }

  /**
   * On édit le mot de passe de l'utilisateur
   * @param modalName
   * @param value
   */
  openModalEditPasswordUser(modalName, value: { id: number, username: string }) {
    this.selectUsername = value.username;
    this.selectUserId = value.id;
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg', keyboard: false
    });
  }

  initForm() {
    this.addParamMailGrcForm = new FormGroup({
      username: new FormControl(this.user.username, Validators.required),
      email: new FormControl(this.user.email, [Validators.required, Validators.email]),
      activerMessage: new FormControl('N', Validators.required),
      activerDemande: new FormControl('N', Validators.required),
    });
    this.loading = false;
  }

  submitParamsMailGrc() {
    this.initForm();
    this.loadingBtn = true;
    const sub = this.grcSvc.postNotificationGrcByUsername(this.user.username, this.addParamMailGrcForm.value).subscribe(
      (notification) => {
        sub.unsubscribe();
        this.loadingBtn = false;
        this.toastr.success(
          this.translate.instant("Les paramètres des notifications GRC ont été enregistrés"),
          this.translate.instant("Notifications GRC"),
          {progressBar: true}
        );
        this.submited.emit(notification);
        this.loadDataNotification();
      },
      () => {
        this.loadingBtn = false;
        Swal.fire({
          title: this.translate.instant('Désolé'),
          text: this.translate.instant('Une erreur est survenue'),
          type: 'error',
          showCancelButton: false,
        })
      }
    );
  }

  /**
   * On édit les paramètres des notification Grc par mail
   * @param modalName
   * @param value
   */
  openModalEditDataNotification(modalName, value: { id: number, client: string }) {
    this.selectId = value.id
    this.selectClient = value.client
    this.activModal = this.modalSvc.open(modalName, {
      size: 'md', backdrop: "static", keyboard: false
    });
    this.activModal.result.then(() => {
      this.loadDataNotification();
    });
  }

  /**
   * On édit les paramètres des notification Grc par mail
   * @param modalName
   */
  openModalEditAllDataNotification(modalName) {
    this.selectEmail = this.user.email
    this.activModal = this.modalSvc.open(modalName, {
      size: 'md', backdrop: "static", keyboard: false
    });
    this.activModal.result.then(() => {
      this.loadDataNotification();
    });
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
