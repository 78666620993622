const pageMenuPrincipal = [
  {
    icon: 'fa fa-th-large',
    title: 'Mon tableau de bord',
    url: '/tableau-de-bord',
    restrictRole: 'COMMANDE_MENU_TABLEAU-DE-BORD'
  },
  {
    icon: 'fas fa-exclamation-triangle',
    title: 'Mes anomalies',
    url: '/anomalies',
    restrictRole: 'COMMANDE_MENU_ANOMALIES'
  },
  {
    icon: 'fas fa-truck-moving',
    title: 'Mon suivi de livraison',
    url: '/suivi-de-livraison',
    restrictRole: 'COMMANDE_MENU_SUIVI-LIVRAISON'
  },
  {
    icon: 'fas fa-cubes',
    title: 'Mes livraisons',
    url: '/livraisons',
    restrictRole: 'COMMANDE_MENU_LIVRAISON'
  },
  {
    icon: 'fas fa-cubes',
    title: 'Mes retours',
    url: '/retours',
    restrictRole: 'COMMANDE_MENU_RETOUR'
  },
  {
    icon: 'fa fa-balance-scale',
    title: 'Mes litiges',
    restrictRole: 'LITIGE_MENU_LITIGE',
    caret: true,
    submenu: [
      {
        title: 'Mes litiges',
        url: '/litiges',
        restrictRole: 'LITIGE_MENU_LITIGE'
      },
      {
        title: 'Mes contestations',
        url: '/contestations',
        restrictRole: 'LITIGE_MENU_CONTESTATION'
      },
      {
        title: 'Mes factures',
        url: '/mes-factures',
        restrictRole: 'LITIGE_MENU_FACTURE'
      },
      {
        title: 'Nouvelle facture',
        url: '/nouvelle-facture',
        restrictRole: 'LITIGE_MENU_NOUVELLE-FACTURE'
      },
    ]
  },
  {
    icon: 'fas fa-handshake',
    title: 'Gestion relation client',
    url: '/gestion-relation-clients',
    restrictRole: 'GRC_MENU_GESTION-RELATION-CLIENTS'
  },
  {
    icon: 'fas fa-file-excel',
    title: 'Mes extractions',
    url: '/extractions',
    restrictRole: 'EXTRACTION_MENU_EXTRACTION'
  },
  {
    icon: 'fas fa-file-excel',
    title: 'Mes KPIs',
    url: '/kpi',
    restrictRole: 'LIBRAIRIE_MENU_KPI'
  }
  // {
  //   icon: 'fas fa-play',
  //   title: 'Mes vidéos',
  //   url: '/videos',
  //   restrictRole: 'LIBRAIRIE_MENU_VIDEO'
  // }
];


export default pageMenuPrincipal;
