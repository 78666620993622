<div *ngIf="loading" class="spinner"></div>
<div *ngIf="!loading">
  <div *ngFor="let prestation of data" class="pt-3">
    <div class="row m-0 py-1">
      <div class="col-lg-7 col-md-7 col-sm-7 col-7 p-0">
        <div class="form-group m-0">
          <label class="col-lg-12 col-md-12 col-sm-12">{{'Désignation' | translate }}
            <input type="text" class="input text-truncate form-control form-control-filter"
                   [value]="prestation?.designation"
                   readonly
                   placement="top"
                   container="body"
                   ngbTooltip="{{prestation?.designation}}">
          </label>
        </div>
      </div>
      <div class="col-lg-5 col-md-5 col-sm-5 col-5 p-0">
        <label class="col-lg-12 col-md-12 col-sm-12">{{'Temps' | translate }}
          <div class="input-group">
            <input type="text" class="input form-control form-control-filter" [value]="prestation?.temps"
                   readonly>
            <span class="input-group-append"><span
              class="input-group-text input-group-border-left"> {{'min' | translate }} </span></span>
          </div>
        </label>
      </div>
    </div>
  </div>
</div>
