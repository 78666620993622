import {Injectable} from '@angular/core';
import {Contestation} from "../../models/api/contestation.model";
import {Observable} from "rxjs";
import {HttpUtil} from "../../utils/http.util";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {PieceJointe} from "../../models/api/piece-jointe.model";
import {ContestationData} from "../../models/api/contestation-data.model";

@Injectable({
  providedIn: 'root'
})
export class ContestationService {

  urlRessource = environment.url_api_espace_client + "/contestations";

  constructor(private  http: HttpClient) {}

  /**
   * @param filter
   */
  getContestations(filter: any = null): Observable<Contestation> {
    return this.http
      .get<Contestation>(`${this.urlRessource}`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((contestation) => {
        return new Contestation().deserialize(contestation);
      }));
  }

  /**
   * @param contestationId
   */
  getContestation(contestationId: number): Observable<ContestationData> {
    return this.http
      .get<Contestation>(`${this.urlRessource}/${contestationId}`)
      .pipe(map((contestation) => {
        return new ContestationData().deserialize(contestation);
      }));
  }

  /**
   * @param id
   */
  getPieceJointes(id: number): Observable<PieceJointe> {
    return this.http
      .get<PieceJointe[]>(`${this.urlRessource}/${id}/piece-jointes`)
      .pipe(map((pieceJointes) => {
        return new PieceJointe().deserialize(pieceJointes);
      }));
  }

  /**
   * @return Observable
   */
  updateContestation(contestation: ContestationData): Observable<ContestationData> {
    return this.http
      .put(`${this.urlRessource }/${contestation.id}`, contestation)
      .pipe(map((contestation) => {
        return new ContestationData().deserialize(contestation);
      }));
  }
}
