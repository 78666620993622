import {Injectable} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {User} from '../../../models/user.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpUtil} from '../../../utils/http.util';
import {Role} from '../../../models/api/role.model';
import {UserData} from "../../../models/user-data.model";

@Injectable({providedIn: 'root'})
export class UserService {

  urlRessource = environment.url_api_espace_client + "/sso-users";

  constructor(private http: HttpClient) {}

  /**
   * on récupère les utilisateurs liés au comptes client dans le sso
   * @param filter
   */
  getUsers(filter: {} = {}): Observable<User> {
    return this.http
      .get<User>(`${this.urlRessource}/all-users`,{params:HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((user) => {
          return new User().deserialize(user);
      }));
  }

  /**
   * on récupère les informations d'un utilisateur dans le sso
   * @param userId
   */
  getDataUserByUserId(userId: number): Observable<UserData> {
    return this.http
      .get<UserData>(`${this.urlRessource}/${userId}`)
      .pipe(map((user) => {
        return new UserData().deserialize(user);
      }));
  }

  getDataRoleByUser(userId: number) {
    return this.http
      .get<Role>(`${this.urlRessource}/${userId}/roles`)
      .pipe(map((user) => {
        return new User().deserialize(user);
      }));
  }

  /**
   *
   * @param user
   */
  addUser(user: User): Observable<User> {
    return this.http.post<User>(`${this.urlRessource}/add-user`, user)
      .pipe(map((user) => {
        return new User().deserialize(user);
      }));
  }

  /**
   *
   * @param userId
   * @param user
   */
  updateUser(userId: number, user: User): Observable<any> {
    return this.http.put<{ message: string, next: boolean }>(`${this.urlRessource}/${userId}/update`, user)
      .pipe(map((response) => {
        return new User().deserialize(response);
      }));
  }

  /**
   *
   * @param username
   * @param user
   */
  updateInfosUser(username: string, user: User): Observable<any> {
    return this.http.put<{ message: string, next: boolean }>(`${this.urlRessource}/${username}/update-infos-user`, user)
      .pipe(map((response) => {
        return new User().deserialize(response);
      }));
  }

  /**
   *
   * @param username
   * @param user
   */
  resetPassword(username: string, user: User): Observable<any> {
    return this.http
      .post<{ message: string, next: boolean }>(`${this.urlRessource}/${username}/reset-password`, user)
      .pipe(map((user) => {
        return new User().deserialize(user);
      }));
  }

  /**
   *
   * @param userId
   */
  delete(userId: number): Observable<any> {
    return this.http.delete(`${this.urlRessource}/${userId}/delete`, {});
  }

}
