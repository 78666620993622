import {Deserializable} from '../interfaces/deserializable.interface';
import {ConversationData} from "./conversation-data.model";

export class Conversation implements Deserializable {

  public nb: number;
  public data: ConversationData[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
