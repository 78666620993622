import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Litige} from '../../../../shared/models/api/litige.model';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import {Contestation} from '../../../../shared/models/api/contestation.model';
import {View} from '../../../../shared/models/view.model';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {TranslateService} from "@ngx-translate/core";
import {FilterMultiCritereModel} from "../../../../shared/models/filters/filterMultiCritere.model";
import {ContestationData} from "../../../../shared/models/api/contestation-data.model";
import {PageEvent} from "@angular/material/paginator";
import {PaginationService} from "../../../../shared/services/pagination.service";
import Swal from "sweetalert2";
import {Router} from "@angular/router";

@Component({
  selector: 'app-litige-contestation-content',
  templateUrl: './litige-contestation-content.html',
  styleUrls: ['./litige-contestation-content.scss']
})
export class LitigeContestationContent implements OnInit, OnDestroy {

  @Input() litigeId: number;
  @Input() litigeStatusCode: string;
  @Input() displayedColumns: string[] = [
    'id',
    'numero_avis',
    'numero_commande',
    'created_at',
    'destinataire_nom',
    'client',
    'traite_par',
    'options',
    'options1',
  ];

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  //Tableau
  public pageSizeOption = [5, 10, 20];
  public contestations: Contestation[];
  public contestationData: ContestationData[];
  public subscriptions: Subscription[] = [];

  // number
  public pageSize = 5;
  public nbData: number = 0;

  //loading
  public loadingPage = false;
  public loadingTableContestations = false;
  public loadingNbContestations = false;

  public litige: Litige;
  public activModal: NgbModalRef;
  public innerWidth: number;
  public view: View;

  constructor(private translate: TranslateService,
              private modalSvc: NgbModal,
              private litigeSvc: LitigeService,
              private paginationSvc: PaginationService,
              private authSvc: AuthenticationService) {
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
  }

  ngOnInit() {
    if (!this.litigeStatusCode) {
      throw new Error('Vous devez passer le code statut du litige au composant app-litige-contestation-content');
    }
    this.authSvc.currentView.subscribe(x => this.view = x);
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.loadDataContestation();
    }))
    this.loadDataContestation();
  }

  /**
   * On ajoute une contestation au litige
   * @param modalName
   */
  openModalAddContestation(modalName) {
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg', keyboard: false
    });
    this.activModal.result.then(
      (result) => {
        this.loadDataContestation();
      });
  }

  /**
   * On récupère les contestations en fonction de l'id du litige
   */
  loadDataContestation() {
    this.loadingTableContestations = true;
    this.loadingNbContestations = true;
    this.subscriptions.push(this.litigeSvc.getContestations(this.filter, this.litigeId).subscribe(contestation => {
      this.contestationData = contestation.data;
      this.nbData = contestation.nb;
      this.loadingTableContestations = false;
      this.loadingNbContestations = false;
      },
      (error) => {
        this.loadingTableContestations = false;
        this.loadingNbContestations = false;
      }
    ));
  }

  onLoadDataByEvent(event: PageEvent) {
    this.filter.limit = event.pageSize
    this.filter.offset = event.pageIndex ? event.pageIndex * event.pageSize : 0
    this.loadDataContestation();
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
