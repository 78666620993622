import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AgedissError} from '../models/agediss-error.model';
import {AgedissErrorBody} from '../models/agediss-error-body.model';
import Swal from 'sweetalert2';
import {CookieService} from 'ngx-cookie-service';
export function checkApi(http: HttpClient, cookieSvc: CookieService): () => Promise<any> {
  function removeLoader() {
    const element = document.getElementById('page-loader');
    element.parentNode.removeChild(element);
  }
  function checkCookie(reject){
    if (!cookieSvc.check('_taged_rights_access') || !cookieSvc.check('_taged_view')) {
      console.error('cookie _taged_rights_access or _taged_view not found');
      return reject();
    }
  }
  // inj = new Inject
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      checkCookie(reject);
      return http.get(environment.url_api_espace_client + '/check-connexion')
        .toPromise().then(
          success => {
            resolve(success);
          }
        )
        .catch((err: HttpErrorResponse) => {
          const log = new AgedissError();
          log.message = this.translate.instant('Une erreur est survenue lors du controle de l\'api :', {value: environment.url_api_espace_client} );
          log.name = 'INITIALIZE_APP';
          log.body = new AgedissErrorBody().deserialize({
            erreur: err,
          } as AgedissErrorBody);
          const error = new HttpErrorResponse({
            error: log,
            headers: err.headers,
            status: err.status,
            statusText: err.statusText,
            url: err.url
          });
          if (err.status === 500 || err.status === 0) {
            removeLoader();
            Swal.fire({
              title: 'Attention',
              text: 'L\'application n\'a pas pu vous authentifier sur l\'api : ' + environment.url_api_espace_client + ' \n Veuillez contacter le service informatique',
              showCancelButton: false,
              type: 'error',
              allowOutsideClick: false,
              allowEnterKey: false,
              allowEscapeKey: false,
            }).then(() => {
              document.location.href = environment.urlsso;
            });
            return reject(error);
          }
          // le token est expiré ou absent
          if (err.status === 401 && err.url.match(/refresh/gm) !== null) {
            document.location.href = environment.urlsso;
            return reject(error);
          }
          // l'utilisateur n'est pas présent dans les utilisateur de l'application ou il n'a pas les droit d'accès nécéssaires.
          if (err.status === 401) {
            removeLoader();
            Swal.fire({
              title: 'Attention',
              text: 'Vous n\'avez pas accès à l\'application espace client, vous allez être redirigé vers la liste des applications',
              showCancelButton: false,
              type: 'error',
              allowOutsideClick: false,
              allowEnterKey: false,
              allowEscapeKey: false,
            }).then(() => {
              document.location.href = environment.urlsso;
            });
            return reject(error);
          }
          // on laisse l'interceptor http interpréter le retour
          resolve(error);
        });
    });
  };
}
