<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">
  <div class="row m-0 padding-tab">
    <div class="col-sm-12 py-4 m-0">
      <panel variants="danger" noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-12"><h5 class="p-t-5 text-agediss">{{'Historique' | translate}} : <span *ngIf="!loadingNbHistorique" >{{nbData}}</span> <i *ngIf="loadingNbHistorique" class='fas fa-spin fa-circle-notch'></i></h5></div>
        </div>
        <div>
          <app-table-historique [data]="historiqueData"
                                [length]="nbData" [pageSize]="pageSize"
                                (paginatorEvent)="onLoadDataByEvent($event)" [pageSizeOptions]="pageSizeOption"
                                [showInfosBtn]="true" [loading]="loadingTableHistorique">
          </app-table-historique>
        </div>
      </panel>
    </div>
  </div>
</div>
