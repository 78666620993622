import {Component, Input, OnInit} from '@angular/core';
import {WexlogGrcData} from "../../../shared/models/api/wexlog/wexlog-grc-data.model";
import {View} from "../../../shared/models/view.model";
import {AuthenticationService} from "../../../shared/services/authentication.service";

@Component({
  selector: 'app-infos-commande-wexlog',
  templateUrl: './infos-commande-wexlog.component.html',
  styleUrls: ['./infos-commande-wexlog.component.scss']
})
export class InfosCommandeWexlogComponent implements OnInit {

  @Input() grcData: WexlogGrcData;
  @Input() loading = false;
  public loadingPage = false;
  @Input() showViewCommandeBtn: boolean = false;
  public view: View;

  constructor(private authSvc: AuthenticationService) { }

  ngOnInit() {
    if(!this.grcData) {
      throw new Error('Le composent infos-commande-wexlog attend un objet de type WexlogGrcData.')
    }
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

}
