<table id="extractionAgediss" mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)"
       multiTemplateDataRows matSort
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="code_societe">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Code société' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.code_societe}}
    </td>
  </ng-container>
  <ng-container matColumnDef="numero_avis">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Bon de livraison' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      <a [routerLink]="['/','livraisons',element.numero_avis]" class="btn btn-icon btn-info">
        {{element?.numero_avis}}
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="numero_commande">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Commande' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.numero_commande}}
    </td>
  </ng-container>
  <ng-container matColumnDef="numero_commande_2">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Commande bis' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.numero_commande_2}}
    </td>
  </ng-container>
  <ng-container matColumnDef="numero_client">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Numéro client' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.numero_client}}
    </td>
  </ng-container>
  <ng-container matColumnDef="expediteur_nom">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Expéditeur' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle">
      {{element.expediteur_nom}}
    </td>
  </ng-container>
  <ng-container matColumnDef="activite_nom">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Activité' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.activite_nom}}
    </td>
  </ng-container>
  <ng-container matColumnDef="code_plateforme">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Code plateforme' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.code_plateforme}}
    </td>
  </ng-container>
  <ng-container matColumnDef="plateforme_nom">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Plateforme' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.plateforme_nom}}
    </td>
  </ng-container>
  <ng-container matColumnDef="plateforme_transit_code">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Plateforme transit' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.plateforme_transit_code}}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataire_nom">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Destinataire' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.destinataire_nom}}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataire_adresse">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Adresse' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.destinataire_adresse}}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataire_adresse_complementaire">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Adresse complémentaire' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.destinataire_adresse_complementaire}}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataire_code_postal">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CP' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.destinataire_code_postal}}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataire_ville">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Ville' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.destinataire_localite}}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataire_pays">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Pays' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.destinataire_pays}}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataire_tel_fixe">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Fixe' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.destinataire_tel_fixe}}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataire_tel_portable">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Mobile' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middl text-truncate">
      {{element.destinataire_tel_portable}}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataire_email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Email' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.destinataire_email}}
    </td>
  </ng-container>
  <ng-container matColumnDef="instructions_complementaires_1">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Instr 1' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.instructions_complementaires_1}}
    </td>
  </ng-container>
  <ng-container matColumnDef="instructions_complementaires_2">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Instr 2' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.instructions_complementaires_2}}
    </td>
  </ng-container>
  <ng-container matColumnDef="instructions_complementaires_3">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Instr 3' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.instructions_complementaires_3}}
    </td>
  </ng-container>
  <ng-container matColumnDef="type_habitat">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Type d\'habitat' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.type_habitat}}
    </td>
  </ng-container>
  <ng-container matColumnDef="numero_etage">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Etage' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.numero_etage}}
    </td>
  </ng-container>
  <ng-container matColumnDef="ascenseur">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Ascenseur' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.ascenseur}}
    </td>
  </ng-container>
  <ng-container matColumnDef="interphone">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Interphone' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.interphone}}
    </td>
  </ng-container>
  <ng-container matColumnDef="code_interphone">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Code interphone' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.code_interphone}}
    </td>
  </ng-container>
  <ng-container matColumnDef="nb_colis">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Colis' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.nb_colis}}
    </td>
  </ng-container>
  <ng-container matColumnDef="volume_total">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Volume total' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.volume_total}}
    </td>
  </ng-container>
  <ng-container matColumnDef="poids_total">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Poids total' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.poids_total}}
    </td>
  </ng-container>
  <ng-container matColumnDef="solde">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Solde' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.solde}}
    </td>
  </ng-container>
  <ng-container matColumnDef="date_commande">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Date de commande' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.date_commande}}
    </td>
  </ng-container>
  <ng-container matColumnDef="date_reception_hub">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Date réception HUB' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.date_reception_hub}}
    </td>
  </ng-container>
  <ng-container matColumnDef="date_depart_hub">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Date départ HUB' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.date_depart_hub}}
    </td>
  </ng-container>
  <ng-container matColumnDef="dernier_evt_recep_pf">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Dernier évènement réception PF' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.dernier_evenement_reception_plateforme}}
    </td>
  </ng-container>
  <ng-container matColumnDef="date_dernier_evt_recep_pf">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Date dernier évènement réception PF' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.date_dernier_evenement_reception_plateforme}}
    </td>
  </ng-container>
  <ng-container matColumnDef="com_dernier_evt_recep_pf">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Com dernier évènement réception PF' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.com_dernier_evenement_reception_plateforme}}
    </td>
  </ng-container>
  <ng-container matColumnDef="date_rdv">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Date de rdv' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.date_rdv}}
    </td>
  </ng-container>
  <ng-container matColumnDef="premier_evt_rdv">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Premier évènement rdv' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.premier_evenement_rdv}}
    </td>
  </ng-container>
  <ng-container matColumnDef="date_premier_evt_rdv">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Date premier évènement rdv' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.date_premier_evenement_rdv}}
    </td>
  </ng-container>
  <ng-container matColumnDef="com_premier_evt_rdv">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Com premier évènement RDV' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.com_premier_evenement_rdv}}
    </td>
  </ng-container>
  <ng-container matColumnDef="dernier_evt_rdv">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Dernier évènement RDV' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.dernier_evenement_rdv}}
    </td>
  </ng-container>
  <ng-container matColumnDef="date_dernier_evt_rdv">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Date dernier évènement rdv' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.date_dernier_evenement_rdv}}
    </td>
  </ng-container>
  <ng-container matColumnDef="com_dernier_evt_rdv">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Com dernier évènement RDV' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.com_dernier_evenement_rdv}}
    </td>
  </ng-container>
  <ng-container matColumnDef="dernier_evt_livraison">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Dernier évènement livraison' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.dernier_evenement_livraison}}
    </td>
  </ng-container>
  <ng-container matColumnDef="date_dernier_evt_livraison">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Date dernier évènement livraison' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.date_dernier_evenement_livraison}}
    </td>
  </ng-container>
  <ng-container matColumnDef="com_dernier_evt_livraison">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Com dernier évènement livraison' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.com_dernier_evenement_livraison}}
    </td>
  </ng-container>
  <ng-container matColumnDef="dernier_evt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Dernier évènement' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.dernier_evenement}}
    </td>
  </ng-container>
  <ng-container matColumnDef="date_dernier_evt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Date dernier évènement' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.date_dernier_evenement}}
    </td>
  </ng-container>
  <ng-container matColumnDef="com_dernier_evt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        class="px-10">{{'Com dernier évènement' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.com_dernier_evenement}}
    </td>
  </ng-container>
  <ng-container matColumnDef="groupe_client">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Groupe client' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="px-10 align-middle text-truncate">
      {{element.groupe_client}}
    </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef></th>
    <td class="text-center align-middle" mat-cell *matCellDef="let element">
      <button *ngIf="showInfosBtn" class="button btn btn-rounded-arrow btn-sm btn-agediss mr-1"
              ngbTooltip="{{'Informations complémentaires' | translate }}"
              placement="left"
              container="body"
              (click)="expandedElement = expandedElement === element ? null : element">
        <i class="fas fa-angle-double-down"></i>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
        innerHTML="{{'Aucune extraction' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
        innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Code société' | translate }}
            : </strong><span>{{element?.code_societe  | uppercase}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Commande bis' | translate }}
            : </strong><span>{{element?.numero_commande_2  | uppercase}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description col-12">
          <strong>{{'Numéro client' | translate }} : </strong><span>{{element?.numero_client}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1340"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description col-12">
          <strong>{{'Expéditeur' | translate }} : </strong><span>{{element?.expediteur_nom}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1150"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Activité' | translate }} : </strong><span>{{element?.activite_nom  | uppercase}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description col-12">
          <strong>{{'Code activité' | translate }} : </strong><span>{{element?.activite_code}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Plateforme' | translate }} : </strong><span>{{element?.plateforme_nom}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Code Plateforme transit' | translate }} : </strong><span>{{element?.plateforme_transit_code}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 400"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description col-12">
          <strong>{{'Destinataire' | translate }} : </strong><span>{{element?.destinataire_nom}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 600 || (this.innerWidth > 767 && this.innerWidth < 877)"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'CP' | translate }} : </strong><span>{{element?.destinataire_code_postal}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1051"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Ville' | translate }} : </strong><span>{{element?.destinataire_ville}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Pays' | translate }} : </strong><span>{{element?.destinataire_pays}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Fixe' | translate }} : </strong><span>{{element?.destinataire_tel_fixe}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Mobile' | translate }} : </strong><span>{{element?.destinataire_tel_portable}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Email' | translate }} : </strong><span>{{element?.destinataire_email}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Instr 1' | translate }}
            : </strong><span>{{element?.instructions_complementaires_1}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Instr 2' | translate }}
            : </strong><span>{{element?.instructions_complementaires_2}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Instr 3' | translate }}
            : </strong><span>{{element?.instructions_complementaires_3}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Type d\'habitat' | translate }} : </strong><span>{{element?.type_habitat}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Etage' | translate }} : </strong><span>{{element?.numero_etage}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Ascenseur' | translate }} : </strong><span>{{element?.ascenseur}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Interphone' | translate }} : </strong><span>{{element?.interphone}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Code interphone' | translate }} : </strong><span>{{element?.code_interphone}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Colis' | translate }} : </strong><span>{{element?.nb_colis}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Volume total' | translate }} : </strong><span>{{element?.volume_total}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Poids total' | translate }} : </strong><span>{{element?.poids_total}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Solde' | translate }} : </strong><span>{{element?.solde}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1270"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date de commande' | translate }} : </strong><span>{{element?.date_commande}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date réception HUB' | translate }} : </strong><span>{{element?.date_reception_hub}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date départ HUB' | translate }} : </strong><span>{{element?.date_depart_hub}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Dernier évènement réception PF' | translate }}
            : </strong><span>{{element?.dernier_evenement_reception_plateforme}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date dernier évènement réception PF' | translate }}
            : </strong><span>{{element?.date_dernier_evenement_reception_plateforme}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Com dernier évènement réception PF' | translate }}
            : </strong><span>{{element?.com_dernier_evenement_reception_plateforme}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date de rdv' | translate }} : </strong><span>{{element?.date_rdv}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description col-12">
          <strong>{{'Premier évènement rdv' | translate }}
            : </strong><span>{{element?.premier_evenement_rdv}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date premier évènement rdv' | translate }}
            : </strong><span>{{element?.date_premier_evenement_rdv}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Com premier évènement RDV' | translate }}
            : </strong><span>{{element?.com_premier_evenement_rdv}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description col-12">
          <strong>{{'Dernier évènement RDV' | translate }} : </strong><span>{{element?.dernier_evenement_rdv}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date dernier évènement rdv' | translate }}
            : </strong><span>{{element?.date_dernier_evenement_rdv}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Com dernier évènement RDV' | translate }}
            : </strong><span>{{element?.com_dernier_evenement_rdv}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description col-12">
          <strong>{{'Dernier évènement livraison' | translate }} : </strong><span>{{element?.dernier_evenement_livraison}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date dernier évènement livraison' | translate }}
            : </strong><span>{{element?.date_dernier_evenement_livraison}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Com dernier évènement livraison' | translate }}
            : </strong><span>{{element?.com_dernier_evenement_livraison}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1340"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description col-12">
          <strong>{{'Com dernier évènement' | translate }} : </strong><span>{{element?.dernier_evenement}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Date dernier évènement' | translate }}
            : </strong><span>{{element?.date_dernier_evenement}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Com dernier évènement' | translate }}
            : </strong><span>{{element?.com_dernier_evenement}}</span>
        </div>
      </div>
      <div class="table-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Groupe client' | translate }} : </strong><span>{{element?.groupe_client}}</span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>
<mat-paginator [length]="length"  [pageIndex]="pageIndex" (page)="emit($event)" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
               showFirstLastButtons></mat-paginator>
