import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../../../../shared/models/user.model';
import {forkJoin, Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../../../../shared/services/api/sso/user.service';
import Swal from 'sweetalert2';
import {Client} from '../../../../../shared/models/api/client.model';
import {DroitService} from '../../../../../shared/services/api/sso/droits.service';
import {TranslateService} from '@ngx-translate/core';
import {SousCompteSsoService} from "../../../../../shared/services/api/sso/sous-compte-sso.service";

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Output() submited: EventEmitter<User> = new EventEmitter();

  @Input() username: string;

  public tree;
  public placeholder: string = 'Choisir';

  //tableau
  public subscriptions: Subscription[] = [];
  public dataClients: Client[];
  public selectedDroits: number[] = [];
  public droitsGlobal: any [];

  // loading
  public loading: boolean = false;
  public loadingBtn: boolean = false;
  public loadingClient: boolean = false;
  public loadingDroit: boolean = false;
  public disabled: boolean = false;

  //form
  public addUserInfosForm: FormGroup;
  public formSubmit: boolean = false;

  constructor(private translate: TranslateService,
              private fb: FormBuilder,
              private clientSvc: SousCompteSsoService,
              private droitSvc: DroitService,
              private toastr: ToastrService,
              private userSvc: UserService) {
  }

  ngOnInit() {
    this.loading = true;
    this.initForm();
  }

  initForm() {
    this.subscriptions.push(
      forkJoin([
        this.clientSvc.getSousComptes(),
        this.droitSvc.getDroits()
      ]).subscribe(
        response => {
          this.dataClients = response[0];
          this.tree = response[1];
          if (this.tree.code == 2102) {
            Swal
              .fire({
                title: this.translate.instant('Désolé'),
                text: this.translate.instant("La liste des droits n'est pas disponible !")
              })
              .then(
                () => {
                  this.cancel();
                }
              )
          }
          this.droitsGlobal = [];
          this.getRecursiveRight(this.tree);
          this.loadingClient = false;
          this.loadingDroit = false;
          this.disabled = !this.disabled;
          if (this.disabled == true) {
            this.placeholder = this.translate.instant('Tous les clients sont sélectionnés');
          }
          this.addUserInfosForm = this.fb.group({
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', Validators.required],
            fonction: ['', Validators.required],
            clients: [null],
          });
          this.loading = false;
        },
        () => {
          Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('Une erreur est survenue')
          })
            .then(
              () => {
                this.cancel();
              }
            );
        }
      )
    );
  }

  getRecursiveRight(tree) {
    Object.keys(tree).forEach((key) => {
      let item = tree[key];
      if (item != null) {
        if (item.global_context != undefined) {
          if (this.droitsGlobal[item.global_context] == undefined) {
            this.droitsGlobal[item.global_context] = [];
          }
          this.droitsGlobal[item.global_context].push(item.droitId);
        } else {
          this.getRecursiveRight(item.childrens);
        }
      }
    });
  }

  selectDroit(e, item, libelle, context) {
    if (item.value.libelle == libelle) {
      if (this.droitsGlobal[libelle + '_' + context] != undefined) {
        this.droitsGlobal[libelle + '_' + context].forEach(val => {
          if (e.target.checked) {
            this.droitsGlobal[libelle + '_' + context].checked = true;
            this.selectedDroits.push(val);
          } else {
            this.droitsGlobal[libelle + '_' + context].checked = false;
            this.selectedDroits.splice(this.selectedDroits.findIndex(value => value == val), 1);
          }
        });
      }
    }
  }

  allClients() {
    this.disabled = !this.disabled;
    if (this.disabled == false) {
      this.placeholder = this.translate.instant('Choisir');
    } else {
      this.placeholder = this.translate.instant('Tous les clients sont sélectionnés');
      this.addUserInfosForm.get('clients').reset();
    }
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.selectedDroits.push(parseInt(e.target.value));
    } else {
      this.selectedDroits.splice(this.selectedDroits.findIndex(val => val == parseInt(e.target.value)), 1);
    }
  }

  submitFormAddUser() {
    this.formSubmit = true;
    if (this.addUserInfosForm.invalid) {
      return;
    }
    let values = this.addUserInfosForm.value;
    values.droits = this.selectedDroits;
    this.loadingBtn = true;
    const sub = this.userSvc.addUser(this.addUserInfosForm.value).subscribe(
      (user) => {
        this.submited.emit(user);
        sub.unsubscribe();
        this.loadingBtn = false;
        this.formSubmit = false;
        this.toastr.success(
          this.translate.instant('Un nouvel utilisateur a été créé'),
          this.translate.instant('Informations'),
          {progressBar: true}
        );
      },
      () => {
        this.loadingBtn = false;
        this.formSubmit = false;
        Swal.fire({
          title: this.translate.instant('Désolé'),
          text: this.translate.instant('Une erreur est survenue'),
          type: 'error',
          showCancelButton: false,
        })
      }
    );
  }

  cancel() {
    this.canceled.emit();
  }

  get f() {
    return this.addUserInfosForm.controls;
  }

}
