import {MatPaginatorIntl} from '@angular/material/paginator';
import {TranslateService} from "@ngx-translate/core";

export class PaginatorIntlService extends MatPaginatorIntl {
  translate: TranslateService;
  itemsPerPageLabel = '';
  nextPageLabel     = '';
  previousPageLabel = '';
  firstPageLabel  = '';
  lastPageLabel  = '';
  getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '0 ' + '/' + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' ' + '/' + ' ' + length;
  };

  injectTranslateService(translate: TranslateService) {
    this.translate = translate;

    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });
    this.itemsPerPageLabel = 'Commandes par page'
    this.nextPageLabel     = '';
    this.previousPageLabel = '';
    this.firstPageLabel  = '';
    this.lastPageLabel  = '';
  }

  translateLabels() {
    this.itemsPerPageLabel = this.translate.instant('Commandes par pages');
  }

}



