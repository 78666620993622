<div *ngIf="loading" style="height: 310px">
  <div class="spinner"></div>
</div>
<div *ngIf="!loading">
  <div class="modal-header text-center bg-modal">
    <h4 class="modal-title w-100">{{'Documents & photos' | translate }}</h4>
  </div>
  <div class="modal-body bg-modal">
    <div class="row m-0 py-4 px-2 ">
      <div *ngIf="litigeId || contestationId" class="col-12 p-0 shadow">
        <app-table-file [showInfosBtn]="true"
                        [showAddFileBtn]="view.hasDroit('LITIGE_PIECE-JOINTE_CREATE')"
                        [showDeleteFileBtn]="view.hasDroit('LITIGE_PIECE-JOINTE_DELETE')"
                        [showDownloadFileBtn]="view.hasDroit('LITIGE_PIECE-JOINTE_DOWNLOAD')"
                        [showViewFileBtn]="view.hasDroit('LITIGE_PIECE-JOINTE_SHOW')"
                        [data]="piecesJointes"
                        [loading]="loadingTable"
                        (onAdd)="openModalUpload(modal_file_uploader)"
                        (onSelectFile)="openModalVisualisation(modal_visualisation,$event)">
        </app-table-file>
      </div>
    </div>
  </div>
  <div class="modal-footer p-5">
    <button type="button" class="btn  bg-black-lighter text-white" (click)="cancel()">{{'Fermer' | translate }}</button>
  </div>
</div>

<!--modal upload file-->
<ng-template #modal_file_uploader let-modal>
    <app-file-uploader [showHeader]="true" [idLitige]="litigeId" [idContestation]="contestationId" (canceled)="activModal.close()"
                       (uploaded)="onUploaded($event)"></app-file-uploader>
</ng-template>
<!--fin de modal upload file-->

<!--modal visualisation document-->
<ng-template #modal_visualisation let-modal>
  <app-file-preview-modal [pieceJointeData]="pieceJointe" [piecesJointesGed]="piecesJointesGed" [currentType]="currentType" [showImage]="showImage" [urlPreview]="urlPreview" [loadingPage]="loadingFile" (canceled)="modal.close($event)"></app-file-preview-modal>
</ng-template>
<!-- fin de modal visualisation document-->

