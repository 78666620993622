<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="modal-header">
    <h4
      class="modal-title  w-100 text-center">{{'Nouvelle demande' | translate }}</h4>
  </div>
  <div class="modal-body" style="height: 500px" *ngIf="loadingSubmitBtn">
    <div class="col-12 text-center" style="margin: 150px auto 50px">
      <span class="fas fa-cog fa-spin text-agediss" style="font-size: 40px;"></span>
    </div>
    <div class="col-12 text-center">
      <span class="f-s-20">{{'Envoi de la demande GRC en cours' | translate }}</span>
    </div>
    <div class="col-12 m-t-10" *ngIf="uploader.queue.length > 0">
      <div>{{'Enregistrement des fichiers:' | translate }}
        <div class="progress" style="">
          <div class="progress-bar" role="progressbar"
               [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="!loadingSubmitBtn">
    <form [formGroup]="demandeGrcForm">
      <div class="row m-0 pt-2">
        <div class="col-md-12 px-0 px-lg-3">
          <div class="col-12 p-0">
            <label
              class="d-block d-lg-none">{{'Type de texte' | translate }}</label>
          </div>
          <div class="input-group-prepend">
            <span class="input-group-text input-group-border-right d-none d-lg-flex align-items-center">
              {{'Type de texte' | translate }}
            </span>
            <ng-select class="ng-select filter"
                       [ngClass]="{'is-invalid':demandeGrcForm.get('typeDemandes').invalid && (demandeGrcForm.get('typeDemandes').dirty || formSubmit) }"
                       [items]="typeDemandes"
                       [selectOnTab]="true"
                       bindValue="id"
                       bindLabel="libelle"
                       [loading]="loadingTypeDemande"
                       [clearable]="true"
                       placeholder="{{'Choisir' | translate }}"
                       formControlName="typeDemandes">
            </ng-select>
            <div
              *ngIf="demandeGrcForm.get('typeDemandes').invalid && (demandeGrcForm.get('typeDemandes').dirty || formSubmit)"
              class="text-center invalid-tooltip ml-3 mt-2">
              {{'Veuillez saisir un type de demande !' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="row m-0 pt-2">
        <div class="col-12 col-lg-4 pt-3 px-0 px-lg-3">
          <button class="btn btn-radius btn-agediss btn-block text-truncate shadow" (click)="toggle()">
            {{'Ajouter une pièce jointe' | translate }}
          </button>
        </div>
      </div>
      <div class="row m-0 pt-2" *ngIf="showFileUploader">
        <div class="col-12 px-0 px-lg-3">
          <app-file-uploader-grc [showHeader]="true" [uploader]="uploader"
                                 [idGrc]="grcNumeroAvis" [defaultExtension]="defaultExtension"></app-file-uploader-grc>
          <div class="dropdown-divider mt-4"></div>
        </div>
      </div>
      <div class="row m-0 pt-4">
        <div class="col-md-12 px-0 px-lg-3">
          <label class="col-lg-12 col-md-12 col-sm-12 ">{{'Message' | translate }}
            <textarea rows="6" class="form-control form-control-filter mt-2" formControlName="message"
                      [ngClass]="{'is-invalid': demandeGrcForm.get('message').invalid  && (demandeGrcForm.get('message').dirty || formSubmit)}">
            </textarea>
            <div class="text-center invalid-tooltip ml-3 mt-2"
                 *ngIf="demandeGrcForm.get('message').invalid  && (demandeGrcForm.get('message').dirty || formSubmit)">
              {{'Veuillez saisir un commentaire !' | translate }}
            </div>
          </label>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer p-5" *ngIf="!loadingSubmitBtn">
    <button class="btn btn-agediss text-white" [ladda]="loadingSubmitBtn" data-style="zoom-in"
            (click)="submitForm()">{{'Envoyer' | translate }}
    </button>
    <button class="btn  bg-black-lighter text-white" (click)="cancel()">{{'Fermer' | translate }}</button>
  </div>
</div>

