import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Evenement} from "../../../../shared/models/api/evenement.model";
import {Timeline} from "../../../../shared/models/api/timeline.model";
import {CommandeService} from "../../../../shared/services/api/commande.service";
import {ImageGed} from "../../../../shared/models/api/ged/image-ged.model";
import {FilterMultiCritereModel} from "../../../../shared/models/filters/filterMultiCritere.model";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {PieceJointeGed} from "../../../../shared/models/api/ged/piece-jointe-ged.model";
import {View} from "../../../../shared/models/view.model";
import {AuthenticationService} from "../../../../shared/services/authentication.service";
import {Subscription} from "rxjs";
import {GedService} from "../../../../shared/services/api/ged/ged.service";

@Component({
  selector: 'app-geolocalisation',
  templateUrl: './geolocalisation.component.html',
  styleUrls: ['./geolocalisation.component.scss']
})
export class GeolocalisationComponent implements OnInit {

  @Output() canceled: EventEmitter<void> = new EventEmitter();

  @Input() data: Evenement;
  @Input() dataTimeline: Timeline;
  @Input() commandeId: string;
  @Input() codeSociete: string;
  @Input() numeroClient: string;
  @Input() libelle: string
  @Input() latitude: string
  @Input() longitude: string

  //boolean
  @Input() showViewBlBtn: boolean = false;
  @Input() origine: string;
  @Input() reference: string | undefined;
  @Input() preuveDeLivraisons: boolean = false;
  @Input() loading: boolean = false;

  //tableau
  public subscriptions: Subscription[] = [];

  //number
  public lat: number;
  public lng: number;
  public value: string;
  public styles = [
    {
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#f5f5f5'
        }
      ]
    },
    {
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#616161'
        }
      ]
    },
    {
      'elementType': 'labels.text.stroke',
      'stylers': [
        {
          'color': '#f5f5f5'
        }
      ]
    },
    {
      'featureType': 'administrative.land_parcel',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#bdbdbd'
        }
      ]
    },
    {
      'featureType': 'poi',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#eeeeee'
        }
      ]
    },
    {
      'featureType': 'poi',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#757575'
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#e5e5e5'
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#9e9e9e'
        }
      ]
    },
    {
      'featureType': 'road',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#ffffff'
        }
      ]
    },
    {
      'featureType': 'road.arterial',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#757575'
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#dadada'
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#616161'
        }
      ]
    },
    {
      'featureType': 'road.local',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#9e9e9e'
        }
      ]
    },
    {
      'featureType': 'transit.line',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#e5e5e5'
        }
      ]
    },
    {
      'featureType': 'transit.station',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#eeeeee'
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#c9c9c9'
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#9e9e9e'
        }
      ]
    }
  ];

  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  public evenementRdv: Evenement;
  public blEmarge: ImageGed[];
  public signature: PieceJointeGed[];
  public activModal: NgbModalRef;
  public view: View;

  public showBlEmargeBtn: boolean = false;
  public showSignatureBtn: boolean = false;
  public showDocumentBtn: boolean = false;


  constructor(private commandeSvc: CommandeService,
              private gedSvc: GedService,
              private authSvc: AuthenticationService,
              private modalSvc: NgbModal) {
  }

  ngOnInit() {
    this.value = this.commandeId;
    if (this.origine == 'wexlog') {
      this.value = this.reference;
    }
    this.showDocumentBtn = true;
    this.authSvc.currentView.subscribe(x => this.view = x);
    this.lat = Number.parseFloat(this.latitude);
    this.lng = Number.parseFloat(this.longitude);
    if (this.preuveDeLivraisons) {
      this.loadDataCreneauRdv();
    }
  }


  loadDataCreneauRdv() {
    this.loading = true;
    this.subscriptions.push(this.commandeSvc.getEvenementRdv(this.commandeId).subscribe(
      (evenementRdv) => {
        this.evenementRdv = evenementRdv;
        this.loadBlEmarge()
        this.loadSignature()
        this.loading = false;
      }
    ));
  }

  loadBlEmarge() {
    this.filter.societe = this.codeSociete;
    this.filter.client = this.numeroClient;
    this.subscriptions.push(this.gedSvc.getBlEmarge(this.value, this.filter).subscribe(
      (blEmarge) => {
        this.blEmarge = blEmarge;
        this.showBlEmargeBtn = true;
      }, () => {
        this.showBlEmargeBtn = false;
      }
    ));
  }

  loadSignature() {
    this.subscriptions.push(this.gedSvc.getSignature(this.value).subscribe(
      (signature) => {
        this.signature = signature;
        this.showSignatureBtn = true;
        this.showDocumentBtn = false;
      }, () => {
        this.showSignatureBtn = false;
        this.showDocumentBtn = false;
      }
    ));
  }

  openModalVisualisationDocument(modal: any) {
    this.activModal = this.modalSvc.open(modal, {
      size: 'lg',
      keyboard: false
    });
  }

  cancel() {
    this.canceled.emit();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
