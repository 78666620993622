import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {tableAnimation} from '../../../../../animations';
import {Article} from '../../../../../shared/models/api/article.model';
import {DatatableHelper} from '../../../../../shared/helpers/datatable.helper';

@Component({
  selector: 'app-table-article',
  templateUrl: './table-article.component.html',
  styleUrls: ['./table-article.component.scss'],
  animations: [tableAnimation],
})
export class TableArticleComponent implements OnInit, OnChanges {

  expandedElement: Article | null;

  @Input() data: Article[];
  @Input() displayedColumns: string[] = ['reference', 'libelle', 'nbColis', 'volume', 'poids', 'options'];
  @Input() loading = false;
  @Input() showViewBtn: boolean = false;
  @Input() showInfosBtn: boolean = false;
  @Input() showViewBtnArticle: boolean = false;
  @Input() showEditBtn: boolean = false;
  @Input() showDeleteBtn: boolean = false;

  @Output() selectShowArticle: EventEmitter<{id: number}> = new EventEmitter<{id: number}>();
  @Output() selectArticle: EventEmitter<Article> = new EventEmitter<Article>();

  public innerWidth: number;
  public dataSorted: MatTableDataSource<Article>;

  constructor() {}

  ngOnInit() {
    this.dataSorted = new MatTableDataSource(this.data);
    this.onResizeTable()
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSorted = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      this.loading = changes.loading.currentValue;
    }
  }

  /**
   * Permet d'ordonner les colones.
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSorted = new MatTableDataSource(data);
      return;
    }
    this.dataSorted = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

  selectShowArticleId(id: number) {
    this.selectShowArticle.emit({id});
  };

  onSelectArticle(article: Article) {
    this.selectArticle.emit(article);
  };

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 365) {
      this.displayedColumns = [
        'reference',
        'options',
      ];
    } else if (this.innerWidth > 365 && this.innerWidth <= 600) {
      this.displayedColumns = [
        'reference',
        'libelle',
        'options',
      ];
    } else if (this.innerWidth > 600 && this.innerWidth <= 650) {
      this.displayedColumns = [
        'reference',
        'libelle',
        'nbColis',
        'options',

      ];
    } else if (this.innerWidth > 650 && this.innerWidth <= 700) {
      this.displayedColumns = [
        'reference',
        'libelle',
        'nbColis',
        'volume',
        'options',
      ];
    } else if (this.innerWidth > 700 && this.innerWidth <= 767) {
      this.displayedColumns = [
        'reference',
        'libelle',
        'nbColis',
        'volume',
        'poids',
        'options',
      ];
    } else if (this.innerWidth > 767 && this.innerWidth <= 850) {
      this.displayedColumns = [
        'reference',
        'libelle',
        'nbColis',
        'volume',
        'options',
      ];
    } else if (this.innerWidth > 850 && this.innerWidth <= 991) {
      this.displayedColumns = [
        'reference',
        'libelle',
        'nbColis',
        'volume',
        'poids',
        'options',
      ];
    } else if (this.innerWidth > 991 && this.innerWidth <= 1120) {
      this.displayedColumns = [
        'reference',
        'libelle',
        'options',
      ];
    }  else if (this.innerWidth > 1120 && this.innerWidth <= 1300) {
      this.displayedColumns = [
        'reference',
        'libelle',
        'nbColis',
        'options',
      ];
    } else if (this.innerWidth > 1300 && this.innerWidth <= 1450) {
      this.displayedColumns = [
        'reference',
        'libelle',
        'nbColis',
        'volume',
        'options',
      ];
    } else if (this.innerWidth > 1450) {
      this.displayedColumns = [
        'reference',
        'libelle',
        'nbColis',
        'volume',
        'poids',
        'options',
      ];
    }
  }
}
