<div *ngIf="loading" style="height: 500px" class="row col-12">
    <span class="spinner"></span>
</div>
<div *ngIf="!loading && (idLitige == null || idContestation == null || idCompteClient == null)">
    <div *ngIf="showHeader" class="modal-header bg-modal">
        <h4 class="modal-title  w-100 text-center">{{'Ajouter des documents' | translate }}</h4>
    </div>
    <div class="modal-body bg-modal">
        <div class="row m-b-30 m-t-2">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                <div ng2FileDrop
                     [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                     (fileOver)="fileOverBase($event)"
                     [uploader]="uploader"
                     class="well my-drop-zone text-center">
                    <div class="pt-5">
                        {{'Glissez un fichier ici' | translate }}
                        <div class="mt-2 text-center">
                            <div class="offset-sm-5 col-sm-2 offset-4 col-4 px-0"
                                 style="display:flex; flex-direction: row;">
                                <button class="btn btn-radius btn-agediss btn-block text-truncate shadow"
                                        (click)="fileInput.click()">{{ 'Ajouter un ou plusieurs fichiers' | translate}}
                                    ...
                                </button>
                                <input #fileInput type="file" (change)="onChange($event)" ng2FileSelect
                                       [uploader]="uploader" multiple style="display:none"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>{{'* les formats acceptés:' | translate }} {{(isKpi ? kpiExtension : (isVideo ? videoExtension : defaultExtension ))}}</div>
        </div>
        <div class="row">
            <div class="col-12">
                <table class="table" *ngIf="this.innerWidth > 1000">
                    <thead>
                    <tr>
                        <th class="width20">{{'Nom' | translate }}</th>
                        <th>{{'Extension' | translate }}</th>
                        <th>{{'Taille' | translate }}</th>
                        <th class="width25" *ngIf="!isKpi && !isVideo">{{'Type' | translate }}</th>
                        <th class="width25" *ngIf="isKpi">{{'Compte client' | translate }}</th>
                        <th>{{'Progression' | translate }}</th>
                        <th>{{'Statut' | translate }}</th>
                        <th>{{'Actions' | translate }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of uploader.queue;" class="align-middle">
                        <td class="with-input-group"><input class="form-control" type="text"
                                                            [(ngModel)]="item.formData.name"/></td>
                        <td class="align-middle">{{item.formData.extention}}</td>
                        <td class="align-middle">{{ item?.file?.size / 1024 / 1024 | number:'.2' }} MB</td>
                        <td *ngIf="typePiecesJointes.length > 1 && !isKpi && !isVideo" class="with-input-group">
                            <ng-select class="ng-select"
                                       [ngClass]="{'is-invalid':item.formData.typePjError}"
                                       [items]="typePiecesJointes"
                                       [selectOnTab]="true"
                                       bindValue="id"
                                       bindLabel="libelle"
                                       [clearable]="false"
                                       required
                                       placeholder="{{'Choisir' | translate }}"
                                       [(ngModel)]="item.formData.typePieceJointe">
                            </ng-select>
                        </td>
                        <td *ngIf="typePiecesJointes.length === 1 && !isKpi && !isVideo" class="with-input-group">
                            <ng-select class="ng-select"
                                       [ngClass]="{'is-invalid':item.formData.typePjError}"
                                       [items]="typePiecesJointes"
                                       [selectOnTab]="true"
                                       bindValue="id"
                                       bindLabel="libelle"
                                       [clearable]="false"
                                       required
                                       placeholder="{{'Choisir' | translate }}"
                                       [(ngModel)]="item.formData.typePieceJointe">
                            </ng-select>
                        </td>
                        <td *ngIf="isKpi" class="with-input-group">
                            <ng-select class="ng-select"
                                       [ngClass]="{'is-invalid':item.formData.typePjError}"
                                       [items]="comptesClients"
                                       [selectOnTab]="true"
                                       bindValue="id"
                                       bindLabel="libelle"
                                       [clearable]="false"
                                       required
                                       placeholder="{{'Choisir' | translate }}"
                                       [(ngModel)]="item.formData.compteClient">
                            </ng-select>
                        </td>
                        <td class="align-middle">
                            <div class="progress" style="margin-bottom: 0;">
                                <div class="progress-bar" role="progressbar"
                                     [ngStyle]="{ 'width': item.progress + '%' }"></div>
                            </div>
                        </td>
                        <td class="align-middle text-center">
                            <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                            <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
                        </td>
                        <td nowrap>
                            <button type="button" class="btn btn-green m-r-3"
                                    (click)="uploadItem(item)"
                                    [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                <i class="fa fa-paper-plane " ngbTooltip="{{'Envoyer' | translate }}"
                                   container="body"></i>
                            </button>
                            <button type="button" class="btn btn-danger"
                                    (click)="item.remove()">
                                <i class="fa fa-trash" ngbTooltip="{{'Retirer' | translate }}"
                                   container="body"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <table id="responsive-table" class="table" *ngIf="this.innerWidth <= 1000">
                    <tbody *ngFor="let item of uploader.queue;" class="align-middle">
                    <tr>
                        <td>{{'Nom' | translate }}</td>
                        <td class="with-input-group select-name">
                            <input class="form-control" type="text" [(ngModel)]="item.formData.name"/>
                        </td>
                    </tr>
                    <tr>
                        <td>{{'Extension' | translate }}</td>
                        <td class="align-middle">{{item.formData.extention}}</td>
                    </tr>
                    <tr>
                      <td>{{'Taille' | translate }}</td>
                      <td class="align-middle">{{ item?.file?.size / 1024 / 1024 | number:'.2' }} MB</td>
                    </tr>
                    <tr *ngIf="!isKpi && !isVideo">
                        <td>{{'Type' | translate }}</td>
                        <td *ngIf="typePiecesJointes.length > 1" class="with-input-group select-type">
                            <ng-select class="ng-select"
                                       [ngClass]="{'is-invalid':item.formData.typePjError}"
                                       [items]="typePiecesJointes"
                                       [selectOnTab]="true"
                                       bindValue="id"
                                       bindLabel="libelle"
                                       [clearable]="false"
                                       required
                                       placeholder="{{'Choisir' | translate }}"
                                       [(ngModel)]="item.formData.typePieceJointe">
                            </ng-select>
                        </td>
                        <td *ngIf="typePiecesJointes.length === 1" class="with-input-group">
                            <ng-select class="ng-select"
                                       [ngClass]="{'is-invalid':item.formData.typePjError}"
                                       [items]="typePiecesJointes"
                                       [selectOnTab]="true"
                                       bindValue="id"
                                       bindLabel="libelle"
                                       [clearable]="false"
                                       required
                                       placeholder="{{'Choisir' | translate }}"
                                       [(ngModel)]="item.formData.typePieceJointe">
                            </ng-select>
                        </td>
                    </tr>
                    <tr *ngIf="isKpi">
                        <td>{{'Compte client' | translate }}</td>
                        <td class="with-input-group select-type">
                            <ng-select class="ng-select"
                                       [ngClass]="{'is-invalid':item.formData.typePjError}"
                                       [items]="comptesClients"
                                       [selectOnTab]="true"
                                       bindValue="id"
                                       bindLabel="libelle"
                                       [clearable]="false"
                                       required
                                       placeholder="{{'Choisir' | translate }}"
                                       [(ngModel)]="item.formData.compteClient">
                            </ng-select>
                        </td>
                    </tr>
                    <tr>
                        <td>{{'Progression' | translate }}</td>
                        <td>
                            <div class="progress progress-1" style="margin-bottom: 0;">
                                <div class="progress-bar" role="progressbar"
                                     [ngStyle]="{ 'width': item.progress + '%' }"></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>{{'Statut' | translate }}</td>
                        <td class="align-middle text-center">
                            <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                            <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
                        </td>
                    </tr>
                    <tr>
                        <td>{{'Actions' | translate }}</td>
                        <td>
                            <button type="button" class="btn btn-green m-r-3"
                                    (click)="uploadItem(item)"
                                    [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                <i class="fa fa-paper-plane " ngbTooltip="{{'Envoyer' | translate }}"
                                   container="body"></i>
                            </button>
                            <button type="button" class="btn btn-danger"
                                    (click)="item.remove()">
                                <i class="fa fa-trash" ngbTooltip="{{'Retirer' | translate }}"
                                   container="body"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer p-5">
        <button type="button" class="btn btn-green btn-s m-r-3"
                (click)="sentAllFile()" [ladda]="laddaSentButton">
            <span class="glyphicon glyphicon-upload"></span>{{'Tout envoyer' | translate }}
        </button>

        <button type="button" class="btn btn-danger btn-s m-r-3"
                (click)="uploader.clearQueue()" [disabled]="disabledButton">
            <span class="glyphicon glyphicon-trash"></span>{{'Tout retirer' | translate }}
        </button>
        <button *ngIf="showReturnButton" type="button" class="btn bg-black-lighter text-white" (click)="cancel()">
            {{'Fermer' | translate }}
        </button>
    </div>
</div>
