import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {environment} from '../../../../../environments/environment';
import {WexlogTypeMessageGrc} from "../../../models/api/wexlog/wexlog-type-message-grc.model";


@Injectable({
  providedIn: 'root'
})
export class  TypeMessageGrcWexlogService {


  urlRessource = environment.url_api_espace_client + "/wexlog-type-message-grc";

  constructor(private http: HttpClient) {

  }

  getTypeMessagesGrcClient(): Observable<WexlogTypeMessageGrc[]> {
    return this.http
      .get<WexlogTypeMessageGrc[]>(`${this.urlRessource}`)
      .pipe(map((observe) => observe.map(function (observe) {
        return new WexlogTypeMessageGrc().deserialize(observe);
      })))
  }


}
