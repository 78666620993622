<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="numero_avis">
    <th class="px-10" mat-header-cell *matHeaderCellDef
        mat-sort-header>{{'Bon de livraison' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      <a [routerLink]="['/','livraisons',element.numero_avis]" class="btn btn-icon btn-info">
        {{element?.numero_avis}}
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="reference_commande">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Référence' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.reference_commande}}
    </td>
  </ng-container>
  <ng-container matColumnDef="numero_commande">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Commande' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.numero_commande}}
    </td>
  </ng-container>
  <ng-container matColumnDef="numero_commande_2">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Commande bis' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.numero_commande_2}}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataire_nom">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Destinataire' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.destinataire_nom | titlecase }}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataire_code_postal">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CP' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.destinataire_code_postal }}
    </td>
  </ng-container>
  <ng-container matColumnDef="destinataire_localite">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Ville' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.destinataire_ville | titlecase }}
    </td>
  </ng-container>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{'Date' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.date}}
    </td>
  </ng-container>
  <ng-container matColumnDef="activite">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Activité' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle"
        ngbTooltip="{{element?.activite_detail_libelle}}"
        placement="top"
        container="body">
      {{element?.activite_libelle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="statut">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{'Statut' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      <div class="label" [ngClass]="element.color">
        {{element?.statut}}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef></th>
    <td class="text-center align-middle" mat-cell *matCellDef="let element">
      <button
        *ngIf="showInfosBtn && ((this.innerWidth < 1400 && element?.date != '') || (this.innerWidth < 1051 && element?.date == '' && element?.statut !== ''))"
        ngbTooltip="{{'Informations complémentaires' | translate }}"
        placement="left"
        container="body"
        class="button btn btn-rounded-arrow btn-sm btn-agediss ml-1"
        (click)="expandedElement = expandedElement === element ? null : element">
        <i class="fas fa-angle-double-down"></i>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
        innerHTML="{{'Aucune livraison' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
        innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">

      <div class="table-detail"
           *ngIf="this.innerWidth < 400"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Commande' | translate }} : </strong><span>{{element?.numero_commande }}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1240"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Commande bis' | translate }} : </strong><span>{{element?.numero_commande_2 }}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 500"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Destinataire' | translate }} : </strong>{{element?.destinataire_nom | titlecase}}
        </div>
      </div>
      <div class="table-detail"
           *ngIf="(this.innerWidth < 933 && this.innerWidth > 767) || this.innerWidth < 715"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Ville' | translate }}
            : </strong><span>{{element?.destinataire_ville  | titlecase }}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1400 && element?.date !== ''"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Activité' | translate }} : </strong><span>{{element?.activite_detail_libelle}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1051"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Statut' | translate }} : </strong>
          <span class="table-description label" [ngClass]="element.color">
          {{element?.statut }}
          </span>
        </div>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']"
      [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>

<mat-paginator [length]="dataSource.data.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
               showFirstLastButtons></mat-paginator>
