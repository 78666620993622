import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';
import {TranslateService} from '@ngx-translate/core';
import {AnomalieModel} from '../../shared/models/api/anomalie.model';
import {AnomaliesService} from '../../shared/services/api/anomalies.service';
import {AppEvent, EventQueueService} from '../../shared/services/event-queue.service';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {View} from '../../shared/models/view.model';
import {Router} from '@angular/router';
import {TableUtil} from '../../shared/utils/table.util';

@Component({
  selector: 'app-anomalies',
  templateUrl: './anomalies.html',
  styleUrls: ['./anomalies.scss']
})
export class AnomaliesPageComponent implements OnInit, OnDestroy {

  private filter = null;
  public view: View;
  public anomalies: AnomalieModel[];
  public subscription: Subscription = null;
  public timerSubscription: Subscription;
  public eventSubscription: Subscription;
  public anomaliesTitle: string;
  public loadingAnomalies = false;

  private sevenDays = 'Anomalies des 7 derniers jours';

  constructor(private translate: TranslateService,
              private anomaliesService: AnomaliesService,
              private authSvc: AuthenticationService,
              private router: Router,
              private eventQueue: EventQueueService)
  {
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {
    if (this.view.hasDroit('COMMANDE_MENU_ANOMALIES')) {
      // on écoute la création de message GRC
      this.eventSubscription = this.eventQueue.on(AppEvent.EventType.GRC_MESSAGE_ADDED).subscribe(event => {
        this.loadAnomalies();
      });
      // on recharge les anomalies toutes les 30mn
      const delay = 30 * 60 * 1000;
      this.timerSubscription = timer(delay, delay).subscribe(val => {
        this.loadAnomalies();
      });
      // on recharge au changement de langue
      this.eventSubscription = this.translate.onLangChange.subscribe(() => {
        this.loadAnomalies();
      });
    } else {
      this.router.navigate(['/', 'home']);
    }
  }

  onSubmitFilter(filter = null) {
    // récupère les infos du filtre
    if (filter) {
      this.filter = filter;
    }
    this.loadAnomalies();
  }

  onLoadFilter(filter = null) {
    // récupère les infos du filtre
    if (filter) {
      this.filter = {...filter, ...this.filter};
    }
    this.loadAnomalies();
  }

  loadAnomalies() {
    this.loadingAnomalies = true;
    this.unsubscribeAnomalies();
    this.anomalies = [];
    if (this.filter.minDate) {
      this.anomaliesTitle = this.translate.instant('Anomalies depuis le') + ' ' + this.filter.minDate.toLocaleDateString();
    }
    else {
      this.anomaliesTitle = this.translate.instant(this.sevenDays);
    }
    this.subscription = this.anomaliesService.getAnomalies(this.filter).subscribe((anomalies) => {
        this.anomalies = anomalies;
        this.loadingAnomalies = false;
      },
      error => {
        this.loadingAnomalies = false;
      });
  }

  unsubscribeAnomalies() {
    if (!!this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  export() {
    TableUtil.exportTableToExcel('anomalies-table', 'anomalies');
    // TableUtil.exportArrayToExcel(this.anomalies, 'anomalies');
  }

  ngOnDestroy() {
    this.unsubscribeAnomalies();
    this.eventSubscription.unsubscribe();
  }

}
