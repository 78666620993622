<div *ngIf="loading" class="spinner"></div>
<div *ngIf="!loading">
  <div class="box">
    <div *ngFor="let timeline of data; let index = index">
      <div>
        <div class="line timeline-statut">
          <!-- livraison -->
          <div *ngIf="timeline.type === 'DESTINATAIRE' && timeline.flux === 'LIVRAISON'"
               placement="top"
               container="body"
               ngbTooltip="{{timeline.date_evenement}} {{timeline.dernier_evenement}}"
               class="marker marker-bottom marker-bottom-4 {{((!timeline.etat && data[index -1]['etat'] === 'OK') ? 'AT' : timeline.etat) | statutTimeline}}">
            <div class="adresse adresse-home py-3 text-truncate">{{timeline.libelle | uppercase}}</div>
            <i *ngIf="!timeline.etat && data[index -1]['etat'] !== 'OK'"
               class="icon-bottom-color icon-bottom-pos-home icon-bottom-size fas fa-home"></i>
            <i *ngIf="timeline.etat === 'OK'"
               class="icon-bottom-color icon-bottom-pos-home icon-bottom-size fas fa-home"></i>
            <i *ngIf="timeline.etat === 'NK'"
               class="icon-bottom-color icon-bottom-pos-home icon-bottom-size fas fa-exclamation"></i>
            <i *ngIf="(!timeline.etat && data[index -1]['etat'] === 'OK') || timeline.etat === 'AT'"
               class="icon-bottom-color icon-bottom-pos-home icon-bottom-size fas fa-home"></i>
            <div class="statut livraison">{{'Reprise' | translate }}</div>
            <div *ngIf="(!timeline.etat && data[index -1]['etat'] === 'OK')"
                 class="circle-loader circle-loader-pos circle-loader-pos-livraison">
            </div>
            <div *ngIf="timeline.etat === 'AT'"
                 class="circle-loader circle-loader-pos circle-loader-pos-livraison">
            </div>
          </div>
        </div>
      </div>
      <div class="p-btn" *ngIf="timeline.flux === 'LIVRAISON' && view.origine === 'wexlog0' && !!timeline.etat && !!timeline.latitude && !!timeline.longitude">
        <div class="offset-1 col-10 offset-sm-3 col-sm-6">
          <button *ngIf="showSignatureLivraisonBtn" class="btn btn-radius btn-agediss btn-block text-truncate shadow"
                  (click)="selectInfosTimeline(this.timeline)">{{'Preuve de livraison' | translate }}</button>
        </div>
      </div>
      <div class="p-btn" *ngIf="timeline.flux === 'LIVRAISON' && (view.origine === 'tradiss' || view.origine === 'wexlog') && !!timeline.etat">
        <div class="offset-1 col-10 offset-sm-3 col-sm-6">
          <button *ngIf="showSignatureLivraisonBtn && (view.origine === 'tradiss' || view.origine === 'wexlog')" class="btn btn-radius btn-agediss btn-block text-truncate shadow"
                  (click)="selectSignatureLivraison(this.commande.numero_avis)">{{'Preuve de livraison' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

