<div class="row">
  <div class="col-12 px-4 py-2">
    <div class="col-12 p-0">
      <label class="d-block d-sm-none">{{'Rechercher' | translate }}</label>
    </div>
    <div class="input-group">
      <div class="input-group-prepend ">
          <span class="input-group-text input-group-border-right d-none d-sm-flex align-items-center">
            {{'Rechercher' | translate }}
          </span>
      </div>
      <input matInput (keyup)="applyFilter($event.target.value)" class="pl-2 form-control form-control-filter"
             placeholder="{{'Rechercher' | translate }}">
    </div>
  </div>
</div>

<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="px-10">{{'Id' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
        {{element?.id}}
    </td>
  </ng-container>
  <ng-container matColumnDef="username">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Identifiant' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.username}}
    </td>
  </ng-container>
  <ng-container matColumnDef="firstname">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Prénom' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.firstname}}
    </td>
  </ng-container>
  <ng-container matColumnDef="lastname">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Nom' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.lastname}}
    </td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Email' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.email}}
    </td>
  </ng-container>
  <ng-container matColumnDef="phone">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Téléphone' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.tel}}
    </td>
  </ng-container>
  <ng-container matColumnDef="organisation">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Organisation' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.organisation?.libelle | titlecase}}
    </td>
  </ng-container>
  <ng-container matColumnDef="fonction">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Fonction' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="align-middle">
      {{element?.fonction | titlecase}}
    </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef class="text-center align-middle">
      <button *ngIf="showAddButton" class="btn btn-agediss btn-sm"
              placement="left"
              container="body"
              ngbTooltip="{{'Ajouter un utilisateur' | translate }}"
              (click)="selectAddUser()">
        <i class="fa fa-plus"></i>
      </button>
    </th>
    <td class="text-center align-middle" mat-cell *matCellDef="let element; let i = dataIndex">
      <button *ngIf="showEditBtn && this.innerWidth >= 425"
              ngbTooltip="{{'Modifier les informations' | translate }}"
              placement="left"
              container="body"
              class="button btn btn-rounded-arrow btn-sm btn-agediss"
              (click)="selectEditUser(element)">
        <i class="fa fa-pen"></i>
      </button>
      <button *ngIf="showDeleteBtn && this.innerWidth >= 425" [ladda]="element.pending" data-style="zoom-in"
              ngbTooltip="{{'Supprimer les informations' | translate }}"
              placement="left"
              container="body"
              class="button btn btn-rounded-arrow btn-sm btn-agediss mx-1"
              (click)="delete(element.id,i)">
        <i class="fa fa-trash"></i>
      </button>
      <button *ngIf="showInfosBtn && this.innerWidth < 1600"
              ngbTooltip="{{'Informations complémentaires' | translate }}"
              placement="left"
              container="body"
              class="button btn btn-sm btn-agediss"
              (click)="expandedElement = expandedElement === element ? null : element">
        <i class="fas fa-angle-double-down"></i>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="noData">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Aucun utilisateur' | translate }}"></td>
  </ng-container>
  <ng-container matColumnDef="loading">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td class="table-td p-0" mat-cell *matCellDef="let element; let i = dataIndex" [attr.colspan]="displayedColumns.length">

      <div class="table-detail"
           *ngIf="this.innerWidth < 550"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Prénom' | translate }} : </strong><span>{{element?.firstname | titlecase}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="(this.innerWidth < 920 && this.innerWidth > 767) || this.innerWidth < 600"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Nom' | translate }} : </strong><span>{{element?.lastname | titlecase}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1180"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>E-{{'Email' | translate }} : </strong><span>{{element?.email }}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1330"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Téléphone' | translate }} : </strong><span>{{element?.tel }}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 1600"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Fonction' | translate }} : </strong><span>{{element?.fonction | titlecase}}</span>
        </div>
      </div>
      <div class="table-detail"
           *ngIf="this.innerWidth < 425"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="table-description">
          <strong>{{'Options' | translate }} : </strong>
          <span>
            <button *ngIf="showEditBtn"
                    ngbTooltip="{{'Modifier les informations' | translate }}"
                    placement="left"
                    container="body"
                    class="button btn btn-rounded-arrow btn-sm btn-agediss"
                    (click)="selectEditUser(element)">
              <i class="fa fa-pen"></i>
            </button>
            <button *ngIf="showDeleteBtn" [ladda]="element.pending" data-style="zoom-in"
                    ngbTooltip="{{'Supprimer les informations' | translate }}"
                    placement="left"
                    container="body"
                    class="button btn btn-rounded-arrow btn-sm btn-agediss mx-1"
                    (click)="delete(element.id,i)">
              <i class="fa fa-trash"></i>
            </button>
          </span>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
      class="table-row overflow-scroll"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
  <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>

<mat-paginator [length]="length"  [pageIndex]="pageIndex" (page)="emit($event)" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
               showFirstLastButtons></mat-paginator>
