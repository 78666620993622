<div *ngIf="view.hasDroit('ADMINISTRATION_MENU_GESTION-UTILISATEUR')">
  <div class="row m-0 padding">
    <h1 class="page-header text-center m-auto padding-h1">{{'Gestion des utilisateurs' | translate}}</h1>
  </div>

  <div class="row m-0 padding-table" tourAnchor="tableau.gestion.utilisateurs">
    <div class="col-12 p-0">
      <panel noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-12"><h5 class="p-t-5 text-agediss">{{'Gestion des utilisateurs' | translate }} : <span *ngIf="!loadingNbUsers" >{{nbUsers}}</span> <i *ngIf="loadingNbUsers" class='fas fa-spin fa-circle-notch'></i></h5></div>
        </div>
        <div>
          <app-table-users [data]="usersData"
                           [length]="nbUsers" [pageSize]="pageSize" (paginatorEvent)="onLoadDataByEvent($event)" [pageSizeOptions]="pageSizeOption"
                           [showDeleteBtn]="true" [showEditBtn]="true"
                           [showAddButton]="true" [showInfosBtn]="true"
                           [loading]="loadingTableUser"
                           (onEditUser)="openModalEditDataUser(modal_edit_user, $event)"
                           (onAddUser)="openModalAddUser(modal_add_user)"></app-table-users>
        </div>
      </panel>
    </div>
  </div>

  <!--modal add user-->
  <ng-template #modal_add_user let-modal>
    <app-add-user (submited)="modal.close($event)"
                  (canceled)="modal.close()"></app-add-user>
  </ng-template>
  <!-- fin de modal add user-->


  <!--modal edit user-->
  <ng-template #modal_edit_user let-modal>
    <app-edit-user [userId]="selectUserId" [username]="selectUsername" (submited)="modal.close($event)"
                   (canceled)="modal.close()"></app-edit-user>
  </ng-template>
  <!-- fin de modal edit user-->
</div>

