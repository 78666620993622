import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import {Historique} from '../../../../shared/models/api/historique.model';
import {TranslateService} from "@ngx-translate/core";
import {PaginationService} from "../../../../shared/services/pagination.service";
import {Contestation} from "../../../../shared/models/api/contestation.model";
import {ContestationData} from "../../../../shared/models/api/contestation-data.model";
import {HistoriqueData} from "../../../../shared/models/api/historique-data.model";
import {FilterMultiCritereModel} from "../../../../shared/models/filters/filterMultiCritere.model";
import {PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'app-litige-historique-content',
  templateUrl: './litige-historique-content.html',
  styleUrls: ['./litige-historique-content.scss']
})
export class LitigeHistoriqueContent implements OnInit , OnDestroy{

  @Input() litigeId: number;

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  public activModal: NgbModalRef;

  // loader
  public loadingPage = false;
  public loadingTableHistorique: boolean = false;
  public loadingNbHistorique = false;

  // tableaux
  public pageSizeOption = [5, 10, 20];
  public historique: Historique[];
  public historiqueData: HistoriqueData[];
  public subscriptions: Subscription[] = [];

  // number
  public pageSize = 5;
  public nbData: number = 0;

  constructor(private translate: TranslateService,
              private modalSvc: NgbModal,
              private paginationSvc: PaginationService,
              private litigeSvc: LitigeService) {
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
  }

  ngOnInit() {
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.loadDataEvenements();
    }))
    this.loadDataEvenements();
  }

  /**
   *  On recupère tous l'historique en fonction de Id du litige
   */
  loadDataEvenements() {
    this.loadingTableHistorique = true;
    this.loadingNbHistorique = true;
    this.subscriptions.push(this.litigeSvc.getHistoriques(this.filter, this.litigeId).subscribe(historique => {
      this.historiqueData = historique.data;
      this.nbData = historique.nb;
      this.loadingTableHistorique = false;
      this.loadingNbHistorique = false;
      },
      (error) => {
        this.loadingTableHistorique = false;
        this.loadingNbHistorique = false;
      }
    ));
  }

  onLoadDataByEvent(event: PageEvent) {
    this.filter.limit = event.pageSize
    this.filter.offset = event.pageIndex ? event.pageIndex * event.pageSize : 0
    this.loadDataEvenements();
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
