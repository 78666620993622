import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {DatatableHelper} from '../../../shared/helpers/datatable.helper';
import {tableAnimation} from '../../../animations';
import {MatSort} from '@angular/material/sort';
import {AnomalieModel} from '../../../shared/models/api/anomalie.model';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {LivraisonPage} from '../../../pages/livraisons/show/livraison';

@Component({
  selector: 'app-table-anomalies',
  templateUrl: './table-anomalies.component.html',
  styleUrls: ['./table-anomalies.component.scss'],
  animations: [tableAnimation],
})
export class TableAnomaliesComponent implements OnInit {

  @Input() data: AnomalieModel[];
  @Input() loading = false;
  @Input() displayedColumns: string[] = [
    'numero_avis',
    'numero_commande',
    'numero_commande_2',
    'destinataire_nom',
    'phone',
    'email',
    'activite',
    'date_maj',
    'events',
    'statut'
  ];

  @Output() sorted: EventEmitter<Sort> = new EventEmitter();

  public dataSource: MatTableDataSource<AnomalieModel>;
  public modal: NgbModalRef;
  public livraisonNumeroAvis: any;
  public startTabIndex: number;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private modalSvc: NgbModal) {

  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data);
  }

  ngOnChanges(changes: any) {
    this.data = changes.data.currentValue;
    this.dataSource = new MatTableDataSource(this.data);
  }

  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

  openLivraison(modalName, numeroAvis: number, showGrcTab: boolean) {
    this.livraisonNumeroAvis = numeroAvis;
    this.startTabIndex = showGrcTab ? LivraisonPage.TABTYPE.GRC : LivraisonPage.TABTYPE.COMMAND;
    this.modal = this.modalSvc.open(modalName, {size: 'xl'});
  }

  openModal(modalName, numeroAvis: number) {
    this.livraisonNumeroAvis = numeroAvis;
    this.modal = this.modalSvc.open(modalName, {size: 'xl'});
  }

}
