<div *ngIf="view.hasDroit('UTILISATEUR_MENU_CONTACT-PRIVILEGIE')">
  <div class="row m-0 padding">
    <h1 class="page-header text-center m-auto padding-h1">{{'Mon contact privilégié' | translate}}</h1>
  </div>

  <div class="row m-0 padding-table" tourAnchor="panel.parametres">
    <div class="offset-0 col-12 offset-lg-2 col-lg-8 offset-xl-3 col-xl-6 p-0">
      <panel noButton="true" title="{{'Mon contact privilégié' | translate}}" bodyClass="no-padding panel">
        <div style="min-height: 140px">
          <div *ngIf="noContact" class="text-center py-2">{{'Aucun Contact' | translate }}</div>
          <app-contact-privilegie *ngIf="!noContact" [contacts]="contacts" [loading]="loading"></app-contact-privilegie>
        </div>
      </panel>
    </div>
  </div>
</div>
