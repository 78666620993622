<form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div *ngIf="!hiddenInput['sous-compte']" class="col-md-12 col-lg-12 col-xl-6 m-b-15">
      <div class="col-12 p-0">
        <label class="d-block d-lg-none">{{'Sous-compte' | translate }}</label>
      </div>
      <div class="input-group-prepend">
          <span class="input-group-text input-group-border-right d-none d-lg-flex align-items-center">
            {{'Sous-compte' | translate }}
          </span>
        <ng-select class="ng-select filter"
                   [items]="clients"
                   [selectOnTab]="true"
                   [multiple]="true"
                   bindValue="code"
                   bindLabel="libelle"
                   [loading]="loadClient"
                   [clearable]="true"
                   labelForId="client"
                   placeholder="{{'Choisir' | translate }}"
                   formControlName="client">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput['date']" class="col-md-12 col-lg-12 col-xl-6">
      <div class="row">
        <div class="col-12">
          <label class="d-block d-lg-none">{{'Date de commande' | translate }}</label>
        </div>
        <div class="col-12 col-sm-6 col-lg-7">
          <app-date-picker-min-date [filterForm]="filterForm" [name]="'Date' | translate "
                                    [filterName]="'minDateFilterLivraison'" [date]="minDate"
                                    #inputMinDate></app-date-picker-min-date>
        </div>
        <div class="col-12 col-sm-6 col-lg-5">
          <app-date-picker-max-date [filterForm]="filterForm" [filterName]="'maxDateFilterLivraison'" [date]="maxDate"
                                    #inputMaxDate></app-date-picker-max-date>
        </div>
      </div>
    </div>
    <div *ngIf="!hiddenInput['statut']" class="col-md-6 col-lg-6 col-xl-4  m-b-15">
      <div class="col-12 p-0">
        <label class="d-block d-lg-none">{{'Statut' | translate }}</label>
      </div>
      <div class="input-group-prepend">
        <span class="input-group-text input-group-border-right d-none d-lg-flex align-items-center">
          {{'Statut' | translate }}
        </span>
        <ng-select class="ng-select filter"
                   [items]="statutsLivraison"
                   [selectOnTab]="true"
                   [multiple]="true"
                   bindValue="code"
                   bindLabel="libelle"
                   [clearable]="true"
                   [loading]="loadStatutsLivraison"
                   labelForId="statuts"
                   placeholder="{{'Choisir' | translate }}"
                   formControlName="statutsLivraison">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput['Pays']" class="col-md-6 col-lg-6 col-xl-4  m-b-15">
      <div class="col-12 p-0">
        <label class="d-block d-lg-none">{{'Pays' | translate }}</label>
      </div>
      <div class="input-group-prepend">
        <span class="input-group-text input-group-border-right d-none d-lg-flex align-items-center">
          {{'Pays' | translate }}
        </span>
        <ng-select class="ng-select filter"
                   [items]="pays"
                   [selectOnTab]="true"
                   [multiple]="true"
                   bindValue="code"
                   bindLabel="libelle"
                   [loading]="loadPays"
                   [clearable]="true"
                   labelForId="client"
                   placeholder="{{'Choisir' | translate }}"
                   formControlName="pays">
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput['Activite']" class="col-md-6 col-lg-6 col-xl-4 m-b-15">
      <div class="col-12 p-0">
        <label class="d-block d-lg-none">{{'Activité' | translate }}</label>
      </div>
      <div class="input-group-prepend">
        <span class="input-group-text input-group-border-right d-none d-lg-flex align-items-center">
          {{'Activité' | translate }}
        </span>
        <ng-select class="ng-select filter"
                   [items]="activites"
                   [selectOnTab]="true"
                   [multiple]="true"
                   bindValue="code_activite"
                   bindLabel="nom_activite"
                   [clearable]="true"
                   [loading]="loadActivites"
                   labelForId="activites"
                   i18n-placeholder
                   placeholder="{{'Choisir' | translate }}"
                   formControlName="activites">
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
            <div *ngIf="item.destination !== null "><span>{{item.destination}}</span></div>
            <div *ngIf="item.destination == null"><span>{{item.nom_activite}}</span></div>
          </ng-template>
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <div *ngIf="item.destination !== null "><span class="ng-value-icon right" (click)="clear(item)">×</span><span class="mx-1">{{item.destination}}</span></div>
            <div *ngIf="item.destination == null"><span class="ng-value-icon right" (click)="clear(item)">×</span><span class="mx-1">{{item.nom_activite}}</span></div>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div *ngIf="!hiddenInput['search'] " class="col-12">
      <div class="col-12 p-0">
        <label class="d-block d-lg-none">{{'Rechercher' | translate }}</label>
      </div>
      <div class="input-group">
        <div class="input-group-prepend ">
          <span class="input-group-text input-group-border-right d-none d-lg-flex align-items-center">
            {{'Rechercher' | translate }}
          </span>
        </div>
        <input class="form-control form-control-filter"
               placeholder="{{'Rechercher' | translate }}"
               formControlName="search"/>
      </div>
    </div>
  </div>

  <div class="form-group m-b-0 row m-t-10">
    <div class="offset-sm-5 col-sm-2 offset-4 col-4 px-0">
      <div class="input-group ">
        <input class="form-control btn btn-radius btn-agediss font-weight-bold shadow" type="submit"
               value="{{'Filtrer' | translate }}">
        <div class="input-group-append pl-1">
          <button class="btn btn-radius btn-agediss btn-block shadow" type="button"
                  ngbTooltip="{{'Effacer les infos sélectionnées' | translate }}"
                  placement="right"
                  container="body"
                  (click)="reset()"><i class='fa fa-times'></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
