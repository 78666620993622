<div style="max-height: 300px; overflow: auto">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows
         class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>{{'Id' | translate }}</th>
      <td mat-cell *matCellDef="let element" class="align-middle">
        <a [routerLink]="['/','litiges',element?.litige?.id]" class="btn btn-icon btn-info">
          {{element?.litige?.id}}
        </a>
    </ng-container>
    <ng-container matColumnDef="numero_avis">
      <th mat-header-cell *matHeaderCellDef>{{'Bon de livraison' | translate }}</th>
      <td mat-cell *matCellDef="let element" class="align-middle">
        {{element.litige?.numero_avis}} </td>
    </ng-container>
    <ng-container matColumnDef="numero_commande">
      <th mat-header-cell *matHeaderCellDef>{{'Commande' | translate }}</th>
      <td mat-cell *matCellDef="let element" class="align-middle">
        {{element.litige.numero_commande}} </td>
    </ng-container>
    <ng-container matColumnDef="statut_litige">
      <th mat-header-cell *matHeaderCellDef>{{'Statut' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="align-middle">
        <span *ngIf="!!element.litige.date_prescrit" class="label label-dark mr-2">
             {{'Prescrit' | translate}}
           </span>
        <span class="label {{ element.litige.statut.libelle | statutLitigePipe: element.litige.statut.code}}">
          <span *ngIf="element.litige.statut.code === 'LIT_0'">{{'A traiter' | translate }}</span>
          <span *ngIf="element.litige.statut.code === 'LIT_1'">{{'Refusé' | translate }}</span>
          <span *ngIf="element.litige.statut.code === 'LIT_2'">{{"En cours d'analyse" | translate }}</span>
          <span *ngIf="element.litige.statut.code === 'LIT_3'">{{'Eligible' | translate }}</span>
          <span *ngIf="element.litige.statut.code === 'LIT_5'">{{'Validé' | translate }}</span>
          <span *ngIf="element.litige.statut.code === 'LIT_6'">{{"En attente d'approbation" | translate }}</span>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="date_litige">
      <th mat-header-cell *matHeaderCellDef>{{'Date litige' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="align-middle">
        {{element.litige.date_litige | date}}
      </td>
    </ng-container>
    <ng-container matColumnDef="montant">
      <th mat-header-cell *matHeaderCellDef>{{'Montant affecté' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="align-middle">
        {{element.montant | currency:'EUR':'€'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="montant_valide">
      <th mat-header-cell *matHeaderCellDef>{{'Montant validé' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="align-middle">
        {{element.litige.montant_valide | currency:'EUR':'€'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="montantAvoir">
      <th mat-header-cell *matHeaderCellDef>{{'Montant pièce associée' | translate }}</th>
      <td mat-cell *matCellDef="let element" class="align-middle">
        {{element.litige.montantAvoir | currency:'EUR':'€'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="montantAvoirAttendu">
      <th mat-header-cell *matHeaderCellDef>{{'Avoir attendu' | translate }}</th>
      <td mat-cell *matCellDef="let element" class="align-middle">
        {{element.litige.montantAvoirAttendue | currency:'EUR':'€'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="statut">
      <th mat-header-cell *matHeaderCellDef>{{'Statut facture' | translate }}</th>
      <td mat-cell *matCellDef="let element" class="align-middle">
        <div class="label" [ngClass]="{
              'label-danger' : element.litige.BAPFacture === 'CONTESTE',
              'label-dark' : element.litige.BAPFacture === 'PRESCRIT',
              'label-green' : element.litige.BAPFacture === 'BAP'
           }">
          <span *ngIf="element.litige.BAPFacture === 'CONTESTE'">{{"Contesté" | translate }}</span>
          <span *ngIf="element.litige.BAPFacture === 'PRESCRIT'">{{"Prescrit" | translate }}</span>
          <span *ngIf="element.litige.BAPFacture === 'BAP'">{{"Bon à payer" | translate }}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="noData">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
          innerHTML="{{'Aucun litige' | translate }}"></td>
    </ng-container>
    <ng-container matColumnDef="loading">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
          innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
    </ng-container>
    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td class="table-td p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
      </td>
    </ng-container>

    <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
        class="table-row overflow-scroll">
    </tr>
    <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
    <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
  </table>
</div>
