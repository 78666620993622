<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="modal-header">
    <h4 class="modal-title  w-100 text-center">{{"Modification des informations de l'utilisateur" | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="editUserForm">
      <div class="row m-0 form-group">
        <div class="offset-0 col-12 offset-lg-0 col-lg-6">
          <label class="col-lg-12 col-md-12 col-sm-12">{{'Prénom' | translate }}
            <div class="input-group">
              <input id="firstname" type="text" class="form-control form-control-filter" autocomplete="off"
                     formControlName="firstname"
                     [ngClass]="{ 'is-invalid': formSubmit && f.firstname.errors}">
              <div *ngIf="formSubmit && f.firstname.errors" class="invalid-feedback">
                <div *ngIf="f.firstname.errors.required">{{'Veuillez saisir un prénom !' | translate }}</div>
              </div>
            </div>
          </label>
        </div>
        <div class="offset-0 col-12 offset-lg-0 col-lg-6">
          <label class="col-lg-12 col-md-12 col-sm-12">{{'Nom' | translate }}
            <div class="input-group">
              <input id="lastname" type="text" class="form-control form-control-filter" autocomplete="off"
                     formControlName="lastname"
                     [ngClass]="{ 'is-invalid': formSubmit && f.lastname.errors}">
              <div *ngIf="formSubmit && f.lastname.errors" class="invalid-feedback">
                <div *ngIf="f.lastname.errors.required">{{'Veuillez saisir un nom !' | translate }}</div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="row m-0 form-group">
        <div class="offset-0 col-12 offset-lg-0 col-lg-6">
          <label class="col-lg-12 col-md-12 col-sm-12">{{'Email' | translate }}
            <div class="input-group">
              <input id="email" type="email" class="form-control form-control-filter" autocomplete="off"
                     formControlName="email"
                     [ngClass]="{ 'is-invalid': formSubmit && f.email.errors}">
              <div *ngIf="formSubmit && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">{{'Veuillez saisir un email !' | translate }}</div>
              </div>
            </div>
          </label>
        </div>
        <div class="offset-0 col-12 offset-lg-0 col-lg-6">
          <label class="col-lg-12 col-md-12 col-sm-12">{{'Téléphone' | translate }}
            <div class="input-group">
              <input id="phone" type="text" maxlength="20" class="form-control form-control-filter" autocomplete="off"
                     formControlName="phone"
                     [ngClass]="{ 'is-invalid': formSubmit && f.phone.errors}">
              <div *ngIf="formSubmit && f.phone.errors" class="invalid-feedback">
                <div *ngIf="f.phone.errors.required">{{'Veuillez saisir un numéro de téléphone !' | translate }}</div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="row m-0 form-group">
        <div class="offset-0 col-12 offset-lg-0 col-lg-6">
          <label class="col-lg-12 col-md-12 col-sm-12">{{'Fonction' | translate }}
            <div class="input-group">
              <input id="fonction" type="text" class="form-control form-control-filter" autocomplete="off"
                     formControlName="fonction"
                     [ngClass]="{ 'is-invalid': formSubmit && f.fonction.errors}">
              <div *ngIf="formSubmit && f.fonction.errors" class="invalid-feedback">
                <div *ngIf="f.fonction.errors.required">{{'Veuillez saisir une fonction !' | translate }}</div>
              </div>
            </div>
          </label>
        </div>
      </div>
    </form>
  </div>
  <div class="row m-0">
    <div class="col-12 modal-footer text-center">
      <button [ladda]="loadingBtn" data-style="zoom-in" class="btn btn-agediss" (click)="updateFormUser()">
        {{'Enregistrer' | translate }}
      </button>
      <button class="btn bg-black-lighter text-white m-r-10" (click)="cancel()">{{'Fermer' | translate }}</button>
    </div>
  </div>
</div>
