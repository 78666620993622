import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import pageSettings from '../../../config/page-settings';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {View} from '../../../shared/models/view.model';
import {environment} from '../../../../environments/environment';
import {NotificationService} from '../../../shared/services/api/notification/notification.service';
import {urlWithProtocol} from '../../../shared/utils/util';
import {TranslateService} from '@ngx-translate/core';
import {TourService} from "ngx-tour-ngx-bootstrap";
import {UserData} from "../../../shared/models/user-data.model";
import {FileService} from "../../../shared/services/file.service";
import {AdminService} from "../../../shared/services/api/admin.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
import {NotifData} from "../../../shared/models/api/notif-data.model";
import {FilterMultiCritereModel} from "../../../shared/models/filters/filterMultiCritere.model";
import {TutorielService} from "../../../shared/services/tutoriel.service";

@Component({
    selector: 'header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnDestroy, OnInit {

    private filter: FilterMultiCritereModel = new FilterMultiCritereModel();

    @Output() toggleSidebarRightCollapsed = new EventEmitter<boolean>();
    @Output() toggleMobileSidebar = new EventEmitter<boolean>();
    @Output() toggleMobileRightSidebar = new EventEmitter<boolean>();

    pageSettings = pageSettings;
    private authSvc: AuthenticationService;
    public currentUser: UserData;
    public view: View;
    public activModal: NgbModalRef;
    public notifInterval;
    public urlsso: string = urlWithProtocol(environment.urlsso);

    // Number
    public lastNbNotif = 0;
    public notifLength = 0;

    // Tableau
    public notifsData: NotifData[];
    public locales = []


    // loading
    public loadingNbNotifs: boolean = false;
    public loadingIlesEtAltitudesBtn: boolean = false;
    public loading: boolean = false;
    public loadingBtn: boolean = false;

    constructor(authSvc: AuthenticationService,
                public translate: TranslateService,
                private adminSvc: AdminService,
                public tourService: TourService,
                private modalSvc: NgbModal,
                private toastr: ToastrService,
                private notificationService: NotificationService,
                private tutorielService: TutorielService) {
        this.authSvc = authSvc;
        this.authSvc.currentUser.subscribe(x => this.currentUser = x);
        this.authSvc.currentView.subscribe(x => this.view = x);

    }

    ngOnInit() {
        this.locales = [
            {code: 'fr', libelle: this.translate.instant('Français')},
            {code: 'en', libelle: this.translate.instant('Anglais')},
            {code: 'nl', libelle: this.translate.instant('Néerlandais')},
            {code: 'es', libelle: this.translate.instant('Espagnol')},
            {code: 'de', libelle: this.translate.instant('Allemand')},
        ];

        this.tutorielService.stepsTourWithRights(this.view.context);
        this.getNotif();
        this.notifInterval = setInterval(() => {
            this.getNotif();
        }, 600000);
    }

    getNotif() {
        if (this.view.context === '3') {
            this.loadingNbNotifs = true;
            this.notifsData = [];
            this.notifLength = 0;
            this.filter.context = 'length'
            const sub = this.notificationService.getNotifications(this.filter).subscribe(notifs => {
                this.notifLength = notifs.nb;
                if (this.lastNbNotif > this.notifLength) {
                    this.lastNbNotif = 0;
                }
                if (this.lastNbNotif < this.notifsData.filter(notif => notif.viewed == false).length) {
                    this.toastr.info(
                        this.translate.instant("Vous avez {{ value }} nouvelles notifications", {value: (this.notifLength - this.lastNbNotif)}),
                        this.translate.instant('Notifications'), {
                            progressBar: true,
                            timeOut: 1000,
                            positionClass: 'toast-top-center'
                        }
                    );
                }
                this.lastNbNotif = this.notifsData.filter(notif => notif.viewed == false).length;
                this.loadingNbNotifs = false;
                sub.unsubscribe();
            }, err => {
                if (err.error.code === 2102) {
                    this.toastr.info(
                        this.translate.instant('Aucune notification'),
                        this.translate.instant('Notifications'), {
                            progressBar: false,
                            timeOut: 2000,
                            positionClass: 'toast-top-right'
                        }
                    );
                } else {
                    this.toastr.error(
                        this.translate.instant('Les notifications sont indisponibles !'),
                        this.translate.instant('Notifications'), {
                            progressBar: false,
                            timeOut: 2000,
                            positionClass: 'toast-top-right'
                        }
                    );
                }
                this.loadingNbNotifs = false;
                sub.unsubscribe();
            });
        }
    }

    cancel() {
        this.activModal.close()
        this.getNotif()
    }

    /**
     * On crée une nouvlle demande grc
     * @param modalName
     */
    openModalNotifications(modalName) {
        this.activModal = this.modalSvc.open(modalName, {
            size: 'xl', keyboard: false
        });
    }

    mobileSidebarToggle() {
        this.toggleMobileSidebar.emit(true);
    }

    mobileRightSidebarToggle() {
        this.toggleMobileRightSidebar.emit(true);
    }

    toggleSidebarRight() {
        this.toggleSidebarRightCollapsed.emit(true);
    }

    mobileMegaMenuToggle() {
        this.pageSettings.pageMobileMegaMenuToggled = !this.pageSettings.pageMobileMegaMenuToggled;
    }

    ngOnDestroy() {
        this.pageSettings.pageMobileTopMenuToggled = false;
        this.pageSettings.pageMobileMegaMenuToggled = false;
    }

    downloadExcelFile() {
        this.loadingIlesEtAltitudesBtn = true;
        this.adminSvc.downloadFichierIlesEtAltitudes().subscribe((res) => {
                this.loadingIlesEtAltitudesBtn = false;
                FileService.downloadDataToFile(res);
            },
            () => {
                FileService.handleDownloadError('Iles et Altitudes');
                this.loadingIlesEtAltitudesBtn = false
            }
        );
    }

    logout(returnUrl) {
        this.authSvc.logout(returnUrl);
    }

    switchLang(lang: string) {
        localStorage.setItem('langueFilter', lang);
        this.translate.use(lang);
    }

    changeLang() {
        return localStorage.getItem('langueFilter');
    }

    tour() {
      this.tutorielService.tour();
    }

}
