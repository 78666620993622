<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">
  <div class="row m-0 pt-2 bg-white">
    <div class="col-12 p-t-20">
      <div class="title">{{'Expédition n°' | translate }} <span>{{ commandeData.numero_avis}}</span></div>
    </div>
  </div>
  <app-header-livraison [commandeData]="commandeData"></app-header-livraison>
  <app-tab-livraison [livraison]="livraison" [commandeData]="commandeData" [startTabIndex]="startTabIndex"></app-tab-livraison>
</div>
