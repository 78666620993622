import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({name: 'ouiNon'})
export class OuiNonPipe implements PipeTransform {

  public constructor(private translate: TranslateService) {
  }

  transform(value: boolean, exponent?: string): string {
    switch (value) {
      case true:
        return this.translate.instant('OUI');
      case false:
        return this.translate.instant('NON');
    }
  }
}

@Pipe({name: 'ouiNonTradiss'})
export class OuiNonTradissPipe implements PipeTransform {
  public constructor(private translate: TranslateService) {
  }

  transform(value: string, exponent?: number): string {
    switch (value) {
      case'O':
        return this.translate.instant('OUI');
      case 'N':
        return this.translate.instant('NON');
      default:
        return this.translate.instant('NON')
    }
  }
}
