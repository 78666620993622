import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Timeline} from '../../../../shared/models/api/timeline.model';
import {Commande} from "../../../../shared/models/api/commande.model";
import {CommandeData} from "../../../../shared/models/api/commande-data.model";

@Component({
  selector: 'app-timeline-reverse-hub',
  templateUrl: './timeline-reverse-hub.component.html',
  styleUrls: ['./timeline-reverse-hub.component.scss']
})
export class TimelineReverseHubComponent implements OnInit {

  @Input() data: Timeline[];
  @Input() commande: CommandeData;
  @Input() loading: boolean = false;

  @Input() showSignatureLivraisonBtn: boolean = false;

  @Output() onSelectSignatureLivraison: EventEmitter<{id: number}> = new EventEmitter<{id: number}>();

  constructor() {
  }

  ngOnInit() {
  }

  selectSignatureLivraison(id: number) {
    this.onSelectSignatureLivraison.emit({id});
  };

}
