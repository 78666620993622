import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {View} from "../../../shared/models/view.model";
import {CompteClientSsoService} from "../../../shared/services/api/sso/compte-client-sso.service";
import {takeUntil} from "rxjs/operators";
import {CompteClient} from "../../../shared/models/api/compte-client.model";
import {Subject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {FilterService} from "../../../shared/services/filter.service";
import {FormfilterHelper} from "../../../shared/helpers/Formfilter.helper";
import {FieldParametersModel} from "../../../shared/models/filters/field-parameters.model";

@Component({
    selector: 'app-filter-rapports-kpi',
    templateUrl: './filter-rapports-kpi.component.html',
    styleUrls: ['./filter-rapports-kpi.component.scss']
})
export class FilterRapportsKpiComponent implements OnInit, OnDestroy {

    @Output() submitFilter: EventEmitter<FilterMultiCritereModel> = new EventEmitter<FilterMultiCritereModel>();
    @Output() loadFilter: EventEmitter<FilterMultiCritereModel> = new EventEmitter<FilterMultiCritereModel>();

    @Input() displayInput: FieldParametersModel[] = [];
    @Input() view: View;

    public filterForm = new FormGroup({
        search: new FormControl(),
        compteClient: new FormControl()
    });

    private _unsubscribeAll: Subject<any> = new Subject();

    // Tableaux
    public hiddenInput = [];
    public compteClients: CompteClient[];

    // Loader
    public loadCompteClient = true;
    constructor(private translate: TranslateService,
                private toastr: ToastrService,
                private compteClientService: CompteClientSsoService,
                private filterSvc: FilterService) {
    }

    ngOnInit(): void {
        this.translate.onLangChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            if (this.view.context === '1') {
                this.onLoadCompteClient();
            }
        });
        this.onInitFilter();
    }

    onInitFilter() {
        FormfilterHelper.hydrateForm(this.displayInput, this.filterForm);
        this.hiddenInput = FormfilterHelper.extractHiddenInput(this.displayInput);
        if (this.view.context === '1') {
            this.onLoadCompteClient();
        }
        this.getValueLocalstorage();
        this.loadFilter.emit(this.filterForm.getRawValue());
    }

    onLoadCompteClient() {
        this.loadCompteClient = true;
        this.compteClientService.getAllCompteClient().pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
                this.compteClients = data;
                this.loadCompteClient = false;
            }, () => {
                this.toastr.error(
                    this.translate.instant("La liste des comptes-clients n'est pas disponible", {application: this.translate.instant('Mes KPIs')}),
                    this.translate.instant('Désolé'),
                    {
                        timeOut: 20000,
                        progressBar: true
                    }
                );
                this.loadCompteClient = false;
            }
        );
    }

    getValueLocalstorage() {
        this.filterForm.get('search').setValue(localStorage.getItem('kpiSearchFilter'));
        this.filterSvc.onCheckValueLocalStorage(this.filterForm, 'kpiCompteClientFilter', 'compteClient');
    }

    onSubmit() {
        // récupère les infos du filtre
        this.filterSvc.onSaveInLocalstorage(this.filterForm, 'kpiSearchFilter', 'search');
        this.filterSvc.onSaveInLocalstorage(this.filterForm, 'kpiCompteClientFilter', 'compteClient');

        if(this.filterForm.get('search').value !== null && this.filterForm.get('search').value === '') {
            this.filterForm.get('search').setValue(null)
            localStorage.removeItem('kpiSearchFilter');
        }

        if(this.filterForm.get('compteClient').value !== null && typeof this.filterForm.get('compteClient') === 'object') {
            if(this.filterForm.get('compteClient').value.length === 0) {
                this.filterForm.get('compteClient').setValue(null)
                localStorage.removeItem('kpiCompteClientFilter');
            }
        }

        this.submitFilter.emit(this.filterForm.getRawValue());
    }

    reset(): void {
        this.filterForm.reset();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(false);
        this._unsubscribeAll.complete();
    }
}
