import {Deserializable} from '../interfaces/deserializable.interface';

export class Contact implements Deserializable {

  public id?: number;
  public nom?: string;
  public prenom?: string;
  public email?: string;
  public tel_portable?: string;
  public pole_tel_fixe?: string;
  public pole_fax?: string;
  public pole_libelle: Date;
  public pole_email: Date;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
