import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {View} from '../../../../shared/models/view.model';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {Subscription} from 'rxjs';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from "@ngx-translate/core";
import {CommandeService} from "../../../../shared/services/api/commande.service";
import {CommandeData} from "../../../../shared/models/api/commande-data.model";
import {GrcData} from "../../../../shared/models/api/grc-data.model";
import {FilterMultiCritereModel} from "../../../../shared/models/filters/filterMultiCritere.model";
import {PaginationService} from "../../../../shared/services/pagination.service";
import {PageEvent} from "@angular/material/paginator";
import {GrcWexlogService} from "../../../../shared/services/api/wexlog/grc-wexlog.service";
import {WexlogGrcData} from "../../../../shared/models/api/wexlog/wexlog-grc-data.model";
import {ToastrService} from "ngx-toastr";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-grc-content',
  templateUrl: './grc-content.component.html',
  styleUrls: ['./grc-content.component.scss']
})
export class GrcContentComponent implements OnInit, OnDestroy {

  @Input() commande: CommandeData;

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();

  public grcData: GrcData;
  public activModal: NgbModalRef;
  public view: View;
  public grcWexlog: WexlogGrcData;

  // loader
  public loadingPage = false;
  public loading: boolean = false;
  public loadingTableGrc: boolean = false;
  public loadingNbGrc: boolean = false;
  public openId: number = null;
  public loadingTableGrcWewlog: boolean = false;

  // number
  public pageSize = 10;
  public nbData: number = 0;

  //tableau
  public subscriptions: Subscription[] = [];
  public grcDataWexlog: WexlogGrcData[];
  public grcsData: GrcData[];
  public pageSizeOption = [5, 10, 20];

  constructor(private translate: TranslateService,
              private authSvc: AuthenticationService,
              private commandeSvc: CommandeService,
              private paginationSvc: PaginationService,
              private toastr: ToastrService,
              private grcWexlogSvc: GrcWexlogService,
              private modalSvc: NgbModal) {
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
  }

  ngOnInit() {
    if (!this.commande) {
      throw new Error('Le composent grc-content attend un objet de type commande.')
    }
    this.authSvc.currentView.subscribe(x => this.view = x);
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      if (this.view.origine === 'tradiss') {
        this.loadDataGrc();
      } else {
        this.loadDataGrcsWexlog();
      }
    }))
    if (this.view.origine === 'tradiss') {
      this.loadDataGrc();
    } else {
      this.loadDataGrcsWexlog();
    }
  }


  onNewMessageSubmited(event) {
    if (this.view.origine === 'tradiss') {
      this.loadDataGrc();
    } else {
      this.loadDataGrcsWexlog();
    }
    this.activModal.close();
  }


  onDeleteGrc(num) {
    --this.nbData;
  }

  /**
   * On récupère les demandes Grc en fonction du numero Avis
   */
  loadDataGrc() {
    this.loadingTableGrc = true;
    this.loadingNbGrc = true;
    this.grcsData = [];
    this.nbData = 0;
    this.subscriptions.push(this.commandeSvc.getDemandesGrcByNumeroAvis(this.filter, this.commande.numero_avis).subscribe(grc => {
        this.grcsData = grc.data;
        this.nbData = grc.nb;
        this.loadingTableGrc = false;
        this.loadingNbGrc = false;
      },
      (error) => {
        this.loadingTableGrc = false;
        this.loadingNbGrc = false;
      }
    ));
  }

  /**
   * On récupère les demandes Grc wexlog en fonction du numero Avis
   */
  loadDataGrcsWexlog() {
    this.loadingTableGrcWewlog = true;
    this.grcDataWexlog = [];
    this.subscriptions.push(this.grcWexlogSvc.getDemandesGrcByNumeroAvis(this.filter, this.commande.reference_commande).subscribe(
      (grc) => {
        this.grcDataWexlog = grc;
        this.grcDataWexlog.forEach((value, index) => {
          if (value.libelle_statut === 'ASSIGNED' && value.creator_id !== this.view.srcClient) {
            if (value.messages.length === 1) {
              this.grcDataWexlog[index]['deletable'] = true;
            }
          }
        });
        this.loadingTableGrcWewlog = false;
      },
      (error) => {
        this.loadingTableGrcWewlog = false;
      }));
  }

  onLoadDataGrcByEvent(event: PageEvent) {
    this.filter.limit = event.pageSize;
    this.filter.offset = event.pageIndex ? event.pageIndex * event.pageSize : 0;
    this.loadDataGrc();
  }

  /**
   * On affiche tous les documents en lien avec les demandes Grc
   * @param modalName
   * @param grc
   */
  openModalVisualisationFile(modalName, grc: GrcData) {
    this.grcData = grc;
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg',
    });
  }

  /**
   * On affiche la conversion Grc sélectionnée
   * @param modalName
   * @param grc
   */
  openModalvisualisationMessages(modalName, grc: GrcData) {
    this.grcData = grc;
    this.activModal = this.modalSvc.open(modalName, {
      size: 'xl', keyboard: false
    });
  }

  /**
   * On affiche la conversion Grc sélectionnée
   * @param modalName
   * @param grcWexlog
   */
  openModalvisualisationMessagesWexlog(modalName, grcWexlog: WexlogGrcData) {
    this.grcWexlog = grcWexlog;
    this.activModal = this.modalSvc.open(modalName, {
      size: 'xl', keyboard: false, backdrop: 'static'
    });
    this.activModal.result.then(() => {
      this.loadDataGrcsWexlog()
    })
  }

  /**
   * On crée une nouvlle demande grc
   * @param modalName
   */
  openModalNouvelleDemande(modalName) {
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg', keyboard: false
    });
  }

  /**
   * On crée une nouvlle demande grc wexlog
   * @param modalName
   */
  openModalNouvelleDemandeWexlog(modalName) {
    this.activModal = this.modalSvc.open(modalName, {
      size: 'xl', keyboard: false, backdrop: 'static'
    });
    this.activModal.result.then(() => {
      this.loadDataGrcsWexlog();
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
