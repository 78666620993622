import {Deserializable} from '../interfaces/deserializable.interface';
import {Commande} from "./commande.model";


export class Prestation implements Deserializable {

  public id?: number;
  public commande: Commande;
  public type?: string;
  public code?: string;
  public designation?: string;
  public temps?: number;
  public created_at?: Date | string;
  public updated_at?: Date | string;
  public pending?: boolean;

  constructor() {
    this.pending = false;
  }

  deserialize(input: any): this {
    return Object.assign(this, input);

  }

}
