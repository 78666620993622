import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import 'rxjs-compat/add/operator/do';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {urlWithProtocol} from "../utils/util";

@Injectable()
export class HttpTranslateInterceptor implements HttpInterceptor {

  constructor(private translate: TranslateService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let local = environment.url_api_espace_client + '/check-connexion'
    if (request.url != local && this.translate.currentLang != undefined) {
      request = request.clone({
        headers: request.headers.set('Accept', 'application/json'),
        params: request.params.set('lang',this.translate.currentLang)
      })
    }
    return next.handle(request);
  }

}
