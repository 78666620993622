import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {NgbDate, NgbDateParserFormatter, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-picker-min-date',
  templateUrl: './date-picker-min-date.component.html',
  styleUrls: ['./date-picker-min-date.component.scss']
})
export class DatePickerMinDateComponent implements OnInit {

  @Input() filterForm: FormGroup;
  @Input() name: string;
  @Input() date: any;
  @Input() filterName: string;
  @Input() suiviLivraison = false;
  @Input() minDateSelectable: NgbDate = null;

  @ViewChild('datepicker') private inputDatePicker: NgbInputDatepicker;

  public innerWidth: number;

  constructor(public parserFormatter: NgbDateParserFormatter) {
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (!(this.filterForm instanceof FormGroup)) {
      throw new Error('Vous devez passer un formGroup en parametre du composant RangeDatePickerComponent');
    }
  }

  onDateSelection(date) {
    if (!this.date) {
      this.date = date;
    }
    if (this.date && this.filterForm.controls?.date) {
      this.filterForm.controls?.date.setValue(new Date(Date.UTC(this.date.year, this.date.month - 1, this.date.day)));
    }
    if (this.date && this.filterForm.controls?.minDate) {
      this.filterForm.controls?.minDate.setValue(new Date(Date.UTC(this.date.year, this.date.month - 1, this.date.day)));
    }
  }

  openDatePicker() {
    this.reset();
    localStorage.removeItem(this.filterName);
    this.inputDatePicker.toggle();
  }

  reset() {
    this.date = null;
  }

}
