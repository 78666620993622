import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Statut} from '../../../shared/models/api/statut.model';
import {LitigeData} from "../../../shared/models/api/litige-data.model";

@Component({
  selector: 'app-litige-component',
  templateUrl: './litige.component.html',
  styleUrls: ['./litige.component.scss']
})
export class LitigeComponent implements OnInit {

  @Input() litigeData: LitigeData;
  @Input() loading = false;
  public loadingPage = false;

  @Output() selectStatut: EventEmitter<Statut> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    if(!this.litigeData) {
      throw new Error('Le composent litige attend un objet de type litige.')
    }
  }
}
