import {Deserializable} from '../interfaces/deserializable.interface';

export class Pays implements Deserializable {

  public id?: string;
  public created_at?: Date;
  public updated_at?: Date;
  public code?: string;
  public libelle?: string;

  deserialize(input: any): this {
   Object.assign(this, input);
    return this
  }

}
