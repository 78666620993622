import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {User} from '../../../../shared/models/user.model';
import {ConversationService} from '../../../../shared/services/api/conversation.service';
import {UserService} from '../../../../shared/services/api/sso/user.service';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {environment} from '../../../../../environments/environment';
import {MessageConversation} from '../../../../shared/models/api/message-conversation.model';
import {UserData} from "../../../../shared/models/user-data.model";

@Component({
  selector: 'app-dialog-conversation',
  templateUrl: './chat-conversation.component.html'
})
export class ChatConversationComponent implements OnChanges, OnDestroy {

  @Input() public conversationId: number;
  public conversationSub: Subscription;
  public userSub: Subscription;
  public messages: MessageConversation[] = [];
  public currentUser: UserData;
  public urlAPI: string;
  public msg: MessageConversation = new MessageConversation();
  public pending: boolean = true;
  private refresh: number;

  @Output() onChange : EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private conversationSvc: ConversationService,
    private userSvc: UserService,
    private authSvc: AuthenticationService,
    private http: HttpClient
  ) {
  }

  ngOnInit() {
    // this.refresh = setInterval(() => this.waitConversationData(false), 1000);
  }

  ngOnChanges(changes: any) {
    this.waitConversationData();
    this.userSub = this.authSvc.currentUser.subscribe(x => this.currentUser = x);
  }

  refreshConversation() {
    this.waitConversationData();
  }

  waitConversationData(loading = true) {
    if (this.conversationId != undefined) {
      if (loading) {
        this.pending = true;
      }
      this.conversationSub = this.conversationSvc.getConversationMessages(this.conversationId).subscribe(
        (messages) => {
          this.messages = messages;
          this.pending = false;
        },
        error1 => {
          this.pending = false;
        }
      );
    }
  }

  onSubmit() {
    if (!this.msg.corps || this.msg.corps === '') {
      return;
    }
    this.msg.pending = true;
    //Mode put
    if (this.msg.id) {
      this.urlAPI = environment.url_api_espace_client + `/messages/${this.msg.id}`;
      this.http.put(this.urlAPI, this.msg).subscribe(
        x => {
          this.msg.pending = false;
          this.msg = new MessageConversation();
        },
        e => {
        }
      );
    } else {
      //Mode P0ST
      this.urlAPI = environment.url_api_espace_client + `/conversations/${this.conversationId}/messages/sent`;
      this.msg.username =  this.currentUser.username;
      // on ajoute le nouveau message au messages encours
      this.messages.push(this.msg);
      this.http.post<any>(this.urlAPI, this.msg).subscribe(
        x => {
          this.msg.pending = false;
          this.msg.id = parseInt(x);
          this.msg = new MessageConversation();
        },
        e => {
          //on retire le message
          this.messages.splice(-1, 1);
        }
      );
    }
  }

  /**
   *   récupère le message qui va être édité
   */
  onUpdate(message: MessageConversation) {
    if (!this.msg.dirty) {
      //passe le message edité dans une variable temporaire.
      this.msg = message;
      this.msg.dirty = true;
    }
  }

  /**
   *   Affiche le bouton d'édition si le message date de moins d'1h
   */
  ShowEdit(DateCreation: Date) {
    let result;
    let date1 = new Date(DateCreation).getTime();
    let date2 = new Date().getTime();
    let time = date2 - date1;
    let hours = (time / 60000) / 60;
    if (hours >= 1 ? result = false : result = true) {
      return result;
    }
  }

  ngOnDestroy(): void {
    this.conversationSub.unsubscribe();
    this.userSub.unsubscribe();
    clearInterval(this.refresh);
  }
}
