import {Component, OnDestroy, OnInit} from '@angular/core';
import {Litige} from '../../../shared/models/api/litige.model';
import Swal from 'sweetalert2';
import {LitigeService} from '../../../shared/services/api/litige.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {TourService} from 'ngx-tour-ngx-bootstrap';
import {LitigeData} from '../../../shared/models/api/litige-data.model';

@Component({
  selector: 'app-litige-page',
  templateUrl: './litige.html',
  styleUrls: ['./litige.scss']
})
export class LitigePage implements OnInit, OnDestroy {

  public litigeData: LitigeData;
  public litiges: Litige[];
  public loadingPage = false;
  public subscriptions: Subscription[] = [];

  constructor(private translate: TranslateService,
              private litigeSvc: LitigeService,
              public tourService: TourService,
              private routeActive: ActivatedRoute,
              private router: Router) {
  }


  ngOnInit() {
    this.subscriptions.push(this.routeActive.params.subscribe((params) => {
      this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
        this.loadDataLitige(params);
      }));
      this.loadDataLitige(params);
    }));
  }

  loadDataLitige(params) {
    this.loadingPage = true;
    this.subscriptions.push(this.litigeSvc.getLitige(params.id).subscribe(
      (litige) => {
        this.litigeData = litige;
        this.loadingPage = false;
      },
      () => {
        Swal
          .fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant("Le litige demandé n'existe pas")
          })
          .then(
            () => {
              this.router.navigate(['/litiges']);
            }
          ).catch(() => {
          Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('Une erreur est survenue'),
            showCancelButton: false,
            type: 'error',
          });
        });
      }));
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
