import {Deserializable} from '../interfaces/deserializable.interface';
import {Statut} from './statut.model';
import {LitigeData} from "./litige-data.model";
import {ConversationData} from "./conversation-data.model";
import {UserData} from "../user-data.model";

export class ContestationData implements Deserializable {

  public id?: number;
  public statut?: Statut;
  public litige?: LitigeData;
  public traite_par?: UserData;
  public objet?: string;
  public created_at?: Date;
  public updated_at?: Date;
  public contestataire: string;
  public conversation: ConversationData;
  public commentaire_statut?: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    this.statut = (input.statut) ? new Statut().deserialize(input.statut) : null;
    this.litige = (input.litige) ? new LitigeData().deserialize(input.litige) : null;
    this.conversation = (input.conversation) ? new ConversationData().deserialize(input.conversation) : null;
    this.traite_par = (input.traite_par) ? new UserData().deserialize(input.traite_par) : null;
    return this;
  }

}
