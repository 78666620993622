import {Deserializable} from '../interfaces/deserializable.interface';
import {ConversationGrc} from './conversation-grc.model';
import {TypeDemande} from './type-demande.model';
import {PieceJointeGed} from "./ged/piece-jointe-ged.model";

export class GrcData implements Deserializable {

  public id?: number;
  public id_origine?: string;
  public demandeur_origine?: string;
  public code_maj?: string;
  public context_base?: string;
  public demandeur?: string;
  public code?: string;
  public numero_evt_origine?: string;
  public created_at?: Date | string;
  public updated_at?: Date | string;
  public login?: string;
  public numero_avis?: string;
  public numero_client?: number;
  public numero_commande?: string;
  public date_commande?: Date | string;
  public destinataire_nom?: string;
  public destinataire_tel_fixe?: number;
  public destinataire_tel_portable?: number;
  public destinataire_email?: string;
  public destinataire_adresse?: string;
  public destinataire_adresse2?: string;
  public destinataire_code_postal?: string;
  public destinataire_ville?: string;
  public destinataire_pays?: string;
  public nb_colis?: number;
  public poids_total?: number;
  public volume_total?: number;
  public plateforme_transit_nom?: string;
  public code_plateforme_transit?: string;
  public plateforme_nom?: string;
  public code_plateforme?: string;
  public client_nom?: string;
  public groupe_client?: string;
  public defaut_statut?: string;
  public code_statut?: string;
  public libelle_statut?: string;
  public dernier_evenement?: string;
  public sacods?: string;
  public sacodm?: string;
  public objet?: string;
  public commentaire?: string;
  public conversation_grc?: ConversationGrc[]
  public type_demande?: TypeDemande;
  public doc?: PieceJointeGed[];
  public pending: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this
  }

}
