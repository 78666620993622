<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">

  <!-- debut header -->

  <div class="modal-header" *ngIf="!loadingSubmitBtn">
    <h4 class="modal-title  w-100 text-center">{{'Détails de la demande GRC' | translate }}
      n° {{wexlogGrcData.id}}
    </h4>
  </div>

  <!-- fin header -->

  <!-- debut body -->

  <div class="modal-body" *ngIf="!loadingSubmitBtn">
    <div class="row m-0">
      <div class="col-12 p-0">
        <div class="widget-chat text-left">
          <div class="" ngx-auto-scroll style="min-height: 190px;">
            <div *ngIf="!loading && wexlogGrcData.messages !== null"
                 style="min-height: 250px;position: relative; max-height: 500px;" class="overflow-scroll"
                 ngx-auto-scroll>
              <div *ngFor="let messageGrc of wexlogGrcData.messages | sort:'ffumsg_id'">
                <div class="widget-chat-body"
                     data-scrollbar="true" data-height="235px">

                  <!-- Début chatbox à gauche si le message correspond au SRC ou un autre utilisateur du DO -->

                  <div *ngIf="messageGrc.statut_message_type === 'QUESTION'"
                       class="widget-chat-item with-media left">
                    <div class="widget-chat-info">
                      <div class="widget-chat-info-container widget-chat-rounded-left" [ngClass]="{
                        'bg-color-client':messageGrc.creator_id !== this.view.srcClient ,
                        'bg-color-grc':messageGrc.creator_id === this.view.srcClient}">

                        <div class="row">
                          <div class="col-5">
                            <div class="row">
                              <div class="col-12">
                                <div class="widget-chat-name text-truncate" [ngClass]="{
                                'text-color':messageGrc.creator_id !== this.view.srcClient,
                                'text-agediss':messageGrc.creator_id === this.view.srcClient}">
                                  {{messageGrc.created_by | firstLetterOfFirstnamePipe}}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-7">
                            <div class="float-right"
                                 *ngIf="messageGrc.creator_id === this.view.srcClient">
                              <img src="../../../../../assets/img/logo2.svg" alt="> Agediss" class="logo-img  ml-2"
                                   style="width: 70px">
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12">
                            <div class="widget-chat-title">{{messageGrc.objet}}</div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12">
                            <div class="widget-chat-message" [innerText]="messageGrc.commentaire | regex"></div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12">
                            <div class="widget-chat-date-only" *ngIf="messageGrc.doc == null">
                              <div>
                                {{wexlogGrcData.created_at}}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <!-- Fin chatbox à gauche si le message correspond au SRC ou un autre utilisateur du DO -->

                  <!-- Début chatbox côté gauche si le user actuel correspond à l' utilisateur connecté -->

                  <div *ngIf="messageGrc.statut_message_type === 'ANSWER'"
                       class="widget-chat-item with-media right">
                    <div class="widget-chat-info">
                      <div class="widget-chat-info-container widget-chat-rounded-right" [ngClass]="{
                    'bg-color-client':messageGrc.creator_id !== this.view.srcClient,
                    'bg-color-grc':messageGrc.creator_id === this.view.srcClient}">

                        <div class="row">
                          <div class="col-7">
                            <div class="float-left"
                                 *ngIf="messageGrc.creator_id === this.view.srcClient">
                              <img src="../../../../../assets/img/logo2.svg" alt="> Agediss" class="logo-img  ml-2"
                                   style="width: 70px">
                            </div>
                          </div>
                          <div class="col-5">
                            <div class="row">
                              <div class="col-12">
                                <div class="widget-chat-name" [ngClass]="{
                                'text-color':messageGrc.creator_id !== this.view.srcClient ,
                                'text-agediss':messageGrc.creator_id === this.view.srcClient}">
                                  {{messageGrc.created_by | firstLetterOfFirstnamePipe}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12">
                            <div class="widget-chat-title">{{messageGrc.objet}}</div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12">
                            <div class="widget-chat-message text-left"
                                 [innerText]="messageGrc.commentaire | regex"></div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12">
                            <div class="widget-chat-date">
                              <div>
                                {{messageGrc.created_at}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Fin chatbox côté gauche si le message correspond a l'utilisateur connecté -->

                  </div>
                </div>
              </div>
            </div>

            <!-- fin conversation de la demande GRC -->

            <!-- debut formulaire de message de demande GRC -->

            <div
              *ngIf="!loadingSubmitBtn && wexlogGrcData.libelle_statut === 'ASSIGNED' && view.hasDroit('GRC_CREATE') && view.origine === 'wexlog' && view.contextId === '3891'"
              class="widget-input widget-input-rounded">
              <div class="widget-input widget-input-rounded">
                <form [formGroup]="messageDemandeGrcForm">
                  <div class="row m-0 pt-2">
                    <div class="col-12 px-0 pt-2">
                      <div class="col-12 p-0">
                        <label
                          class="d-block d-lg-none">{{'Type de texte' | translate }}</label>
                      </div>
                      <div class="input-group-prepend">
                        <span class="input-group-text input-group-border-right d-none d-lg-flex align-items-center">
                          {{'Type de texte' | translate }}
                        </span>
                        <ng-select class="ng-select filter"
                                   [ngClass]="{'is-invalid':messageDemandeGrcForm.get('typeTextMessage').invalid && (messageDemandeGrcForm.get('typeTextMessage').dirty || formSubmit) }"
                                   [items]="typeTextMessages | sort: 'objet'"
                                   [selectOnTab]="true"
                                   (change)="onChangeTextMessage($event) && disableEnableInput('message',messageDemandeGrcForm.get('typeTextMessage').value)"
                                   bindValue="objet"
                                   bindLabel="objet"
                                   [loading]="loadingTypeTextMessage"
                                   [clearable]="true"
                                   [placeholder]="placeholder"
                                   formControlName="typeTextMessage">
                        </ng-select>
                        <div
                          *ngIf="messageDemandeGrcForm.get('typeTextMessage').invalid && (messageDemandeGrcForm.get('typeTextMessage').dirty || formSubmit)"
                          class="text-center invalid-tooltip ml-1">
                          {{'Veuillez saisir un type de texte !' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row m-0 pt-2">
                    <div class="col-lg-12 col-md-12 p-0">
                      <div class="row m-0">
                        <div class="col-5 col-md-6 p-0">
                          <label class="col-lg-12 col-md-12 col-sm-12 p-0">{{'Votre message' | translate }}
                            :</label>
                        </div>
                        <div class="col-7 col-md-6 d-flex justify-content-end"
                             [ngClass]="{'text-danger': numberOfCharactersMessage <= 10,'text-dark': numberOfCharactersMessage > 10}">
                          {{numberOfCharactersMessage}} {{'caractères restants' | translate }}
                        </div>
                      </div>
                      <div class="row m-0">
                      <textarea rows="7" [readOnly]="isReadOnly" [maxlength]="'maxNumberOfCharactersMessage'"
                                (keyup)="onKeyUpInfos(maxNumberOfCharactersMessage, $event)"
                                class="form-control form-control-filter" formControlName="message"
                                placeholder="{{'Sélectionnez d\'abord un type de texte' | translate }}"
                                [ngClass]="{'is-invalid': messageDemandeGrcForm.get('message').invalid  && (messageDemandeGrcForm.get('message').dirty || formSubmit)}">
                      </textarea>
                        <div class="text-center invalid-tooltip"
                             *ngIf="messageDemandeGrcForm.get('message').invalid  && (messageDemandeGrcForm.get('message').dirty || formSubmit)">
                          {{'Veuillez saisir un message !' | translate}}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <!-- fin formulaire de message de demande GRC -->

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- footer -->

  <div class="modal-body py-0" *ngIf="!loadingSubmitBtn && wexlogGrcData.messages !== null && wexlogGrcData.creator_id !== this.view.srcClient">
    <div *ngIf="wexlogGrcData.libelle_statut == 'ASSIGNED'"
         class="alert alert-warning py-2" role="alert">
      {{ "Si vous êtes satisfait de la réponse du Service client, cliquez sur le bouton ' Résoudre ' !" | translate }}
    </div>
    <div *ngIf="wexlogGrcData.libelle_statut == 'RESOLVED'"
         class="alert alert-primary py-2" role="alert">
      {{ "Si vous souhaitez fournir de plus amples informations à votre demande GRC, cliquez sur ' Compléter ' !" | translate }}
    </div>
    <div *ngIf="wexlogGrcData.libelle_statut == 'RESOLVED'"
         class="alert alert-green py-2" role="alert">
      {{ "Pensez à clôturer votre demande GRC en cliquant sur le bouton ' Clôturer ' !" | translate }}
    </div>
  </div>

  <div class="modal-footer modal-footer-flex p-5" *ngIf="!loadingSubmitBtn">
    <div>
      <button
        *ngIf="wexlogGrcData.libelle_statut == 'ASSIGNED' && wexlogGrcData.messages !== null && wexlogGrcData.creator_id !== this.view.srcClient"
        class="btn btn-warning text-white mr-2" [ladda]="loadingUpdateResolvedStatutBtn" data-style="expand-right"
        (click)="onUpdateStatut('RESOLVED')">{{ 'Résoudre' | translate }}
      </button>
      <button
        *ngIf="wexlogGrcData.libelle_statut == 'RESOLVED' && wexlogGrcData.messages !== null && wexlogGrcData.creator_id !== this.view.srcClient"
        class="btn btn-primary text-white mr-2" [ladda]="loadingUpdateAssignedStatutBtn" data-style="expand-right"
        (click)="onUpdateStatut('ASSIGNED')">{{ 'Compléter' | translate }}
      </button>
      <button
        *ngIf="wexlogGrcData.libelle_statut == 'RESOLVED' && wexlogGrcData.messages !== null && wexlogGrcData.creator_id !== this.view.srcClient"
        class="btn btn-green text-white" [ladda]="loadingUpdateClosedStatutBtn" data-style="expand-right"
        (click)="onUpdateStatut('CLOSED')">{{ 'Clôturer' | translate }}
      </button>
    </div>
    <div>
      <button *ngIf="wexlogGrcData.libelle_statut !== 'CLOSED'" class="btn btn-primary text-white mr-2" [disabled]="disabledButton" [ladda]="loadingSubmitBtn" data-style="zoom-in"
              (click)="refreshConversation()">{{'Actualiser' | translate }}
      </button>
      <button *ngIf="wexlogGrcData.libelle_statut !== 'CLOSED'" class="btn btn-agediss text-white mr-2" [disabled]="disabledButton" [ladda]="loadingSubmitBtn" data-style="zoom-in"
              (click)="onSubmit()">{{'Envoyer' | translate }}
      </button>
      <button type="button" class="btn bg-black-lighter text-white"
              (click)="cancel()">{{ 'Fermer' | translate}}</button>
    </div>
  </div>

  <!-- Fin footer -->

  <!-- debut loading submit form -->

  <div class="modal-body" style="height: 500px" *ngIf="loadingSubmitBtn">
    <div class="col-12 text-center" style="margin: 80px auto">
      <span class="fas fa-cog fa-spin text-agediss" style="font-size: 40px;"></span>
    </div>
    <div class="col-12 text-center">
      <span class="f-s-20">{{'Envoi de la réponse GRC en cours' | translate }}</span>
    </div>
  </div>

  <!-- fin loading submit form -->

</div>
