import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Notif} from '../../../shared/models/api/notif.model';
import {MatSort, Sort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {DatatableHelper} from '../../../shared/helpers/datatable.helper';
import {FormControl} from '@angular/forms';
import {TranslateService} from "@ngx-translate/core";
import {NotificationService} from "../../../shared/services/api/notification/notification.service";
import {Subscription} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {NotifData} from "../../../shared/models/api/notif-data.model";

@Component({
  selector: 'app-table-notification',
  templateUrl: './table-notification.component.html',
  styleUrls: ['./table-notification.component.scss']
})
export class TableNotificationComponent implements OnInit {

  search = new FormControl();

  @Input() data: NotifData[];
  @Input() pageSize: number = 10;
  @Input() pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  @Input() displayedColumns: string[] = [
    'select',
    'content',
    'created_by',
    'options'
  ];
  @Input() loading = false;
  @Input() showCheckIcon = false;
  @Input() showDeleteIcon = false;
  @Input() loadingBtn: Boolean = false;
  @Input() showBtn: Boolean = false;
  @Input() hiddenColumns: string[] = [];
  public dataSource: any;
  private subscription: Subscription;
  public lang;
  public innerWidth: number;

  @Output() selectCheckNotif: EventEmitter<{ id: number, index: number }> = new EventEmitter<{ id: number, index: number }>();
  @Output() onGoToUrl: EventEmitter<{ url: string, id: number, index: number }> = new EventEmitter<{ url: string, id: number, index: number }>();
  @Output() selectCheckAllNotif: EventEmitter<Notif> = new EventEmitter<Notif>();
  @Output() selectDeleteAllNotif: EventEmitter<Notif> = new EventEmitter<Notif>();
  @Output() paginatorEvent : EventEmitter<PageEvent> = new EventEmitter<PageEvent>()

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public translate: TranslateService, private toastr: ToastrService, private notificationSvc: NotificationService) {
  }

  ngOnInit() {
    this.onResizeTable();
    this.dataSource = new MatTableDataSource<NotifData>(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1)
        }
      })
    }
    this.translate.onLangChange.subscribe(() => {
      this.lang = this.translate.currentLang
    })
    this.lang = this.translate.currentLang
  }

  selectCheckNotifId(id: number, index: number) {
    this.selectCheckNotif.emit({id, index});
  };

  goTo(url: string, id: number, index: number) {
    this.onGoToUrl.emit({url, id, index})
  }

  onCheckAllNotif(notif) {
    this.selectCheckAllNotif.emit(notif);
  }

  onDeleteAllNotif(notif) {
    this.selectDeleteAllNotif.emit(notif);
  }

  removeNotif(id: number, index: number) {
    this.dataSource.data[index].pending = true;
    this.subscription = this.notificationSvc.removeNotif(id).subscribe(
      () => {
        this.dataSource.data[index].pending = false;
        this.dataSource.data.splice(index, 1);
        this.dataSource = new MatTableDataSource(this.dataSource.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      () => {
        this.dataSource.data[index].pending = false;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.toastr.error(
          this.translate.instant("La notification n'a pas pu être supprimées"),
        );
      }
    );
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  /**
   * Permet d'ordonner les colones.
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 500) {
      this.displayedColumns = [
        'select',
        'content',
        'options'
      ];
    } else if (this.innerWidth > 500 ) {
      this.displayedColumns = [
        'select',
        'content',
        'created_by',
        'options'
      ];
    }
  }

}
