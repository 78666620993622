import {Component, ViewChild, forwardRef, AfterViewInit, Input, ElementRef, Renderer2} from '@angular/core';
import {
  FormControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {NgbDate, NgbCalendar, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-week-date-picker',
  templateUrl: './week-date-picker.html',
  styleUrls: ['./week-date-picker.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WeekDatePickerComponent),
      multi: true
    }
  ]
})
export class WeekDatePickerComponent implements ControlValueAccessor, AfterViewInit {
  fromDate: NgbDate;
  toDate: NgbDate;

  @Input() formControl: FormControl;

  @ViewChild('datePicker') private datePicker: NgbInputDatepicker;
  @ViewChild('dateInput') private dateInput: ElementRef;

  set week(value) {
    const dateFrom = (new Date(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day)).toISOString();
    const dateTo = (new Date(this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day)).toISOString();
    this.onChange({ dateFrom, dateTo, year: this.fromDate.year, week: value });
  }

  disabled = false;
  onChange: (_: any) => void;
  onTouched: any;

  constructor(element: ElementRef, private renderer: Renderer2, private ngbCalendar: NgbCalendar)
  {
  }

  openDatePicker() {
    this.datePicker.toggle();
  }

  onDateSelection(date: NgbDate) {
    let fromDate = new Date(date.year + '-' + date.month + '-' + date.day);
    const time = fromDate.getDay() ? fromDate.getDay() - 1 : 6;
    fromDate = new Date(fromDate.getTime() - time * 24 * 60 * 60 * 1000);
    this.fromDate = new NgbDate(
      fromDate.getFullYear(),
      fromDate.getMonth() + 1,
      fromDate.getDate()
    );
    const toDate = new Date(fromDate.getTime() + 6 * 24 * 60 * 60 * 1000);
    this.toDate = new NgbDate(
      toDate.getFullYear(),
      toDate.getMonth() + 1,
      toDate.getDate()
    );
    if (this.onTouched) { this.onTouched(); }
    if (this.onChange) {
      this.week = this.calculateWeek(fromDate);
    }

    // const s = this.calculateWeek(fromDate) + ' (' + fromDate.getFullYear() + ')';
    let s = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
    s += '  ...  ';
    s += this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
    this.renderer.setProperty(this.dateInput.nativeElement, 'value', s);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date)
    );
  }

  calculateWeek(date: any) {
    const time = date.getTime() + 4 * 24 * 60 * 60 * 1000;
    const firstDay = new Date(date.getFullYear() + '-1-1');
    return (
      Math.floor(Math.round((time - firstDay.getTime()) / 86400000) / 7) + 1
    );
  }

  calculateDate(week: number, year: number) {
    const firstDay = new Date(year + '-1-4');
    const date = new Date(
      firstDay.getTime() + (week - 1) * 7 * 24 * 60 * 60 * 1000
    );
    const selectDate = new NgbDate(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    );
    this.onDateSelection(selectDate);
  }

  ngAfterViewInit() {
    if (this.fromDate) {
      setTimeout(() => {
        this.datePicker.navigateTo(this.fromDate);
      });
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    if (value) {
      this.calculateDate(value.week, value.year);
    }
  }
}
