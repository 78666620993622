import {Injectable} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { DatePickerMaxDateComponent } from 'src/app/components/shared/date-picker/date-picker-max-date/date-picker-max-date.component';
import { DatePickerMinDateComponent } from 'src/app/components/shared/date-picker/date-picker-min-date/date-picker-min-date.component';
import { DatePickerMaxDateExtractionLivraisonComponent } from 'src/app/components/shared/date-picker/extraction-livraison/date-picker-max-date-extraction-livraison/date-picker-max-date-extraction-livraison.component';
import { DatePickerMinDateExtractionLivraisonComponent } from 'src/app/components/shared/date-picker/extraction-livraison/date-picker-min-date-extraction-livraison/date-picker-min-date-extraction-livraison.component';
import { DatePickerMaxDateExtractionRdvComponent } from 'src/app/components/shared/date-picker/extraction-rdv/date-picker-max-date-extraction-rdv/date-picker-max-date-extraction-rdv.component';
import { DatePickerMinDateExtractionRdvComponent } from 'src/app/components/shared/date-picker/extraction-rdv/date-picker-min-date-extraction-rdv/date-picker-min-date-extraction-rdv.component';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor() {
  }

  /**
   * On check si la le valeur existe dans le localstorage ou si il est vide
   * @param filterForm
   * @param name
   * @param field
   */
  onCheckValueLocalStorage(filterForm: FormGroup, name: string, field: string) {
    if (!localStorage.getItem(name) || localStorage.getItem(name) === '' || localStorage.getItem(name) === null) {
      localStorage.removeItem(name);
      if (name == 'grcStatutFilter') {
        this.onSelectStatutDefault(filterForm, '2', '4');
      }
      if (name == 'wexlogGrcStatutFilter') {
        this.onSelectStatutDefaultGrcWexlog(filterForm, 'ASSIGNED_DO');
      }
    } else {
      this.onGetMultipleValue(filterForm, name, field);
    }
  }

  /**
   * Statut GRC
   * @param filterForm
   * @param codeStatut1
   * @param codeStatut2
   */
  onSelectStatutDefault(filterForm: FormGroup, codeStatut1: string, codeStatut2: string) {
    filterForm.get('statut').patchValue([codeStatut1, codeStatut2]);
  }

  /**
   * Statut GRC
   * @param filterForm
   * @param codeStatut1
   */
  onSelectStatutDefaultGrcWexlog(filterForm: FormGroup, codeStatut1: string) {
    filterForm.get('statut').patchValue([codeStatut1]);
  }

  /**
   * On récupère la valeur dans le localstorage et on créé un tableau à l'aide de la function split() pour la recherche multiple
   * @param filterForm
   * @param name
   * @param field
   */
  onGetMultipleValue(filterForm: FormGroup, name: string, field: string) {
    let l = localStorage.getItem(name);
    if (l !== null) {
      let ls = l.split(',');
      if (ls !== null) {
        for (let i = 0; i < ls.length; i++) {
          filterForm.get(field).patchValue(ls);
        }
      }
    }
  }

  /**
   * On enregistre les info du filter dans le localstorage
   * @param filterForm
   * @param name
   * @param field
   */
  onSaveInLocalstorage(filterForm: FormGroup, name: string, field: string) {
    if (filterForm.get(field).value !== null && filterForm.get(field).value !== '') {
      localStorage.setItem(name, filterForm.get(field).value);
    } else {
      localStorage.removeItem(name);
    }
  }

  /**
   * on récupère la date dans le localstorage.
   * si elle est null, on envoie la date du jour.
   * @param filterForm
   * @param name
   * @param field
   */
  getDateLocalstorage(filterForm: FormGroup, name: string, field: string) {
    if (filterForm.get(field).value !== null) {
      localStorage.setItem(name, filterForm.get(field).value);
    } else {
      localStorage.setItem(name, new Date().toString());
    }
  }

  /**
   *  On convertit la date enregistrée dans le localstorage pour l'utiliser dans les paramètres de l'url
   */
  convertDateLocalstorageForUrl(filterForm: FormGroup, field: string, name: string) {
    let date = localStorage.getItem(name);
    if (date === null) {
      return;
    }
    let newDate = new Date(date);
    let dateFormat = newDate.toISOString();
    filterForm.get(field).patchValue(dateFormat);
  }

  /**
   * on convertit la date enrgistrée dans le localStorage, pour l'afficher dans l'input
   * @param date
   */
  convertDateLocalstorageForShowInput(date: string) {
    const d = new Date(date);
    return <NgbDateStruct>{
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
  }

  /**
   *
   * @param date
   * @param days
   */
  addDays(date: string | number | Date, days: number) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  intervalDate(minDateValue: string | number | Date, maxDateValue: string | number | Date) {
    let minDate = new Date(minDateValue);
    let maxDate = new Date(maxDateValue);
    return Math.floor((maxDate.getTime() - minDate.getTime()) / 1000 / 60 / 60 / 24);
  }

  ckeckInputIsNull(filterForm: FormGroup, inputDate: DatePickerMinDateComponent | DatePickerMaxDateComponent | DatePickerMinDateExtractionRdvComponent | DatePickerMaxDateExtractionRdvComponent | DatePickerMinDateExtractionLivraisonComponent | DatePickerMaxDateExtractionLivraisonComponent, field: string) {
    if (inputDate.date == null) {
      filterForm.get(field).reset();
    }
  }
}
