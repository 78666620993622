<div *ngIf="loading" class="spinner"></div>
<div *ngIf="!loading">
  <div class="row m-0">
    <div class="col-md-12 pt-3">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{grc?.destinataire_nom | uppercase}}">{{'Nom' | translate }} :</strong>
        <span> {{grc?.destinataire_nom | uppercase}}</span>
      </p>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-md-12">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{grc?.destinataire_adresse | uppercase}} {{grc?.destinataire_code_postal}} {{grc?.destinataire_ville | uppercase}}">{{'Adresse' | translate }} :</strong>
        <span>
          {{grc?.destinataire_adresse | uppercase}} {{grc?.destinataire_code_postal}} {{grc?.destinataire_ville | uppercase}}
        </span>
      </p>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-md-12">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{grc?.destinataire_email}}">{{'Email' | translate }} :</strong>
        <span> {{grc?.destinataire_email}}</span>
      </p>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-md-6">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{grc?.destinataire_tel_fixe}}">{{'Fixe' | translate }} :</strong>
        <span> {{grc?.destinataire_tel_fixe}}</span>
      </p>
    </div>
    <div class="col-md-6">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{grc?.destinataire_tel_portable}}">{{'Mobile' | translate }} :</strong>
        <span> {{grc?.destinataire_tel_portable}}</span>
      </p>
    </div>
  </div>
</div>
