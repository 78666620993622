<div class="row m-0 text-white info">
  <div class="size-block py-1">
    <div class="info-div text-center element">
      <div class="title text-truncate"><span class="font-weight-bold">{{'Contestation' | translate }} n° </span></div>
      <div class="contenu"><span>{{contestationData.id}}</span></div>
    </div>
  </div>
  <div class="size-block py-1">
    <div class="info-div text-center element">
      <div class="title text-truncate"><span class="font-weight-bold">{{'Litige' | translate }} n°</span></div>
      <div class="contenu"><span>{{contestationData.litige.id}}</span></div>
    </div>
  </div>
  <div class="size-block py-1">
    <div class="info-div text-center element">
      <div class="title text-truncate"><span class="font-weight-bold">{{'Commande' | translate }} n°</span></div>
      <div class="contenu"><span>{{contestationData.litige?.numero_commande}}</span></div>
    </div>
  </div>
  <div class="size-block py-1">
    <div class="info-div text-center element">
      <div class="title text-truncate"><span class="font-weight-bold">{{'Créé par' | translate }}</span></div>
      <div class="contenu"><span>{{contestationData.created_at | date : 'dd/MM/yyy'}}</span></div>
    </div>
  </div>
  <div class="size-block py-1">
    <div class="info-div text-center element">
      <div class="title"><span class="font-weight-bold">{{'Statut' | translate }}</span></div>
      <div class="contenu"><span>{{contestationData?.statut?.libelle}}</span></div>
    </div>
  </div>
</div>
