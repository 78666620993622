import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'statutGrcPipe'})
export class StatutGrcPipe implements PipeTransform {
  transform(value: string, exponent?: number): string {
    switch(value) {
      case 'Info':
        return 'label-info';

      case 'A traiter':
      case 'ASSIGNED':
        return 'label-primary';

      case 'Clos':
      case 'CLOS':
      case 'Archivé':
      case 'RESOLVED':
      case 'CLOSED':
        return 'label-green';

      case 'En Traitement':
        return 'label-warning';

      case 'DELETED':
        return 'label-danger';
    }
  }
}
