import {Deserializable} from '../interfaces/deserializable.interface';
import {Grc} from './grc.model';
import {PieceJointeGed} from "./ged/piece-jointe-ged.model";

export class ConversationGrc implements Deserializable {

  public id?: number;
  public id_detail?: number;
  public grc: Grc;
  public numero_event: number;
  public date_message: Date | string;
  public login: string;
  public context_base: string;
  public demandeur: string;
  public SACODS: string;
  public SACODM: string;
  public message: string;
  public libelle_event: string;
  public pending: boolean;
  public idTRT_DemandeGRC?: number;
  public NUMEVT: number;
  public dateHeureMessage: Date | string;
  public contexteBase: string;
  public quiDemande: string;
  public libelleEvt: string;
  public doc?: PieceJointeGed[];


  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
