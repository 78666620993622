import {Injectable} from '@angular/core';
import {FilterMultiCritereModel} from "../models/filters/filterMultiCritere.model";

@Injectable({
  providedIn: 'root'
})
export class PaginationService {


  constructor() { }

  defaultFilterPagination(filter, pageSize) {
    filter.limit = pageSize
    filter.offset = 0
  }

  onPaginationEvent(filter, event) {
    filter.limit = event.pageSize
    filter.offset = event.pageIndex ? event.pageIndex * event.pageSize : 0
  }

}
