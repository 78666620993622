import {Component, OnDestroy, OnInit} from '@angular/core';
import {Contestation} from '../../../shared/models/api/contestation.model';
import Swal from "sweetalert2";
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ContestationService} from '../../../shared/services/api/contestation.service';
import {TranslateService} from '@ngx-translate/core';
import {TourService} from "ngx-tour-ngx-bootstrap";
import {ContestationData} from "../../../shared/models/api/contestation-data.model";

@Component({
  selector: 'app-contestation-page',
  templateUrl: './contestation.html',
  styleUrls: ['./contestation.scss']
})
export class ContestationPage implements OnInit, OnDestroy {

  public contestationData: ContestationData;
  public loadingPage = false;
  public subscriptions: Subscription[] = [];

  constructor(private translate: TranslateService,
              private contestationSvc: ContestationService,
              public tourService: TourService,
              private routeActive: ActivatedRoute,
              private router: Router) { }


  ngOnInit() {
    this.loadingPage = true;
    // récupération de l'id de la contestations
    this.subscriptions.push(this.routeActive.params.subscribe((params) => {
      this.subscriptions.push(this.contestationSvc.getContestation(params.id).subscribe(
        (contestation) => {
          this.contestationData = contestation;
          this.loadingPage = false;
        },
        () => {
          Swal
            .fire({
              title: this.translate.instant('Désolé'),
              text: this.translate.instant("La contestation demandée n'existe pas !")
            })
            .then(
              () => {
                this.router.navigate(['/contestations']);
              }
            ).catch(() => {
            Swal.fire({
              title: this.translate.instant('Désolé'),
              text: this.translate.instant('Une erreur est survenue'),
              showCancelButton: false,
              type: 'warning',
            });
          });
        }));
    }));
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }


}
