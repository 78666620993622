import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {View} from "../models/view.model";
import {AuthenticationService} from "./authentication.service";
import {UserData} from "../models/user-data.model";
import {TourService} from "ngx-tour-ngx-bootstrap";

@Injectable({
    providedIn: 'root'
})
export class TutorielService {

    public view: View;
    public currentUser: UserData;
    public steps = [];
    public anchorDashboard = [];
    public anchorStatsTradiss = [];
    public anchorStatsWexlog = [];
    public anchorAnomalies = [];
    public anchorSuiviLivraison = [];
    public anchorLivraison = [];
    public anchorRetour = [];
    public anchorLitige = [];
    public anchorContestation = [];
    public anchorFacture = [];
    public anchorNouvelleFacture = [];
    public anchorGrcWexlog = [];
    public anchorGrcTradiss = [];
    public anchorExtraction = [];
    public anchorAdministration = [];
    public anchorParametres = [];
    public anchorGrcTradissNotifications = [];
    public anchorVideoNotifications = [];


    constructor(public authSvc: AuthenticationService,
                public translate: TranslateService,
                public tourService: TourService,) {
        this.authSvc.currentUser.subscribe(x => this.currentUser = x);
        this.authSvc.currentView.subscribe(x => this.view = x);
    }

    tour() {
        this.tourService.initialize(this.steps, {route: 'home'})
        this.tourService.start();
    }

    stepsTourWithRights(context: number | string) {
        if (context === '1') {
            this.steps = [
                {
                    anchorId: 'start.tour',
                    content: this.translate.instant('Le tutoriel vous présente les fonctionnalités de l’espace client AGEDISS<br><br>Cliquez sur  >  pour continuer la visite'),
                    placement: 'auto',
                    title: this.translate.instant('Bienvenue dans votre espace client Agediss'),
                },
                {
                    anchorId: 'menu.principal',
                    content: this.translate.instant("<strong>Mes Videos :</strong><br>Ce menu vous permet de rechercher une vidéo<br><br><strong>Mes KPIs (rapports chiffrés) :</strong><br>Ce menu vous permet de rechercher un KPI (rapport chiffré)"),
                    placement: 'left',
                    title: this.translate.instant('Navigation'),
                },
                {
                    anchorId: 'menu.agediss',
                    content: this.translate.instant('Retour à l\'administration sso'),
                    placement: 'left',
                    title: this.translate.instant('Agediss'),
                },
                {
                    anchorId: 'menu.langue',
                    content: this.translate.instant('La liste des langues disponibles'),
                    placement: 'auto',
                    title: this.translate.instant('Les Langues'),
                },
            ];
        } else if (context === '3') {
            this.steps = [
                {
                    anchorId: 'start.tour',
                    content: this.translate.instant('Le tutoriel vous présente les fonctionnalités de l’espace client AGEDISS<br><br>Cliquez sur  >  pour continuer la visite'),
                    placement: 'auto',
                    title: this.translate.instant('Bienvenue dans votre espace client Agediss'),
                },
                {
                    anchorId: 'menu.principal',
                    content: this.translate.instant("<strong>Tableau de bord :</strong><br> Vision synthétique de votre activité avec Agediss : <br>NPS,compteur livraison, compteur GRC, répartition des livraisons et suivi des livraisons programmées ce jour<br><br><strong>Mes livraisons :</strong><br>Ce menu vous permet de rechercher une demande de livraison précise et de visualiser avec précision toutes les étapes de la livraison<br><br><strong>Mes retours :</strong><br>Ce menu vous permet de rechercher une demande de reverse précise et de visualiser avec précision toutes les étapes du retour<br><br><strong>Mes litiges :</strong><br>Ce menu vous permet de visualiser l’ensemble des dossiers litiges ouverts avec Agediss - Vous pouvez consulter l’analyse faite par votre conseiller ainsi que les justificatifs associés, grâce à ce menu vous êtes libre de transmettre vos factures associées aux litiges<br><br><strong>Gestion relation client :</strong><br>Ce menu vous permet d’ouvrir des conversations avec votre conseiller Agediss<br><br> <strong>Mes extractions :</strong><br>Ce menu vous permet de réaliser des extractions généralisées sur vos demandes de livraisons<br><br><strong>Mes Vides :</strong><br>Ce menu vous permet de rechercher une vidéo<br><br><strong>Mes KPIs (rapports chiffrés) :</strong><br>Ce menu vous permet de rechercher un KPI (rapport chiffré)"),
                    placement: 'left',
                    title: this.translate.instant('Navigation'),
                },
                {
                    anchorId: 'menu.secondaire',
                    content: this.translate.instant('Les coordonnées de votre conseiller dédié'),
                    placement: 'left',
                    title: this.translate.instant('Mon contact privilégié'),
                },
                {
                    anchorId: 'menu.agediss',
                    content: this.translate.instant('Retour au menu principal'),
                    placement: 'left',
                    title: this.translate.instant('Agediss'),
                },
                {
                    anchorId: 'menu.langue',
                    content: this.translate.instant('La liste des langues disponibles'),
                    placement: 'auto',
                    title: this.translate.instant('Les Langues'),
                },
                {
                    anchorId: 'menu.parametres',
                    content: this.translate.instant("<strong>Gestion des utilisateurs : </strong><br>Vous permet de créer des accès à l’outil de suivi Agediss<br><br><strong>Mes paramètres :</strong><br>Vous permet de gérer vos données personnelles"),
                    placement: 'auto',
                    title: this.translate.instant('Mes paramètres'),
                },
            ]
        } else {
            this.steps = []
        }

        if (this.view.hasDroit('COMMANDE_MENU_TABLEAU-DE-BORD') && this.view.origine === 'tradiss') {
            this.anchorStatsTradiss = [
                {
                    anchorId: 'stats.nps',
                    content: this.translate.instant('Il calcule la satisfaction de vos consommateurs<br><br><strong>Règle de calcul:</strong><br>% Promoteur - % Détracteur<br>Promoteur note entre 9 et 10<br>Détracteur note entre 1 et 6'),
                    placement: 'auto',
                    route: 'tableau-de-bord',
                    title: this.translate.instant('NPS : Net Promoter Score'),
                },
                {
                    anchorId: 'stats.livraison',
                    content: this.translate.instant('Nombre de demande de livraison transmis à Agediss'),
                    placement: 'auto',
                    route: 'tableau-de-bord',
                    title: this.translate.instant('Mes livraisons'),
                },
                {
                    anchorId: 'stats.grc',
                    content: this.translate.instant("Nombre de demande transmis via le module Gestion Relation Client<br><br><strong>GRC en attente du client:</strong><br>Nombre de GRC que vous avez ouvert dont Agediss doit vous répondre<br><br><strong>GRC en attente d’Agediss:</strong><br>Nombre de GRC ouvert par Agediss dont vous n’avez pas répondu"),
                    placement: 'auto',
                    route: 'tableau-de-bord',
                    title: this.translate.instant('Mes demandes GRC'),
                },
                {
                    anchorId: 'stats.commande',
                    content: this.translate.instant("Ce graphique représente la répartition de vos demandes de livraison en fonction des étapes de livraison<br>Vous pouvez retrouver le détail des demandes par catégorie dans le menu « Mes livraisons », en sélectionnant le statut adéquate"),
                    placement: 'auto',
                    route: 'tableau-de-bord',
                    title: this.translate.instant('Nombre de commandes par statuts'),
                },
            ]
            this.anchorStatsTradiss.forEach((val) => {
                this.steps.push(val)
            })
        }

        if (this.view.hasDroit('COMMANDE_MENU_TABLEAU-DE-BORD') && this.view.origine === 'wexlog') {
            this.anchorStatsWexlog = [
                {
                    anchorId: 'stats.nps',
                    content: this.translate.instant('Il calcule la satisfaction de vos consommateurs<br><br><strong>Règle de calcul:</strong><br>% Promoteur - % Détracteur<br>Promoteur note entre 9 et 10<br>Détracteur note entre 1 et 6'),
                    placement: 'auto',
                    route: 'tableau-de-bord',
                    title: this.translate.instant('NPS : Net Promoter Score'),
                },
                {
                    anchorId: 'stats.livraison',
                    content: this.translate.instant('Nombre de demande de livraison transmis à Agediss'),
                    placement: 'auto',
                    route: 'tableau-de-bord',
                    title: this.translate.instant('Mes livraisons'),
                },
                {
                    anchorId: 'stats.commande',
                    content: this.translate.instant("Ce graphique représente la répartition de vos demandes de livraison en fonction des étapes de livraison<br>Vous pouvez retrouver le détail des demandes par catégorie dans le menu « Mes livraisons », en sélectionnant le statut adéquate"),
                    placement: 'auto',
                    route: 'tableau-de-bord',
                    title: this.translate.instant('Nombre de commandes par statuts'),
                },
            ]
            this.anchorStatsWexlog.forEach((val) => {
                this.steps.push(val)
            })
        }

        if (this.view.hasDroit('COMMANDE_MENU_TABLEAU-DE-BORD')) {
            this.anchorDashboard = [
                {
                    anchorId: 'tableau.livraison-du-jour',
                    content: this.translate.instant('Vous permet de visualiser en temps réel la réalisation des livraisons prévues ce jour'),
                    placement: 'auto',
                    route: 'tableau-de-bord',
                    title: this.translate.instant('Livraisons du jour'),
                },
            ]
            this.anchorDashboard.forEach((val) => {
                this.steps.push(val)
            })
        }

        if (this.view.hasDroit('COMMANDE_MENU_ANOMALIES')) {
            this.anchorAnomalies = [
                {
                    anchorId: 'tableau.anomalies',
                    content: this.translate.instant('Vous permet de visualiser les livraisons prévues à la date choisies ci-dessus'),
                    placement: 'auto',
                    route: 'anomalies',
                    title: this.translate.instant('Mes anomalies'),
                },
            ]
            this.anchorAnomalies.forEach((val) => {
                this.steps.push(val)
            })
        }

        if (this.view.hasDroit('COMMANDE_MENU_SUIVI-LIVRAISON')) {
            this.anchorSuiviLivraison = [
                {
                    anchorId: 'tableau.suivi.livraisons',
                    content: this.translate.instant('Vous permet de visualiser les livraisons prévues à la date choisies ci-dessus'),
                    placement: 'auto',
                    route: 'suivi-de-livraison',
                    title: this.translate.instant('Mon suivi de livraison'),
                },
            ]
            this.anchorSuiviLivraison.forEach((val) => {
                this.steps.push(val)
            })
        }

        if (this.view.hasDroit('COMMANDE_MENU_LIVRAISON')) {
            this.anchorLivraison = [
                {
                    anchorId: 'tableau.livraisons',
                    content: this.translate.instant('Cliquez sur le numéro de BL surligné en bleu pour visualiser le détail de votre demande de livraison'),
                    placement: 'auto',
                    route: 'livraisons',
                    title: this.translate.instant('Mes livraisons'),
                },
            ]
            this.anchorLivraison.forEach((val) => {
                this.steps.push(val)
            })
        }

        if (this.view.hasDroit('COMMANDE_MENU_RETOUR')) {
            this.anchorRetour = [
                {
                    anchorId: 'tableau.retours',
                    content: this.translate.instant('Cliquez sur le numéro de BL surligné en bleu pour visualiser le détail de votre demande de retour'),
                    placement: 'auto',
                    route: 'retours',
                    title: this.translate.instant('Mes retours'),
                },
            ]
            this.anchorRetour.forEach((val) => {
                this.steps.push(val)
            })
        }

        if (this.view.hasDroit('LITIGE_MENU_LITIGE')) {
            this.anchorLitige = [
                {
                    anchorId: 'tableau.litiges',
                    content: this.translate.instant('Visualisation de tous les dossiers litiges ouverts avec Agediss'),
                    placement: 'auto',
                    route: 'litiges',
                    title: this.translate.instant('Mes litiges'),
                },
            ]
            this.anchorLitige.forEach((val) => {
                this.steps.push(val)
            })
        }


        if (this.view.hasDroit('LITIGE_MENU_CONTESTATION')) {
            this.anchorContestation = [
                {
                    anchorId: 'tableau.contestations',
                    content: this.translate.instant('Visualisation de toutes vos contestations faites auprès d’Agediss'),
                    placement: 'auto',
                    route: 'contestations',
                    title: this.translate.instant('Mes contestations'),
                },
            ]
            this.anchorContestation.forEach((val) => {
                this.steps.push(val)
            })
        }

        if (this.view.hasDroit('LITIGE_MENU_FACTURE')) {
            this.anchorFacture = [
                {
                    anchorId: 'tableau.factures.traitement',
                    content: this.translate.instant('Visualisation de toutes vos factures transmises et en cours de traitement par Agediss'),
                    placement: 'auto',
                    route: 'mes-factures',
                    title: this.translate.instant('Mes factures en cours de traitement'),
                },
                {
                    anchorId: 'tableau.factures.attente',
                    content: this.translate.instant('Visualisation de toutes vos factures transmises et en attente de prise en charge par Agediss'),
                    placement: 'auto',
                    route: 'mes-factures',
                    title: this.translate.instant('Mes factures en attente'),
                },
            ]
            this.anchorFacture.forEach((val) => {
                this.steps.push(val)
            })
        }

        if (this.view.hasDroit('LITIGE_MENU_NOUVELLE-FACTURE')) {
            this.anchorNouvelleFacture = [
                {
                    anchorId: 'tableau.nouvelle.facture',
                    content: this.translate.instant('Vous permet de déposer vos factures liées à la prise en charge d’un litige'),
                    placement: 'auto',
                    route: 'nouvelle-facture',
                    title: this.translate.instant('Nouvelle facture'),
                },
            ]
            this.anchorNouvelleFacture.forEach((val) => {
                this.steps.push(val)
            })
        }

        if (this.view.hasDroit('GRC_MENU_GESTION-RELATION-CLIENTS') && this.view.origine === 'tradiss') {
            this.anchorGrcTradiss = [
                {
                    anchorId: 'tableau.grc',
                    content: this.translate.instant('Visualisation de toutes vos demandes GRC faites auprès d’Agediss'),
                    placement: 'auto',
                    route: 'gestion-relation-clients',
                    title: this.translate.instant('Mes demandes GRC'),
                },
            ]
            this.anchorGrcTradiss.forEach((val) => {
                this.steps.push(val)
            })
        }

        if (this.view.hasDroit('GRC_MENU_GESTION-RELATION-CLIENTS') && this.view.origine === 'wexlog') {
            this.anchorGrcWexlog = [
                {
                    anchorId: 'tableau.grc-wexlog',
                    content: this.translate.instant('Visualisation de toutes vos demandes GRC faites auprès d’Agediss'),
                    placement: 'auto',
                    route: 'gestion-relation-clients',
                    title: this.translate.instant('Mes demandes GRC'),
                },
            ]
            this.anchorGrcWexlog.forEach((val) => {
                this.steps.push(val)
            })
        }

        if (this.view.hasDroit('EXTRACTION_MENU_EXTRACTION')) {
            this.anchorExtraction = [
                {
                    anchorId: 'tableau.extractions',
                    content: this.translate.instant('Vous pouvez réaliser des extractions généralisées sur vos demandes de livraisons'),
                    placement: 'auto',
                    route: 'extractions',
                    title: this.translate.instant('Mes extractions'),
                },
            ]
            this.anchorExtraction.forEach((val) => {
                this.steps.push(val)
            })
        }

        if (this.view.hasDroit('ADMINISTRATION_MENU_GESTION-UTILISATEUR')) {
            this.anchorAdministration = [
                {
                    anchorId: 'tableau.gestion.utilisateurs',
                    content: this.translate.instant('Visualisation de tous les accès à l’espace client Agediss'),
                    placement: 'auto',
                    route: 'administration',
                    title: this.translate.instant('Gestion des utilisateurs'),
                },
            ]
            this.anchorAdministration.forEach((val) => {
                this.steps.push(val)
            })
        }

        if (this.view.hasDroit('UTILISATEUR_MENU_PARAMETRE')) {
            this.anchorParametres = [
                {
                    anchorId: 'panel.parametres',
                    content: this.translate.instant('Vous permet de gérer vos données personnelles et votre mot de passe'),
                    placement: 'auto',
                    route: 'paramètres',
                    title: this.translate.instant('Mes paramètres'),
                },
            ]
            this.anchorParametres.forEach((val) => {
                this.steps.push(val)
            })
        }

        if (this.view.hasDroit('UTILISATEUR_MENU_PARAMETRE') && this.view.origine === 'tradiss' && this.currentUser.organisation.name === 'Client') {
            this.anchorGrcTradissNotifications = [
                {
                    anchorId: 'panel.notification_grc',
                    content: this.translate.instant('Vous permet de gérer les notifications GRC en lien avec les sous-comptes attitrés'),
                    placement: 'auto',
                    route: 'paramètres',
                    title: this.translate.instant('Gestion des notifications GRC par mail'),
                },
                {
                    anchorId: 'panel.notification_grc.actualiser',
                    content: this.translate.instant('Vous permet de créer ou d\'actualiser les notifications GRC en lien avec les sous-comptes attitrés'),
                    placement: 'auto',
                    route: 'paramètres',
                    title: this.translate.instant('Création / actualisation des notifications GRC par mails'),
                },
                {
                    anchorId: 'panel.notification_grc.edit_all',
                    content: this.translate.instant('Vous permet de modifier vos paramètres (activer/désactiver)'),
                    placement: 'auto',
                    route: 'paramètres',
                    title: this.translate.instant('Modification globale de tous vos paramètres'),
                },
                {
                    anchorId: 'panel.notification_grc.edit',
                    content: this.translate.instant('Vous permet de modifier vos paramétrages (activer/désactiver) par sous-compte'),
                    placement: 'auto',
                    route: 'paramètres',
                    title: this.translate.instant('Modifications des paramètres'),
                },
            ]
            this.anchorGrcTradissNotifications.forEach((val) => {
                this.steps.push(val)
            })
        }

        if (this.view.hasDroit('LIBRAIRIE_MENU_VIDEO')) {
            this.anchorVideoNotifications = [
                {
                    anchorId: 'tableau.videos',
                    content: this.translate.instant('Visualisation de toutes vos vidéos Agediss'),
                    placement: 'auto',
                    route: 'videos',
                    title: this.translate.instant('Mes vidéos'),
                },
            ]
            this.anchorVideoNotifications.forEach((val) => {
                this.steps.push(val)
            })
        }

        if (this.view.hasDroit('LIBRAIRIE_MENU_KPI')) {
            this.anchorVideoNotifications = [
                {
                    anchorId: 'tableau.videos',
                    content: this.translate.instant('Visualisation de toutes vos KPI (rapports chiffrés) Agediss'),
                    placement: 'auto',
                    route: 'videos',
                    title: this.translate.instant('Mes KPIs (rapports chiffrés)'),
                },
            ]
            this.anchorVideoNotifications.forEach((val) => {
                this.steps.push(val)
            })
        }
    }

}
