<div class="row m-0 text-white info">
  <div class="col-lg-3 col-md-6 col-sm-6 col-6 py-1">
    <div class="info-div text-center element">
      <div class="title text-truncate"><span class="font-weight-bold">{{'Litige' | translate }} n° </span></div>
      <div class="contenu"><span>{{litigeData.id}}</span></div>
    </div>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-6 col-6 py-1">
    <div class="info-div text-center element">
      <div class="title text-truncate"><span class="font-weight-bold">{{'BL' | translate }} n°</span></div>
      <div class="contenu"><span>{{litigeData.numero_avis}}</span></div>
    </div>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-6 col-6 py-1">
    <div class="info-div text-center element">
      <div class="title text-truncate"><span class="font-weight-bold">{{'Date de litige' | translate }}</span></div>
      <div class="contenu"><span>{{litigeData.date_litige | date : 'dd/MM/yyy'}}</span></div>
    </div>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-6 col-6 py-1">
    <div class="info-div text-center element">
      <div class="title">
        <span class="font-weight-bold">{{'Statut' | translate }}</span>
      </div>
      <div class="contenu">
        <span class="label label-dark mr-2 badge" *ngIf="litigeData?.date_prescrit">{{'Prescrit' | translate }}</span>
        <span class="label {{ litigeData?.statut.libelle | statutLitigePipe: litigeData?.statut.code}} badge">
          <span *ngIf="litigeData?.statut.code === 'LIT_0'">{{'A traiter' | translate }}</span>
          <span *ngIf="litigeData?.statut.code === 'LIT_1'">{{'Refusé' | translate }}</span>
          <span *ngIf="litigeData?.statut.code === 'LIT_2'">{{"En cours d'analyse" | translate }}</span>
          <span *ngIf="litigeData?.statut.code === 'LIT_3'">{{'Eligible' | translate }}</span>
          <span *ngIf="litigeData?.statut.code === 'LIT_5'">{{'Validé' | translate }}</span>
          <span *ngIf="litigeData?.statut.code === 'LIT_6'">{{"En attente d'approbation" | translate }}</span>
        </span>
      </div>
    </div>
  </div>
</div>
