<div *ngIf="loading" style="height: 500px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="modal-header">
    <h4 class="modal-title  w-100 text-center">{{'Modification des informations de l\'utilisateur' | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <form [formGroup]="editUserForm">
      <div class="row m-0 form-group">
        <div class="offset-0 col-12 offset-lg-0 col-lg-6">
          <label class="col-lg-12 col-md-12 col-sm-12 p-0">{{'Prénom' | translate }}
            <div class="input-group">
              <input id="firstname" type="text" class="form-control form-control-filter" autocomplete="off"
                     formControlName="firstname"
                     [ngClass]="{ 'is-invalid': formSubmit && f.firstname.errors}">
              <div *ngIf="formSubmit && f.firstname.errors" class="invalid-feedback">
                <div *ngIf="f.firstname.errors.required">{{'Veuillez saisir un prénom !' | translate }}</div>
              </div>
            </div>
          </label>
        </div>
        <div class="offset-0 col-12 offset-lg-0 col-lg-6">
          <label class="col-lg-12 col-md-12 col-sm-12 p-0">{{'Nom' | translate }}
            <div class="input-group">
              <input id="lastname" type="text" class="form-control form-control-filter" autocomplete="off"
                     formControlName="lastname"
                     [ngClass]="{ 'is-invalid': formSubmit && f.lastname.errors}">
              <div *ngIf="formSubmit && f.lastname.errors" class="invalid-feedback">
                <div *ngIf="f.lastname.errors.required">{{'Veuillez saisir un nom !' | translate }}</div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="row m-0 form-group">
        <div class="offset-0 col-12 offset-lg-0 col-lg-6">
          <label class="col-lg-12 col-md-12 col-sm-12 p-0">{{'Email' | translate }}
            <div class="input-group">
              <input id="email" type="email" class="form-control form-control-filter" autocomplete="off"
                     formControlName="email"
                     [ngClass]="{ 'is-invalid': formSubmit && f.email.errors}">
              <div *ngIf="formSubmit && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">{{'Veuillez saisir un email !' | translate }}</div>
              </div>
            </div>
          </label>
        </div>
        <div class="offset-0 col-12 offset-lg-0 col-lg-6">
          <label class="col-lg-12 col-md-12 col-sm-12 p-0">{{'Téléphone' | translate }}
            <div class="input-group">
              <input id="phone" type="text" maxlength="20" class="form-control form-control-filter" autocomplete="off"
                     formControlName="phone"
                     [ngClass]="{ 'is-invalid': formSubmit && f.phone.errors}">
              <div *ngIf="formSubmit && f.phone.errors" class="invalid-feedback">
                <div *ngIf="f.phone.errors.required">{{'Veuillez saisir un numéro de téléphone !' | translate }}</div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="row m-0 form-group">
        <div class="offset-0 col-12 offset-lg-0 col-lg-6">
          <label class="col-lg-12 col-md-12 col-sm-12 p-0">{{'Fonction' | translate }}
            <div class="input-group">
              <input id="fonction" type="text" class="form-control form-control-filter" autocomplete="off"
                     formControlName="fonction"
                     [ngClass]="{ 'is-invalid': formSubmit && f.fonction.errors}">
              <div *ngIf="formSubmit && f.fonction.errors" class="invalid-feedback">
                <div *ngIf="f.fonction.errors.required">{{'Veuillez saisir une fonction !' | translate }}</div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="dropdown-divider"></div>
      <div class="row m-0">
        <h4 class="modal-title w-100 text-center py-2">{{'Gestion des droits' | translate }}</h4>
      </div>
      <div class="row m-0 form-group">
        <div class="offset-0 col-12 offset-lg-0 col-lg-9 offset-xl-0 col-lg-10">
          <label class="col-lg-12 col-md-12 col-sm-12 p-0">{{'Sous-compte' | translate }}
            <div class="input-group">
              <ng-select class="ng-select filter"
                         [readonly]="disabled"
                         [ngClass]="{'is-invalid':editUserForm.get('clients').invalid && (editUserForm.get('clients').dirty || formSubmit) }"
                         [items]="dataClients"
                         [selectOnTab]="true"
                         [multiple]="true"
                         [closeOnSelect]="false"
                         bindValue="id"
                         bindLabel="libelle"
                         [loading]="loadingClient"
                         [clearable]="true"
                         [placeholder]="placeholder"
                         formControlName="clients">
              </ng-select>
              <div
                *ngIf="editUserForm.get('clients').invalid && (editUserForm.get('clients').dirty || formSubmit)"
                class="invalid-feedback">
                {{'Veuillez choisir un ou plusieurs clients !' | translate }}
              </div>
            </div>
          </label>
        </div>
        <div class="offset-0 col-8 offset-md-4 col-md-4 offset-lg-0 col-lg-3 offset-xl-0 col-xl-2 align-middle">
          <div id="ck-button-clients" class="col-12">
            <label class="padding-check-clients">
              <input class="hide" type="checkbox" name="allClients" id="allClients" [checked]="disabled" (change)="allClients()"/>
              <span class="btn col-12">{{'Tous les clients' | translate }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row m-0 form-group">
        <div *ngIf="loadingDroit" style="height: 200px" class="row col-12">
          <span class="spinner"></span>
        </div>
        <div *ngIf="!loadingDroit" class="offset-0 col-12 offset-lg-0 col-lg-12">
          <div class="row">
            <div class="col-12 col-lg-6 my-3 list-group" *ngFor="let item of tree | keyvalue">
              <div *ngIf="item.value != null" class="dropdown-divider"></div>
              <div *ngIf="item.value !== null">
                <div class="col-12" *ngIf="item.value.isCat == true">
                  <div class="text-center">
                    <h4>{{item.value.libelle | translate}}</h4>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12 p-0">
                      <ul class="list-group list-unstyled">
                        <li class="col-12 p-0">
                          <label class="bg-white m-4">{{'Autorisations' | translate }}</label>
                          <div id="ck-button">
                            <label *ngIf="droitsGlobal[item.value.libelle + '_SHOW']" class="mx-1">
                              <input class="hide" type="checkbox" [name]="item.value.libelle + '_SHOW'"
                                     [checked]="droitsGlobalChecked[item.value.libelle + '_SHOW']"
                                     (change)="selectDroit($event, item, item.value.libelle,'SHOW')"><span class="btn">{{'Visualisation' | translate }}</span>
                            </label>
                            <label *ngIf="droitsGlobal[item.value.libelle + '_DOWNLOAD']" class="mx-1">
                              <input class="hide" type="checkbox" [name]="item.value.libelle + '_DOWNLOAD'"
                                     [checked]="droitsGlobalChecked[item.value.libelle + '_DOWNLOAD']"
                                     (change)="selectDroit($event, item, item.value.libelle,'DOWNLOAD')"><span
                              class="btn">{{'Téléchargement' | translate }}</span>
                            </label>
                            <label *ngIf="droitsGlobal[item.value.libelle + '_CREATE']" class="mx-1">
                              <input class="hide" type="checkbox" [name]="item.value.libelle + '_CREATE'"
                                     [checked]="droitsGlobalChecked[item.value.libelle + '_CREATE']"
                                     (change)="selectDroit($event, item, item.value.libelle, 'CREATE')"><span
                              class="btn">{{'Création' | translate }}</span>
                            </label>
                            <label *ngIf="droitsGlobal[item.value.libelle + '_EDIT']" class="mx-1">
                              <input class="hide" type="checkbox" [name]="item.value.libelle + '_EDIT'"
                                     [checked]="droitsGlobalChecked[item.value.libelle + '_EDIT']"
                                     (change)="selectDroit($event, item, item.value.libelle, 'EDIT')"><span class="btn">{{'Edition' | translate }}</span>
                            </label>
                            <label *ngIf="droitsGlobal[item.value.libelle + '_DELETE']" class="mx-1">
                              <input class="hide" type="checkbox" [name]="item.value.libelle + '_DELETE'"
                                     [checked]="droitsGlobalChecked[item.value.libelle + '_DELETE']"
                                     (change)="selectDroit($event, item, item.value.libelle, 'DELETE')"><span
                              class="btn">{{'Suppression' | translate }}</span>
                            </label>
                          </div>
                        </li>
                        <li class="col-12 p-0">
                          <label class="bg-white m-4">{{'Menu' | translate }}</label>
                          <div class="list-group list-unstyled"
                               *ngFor="let itemMenu of item.value.childrens.MENU.childrens | keyvalue">
                            <div class="col-12" *ngIf="itemMenu.value.droit == true">
                              <div id="ck-button-menu">
                                <label>
                                  <input class="hide" type="checkbox" [value]="itemMenu.value.droitId" [checked]="droitsGlobalChecked[itemMenu.value.droitId]"
                                         (change)="onCheckboxChange($event)" name="menu"
                                  ><span class="btn">{{itemMenu.value.libelle}}</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="row m-0">
    <div class="col-12 modal-footer text-center">
      <button [ladda]="loadingBtn" data-style="zoom-in" class="btn btn-agediss" (click)="updateFormUser()">
        {{'Enregistrer' | translate }}
      </button>
      <button class="btn bg-black-lighter text-white m-r-10" (click)="cancel()">{{'Fermer' | translate }}</button>
    </div>
  </div>
</div>
