<div *ngIf="!conversation" class="modal-body" style="height: 350px">
  <div class="spinner"></div>
</div>
<div *ngIf="conversation">
  <div>
    <div class="modal-header {{modalHeaderClass}}">
      <h4 class="modal-title w-100 {{modalTitleClass}}"
          id="modal-basic-title">{{conversation.contexte | contextConversation}}</h4>
    </div>
    <div class="modal-body p-0">
      <app-dialog-conversation [conversationId]="conversation.id"></app-dialog-conversation>
    </div>
    <div class="modal-footer p-5">
      <button type="button" class="btn bg-black-lighter text-white" (click)="cancel()">{{'Fermer' | translate }}</button>
    </div>
  </div>
</div>
