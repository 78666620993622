import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, Sort} from "@angular/material/sort";
import {DatatableHelper} from "../../../shared/helpers/datatable.helper";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {tableAnimation} from "../../../animations";
import {ExtractionData} from "../../../shared/models/api/extraction-data.model";

@Component({
  selector: 'app-table-extractions',
  templateUrl: './table-extractions.component.html',
  styleUrls: ['./table-extractions.component.scss'],
  animations: [tableAnimation],
})
export class TableExtractionsComponent implements OnInit, OnChanges {

  expandedElement: ExtractionData | null;

  @Output() paginatorEvent : EventEmitter<PageEvent> = new EventEmitter<PageEvent>()
  @Output() export: EventEmitter<void> = new EventEmitter();

  @Input() data: ExtractionData[];
  @Input() loading = false;
  @Input() hiddenColumns: string[] = [];
  @Input() length: number;
  @Input() pageSize: number;
  @Input() pageIndex: number;
  @Input() pageEvent: PageEvent;
  @Input() pageSizeOptions: number[];
  @Input() showInfosBtn: boolean = false;

  @Input() displayedColumns: string[] = [
    'code_societe',
    'numero_avis',
    'numero_commande',
    'numero_commande_2',
    'numero_client',
    'expediteur_nom',
    'activite_nom',
    'code_plateforme',
    'plateforme_nom',
    'plateforme_transit_code',
    'destinataire_nom',
    'destinataire_adresse',
    'destinataire_adresse_complementaire',
    'destinataire_code_postal',
    'destinataire_ville',
    'destinataire_pays',
    'destinataire_tel_fixe',
    'destinataire_tel_portable',
    'destinataire_email',
    'instructions_complementaires_1',
    'instructions_complementaires_2',
    'instructions_complementaires_3',
    'type_habitat',
    'numero_etage',
    'ascenseur',
    'interphone',
    'code_interphone',
    'nb_colis',
    'volume_total',
    'poids_total',
    'solde',
    'date_commande',
    'date_reception_hub',
    'date_depart_hub',
    'dernier_evt_recep_pf',
    'date_dernier_evt_recep_pf',
    'com_dernier_evt_recep_pf',
    'date_rdv',
    'dernier_evt_rdv',
    'date_dernier_evt_rdv',
    'com_dernier_evt_rdv',
    'dernier_evt_livraison',
    'date_dernier_evt_livraison',
    'com_dernier_evt_livraison',
    'premier_contact',
    'date_premier_contact',
    'com_premier_contact',
    'dernier_evt',
    'date_dernier_evt',
    'com_dernier_evt',
    'groupe_client',
    'options'
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public dataSource: any;
  public innerWidth: number;

  ngOnInit() {
    this.onResizeTable();
    this.dataSource = new MatTableDataSource<ExtractionData>(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1)
        }
      })
    }
  }

  emit($event: PageEvent) {
    return this.paginatorEvent.emit($event);
  }


  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
      this.dataSource.sort = this.sort;
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
        this.dataSource.sort = this.sort;
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }

  }

  /**
   * Permet d'ordonner les colones.
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
    this.dataSource.sort = this.sort;
  }

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 540) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'options'
      ];
    } else if (this.innerWidth > 540 && this.innerWidth <= 600) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'options'
      ];
    } else if (this.innerWidth > 600 && this.innerWidth <= 767) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'destinataire_code_postal',
        'options'
      ];
    } else if (this.innerWidth > 767 && this.innerWidth <= 877) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'options'
      ];
    } else if (this.innerWidth > 877 && this.innerWidth <= 1051) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'destinataire_code_postal',
        'options'
      ];
    } else if (this.innerWidth > 1051 && this.innerWidth <= 1150) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'destinataire_code_postal',
        'destinataire_ville',
        'options'
      ];
    } else if (this.innerWidth > 1150 && this.innerWidth <= 1270) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'destinataire_code_postal',
        'destinataire_ville',
        'activite_nom',
        'options'
      ];
    } else if (this.innerWidth > 1270 && this.innerWidth <= 1340) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'destinataire_code_postal',
        'destinataire_ville',
        'activite_nom',
        'date_commande',
        'options'
      ];
    } else if (this.innerWidth > 1340 && this.innerWidth <= 1575) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'destinataire_code_postal',
        'destinataire_ville',
        'activite_nom',
        'date_commande',
        'expediteur_nom',
        'options'
      ];
    } else if (this.innerWidth > 1575) {
      this.displayedColumns = [
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'destinataire_code_postal',
        'destinataire_ville',
        'activite_nom',
        'date_commande',
        'expediteur_nom',
        'dernier_evt',
        'options'
      ];
    }
  }

}
