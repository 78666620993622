import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LitigeService} from '../../../../shared/services/api/litige.service';
import {Contestation} from '../../../../shared/models/api/contestation.model';
import Swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Router} from "@angular/router";
import {ContestationData} from "../../../../shared/models/api/contestation-data.model";

@Component({
  selector: 'app-add-contestation',
  templateUrl: './add-contestation.component.html',
  styleUrls: ['./add-contestation.component.scss']
})
export class AddContestationComponent implements OnInit {

  @Output() submited: EventEmitter<Contestation> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Input() litigeId: number = null;

  public contestataires: { libelle: string, code: string }[];
  public contestationForm: FormGroup;
  public contestationsData: ContestationData[];
  public contestationData: ContestationData;
  public formSubmit: boolean;
  public loadingBtn: boolean;

  constructor(
    private translate: TranslateService,
    private litigeSvc: LitigeService,
    private router: Router,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.contestataires = [
      {libelle: 'Le donneur d\'ordre', code: 'DO'},
    ];
    this.contestationForm = new FormGroup({
      contestataire: new FormControl('DO', Validators.required),
      objet: new FormControl(null, Validators.required)
    });
  }

  submitFormAddContestation() {
    this.formSubmit = true;
    if (this.contestationForm.invalid) {
      return;
    }
    this.loadingBtn = true;
    // si on est en présence d'un id de litige
    const sub = this.litigeSvc.addContestation(this.litigeId, this.contestationForm.value).subscribe((contestationData) => {
      this.contestationData = contestationData
      this.submited.emit(contestationData);
      sub.unsubscribe();
      this.loadingBtn = false
      this.formSubmit = false;
      this.toastr.success(
        this.translate.instant("Une nouvelle contestation a été créée"),
        this.translate.instant("Informations"),
        {progressBar: true}
      );
      Swal.fire({
        title: this.translate.instant('Question'),
        text: this.translate.instant('Souhaitez-vous être redirigé vers la nouvelle contestation ou rester sur la page en cours ?'),
        showCancelButton: true,
        cancelButtonText: this.translate.instant('Rester'),
        confirmButtonText: this.translate.instant('Rediriger-moi'),
        type: 'question',
      }).then(
        result => {
          if (result.value) {
            this.router.navigate(['/contestations', this.contestationData.id]);
          }
        });
    }, () => {
      this.loadingBtn = false;
      this.formSubmit = false;
      Swal.fire(
        this.translate.instant('Désolé'),
        this.translate.instant('Une erreur est survenue'),
        'error'
      );
    });
  }

  get f() {
    return this.contestationForm.controls;
  }

  cancel() {
    this.canceled.emit();
  }
}
