import {Injectable} from '@angular/core';
import {Client} from '../../../models/api/client.model';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {CompteClient} from "../../../models/api/compte-client.model";

@Injectable({
  providedIn: 'root'
})
export class CompteClientSsoService {

  urlRessource = environment.url_api_espace_client + '/sso-comptes-clients';

  constructor(private http: HttpClient) {
  }


  getAllCompteClient(): Observable<CompteClient[]> {
    return this.http
      .get<CompteClient[]>(this.urlRessource)
      .pipe(map((compteClients) => compteClients.map((compteClient) => {
        return new Client().deserialize(compteClient);
      })));
  }
}
