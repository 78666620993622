<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">
  <div class="row m-0">
    <div class="col-md-12 pt-3">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{commande?.plateforme_nom}}">{{'Nom' | translate }} : </strong>
        <span> {{commande?.plateforme_nom | uppercase}}</span>
      </p>
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{commande?.plateforme_adresse1 | uppercase}}
                            {{commande?.plateforme_code_postal}}
                            {{commande?.plateforme_ville | uppercase}}">{{'Adresse' | translate }} :</strong>
        <span>
          {{commande?.plateforme_adresse1 | uppercase}} {{commande?.plateforme_code_postal}} {{commande?.plateforme_ville | uppercase}}
        </span>
      </p>
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{commande?.plateforme_adresse2 | uppercase}}">{{"Complément d'adresse" | translate }} :</strong>
        <span> {{commande?.plateforme_adresse2 | uppercase}}</span>
      </p>
    </div>
  </div>
</div>

