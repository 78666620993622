import {Component, Input, OnInit} from '@angular/core';
import {CommandeData} from "../../../shared/models/api/commande-data.model";


@Component({
  selector: 'app-instruction-complementaire',
  templateUrl: './instruction-complementaire.component.html',
  styleUrls: ['./instruction-complementaire.component.scss']
})
export class InstructionComplementaireComponent implements OnInit {

  @Input() commande: CommandeData;

  constructor() { }

  ngOnInit() {
    if(!this.commande) {
      throw new Error('Le composent instruction attend un objet de type commande.')
    }
  }

}
