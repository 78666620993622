import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../../../../shared/services/authentication.service';
import {UserService} from '../../../../../shared/services/api/sso/user.service';
import {User} from '../../../../../shared/models/user.model';
import {TranslateService} from '@ngx-translate/core';
import {UserData} from "../../../../../shared/models/user-data.model";
import {GrcService} from "../../../../../shared/services/api/grc.service";
import {FilterMultiCritereModel} from "../../../../../shared/models/filters/filterMultiCritere.model";

@Component({
  selector: 'app-edit-informations-user',
  templateUrl: './edit-informations-user.component.html',
  styleUrls: ['./edit-informations-user.component.scss']
})
export class EditInformationsUserComponent implements OnInit {

  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Output() submited: EventEmitter<User> = new EventEmitter();
  @Input() username: string;
  @Input() userId: number;

  public user: UserData;
  public subscriptions: Subscription[] = [];

  // number
  public nbData: number = 0;

  // loading
  public loading: boolean = false;
  public loadingBtn: boolean = false;

  //form
  public editUserForm: FormGroup;
  public editAllNotificationForm: FormGroup;
  public formSubmit = false;

  constructor(private translate: TranslateService,
              private toastr: ToastrService,
              private authSvc: AuthenticationService,
              private grcSvc: GrcService,
              private userSvc: UserService) {
  }

  ngOnInit() {
    this.loading = true;
    this.subscriptions.push(this.userSvc.getDataUserByUserId(this.userId).subscribe(
      (user) => {
        this.user = user;
        this.initForm();
      }));
  }

  initForm() {
    this.editUserForm = new FormGroup({
      firstname: new FormControl(this.user.firstname, Validators.required),
      lastname: new FormControl(this.user.lastname, Validators.required),
      email: new FormControl(this.user.email.toLowerCase(), [Validators.required, Validators.email]),
      phone: new FormControl(this.user.tel, Validators.required),
      fonction: new FormControl(this.user.fonction, Validators.required),
    });
    this.loading = false;
  }

  initFormNotification(email) {
    this.editAllNotificationForm = new FormGroup({
      username: new FormControl(this.username, Validators.required),
      email: new FormControl(email, [Validators.required, Validators.email]),
    });
  }

  updateFormParamNotification() {
    this.initFormNotification(this.editUserForm.get('email').value)
    const sub1 = this.grcSvc.updateEmailNotificationGrcByUsername(this.username, this.editAllNotificationForm.value).subscribe(
      (notification) => {
        sub1.unsubscribe();
        this.loadingBtn = false;
        this.formSubmit = false;
        this.toastr.success(
          this.translate.instant("Les paramètres des notifications GRC ont été enregistrés"),
          this.translate.instant("Notifications GRC"),
          {progressBar: true}
        );
        this.submited.emit(notification);
      },
      () => {
        this.loadingBtn = false;
        this.formSubmit = false;
        Swal.fire({
          title: this.translate.instant('Désolé'),
          text: this.translate.instant('Une erreur est survenue'),
          type: 'error',
          showCancelButton: false,
        })
      }
    );
  }

  updateFormUser() {
    this.formSubmit = true;
    if (this.editUserForm.invalid) {
      return;
    }
    this.loadingBtn = true;
    const sub = this.userSvc.updateInfosUser(this.username, this.editUserForm.value).subscribe(
      (user) => {
        this.updateFormParamNotification()
        sub.unsubscribe();
        this.loadingBtn = false;
        this.formSubmit = false;
        this.toastr.info(
          this.translate.instant("Les informations de l'utilisateur ont été modifiées"),
          this.translate.instant("Informations"),
          {progressBar: true}
        );
        this.submited.emit(user);
      },
      () => {
        this.loadingBtn = false;
        this.formSubmit = false;
        Swal.fire({
          title: this.translate.instant('Désolé'),
          text: this.translate.instant('Une erreur est survenue'),
          type: 'error',
          showCancelButton: false,
        })
      }
    );
  }

  cancel() {
    this.canceled.emit();
  }

  get f() {
    return this.editUserForm.controls;
  }

}
