import {Deserializable} from '../interfaces/deserializable.interface';
import {FactureData} from "./facture-data.model";

export class Facture implements Deserializable {

  public nb: number;
  public data: FactureData[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
