import {Deserializable} from '../../interfaces/deserializable.interface';
import {WexlogTypeTextMessageGrc} from "./wexlog-type-text-message-grc.model";

export class WexlogTypeMessageGrc implements Deserializable {

  public id?: string;
  public libelle?: string;
  public type_text_message?: WexlogTypeTextMessageGrc;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
