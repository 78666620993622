import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import Swal from "sweetalert2";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {NotificationGrcData} from "../../../../shared/models/api/notification-grc-data.model";
import {GrcService} from "../../../../shared/services/api/grc.service";
import {User} from "../../../../shared/models/user.model";
import {UserData} from "../../../../shared/models/user-data.model";

@Component({
  selector: 'app-edit-notification-grc',
  templateUrl: './edit-notification-grc.component.html',
  styleUrls: ['./edit-notification-grc.component.scss']
})
export class EditNotificationGrcComponent implements OnInit {

  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Output() submited: EventEmitter<User> = new EventEmitter();
  @Input() notificationId: number;
  @Input() notificationClient: string;
  @Input() email: string;

  public notificationGrcData: NotificationGrcData;
  public subscriptions: Subscription[] = [];

  // loading
  public loading: boolean = false;
  public loadingBtn: boolean = false;

  //form
  public editNotificationForm: FormGroup;
  public formSubmit = false;
  public currentUser: UserData;
  public isChecked = false;

  constructor(private grcSvc: GrcService,
              private translate: TranslateService,
              private toastr: ToastrService,) {
  }

  ngOnInit() {
    this.loading = true;
    this.subscriptions.push(this.grcSvc.getNotificationGrcById(this.notificationId).subscribe(
      (notification) => {
        this.notificationGrcData = notification;
        this.initForm();
      }));
  }

  initForm() {
    this.editNotificationForm = new FormGroup({
      username: new FormControl(this.notificationGrcData.username, Validators.required),
      email: new FormControl(this.notificationGrcData.email, [Validators.required, Validators.email]),
      client: new FormControl(this.notificationGrcData.numero_client, [Validators.required]),
      activerMessage: new FormControl(this.notificationGrcData.activer_message, Validators.required),
      activerDemande: new FormControl(this.notificationGrcData.activer_demande, Validators.required),
    });
    this.loading = false;
  }

  public statuts = [
    {id: 1, code: "O", libelle: "Notification activée"},
    {id: 2, code: "N", libelle: "Notification desactivée"},
  ]

  updateFormParamNotification() {
    this.formSubmit = true;
    if (this.editNotificationForm.invalid) {
      return;
    }
    this.loadingBtn = true;
    const sub = this.grcSvc.updateNotificationGrcById(this.notificationId, this.editNotificationForm.value).subscribe(
      (user) => {
        sub.unsubscribe();
        this.loadingBtn = false;
        this.formSubmit = false;
        this.toastr.success(
          this.translate.instant("Les paramètres des notifications GRC ont été enregistrés"),
          this.translate.instant("Notifications GRC"),
          {progressBar: true}
        );
        this.submited.emit(user);
      },
      () => {
        this.loadingBtn = false;
        this.formSubmit = false;
        Swal.fire({
          title: this.translate.instant('Désolé'),
          text: this.translate.instant('Une erreur est survenue'),
          type: 'error',
          showCancelButton: false,
        })
      }
    );
  }

  cancel() {
    this.canceled.emit();
  }

  get f() {
    return this.editNotificationForm.controls;
  }

}
