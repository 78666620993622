<div *ngIf="loading" class="spinner"></div>
<div *ngIf="!loading">
  <div class="row m-0">
    <div class="col-md-6">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{grcData?.numero_commande}}">{{'Commande' | translate }} :</strong>
        <span> {{grcData?.numero_commande}}</span>
      </p>
    </div>
    <div class="col-md-6">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{grcData?.ord_id}}">{{'BL' | translate }} :</strong>
        <span> {{grcData?.ord_id}}</span>
      </p>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-md-6">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{grcData?.created_at}}">{{'Créé le' | translate }} :</strong>
        <span> {{grcData?.created_at}}</span>
      </p>
    </div>
    <div class="col-md-6">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{grcData?.nb_colis}}">{{'Colis' | translate }} :</strong>
        <span> {{grcData?.nb_colis}}</span>
      </p>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-md-6">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{grcData?.poids_total}}">{{'Poids' | translate }} :</strong>
        <span> {{grcData?.poids_total}} kg</span>
      </p>
    </div>
    <div class="col-md-6">
      <p class="text-truncate">
        <strong placement="right"
                container="body"
                ngbTooltip="{{grcData?.volume_total}}">{{'Volume' | translate }} :</strong>
        <span> {{grcData?.volume_total}} m³</span>
      </p>
    </div>
  </div>
  <div class="row m-0 pt-2">
    <div class="offset-xl-4 col-xl-4 col-lg-6 offset-lg-3 col-lg-6 offset-md-4 col-md-4 offset-sm-3 col-sm-6 offset-2 col-8 p-0">
      <a *ngIf="showViewCommandeBtn && view.hasDroit('GRC_CREATE')" class="btn btn-radius btn-agediss btn-block text-truncate shadow"
         [routerLink]="['/','livraisons',grcData.ord_id]">{{'Visualisation de la commande' | translate }}
      </a>
    </div>
  </div>
</div>

