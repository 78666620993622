import {Component, OnDestroy, OnInit} from '@angular/core';
import {Contact} from '../../shared/models/api/contact.model';
import {ContactService} from '../../shared/services/api/contact.service';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {View} from "../../shared/models/view.model";
import {AuthenticationService} from "../../shared/services/authentication.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.html',
  styleUrls: ['./contact.scss']
})
export class ContactPage implements OnInit, OnDestroy {

  public contact: Contact;
  public view: View;
  public contacts: Contact[];
  public subscriptions: Subscription[] = [];
  public subscription: Subscription;

  // loading
  public loading = false;

  public noContact = false;

  constructor(private translate: TranslateService,
              private authSvc: AuthenticationService,
              private router: Router,
              private contactSvc: ContactService) {
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {
    if (this.view.hasDroit('UTILISATEUR_MENU_CONTACT-PRIVILEGIE')) {
      this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
        this.loadDataContact();
      }))
      this.loadDataContact();
    } else {
      this.router.navigate(['/', 'home']);
    }
  }

  loadDataContact() {
    this.loading = true;
    this.contacts = [];
    this.subscriptions.push(this.contactSvc.getContactPrivilegie().subscribe((contacts) => {
      this.contacts = contacts;
      this.loading = false;
      },
      (error) => {
        this.noContact = true;
        this.loading = false;
      }
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
