<div *ngIf="view.hasDroit('UTILISATEUR_MENU_PARAMETRE')">
  <div class="row m-0 padding">
    <h1 class="page-header text-center m-auto padding-h1">{{'Mes paramètres' | translate }}</h1>
  </div>

  <div class="row m-0 padding-table" tourAnchor="panel.parametres">
    <div class="offset-0 col-12 offset-lg-2 col-lg-8 p-0">
      <panel noButton="true" title="{{'Mes informations personnelles' | translate }}"
             bodyClass="no-padding panel">
        <div style="min-height: 140px">
          <app-informations-personnelles [currentUser]="user" [loading]="loading" [showViewEditPasswordBtn]="true"
                                         [showViewEditDataBtn]="true"
                                         [showViewCreateParamMailGrcBtn]="true"
                                         (selectEditDataByUser)="openModalEditDataUser(modal_edit_data, $event)"
                                         (selectEditPasswordByUser)="openModalEditPasswordUser(modal_edit_password, $event)"></app-informations-personnelles>
        </div>
      </panel>
    </div>
  </div>

  <div class="row m-0 padding-table" tourAnchor="panel.notification_grc"
       *ngIf="view.hasDroit('GRC_PARAMETRE-NOTIFICATION-MAIL_SHOW') && currentUser.organisation.name === 'Client' && view.origine === 'tradiss'">
    <div class="offset-0 col-12 offset-lg-2 col-lg-8 p-0">
      <panel noButton="true" bodyClass="no-padding panel">
        <div class="row" header>
          <div class="col-8">
            <h5 class="p-t-5 text-agediss">{{'Gestion des notifications GRC par mail' | translate }} : <span
              *ngIf="!loadingNbNotification">{{nbData}}</span> <i *ngIf="loadingNbNotification"
                                                                  class='fas fa-spin fa-circle-notch'></i></h5></div>
          <div class="col-4">
            <button tourAnchor="panel.notification_grc.actualiser"
                    class="btn btn-radius btn-agediss btn-block text-truncate shadow" [ladda]="loadingBtn"
                    data-style="zoom-in"
                    (click)="submitParamsMailGrc()">{{'Créer / Actualiser' | translate }}
            </button>
          </div>
        </div>
        <div>
          <app-table-parametres-mail-grc [data]="notificationsGrcData"
                                         [length]="nbData" [pageSize]="pageSize"
                                         (paginatorEvent)="onLoadDataByEvent($event)" [pageSizeOptions]="pageSizeOption"
                                         [loading]="loadingTableNotification"
                                         (onEditNotification)="openModalEditDataNotification(modal_edit_notification, $event)"
                                         (onEditAllNotification)="openModalEditAllDataNotification(modal_edit_all_notification)"
                                         [showInfosBtn]="true" [notifLength]="notifLength"
                                         [showValidateBtn]="true"></app-table-parametres-mail-grc>
        </div>
      </panel>
    </div>
  </div>

  <!-- modal Edit Data User -->
  <ng-template #modal_edit_data let-modal>
    <app-edit-informations-user [userId]="selectUserId" [username]="selectUsername" (submited)="modal.close($event)"
                                (canceled)="modal.close()"></app-edit-informations-user>
  </ng-template>
  <!-- fin de modal Nouvelle demande -->

  <!-- modal Edit password -->
  <ng-template #modal_edit_password let-modal>
    <app-edit-password-user [userId]="selectUserId" [username]="selectUsername" (submited)="modal.close($event)"
                            (canceled)="modal.close()"></app-edit-password-user>
  </ng-template>
  <!-- fin de modal Edit password -->

  <!--modal edit user-->
  <ng-template #modal_edit_notification let-modal>
    <app-edit-notification-grc [notificationId]="selectId" [email]="user.email" [notificationClient]="selectClient"
                               (submited)="modal.close($event)" (canceled)="modal.close()"></app-edit-notification-grc>
  </ng-template>
  <!-- fin de modal edit user-->

  <!--modal edit user-->
  <ng-template #modal_edit_all_notification let-modal>
    <app-edit-all-notification-grc [username]="user.username" [email]="selectEmail" (submited)="modal.close($event)"
                                   (canceled)="modal.close()"></app-edit-all-notification-grc>
  </ng-template>
  <!-- fin de modal edit user-->
</div>
