import {Deserializable} from '../interfaces/deserializable.interface';
import {FactureLitige} from './facture-litige.models';
import {Statut} from './statut.model';
import {PieceJointeData} from './piece-jointe-data.model';

export class FactureData implements Deserializable {

  public id: number;
  public date: Date | string;
  public statut_bap: string;
  public date_bap: Date | string;
  public montant: number;
  public numero_interne: string;
  public numero_client: string;
  public pieceJointe?: PieceJointeData;
  public facture_litiges?: FactureLitige[];
  public statut?: Statut;
  avoirs_asso: [];
  factures_asso: [];
  type_facture: any;

  deserialize(input: any): this {
    Object.assign(this, input);
    this.statut = (input.statut) ? new Statut().deserialize(input.statut) : null;
    this.pieceJointe = (input.piece_jointe) ? new PieceJointeData().deserialize(input.piece_jointe) : null;
    return this;
  }

}
