<form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-12">
            <div class="col-12 p-0">
                <label class="d-block d-lg-none">{{'Rechercher' | translate }}</label>
            </div>
            <div class="input-group">
                <div class="input-group-prepend ">
          <span class="input-group-text input-group-border-right d-none d-lg-flex align-items-center">
            {{'Rechercher par nom' | translate }}
          </span>
                </div>
                <input class="form-control form-control-filter"
                       placeholder="{{'Rechercher par nom' | translate }}"
                       formControlName="search"/>
            </div>
        </div>
    </div>

    <div class="form-group m-b-0 row m-t-10">
        <div class="offset-sm-5 col-sm-2 offset-4 col-4 px-0">
            <div class="input-group">
                <input class="form-control btn btn-radius btn-agediss font-weight-bold shadow" type="submit"
                       value="{{'Filtrer' | translate }}">
                <div class="input-group-append pl-1">
                    <button class="btn btn-radius btn-agediss btn-block shadow" type="button"
                            ngbTooltip="{{'Effacer les infos sélectionnées' | translate }}"
                            placement="right"
                            container="body"
                            (click)="reset()"><i class='fa fa-times'></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
