import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FieldParametersModel} from '../../../shared/models/filters/field-parameters.model';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {Statut} from '../../../shared/models/api/statut.model';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {StatutService} from '../../../shared/services/api/statut.service';
import {FormfilterHelper} from '../../../shared/helpers/Formfilter.helper';
import {Client} from '../../../shared/models/api/client.model';
import {DatePickerMinDateComponent} from '../../shared/date-picker/date-picker-min-date/date-picker-min-date.component';
import {DatePickerMaxDateComponent} from '../../shared/date-picker/date-picker-max-date/date-picker-max-date.component';
import {FilterService} from '../../../shared/services/filter.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from "ngx-toastr";
import {SousCompteSsoService} from "../../../shared/services/api/sso/sous-compte-sso.service";

@Component({
  selector: 'app-filter-contestation',
  templateUrl: './filter-contestation.component.html',
  styleUrls: ['./filter-contestation.component.scss']
})
export class FilterContestationComponent implements OnInit, OnDestroy {

  @ViewChild('inputMinDate') inputMinDate: DatePickerMinDateComponent;
  @ViewChild('inputMaxDate') inputMaxDate: DatePickerMaxDateComponent;

  @Input() displayInput: FieldParametersModel[] = [];

  @Output() submitFilter = new EventEmitter<FilterMultiCritereModel>();
  @Output() onLoad = new EventEmitter<FilterMultiCritereModel>();

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public hiddenInput = [];
  public statuts: Statut[];
  public clients: Client[];
  public subscriptions: Subscription[] = [];
  public filterForm = new FormGroup({
    statuts: new FormControl(),
    clients: new FormControl(),
    minDate: new FormControl(),
    maxDate: new FormControl(),
    dateLitige: new FormControl(),
    createdAt: new FormControl(),
    search: new FormControl()
  });

  public loadStatut: boolean = false;
  public loadClient: boolean = false;
  public minDate;
  public maxDate;

  constructor(private translate: TranslateService,
              private statutSvc: StatutService,
              private clientSvc: SousCompteSsoService,
              private toastr: ToastrService,
              private filterSvc: FilterService) {
  }

  ngOnInit() {
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.loadClient = true;
      this.loadStatut = true;
      this.onLoadSousComptes();
      this.onLoadStatuts();
    }))
    this.onInitFilter();
  }

  onInitFilter() {
    this.loadClient = true;
    this.loadStatut = true;
    FormfilterHelper.hydrateForm(this.displayInput, this.filterForm);
    this.hiddenInput = FormfilterHelper.extractHiddenInput(this.displayInput);
    this.onLoadSousComptes();
    this.onLoadStatuts();
    this.getValueLocalstorage();
    this.onLoad.emit(this.filterForm.getRawValue());
  }

  /**
   * Chargement des sous-comptes
   */
  onLoadSousComptes() {
    this.clients = [];
    this.subscriptions.push(this.clientSvc.getSousComptes().subscribe(value => {
      this.clients = value;
      this.loadClient = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des sous-comptes n'est pas disponible", {application: this.translate.instant('Mes contestations')}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadClient = false;
      }
    ));
  }

  /**
   * Chargement des statuts
   */
  onLoadStatuts() {
    this.statuts = [];
    this.subscriptions.push(this.statutSvc.getStatuts(StatutService.CONTESTATION).subscribe(value => {
      this.statuts = value;
      this.loadStatut = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des statuts n'est pas disponible", { application: this.translate.instant('Mes contestations')}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadStatut = false;
      }
    ));
  }

  /**
   * On récupère les valeurs du localstorage
   */
  getValueLocalstorage() {
    this.filterForm.get('search').setValue(localStorage.getItem('contestationSearchFilter'));
    this.filterSvc.onCheckValueLocalStorage(this.filterForm,'contestationClientFilter', 'clients');
    this.filterSvc.onCheckValueLocalStorage(this.filterForm,'contestationStatutFilter', 'statuts');
    this.filterSvc.convertDateLocalstorageForUrl(this.filterForm,'minDate', 'contestationMinDateFilter');
    this.filterSvc.convertDateLocalstorageForUrl(this.filterForm,'maxDate', 'contestationMaxDateFilter');
    this.onDateLocalstorage('contestationMinDateFilter', 'contestationMaxDateFilter');
  }

  /**
   *   on affiche la date enregistrée dans le localstorage, dans l'input
   */
  onDateLocalstorage(minDateFilterName, maxDateFilterName) {
    if (localStorage.getItem(minDateFilterName) != null && localStorage.getItem(maxDateFilterName) == null) {
      if (!this.minDate && !this.maxDate) {
        this.minDate = this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem(minDateFilterName));
        this.maxDate = null;
      }
    } else if (localStorage.getItem(minDateFilterName) != null && localStorage.getItem(maxDateFilterName) != null) {
      if (!this.minDate && !this.maxDate) {
        this.minDate = this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem(minDateFilterName));
        this.maxDate = this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem(maxDateFilterName));
      }
    }
  }

  onSubmit() {
    //récupère les infos du filtre
    if (this.inputMinDate.date == null) {
      this.filterForm.get('minDate').reset();
    }
    if (this.inputMaxDate.date == null) {
      this.filterForm.get('maxDate').reset();
    }
    this.filterSvc.onSaveInLocalstorage(this.filterForm,'contestationSearchFilter', 'search');
    this.filterSvc.onSaveInLocalstorage(this.filterForm,'contestationClientFilter', 'clients');
    this.filterSvc.onSaveInLocalstorage(this.filterForm,'contestationStatutFilter', 'statuts');
    this.filterSvc.onSaveInLocalstorage(this.filterForm,'contestationMinDateFilter', 'minDate');
    this.filterSvc.onSaveInLocalstorage(this.filterForm,'contestationMaxDateFilter', 'maxDate');
    this.submitFilter.emit(this.filterForm.getRawValue());
  }

  reset(): void {
    this.filterForm.reset();
    this.inputMinDate.reset();
    this.inputMaxDate.reset();
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
