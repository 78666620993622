import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LitigeData} from "../../../shared/models/api/litige-data.model";

@Component({
  selector: 'app-informations-complementaires',
  templateUrl: './informations-complementaires.component.html',
  styleUrls: ['./informations-complementaires.component.scss']
})
export class InformationsComplementairesComponent implements OnInit {

  @Input() litigeData: LitigeData;

  @Output() selectEditLitige: EventEmitter<{id: number}> = new EventEmitter<{id: number}>();

  constructor() { }

  ngOnInit() {
    if(!this.litigeData) {
      throw new Error('Le composent litige attend un objet de type litige.')
    }
  }
}
