import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpUtil} from '../../../utils/http.util';
import {WexlogGrcData} from "../../../models/api/wexlog/wexlog-grc-data.model";
import {WexlogConversationGrc} from "../../../models/api/wexlog/wexlog-conversation-grc.model";


@Injectable({
  providedIn: 'root'
})
export class GrcWexlogService {

  urlRessource = environment.url_api_espace_client + '/wexlog-demandes-grc';

  constructor(private http: HttpClient) {
  }

  getDemandesGrcWexlog(filter: any = null): Observable<WexlogGrcData[]> {
    return this.http
      .get<WexlogGrcData[]>(`${this.urlRessource}`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((grcs) => grcs.map((grc) => {
        return new WexlogGrcData().deserialize(grc);
      })));
  }

  getGrcById(id: number): Observable<WexlogGrcData> {
    return this.http
      .get<WexlogGrcData>(`${this.urlRessource}/${id}`)
      .pipe(map((grc) => {
        return new WexlogGrcData().deserialize(grc);
      }));
  }

  getDemandesGrcByNumeroAvis(filter: any = null, numeroAvis: string): Observable<WexlogGrcData[]> {
    return this.http
      .get<WexlogGrcData[]>(`${this.urlRessource}/${numeroAvis}/demandes-grc`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((grcs) => grcs.map((grc) => {
        return new WexlogGrcData().deserialize(grc);
      })));
  }

  messageDemandeGrc(id: string, conversationGrc: WexlogConversationGrc): Observable<any> {
    return this.http.post<{ message: string, next: boolean }>(`${this.urlRessource}/message-demande-grc/${id}`, conversationGrc)
      .pipe(map((response) => {
        return new WexlogConversationGrc().deserialize(response);
      }));
  }

  addDemandeGrc( referenceCommande : string, demandeGrc: WexlogGrcData): Observable<any> {
    return this.http.post<{ message: string, next: boolean }>(`${this.urlRessource}/${referenceCommande}/demande-grc`, demandeGrc)
      .pipe(map((response) => {
        return new WexlogGrcData().deserialize(response);
      }));
  }

  updateStatutDemandeGrc(id: string, demandeGrc: WexlogGrcData): Observable<any> {
    return this.http.post<{ message: string, next: boolean }>(`${this.urlRessource}/update-statut-demande-grc/${id}`, demandeGrc)
      .pipe(map((response) => {
        return new WexlogGrcData().deserialize(response);
      }));
  }

  deleteDemandeGrc(id: string, filter: any = null) {
    return this.http.get<WexlogGrcData>(`${this.urlRessource}/delete-demande-grc/${id}`, {params: HttpUtil.convertObjectToHttpParams(filter)})
      .pipe(map((grc) => {
        return new WexlogGrcData().deserialize(grc);
      }));
  }

}
