import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';
import {TranslateService} from '@ngx-translate/core';
import {CommandeService} from "../../shared/services/api/commande.service";
import {CommandeData} from "../../shared/models/api/commande-data.model";
import {PageEvent} from "@angular/material/paginator";
import {PaginationService} from "../../shared/services/pagination.service";
import {View} from "../../shared/models/view.model";
import {AuthenticationService} from "../../shared/services/authentication.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-livraisons',
  templateUrl: './livraisons.html',
  styleUrls: ['./livraisons.scss']
})
export class LivraisonsPage implements OnInit, OnDestroy {

  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public view: View;

  //string
  public flux = 'aller';

  //tabeau
  public commandesData: CommandeData[];
  public subscriptions: Subscription[] = [];
  public pageSizeOption = [5, 10, 20];

  // number
  public pageSize: number = 10;
  public nbData: number = 0;

  // loading
  public loadingTableLivraison = false;
  public loadingNbLivraison = false;

  constructor(private translate: TranslateService,
              private paginationSvc: PaginationService,
              private authSvc: AuthenticationService,
              private router: Router,
              private commandeSvc: CommandeService) {
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {
    if (this.view.hasDroit('COMMANDE_MENU_LIVRAISON')) {
      this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
        this.loadDataLivraisons();
      }));
    } else {
      this.router.navigate(['/', 'home']);
    }
  }

  onSubmit(filter = null) {
    // récupère les infos du filtre
    if (filter) {
      this.filter = filter;
    }
    this.paginationSvc.defaultFilterPagination(this.filter, this.pageSize)
    this.loadDataLivraisons();
  }

  onLoadFilter(filter = null) {
    // récupère les infos du filtre
    if (filter) {
      this.filter = {...filter, ...this.filter};
    }
    this.loadDataLivraisons();
  }

  /**
   * On récupère toutes les commandes liès au compte client
   */
  loadDataLivraisons() {
    this.loadingTableLivraison = true;
    this.loadingNbLivraison = true;
    this.filter.flux = this.flux;
    this.commandesData = [];
    this.nbData = 0;
    this.subscriptions.push(this.commandeSvc.getLivraisons(this.filter).subscribe((livraison) => {
      this.commandesData = livraison.data;
      this.nbData = livraison.nb;
      this.commandesData.forEach((value, index) => {
        const progessStatut = this.progressBar(value);
        this.commandesData[index]['progressBar'] = progessStatut['progressBar'];
        this.commandesData[index]['color'] = progessStatut['color'];
        this.commandesData[index]['statut'] = progessStatut['statut'];
        this.commandesData[index]['date'] = progessStatut['date'];
      });
      this.loadingTableLivraison = false;
      this.loadingNbLivraison = false;
      },
      error => {
        this.loadingTableLivraison = false;
        this.loadingNbLivraison = false;
      }));
  }

  onLoadDataByEvent(event: PageEvent) {
    this.paginationSvc.onPaginationEvent(this.filter, event)
    this.loadDataLivraisons();
  }

  progressBar(value) {
    const tab = [];
    if ((value.activite_nom !== 'RE') && (!value.solde) && (!!value.plateforme_transit_code) && ((!value.reception_hub_ok) || (value.reception_hub_ok === 'AT')) && (!value.depart_hub_ok) && (!value.reception_plateforme_ok)) {
      tab['statut'] = this.translate.instant('En attente de réception - Dépot national');
      tab['color'] = 'bg-secondary';
    } else if ((value.activite_nom !== 'RE') && (!value.solde) && (value.reception_hub_ok === 'OK') && (!value.depart_hub_ok)) {
      tab['statut'] = this.translate.instant('En attente de préparation - Dépot national');
      tab['color'] = 'bg-warning';
    } else if ((value.activite_nom !== 'RE') && (value.reception_hub_ok === 'NK')) {
      tab['statut'] = this.translate.instant('Anomalie lors de la réception - Dépot national');
      tab['color'] = 'bg-danger';
    } else if ((value.activite_nom !== 'RE') && (value.reception_hub_ok === 'OK') && (value.depart_hub_ok === 'NK')) {
      tab['statut'] = this.translate.instant('Anomalie lors du chargement - Dépot national');
      tab['color'] = 'bg-danger';
    } else if ((value.activite_nom !== 'RE') && (!value.solde) && (!value.plateforme_transit_code || (value.reception_hub_ok === 'OK' || !value.reception_hub_ok) && (value.depart_hub_ok === 'OK')) && (value.reception_plateforme_ok === 'AT' || !value.reception_plateforme_ok)) {
      tab['statut'] = this.translate.instant('En attente de reception - Agence régionale');
      tab['color'] = 'bg-secondary';
    } else if ((value.activite_nom !== 'RE') && (!value.solde) && (!value.plateforme_transit_code || (value.reception_hub_ok === 'OK' || !value.reception_hub_ok) && (value.depart_hub_ok === 'OK')) && (value.reception_plateforme_ok === 'OK') && (!value.depart_plateforme_ok || value.depart_plateforme_ok === 'AT') && (!value.livraison_ok)) {
      tab['statut'] = this.translate.instant('En attente de livraison - Agence régionale');
      tab['color'] = 'bg-warning';
    } else if ((value.activite_nom !== 'RE') && (!value.plateforme_transit_code || (value.reception_hub_ok === 'OK' || !value.reception_hub_ok) && (value.depart_hub_ok === 'OK')) && (value.reception_plateforme_ok === 'NK')) {
      tab['statut'] = this.translate.instant('Anomalie lors de la réception - Agence régionale');
      tab['color'] = 'bg-danger';
    } else if ((value.activite_nom !== 'RE') && (!value.plateforme_transit_code || (value.reception_hub_ok === 'OK' || !value.reception_hub_ok) && (value.depart_hub_ok === 'OK')) && (value.reception_plateforme_ok === 'OK') && (value.depart_plateforme_ok === 'NK') && (!value.livraison_ok)) {
      tab['statut'] = this.translate.instant('Anomalie lors du chargement - Agence régionale');
      tab['color'] = 'bg-danger';
    } else if (((value.activite_nom !== 'RE') && (!value.solde) && (!value.plateforme_transit_code || (value.reception_hub_ok === 'OK' || !value.reception_hub_ok) && (value.depart_hub_ok === 'OK')) && (value.reception_plateforme_ok === 'OK') && (value.depart_plateforme_ok === 'OK') && (!value.livraison_ok))) {
      tab['statut'] = this.translate.instant('Livraison en cours');
      tab['color'] = 'bg-warning';
    } else if ((value.activite_nom !== 'RE') && (value.solde === 'OK') && (value.livraison_ok === 'OK')) {
      tab['statut'] = this.translate.instant('Livré');
      tab['color'] = 'bg-green';
    } else if ((value.activite_nom !== 'RE') && (value.livraison_ok === 'NK')) {
      tab['statut'] = this.translate.instant('Anomalie lors de la livraison');
      tab['color'] = 'bg-danger';
    } else if ((value.solde === null) && (value.reception_plateforme_ok === 'OK') && (value.depart_plateforme_ok === 'AT') && (value.livraison_ok === 'AT')) {
      tab['statut'] = this.translate.instant('Avis de passage');
      tab['color'] = 'bg-warning';
    } else if ((value.solde === 'OK') && (!value.livraison_ok)) {
      tab['statut'] = this.translate.instant('Soldé - annulé');
      tab['color'] = 'bg-danger';
    } else if ((value.activite_nom === 'RE') && (value.solde === 'OK') && (value.livraison_ok === 'OK')) {
      tab['statut'] = this.translate.instant('Reprise effectuée');
      tab['color'] = 'bg-green';
    } else if ((value.activite_nom === 'RE') && (value.solde === 'OK') && (value.livraison_ok === 'NK')) {
      tab['statut'] = this.translate.instant('Anomalie lors de la reprise');
      tab['color'] = 'bg-danger';
    } else if ((value.activite_nom == 'RE') && (!value.solde) && (!value.plateforme_transit_code) && (!value.livraison_ok || value.livraison_ok === 'AT')) {
      tab['color'] = 'bg-secondary';
      tab['statut'] = this.translate.instant('En attente de reprise');
    }
    return tab;
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
