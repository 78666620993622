import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NotificationGrcData} from "../../../../shared/models/api/notification-grc-data.model";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {Subscription} from "rxjs";
import {MatSort, Sort} from "@angular/material/sort";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {MatTableDataSource} from "@angular/material/table";
import {DatatableHelper} from "../../../../shared/helpers/datatable.helper";

@Component({
  selector: 'app-table-parametres-mail-grc',
  templateUrl: './table-parametres-mail-grc.component.html',
  styleUrls: ['./table-parametres-mail-grc.component.scss']
})
export class TableParametresMailGrcComponent implements OnInit {

  expandedElement: NotificationGrcData | null;

  @Output() onEditNotification: EventEmitter<{ id:number, client: string }> = new EventEmitter<{ id:number, client: string }>();
  @Output() onEditAllNotification: EventEmitter<any> = new EventEmitter<any>();

  @Input() data: NotificationGrcData[];
  @Input() displayedColumns: string[] = [
    'email',
    'client',
    'activer_demande',
    'activer_message',
    'options'
  ];

  @Input() length: number;
  @Input() pageSize: number = 10;
  @Input() pageIndex: number;
  @Input() pageEvent: PageEvent;
  @Input() pageSizeOptions: number[] = [5, 10, 20];
  @Input() loading = false;
  @Input() hiddenColumns: string[] = [];
  @Input() showViewBtn: boolean = false;
  @Input() showInfosBtn: boolean = false;
  @Input() showValidateBtn: boolean = false;
  @Input() notifLength: number;
  @Input() showTitle: boolean = false;
  @Input() showAddFileBtn: boolean = false;

  @Output() paginatorEvent : EventEmitter<PageEvent> = new EventEmitter<PageEvent>()


  public dataSource: any;
  public innerWidth: number;
  public filesSub: Subscription;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {
  }


  ngOnInit() {
    this.onResizeTable();
    this.dataSource = new MatTableDataSource<NotificationGrcData>(this.data);
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1);
        }
      });
    }
  }

  emit($event: PageEvent) {
    return this.paginatorEvent.emit($event);
  }

  selectEdit(id: number, client: string) {
    this.onEditNotification.emit({id, client})
  }


  selectEditAll() {
    this.onEditAllNotification.emit()
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }

  }

  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 768) {
      this.displayedColumns = [
        'client',
        'activer_demande',
        'activer_message',
        'options'
      ];
    } else if (this.innerWidth > 768) {
      this.displayedColumns = [
        'email',
        'client',
        'activer_demande',
        'activer_message',
        'options'
      ];
    }
  }

}
