<mat-tab-group class="tab-commande tab-global" #parent disableRipple [(selectedIndex)]="startTabIndex">
  <div *ngFor="let tab of tabs">
    <mat-tab *ngIf="view.hasDroit(tab.droit)">
      <ng-template mat-tab-label>
        <div class="tab"
             placement="top"
             container="body"
             [popoverTitle]="title"
             [ngbPopover]="content" triggers="click:mouseleave">
          <span class="tab-icon-style"><i class="fas fa-cubes"></i></span>
          <span class="font-size-title px-2">{{ tab.libelle }}</span>
        </div>
      </ng-template>
      <div class="content-color" *ngIf="tab.code === 'COMMANDE'">
        <app-commande-content [commande]="commandeData" [loadingPage]="loading"></app-commande-content>
      </div>
      <div class="content-color" *ngIf="tab.code === 'HISTORIQUE'">
        <app-evenement-content [commande]="commandeData"></app-evenement-content>
      </div>
      <div class="content-color" *ngIf="tab.code === 'MEDIA'">
        <app-media-content [commande]="commandeData"></app-media-content>
      </div>
      <div class="content-color" *ngIf="tab.code === 'GRC'">
        <app-grc-content [commande]="commandeData"></app-grc-content>
      </div>
      <div class="content-color" *ngIf="tab.code === 'LITIGES'">
        <app-litige-content [litigeNumeroAvis]="commandeData.numero_avis"></app-litige-content>
      </div>
    </mat-tab>

    <ng-template #title>
      <div class="scrollbar scrollbar-black bordered-black square thin overflow-scroll" style="max-height: 580px">
        <span class="popover-title" [innerHTML]="tab.libelle"></span>
      </div>
    </ng-template>
    <ng-template #content>
      <div class="scrollbar scrollbar-black bordered-black square thin overflow-scroll" style="max-height: 580px">
        <p class="popover-content" *ngIf="livraison === tab.livraisonTitle"
           [innerHTML]="tab.livraisonContent"></p>
        <p class="popover-content" *ngIf="retour === tab.retourTitle"
           [innerHTML]="tab.retourContent"></p>
      </div>
    </ng-template>

  </div>
</mat-tab-group>
