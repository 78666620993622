import {Component, Input, OnInit} from '@angular/core';
import {Commande} from "../../../shared/models/api/commande.model";
import {CommandeData} from "../../../shared/models/api/commande-data.model";

@Component({
  selector: 'app-header-livraison',
  templateUrl: './header-livraison.component.html',
  styleUrls: ['./header-livraison.component.scss']
})
export class HeaderLivraisonComponent implements OnInit {

  @Input() commandeData: CommandeData;

  constructor() { }

  ngOnInit() {
    if(!this.commandeData) {
      throw new Error('Le composent sidebar attend un objet de type commande.')
    }
  }

}
