import {Component, Input, OnInit} from '@angular/core';
import {ContestationData} from "../../../shared/models/api/contestation-data.model";

@Component({
  selector: 'app-sidebar-contestation',
  templateUrl: './sidebar-contestation.component.html',
  styleUrls: ['./sidebar-contestation.component.scss']
})
export class SidebarContestationComponent implements OnInit {

  @Input() contestationData: ContestationData;
  @Input() loadingPage = false;
  public innerWidth: number = window.innerWidth;

  constructor() { }

  ngOnInit() {
    if(!this.contestationData) {
      throw new Error('Le composent sidebar-contestation attend un objet de type contestation.')
    }
  }

}
