import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {PieceJointeGed} from "../../../models/api/ged/piece-jointe-ged.model";
import {HttpUtil} from "../../../utils/http.util";
import {environment} from "../../../../../environments/environment";
import {ImageGed} from "../../../models/api/ged/image-ged.model";

@Injectable({
    providedIn: 'root'
})
export class GedService {

    urlRessourceApiGed = environment.url_api_scansrv;
    urlRessourceApiEspaceClient = environment.url_api_espace_client + '/ged';

    constructor(private http: HttpClient) {

    }
    checkConnexionToGed(type: string) {
        if(type === 'VIDEO') {
            return this.http.get(`${this.urlRessourceApiGed}/check-connexion`, {
                headers: {
                    'Authorization': environment.api_token_agediss,
                    'Content-Type': 'application/json'
                },
            })
        } else {
            return this.http.get(`${this.urlRessourceApiEspaceClient}/check-connexion`)
        }

    }

    getBlEmarge(numeroAvis: string, filter: any = null): Observable<ImageGed[]> {
        return this.http
            .get<ImageGed[]>(`${this.urlRessourceApiEspaceClient}/${numeroAvis}/bl-emarge`, {params: HttpUtil.convertObjectToHttpParams(filter)})
            .pipe(map((images) => images.map((image) => {
                return new ImageGed().deserialize(image);
            })));
    }

    getSignature(numeroAvis: string): Observable<PieceJointeGed[]> {
        return this.http
            .get<PieceJointeGed[]>(`${this.urlRessourceApiEspaceClient}/${numeroAvis}/signature`)
            .pipe(map((piecejointes) => piecejointes.map((piecejointe) => {
                return new PieceJointeGed().deserialize(piecejointe);
            })));
    }

    getVideos(filter: any = null): Observable<PieceJointeGed[]> {
        return this.http
            .get<PieceJointeGed[]>(`${this.urlRessourceApiGed}/ged/liste-documents`, {
                headers: {
                    'Authorization': environment.api_token_agediss,
                    'Content-Type': 'application/json'
                },
                params: HttpUtil.convertObjectToHttpParams(filter)
            })
            .pipe(map((piecejointes) => piecejointes.map((piecejointe) => {
                return new PieceJointeGed().deserialize(piecejointe);
            })));
    }
    getVideo(filter: any = null): Observable<PieceJointeGed> {
        return this.http
            .get<PieceJointeGed>(`${this.urlRessourceApiGed}/ged/image`, {
                headers: {
                    'Authorization': environment.api_token_agediss,
                    'Content-Type': 'application/json'
                },
                params: HttpUtil.convertObjectToHttpParams(filter)
            });
    }

    getKpis(filter: any = null): Observable<PieceJointeGed[]> {
        return this.http
            .get<PieceJointeGed[]>(`${this.urlRessourceApiEspaceClient}/get-kpis`, {params: HttpUtil.convertObjectToHttpParams(filter)})
            .pipe(map((pieceJointes) => pieceJointes.map((pieceJointes) => {
                return new PieceJointeGed().deserialize(pieceJointes);
            })));
    }

    deleteFileGed(id: string, filter: any = null): Observable<PieceJointeGed> {
        return this.http.delete<PieceJointeGed>(
            `${this.urlRessourceApiEspaceClient}/${id}/delete`,
            {params: HttpUtil.convertObjectToHttpParams(filter)})
            .pipe(map((piecejointe) => {
                return new PieceJointeGed().deserialize(piecejointe);
            }));
    }

    downloadFileGed(id: string, type: string): Observable<{ data: Blob, filename: string }> {
        return this.http
            .get(this.urlRessourceApiEspaceClient + '/' + type + '/' + id + '/download',
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            ).pipe(map(res => {
                if (!res.headers.get('content-disposition')) {
                    throw "L'api ne renvoi pas le header 'content-disposition'";
                }
                return {
                    filename: HttpUtil.extractFilenameInContentDisposition(res.headers.get('content-disposition')),
                    data: res.body,
                };
            }));
    }
}
