import {Component, Input, OnInit} from '@angular/core';
import {Commande} from "../../../shared/models/api/commande.model";
import {CommandeData} from "../../../shared/models/api/commande-data.model";

@Component({
  selector: 'app-destinataire',
  templateUrl: './destinataire.component.html',
  styleUrls: ['./destinataire.component.scss']
})
export class DestinataireComponent implements OnInit {
  @Input() commande: CommandeData;
  @Input() loading = false;
  public loadingPage = false;

  constructor() { }

  ngOnInit() {
    if(!this.commande) {
      throw new Error('Le composent destinataire attend un objet de type commande.')
    }
  }
}
