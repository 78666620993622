import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DatatableHelper} from '../../../../../shared/helpers/datatable.helper';
import {tableAnimation} from '../../../../../animations';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {ConversationData} from "../../../../../shared/models/api/conversation-data.model";

@Component({
  selector: 'app-table-conversation',
  templateUrl: './table-conversation.component.html',
  styleUrls: ['./table-conversation.component.scss'],
  animations: [tableAnimation],
})
export class TableConversationComponent implements OnInit, OnChanges {

  expandedElement: ConversationData | null;

  @Output() onAdd: EventEmitter<ConversationData> = new EventEmitter();
  @Output() selectConversation: EventEmitter<{ id: number, index: number }> = new EventEmitter<{ id: number, index: number }>();
  @Output() onDeleted: EventEmitter<number> = new EventEmitter();

  @Input() data: ConversationData[];
  @Input() loading: Boolean;
  @Input() showAddConversationBtn: boolean = false;
  @Input() showInfosBtn: boolean = false;
  @Input() showCommentsBtn: boolean = false;
  @Input() showDeleteConversationBtn: boolean = false;
  @Input() loadingAddConversationBtn: boolean = false;
  @Input() length: number;
  @Input() pageSize: number = 10;
  @Input() pageIndex: number;
  @Input() pageEvent: PageEvent;
  @Input() pageSizeOptions: number[] = [5, 10, 20];
  @Input() displayedColumns: string[] = [
    'id',
    'contexte',
    'created_at',
    'updated_at',
    'message',
    'action'];

  @Output() paginatorEvent: EventEmitter<PageEvent> = new EventEmitter<PageEvent>()

  public innerWidth: number;
  public dataSource: MatTableDataSource<ConversationData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() {
  }

  ngOnInit() {
    this.onResizeTable()
    this.dataSource = new MatTableDataSource(this.data);
  }

  emit($event: PageEvent) {
    return this.paginatorEvent.emit($event);
  }

  /**
   * LifeHook : à chaque changement de value de conversation on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      this.loading = changes.loading.currentValue;
    }
  }

  selectAddConversation() {
    this.onAdd.emit()
  }

  selectOneConversation(id: number, index: number) {
    this.selectConversation.emit({id, index});
  };

  /**
   * Permet d'ordonner les colones.
   * @param sort
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 400) {
      this.displayedColumns = [
        'created_at',
        'action'
      ];
    } else if (this.innerWidth < 720) {
      this.displayedColumns = [
        'created_at',
        'updated_at',
        'action'
      ];
    } else if (this.innerWidth > 720) {
      this.displayedColumns = [
        'created_at',
        'updated_at',
        'message',
        'action'
      ];
    }
  }

}
