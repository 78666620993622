<div *ngIf="loading" style="height: 200px" class="row col-12">
  <span class="spinner"></span>
</div>
<div *ngIf="!loading">
  <div class="modal-header">
    <h4 class="modal-title  w-100 text-center">
      <span *ngIf="facture.type_facture.code === 'FACTURE'">{{'Facture' | translate }} n° {{factureId}}</span>
      <span *ngIf="facture.type_facture.code !== 'FACTURE'">{{'Avoir' | translate }} n° {{factureId}}</span>
    </h4>
  </div>
  <div class="modal-body">
    <div class="row mb-4">
      <span class="col-4">
          <span class="mb-2"><label class="label label-info mr-2">{{'Numéro' | translate}} : {{facture.numero_client}}</label></span>
        </span>
      <span class="col-4">
          <span class="mb-2"><label class="label label-info mr-2">{{'Montant total' | translate}} :
            {{facture.montant  | currency:'EUR':'€'}}
          </label></span>
        </span>
      <span class="col-4">
          <span class="mb-2">
            <label class="label mr-2" [ngClass]="{
              'label-pink' : !facture.statut,
              'label-danger' : !!facture.statut && facture.statut_bap === 'CONTESTE',
              'label-dark' : !!facture.statut && facture.statut_bap === 'PRESCRIT',
              'label-lime' : !!facture.statut && facture.statut_bap === 'BAP' && !facture.date_bap,
              'label-primary' : !!facture.statut && facture.statut_bap === 'BAP' && !!facture.date_bap
           }">
              <span *ngIf="!facture.statut">{{"En attente d'affectation" | translate }}</span>
              <span *ngIf="!!facture.statut && facture.statut_bap === 'PRESCRIT'">{{"Prescrit" | translate }}</span>
              <span *ngIf="!!facture.statut && facture.statut_bap === 'CONTESTE'">{{"En attente d'avoir" | translate }}</span>
              <span *ngIf="!!facture.statut && facture.statut_bap === 'BAP' && !facture.date_bap">{{"Bon à payer" | translate }}</span>
              <span *ngIf="!!facture.statut && facture.statut_bap === 'BAP' && !!facture.date_bap">{{"Transmis pour paiement" | translate }}</span>
            </label>
            <span *ngIf="!!facture.date_bap">{{facture.date_bap | date}}</span>
          </span>
        </span>
    </div>
    <div class="row m-0">
      <div class="col-6">
        <div>
          <div style="max-height: 300px">
            <app-file-preview [pieceJointeData]="pieceJointeData"
                              [piecesJointesGed]="piecesJointesGed"
                              [currentType]="currentType"
                              [showImage]="showImage"
                              [urlPreview]="urlPreview"
                              [loadingPage]="loadingPieceJointe"
                              [height]="300">
            </app-file-preview>
          </div>
        </div>
      </div>

      <div class="col-6 mb-2">
        <panel variants="danger" noButton="true" bodyClass="no-padding panel">

          <div class="col-12"><h5 class="p-t-5 text-agediss">
            <span *ngIf="facture.type_facture.code === 'FACTURE'">{{'Avoir liés' | translate}} : </span>
            <span *ngIf="facture.type_facture.code !== 'FACTURE'">{{'Factures liees' | translate}} : </span>
            <span *ngIf="!loading && facture.type_facture.code === 'FACTURE'" >
              {{facture?.avoirs_asso.length}}
            </span>
            <span *ngIf="!loading && facture.type_facture.code !== 'FACTURE'" >
              {{facture?.factures_asso.length}}
            </span>
            <i *ngIf="loading" class='fas fa-spin fa-circle-notch'></i>
          </h5></div>

          <div>
            <div style="max-height: 230px; overflow: auto">
              <table mat-table [dataSource]="assoSource"
                     class="table mat-responsive table-hover table-bordered mat-elevation-z8 text-center">

                <ng-container matColumnDef="numero_client">
                  <th mat-header-cell *matHeaderCellDef class="px-10">{{'Numéro' | translate}}</th>
                  <td mat-cell *matCellDef="let element" class="px-10 align-middle">
                    {{element?.numero_client}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="montant">
                  <th mat-header-cell *matHeaderCellDef>{{'Montant' | translate}}</th>
                  <td mat-cell *matCellDef="let element" class="align-middle">
                    {{element.montant | currency:'EUR':'€'}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="statut">
                  <th mat-header-cell *matHeaderCellDef>{{'Statut' | translate }}</th>
                  <td mat-cell *matCellDef="let element" class="align-middle">
                    <div class="label" [ngClass]="{
                      'label-danger' : element.statut_bap === 'CONTESTE',
                      'label-dark' : element.statut_bap === 'PRESCRIT',
                      'label-green' : element.statut_bap === 'BAP'
                    }">
                      <span *ngIf="element.statut_bap === 'CONTESTE'">{{"Contesté" | translate }}</span>
                      <span *ngIf="element.statut_bap === 'PRESCRIT'">{{"Prescrit" | translate }}</span>
                      <span *ngIf="element.statut_bap === 'BAP'">{{"Bon à payer" | translate }}</span>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="noData">
                  <td mat-footer-cell *matFooterCellDef [attr.colspan]="assoColumns.length"
                      innerHTML="{{'Aucun' | translate }}"></td>
                </ng-container>
                <ng-container matColumnDef="loading">
                  <td mat-footer-cell *matFooterCellDef [attr.colspan]="assoColumns.length"
                      innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
                </ng-container>
                <tr mat-header-row style="height: 15px;" *matHeaderRowDef="assoColumns;  sticky: true"></tr>
                <tr mat-row *matRowDef="let element; columns: assoColumns;" [hidden]="loading"
                    class="table-row overflow-scroll">
                </tr>
                <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="assoSource && assoSource.data.length > 0 || loading"></tr>
                <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
              </table>
            </div>

          </div>
        </panel>
      </div>

      <div class="col-12">
        <panel variants="danger" noButton="true" bodyClass="no-padding panel">

            <div class="col-12"><h5 class="p-t-5 text-agediss">{{'Les litiges' | translate }} :
              <span *ngIf="!loading" >{{facture?.facture_litiges?.length}}</span>
              <i *ngIf="loading" class='fas fa-spin fa-circle-notch'></i>
            </h5></div>

          <div>
            <app-table-litige-facture [data]="facture?.facture_litiges"
                                      [length]="facture?.facture_litiges?.length">
            </app-table-litige-facture>
          </div>
        </panel>
      </div>

    </div>
  </div>
</div>
