import {Deserializable} from '../interfaces/deserializable.interface';
import {Commande} from "./commande.model";
import {LitigeData} from "./litige-data.model";

export class Client implements Deserializable {

  public id?: number;
  public litige_data: LitigeData;
  public commande: Commande;
  public created_at: Date;
  public updated_at: Date;
  public code: string;
  public code_client: string;
  public libelle: string;
  public viewed?: Boolean;

  deserialize(input: any): this {
   Object.assign(this, input);
    return this
  }

}
