import { Component, OnInit } from '@angular/core';
import {View} from "../../shared/models/view.model";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "../../shared/services/authentication.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.html',
  styleUrls: ['./home.scss']
})
export class HomePage implements OnInit {

  public view: View;
  public title: string = '';

  constructor(
    private translate: TranslateService,
    private authSvc: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.authSvc.currentView.subscribe(x => this.view = x);

    if(this.view.context === '1') {
      this.title = this.translate.instant('Bienvenue dans votre administration de l\'espace client Agediss');
    } else {
      this.title = this.translate.instant('Bienvenue dans votre espace client Agediss');
    }

    if(this.view.hasDroit('COMMANDE_MENU_TABLEAU-DE-BORD')) {
      this.router.navigate(['/','tableau-de-bord']);
    } else if (!this.view.hasDroit('COMMANDE_MENU_TABLEAU-DE-BORD') && this.view.hasDroit('COMMANDE_MENU_SUIVI-LIVRAISON')) {
      this.router.navigate(['/','suivi-de-livraison']);
    } else {
      this.router.navigate(['/','home']);
    }
  }

}
