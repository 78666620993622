import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormfilterHelper} from '../../../shared/helpers/Formfilter.helper';
import {StatutService} from '../../../shared/services/api/statut.service';
import {FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Statut} from '../../../shared/models/api/statut.model';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {FieldParametersModel} from '../../../shared/models/filters/field-parameters.model';
import {Client} from '../../../shared/models/api/client.model';
import {DatePickerMinDateComponent} from '../../shared/date-picker/date-picker-min-date/date-picker-min-date.component';
import {DatePickerMaxDateComponent} from '../../shared/date-picker/date-picker-max-date/date-picker-max-date.component';
import {FilterService} from '../../../shared/services/filter.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from "ngx-toastr";
import {SousCompteSsoService} from "../../../shared/services/api/sso/sous-compte-sso.service";

@Component({
  selector: 'app-filter-litige',
  templateUrl: './filter-litige.component.html',
  styleUrls: ['./filter-litige.component.scss']
})
export class FilterLitigeComponent implements OnInit, OnDestroy {

  @ViewChild('inputMinDate') inputMinDate: DatePickerMinDateComponent;
  @ViewChild('inputMaxDate') inputMaxDate: DatePickerMaxDateComponent;

  @Input() displayInput: FieldParametersModel[] = [];

  @Output() submitFilter = new EventEmitter<FilterMultiCritereModel>();
  @Output() onLoad = new EventEmitter<FilterMultiCritereModel>();

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public hiddenInput = [];
  public statuts: Statut[];
  public clients: Client[];
  public subscriptions: Subscription[] = [];
  public filterForm = new FormGroup({
    clients: new FormControl(),
    statuts: new FormControl(),
    minDate: new FormControl(),
    maxDate: new FormControl(),
    dateLitige: new FormControl(),
    createdAt: new FormControl(),
    search: new FormControl()
  });

  public loadStatut: boolean = false;
  public loadClient: boolean = false;
  public minDate;
  public maxDate;


  constructor(private translate: TranslateService,
              private statutSvc: StatutService,
              private clientSvc: SousCompteSsoService,
              private toastr: ToastrService,
              private filterSvc: FilterService) {
  }

  ngOnInit() {
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.loadClient = true;
      this.loadStatut = true;
      this.onLoadSousComptes();
      this.onLoadStatuts();
    }))
    this.onInitFilter();
  }

  onInitFilter() {
    this.loadClient = true;
    this.loadStatut = true;
    FormfilterHelper.hydrateForm(this.displayInput, this.filterForm);
    this.hiddenInput = FormfilterHelper.extractHiddenInput(this.displayInput);
    this.onLoadSousComptes();
    this.onLoadStatuts();
    this.getValueLocalstorage();
    this.onLoad.emit(this.filterForm.getRawValue());
  }

  /**
   * Chargement des sous-comptes
   */
  onLoadSousComptes() {
    this.subscriptions.push(this.clientSvc.getSousComptes().subscribe(value => {
      this.clients = value;
      this.loadClient = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des sous-comptes n'est pas disponible", {application: this.translate.instant('Mes litiges')}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadClient = false;
      }
    ));
  }

  /**
   * Chargement des statuts
   */
  onLoadStatuts() {
    this.subscriptions.push(this.statutSvc.getStatuts(StatutService.LITIGE).subscribe(value => {
      this.statuts = value;
      this.loadStatut = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des statuts n'est pas disponible", { application: this.translate.instant('Mes litiges')}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadStatut = false;
      }
    ));
  }

  /**
   * On récupère les valeurs du localstorage
   */
  getValueLocalstorage() {
    this.filterForm.get('search').setValue(localStorage.getItem('litigeSearchFilter'));
    this.filterSvc.onCheckValueLocalStorage(this.filterForm,'litigeClientFilter', 'clients');
    this.filterSvc.onCheckValueLocalStorage(this.filterForm,'litigeStatutFilter', 'statuts');
    this.filterSvc.convertDateLocalstorageForUrl(this.filterForm, 'minDate', 'litigeMinDateFilter');
    this.filterSvc.convertDateLocalstorageForUrl(this.filterForm,'maxDate', 'litigeMaxDateFilter');
    this.onDateLocalstorage('litigeMinDateFilter', 'litigeMaxDateFilter');
  }

  /**
   *   on affiche la date enregistrée dans le localstorage, dans l'input
   */
  onDateLocalstorage(minDateFilterName, maxDateFilterName) {
    if (localStorage.getItem(minDateFilterName) != null && localStorage.getItem(maxDateFilterName) == null) {
      if (!this.minDate && !this.maxDate) {
        this.minDate = this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem(minDateFilterName));
        this.maxDate = null;
      }
    } else if (localStorage.getItem(minDateFilterName) != null && localStorage.getItem(maxDateFilterName) != null) {
      if (!this.minDate && !this.maxDate) {
        this.minDate = this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem(minDateFilterName));
        this.maxDate = this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem(maxDateFilterName));
      }
    }
  }

  onSubmit() {
    //récupère les infos du filtre

    this.filterSvc.ckeckInputIsNull(this.filterForm,this.inputMinDate, 'minDate' )
    this.filterSvc.ckeckInputIsNull(this.filterForm,this.inputMaxDate, 'maxDate' )
    this.filterSvc.onSaveInLocalstorage(this.filterForm,'litigeSearchFilter', 'search');
    this.filterSvc.onSaveInLocalstorage(this.filterForm,'litigeClientFilter', 'clients');
    this.filterSvc.onSaveInLocalstorage(this.filterForm,'litigeStatutFilter', 'statuts');
    this.filterSvc.onSaveInLocalstorage(this.filterForm,'litigeMinDateFilter', 'minDate');
    this.filterSvc.onSaveInLocalstorage(this.filterForm,'litigeMaxDateFilter', 'maxDate');
    this.submitFilter.emit(this.filterForm.getRawValue());
  }

  reset(): void {
    this.filterForm.reset();
    this.inputMinDate.reset();
    this.inputMaxDate.reset();
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
