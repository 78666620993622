import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {tableAnimation} from '../../../../animations';
import {Evenement} from '../../../../shared/models/api/evenement.model';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {EvenementColis} from "../../../../shared/models/api/evenement-colis.model";
import {Component } from "@angular/core";
import {EventEmitter } from "@angular/core";
import {HostListener} from "@angular/core";
import {Input} from "@angular/core";
import {OnChanges} from "@angular/core";
import {OnInit} from "@angular/core";
import {Output} from "@angular/core";

@Component({
  selector: 'app-table-evenement-colis',
  templateUrl: './table-evenement-colis.component.html',
  styleUrls: ['./table-evenement-colis.component.scss'],
  animations: [tableAnimation],
})
export class TableEvenementColisComponent implements OnInit, OnChanges {

  expandedElement: EvenementColis | null;

  @Output() onSelectFile: EventEmitter<EvenementColis> = new EventEmitter<EvenementColis>();


  @Input() loading = false;
  @Input() showViewBtn: boolean = false;
  @Input() showInfosBtn: boolean = false;
  @Input() data: EvenementColis[];
  @Input() evenementColis: EvenementColis;
  @Input() displayedColumns: string[] = [
    'id',
    'code',
    'libelle',
    'date_creation',
    'commentaire',
    'date_evenements',
    'creneaux_debut',
    'creneaux_fin',
    'date_proposition_1',
    'date_proposition_2',
    'options',
  ];

  public innerWidth: number;
  public dataSource: any;

  ngOnInit() {
    this.dataSource = new MatTableDataSource<EvenementColis>(this.data);
    this.onResizeTable()
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }
  }

  selectFile(evenementColis: EvenementColis) {
    this.onSelectFile.emit(evenementColis);
  };

  /**
   * Permet d'ordonner les colones.
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }


  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 400) {
      this.displayedColumns = [
        'libelle',
        'options',
      ];
    } else if (this.innerWidth > 400 && this.innerWidth <= 600) {
      this.displayedColumns = [
        'libelle',
        'date_creation',
        'options',
      ];
    } else if (this.innerWidth > 600 && this.innerWidth <= 900) {
      this.displayedColumns = [
        'libelle',
        'date_creation',
        'date_evenements',
        'options',
      ];
    } else if (this.innerWidth > 900 && this.innerWidth <= 1000) {
      this.displayedColumns = [
        'contexte',
        'libelle',
        'date_creation',
        'date_evenements',
        'options',
      ];
    } else if (this.innerWidth > 1000) {
      this.displayedColumns = [
        'contexte',
        'libelle',
        'date_creation',
        'date_evenements',
        'commentaire',
        'options',
      ];
    }
  }

}
