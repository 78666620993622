import {Injectable} from '@angular/core';
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Conversation} from "../../models/api/conversation.model";
import {MessageConversation} from '../../models/api/message-conversation.model';

@Injectable({
  providedIn: 'root'
})
export class ConversationService {

  urlRessource = environment.url_api_espace_client + "/conversations";

  constructor(private http: HttpClient) {
  }

  /**
   * @param id
   */
  getConversation(id: number): Observable<Conversation> {
    return this.http
      .get<Conversation>(`${this.urlRessource}/${id}`)
      .pipe(map((conversation) => {
        return new Conversation().deserialize(conversation);
      }));
  }

  /**
   * @param id
   */
  getConversationMessages(id: number): Observable<MessageConversation[]> {
    return this.http
      .get<MessageConversation[]>(`${this.urlRessource}/${id}/messages`)
      .pipe(map((messages) => messages.map(message => {
        return new MessageConversation().deserialize(message);
      })));
  }



}


