import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Litige} from '../../../shared/models/api/litige.model';
import {LitigeData} from "../../../shared/models/api/litige-data.model";

@Component({
  selector: 'app-litige-livraison-component',
  templateUrl: './livraison.component.html',
  styleUrls: ['./livraison.component.scss']
})
export class LivraisonComponent implements OnInit {


  @Input() litigeData: LitigeData;
  @Input() loading = false;
  @Output() selectShowDocument: EventEmitter<{id: number}> = new EventEmitter<{id: number}>();
  @Input() showViewCommandeBtn: boolean = false;
  @Input() showViewDocumentBtn: boolean = false;

  public loadingPage = false;

  constructor() { }

  ngOnInit() {
    if(!this.litigeData) {
      throw new Error('Le composent litige attend un objet de type litige.')
    }
  }

  selectShowDocumentByCommandeId(id: number) {
    this.selectShowDocument.emit({id});
  };

}
