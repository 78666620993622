import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {DatatableHelper} from '../../../../shared/helpers/datatable.helper';
import {Litige} from '../../../../shared/models/api/litige.model';
import {tableAnimation} from '../../../../animations';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {LitigeData} from "../../../../shared/models/api/litige-data.model";


@Component({
  selector: 'app-table-litige',
  templateUrl: './table-litige.component.html',
  styleUrls: ['./table-litige.component.scss'],
  animations: [tableAnimation],
})
export class TableLitigeComponent implements OnInit, OnChanges {

  expandedElement: LitigeData | null;

  @Input() data: LitigeData[];
  @Input() litige: Litige
  @Input() litigeStatusCode: string;
  @Input() displayedColumns: string[] = [
    'id',
    'numero_avis',
    'client',
    'numero_commande',
    'destinataire_nom',
    'plateforme',
    'date_litige',
    'statut',
    'options'
  ];
  @Input() loading = false;
  @Input() height = null;
  @Input() hiddenColumns: string[] = [];
  @Output() onSorted: EventEmitter<Sort> = new EventEmitter();
  @Input() showInfosBtn: boolean = false;
  @Input() showViewBtn: boolean = false;
  @Input() length: number;
  @Input() pageSize: number = 10;
  @Input() pageIndex: number;
  @Input() pageEvent: PageEvent;
  @Input() pageSizeOptions: number[] = [5, 10, 20];

  @Output() paginatorEvent : EventEmitter<PageEvent> = new EventEmitter<PageEvent>()

  public innerWidth: number;
  public dataSource: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.onResizeTable();
    this.dataSource = new MatTableDataSource<LitigeData>(this.data);
    if (this.hiddenColumns.length > 0) {
      this.hiddenColumns.forEach((value) => {
        let index = this.displayedColumns.indexOf(value);
        if (index) {
          this.displayedColumns.splice(index, 1)
        }
      })
    }
  }

  emit($event: PageEvent) {
    return this.paginatorEvent.emit($event);
  }

  /**
   * LifeHook : à chaque changement de value de data on rafraichi les données du tableau
   * @param changes
   */
  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(changes.data.currentValue);
      this.dataSource.sort = this.sort;
    }
    if (changes.loading) {
      if (changes.data) {
        this.dataSource = new MatTableDataSource(changes.data.currentValue);
        this.dataSource.sort = this.sort;
      }
      if (changes.loading) {
        this.loading = changes.loading.currentValue;
      }
    }

  }


  /**
   * Permet d'ordonner les colones.
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      return;
    }
    this.dataSource = new MatTableDataSource(data.sort(
      (a, b) => {
        const isAsc = sort.direction === 'asc';
        if (sort.active in a) {
          return DatatableHelper.compare(a[sort.active], b[sort.active], isAsc);
        }
        return 0;
      }));
  }

  @HostListener('window:resize', ['$event'])
  onResizeTable() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 400) {
      this.displayedColumns = [
        'id',
        'numero_avis',
        'options',
      ];
    } else if (this.innerWidth > 400 && this.innerWidth <= 600) {
      this.displayedColumns = [
        'id',
        'numero_avis',
        'numero_commande',
        'options'
      ];
    } else if (this.innerWidth > 600 && this.innerWidth <= 767) {
      this.displayedColumns = [
        'id',
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'options'
      ];
    }  else if (this.innerWidth > 767 && this.innerWidth <= 820) {
      this.displayedColumns = [
        'id',
        'numero_avis',
        'numero_commande',
        'options'
      ];
    } else if (this.innerWidth > 820 && this.innerWidth <= 965) {
      this.displayedColumns = [
        'id',
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'options'
      ];
    }else if (this.innerWidth > 965 && this.innerWidth <= 1051) {
      this.displayedColumns = [
        'id',
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 1051 && this.innerWidth <= 1230) {
      this.displayedColumns = [
        'id',
        'numero_avis',
        'numero_commande',
        'destinataire_nom',
        'date_litige',
        'statut',
        'options'
      ];
    } else if (this.innerWidth > 1230) {
      this.displayedColumns = [
        'id',
        'numero_avis',
        'numero_commande',
        'client',
        'destinataire_nom',
        'plateforme',
        'date_litige',
        'statut',
      ];
    }
  }

}
