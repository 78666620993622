import {Deserializable} from './interfaces/deserializable.interface';
import {UserData} from "./user-data.model";

export class User implements Deserializable {
  public nb: number;
  public data: UserData[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
