import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FieldParametersModel} from '../../../shared/models/filters/field-parameters.model';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {FormControl, FormGroup} from '@angular/forms';
import {FormfilterHelper} from '../../../shared/helpers/Formfilter.helper';
import {FilterService} from '../../../shared/services/filter.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'app-filter-facture',
  templateUrl: './filter-facture-component.html',
  styleUrls: ['./filter-facture-component.scss']
})
export class FilterFactureComponent implements OnInit, OnDestroy {

  @Input() displayInput: FieldParametersModel[] = [];

  @Output() submitFilter = new EventEmitter<FilterMultiCritereModel>();
  @Output() onLoad = new EventEmitter<FilterMultiCritereModel>();

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public filterForm = new FormGroup({
    searchFacture: new FormControl()
  });

  // Tableaux
  public hiddenInput = [];
  public dataSource: any;

  constructor(private translate: TranslateService,
              private toastr: ToastrService,
              private filterSvc: FilterService) {
  }

  ngOnInit() {
    this.onInitFilter();
  }

  onInitFilter() {
    FormfilterHelper.hydrateForm(this.displayInput, this.filterForm);
    this.hiddenInput = FormfilterHelper.extractHiddenInput(this.displayInput);
    this.onLoad.emit(this.filterForm.getRawValue());
  }

  onSubmit() {
    // récupère les infos du filtre
    this.filterSvc.onSaveInLocalstorage(this.filterForm, 'livraisonSearchFilter', 'searchFacture');
    this.submitFilter.emit(this.filterForm.getRawValue());
  }

  // On efface tous les champs du filter
  reset(): void {
    this.filterForm.reset();
  }

  ngOnDestroy() {
  }
}
