import {Deserializable} from '../interfaces/deserializable.interface';
import {TypeStatut} from "./type-statut.model";

export class Statut implements Deserializable{

  public id: number;
  public type_statut:TypeStatut;
  public ordre: number;
  public created_at: Date;
  public updated_at: Date;
  public libelle: string;
  public code: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
