import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FieldParametersModel} from '../../../shared/models/filters/field-parameters.model';
import {FilterMultiCritereModel} from '../../../shared/models/filters/filterMultiCritere.model';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {FormfilterHelper} from '../../../shared/helpers/Formfilter.helper';
import {Client} from '../../../shared/models/api/client.model';
import {DatePickerMinDateComponent} from '../../shared/date-picker/date-picker-min-date/date-picker-min-date.component';
import {DatePickerMaxDateComponent} from '../../shared/date-picker/date-picker-max-date/date-picker-max-date.component';
import {StatutService} from '../../../shared/services/api/statut.service';
import {Statut} from '../../../shared/models/api/statut.model';
import {FilterService} from '../../../shared/services/filter.service';
import {TranslateService} from '@ngx-translate/core';
import {ActiviteService} from '../../../shared/services/api/activite.service';
import {Activite} from '../../../shared/models/api/activite.model';
import {SousCompteSsoService} from '../../../shared/services/api/sso/sous-compte-sso.service';
import {ToastrService} from 'ngx-toastr';
import {PaysService} from '../../../shared/services/api/pays.service';
import {Pays} from '../../../shared/models/api/pays.model';

@Component({
  selector: 'app-filter-livraison',
  templateUrl: './filter-livraison.component.html',
  styleUrls: ['./filter-livraison.component.scss']
})
export class FilterLivraisonComponent implements OnInit, OnDestroy {

  @ViewChild('inputMinDate') inputMinDate: DatePickerMinDateComponent;
  @ViewChild('inputMaxDate') inputMaxDate: DatePickerMaxDateComponent;

  @Input() displayInput: FieldParametersModel[] = [];

  @Output() submitFilter = new EventEmitter<FilterMultiCritereModel>();
  @Output() onLoad = new EventEmitter<FilterMultiCritereModel>();

  public filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public filterForm = new FormGroup({
    client: new FormControl(),
    statutsLivraison: new FormControl(),
    activites: new FormControl(),
    pays: new FormControl(),
    minDate: new FormControl(),
    maxDate: new FormControl(),
    dateCommande: new FormControl(),
    createdAt: new FormControl(),
    search: new FormControl()
  });

  // Tableaux
  public hiddenInput = [];
  public clients: Client[];
  public statutsLivraison: Statut[];
  public activites: Activite[];
  public pays: Pays[];
  public subscriptions: Subscription[] = [];
  public minDate;
  public maxDate;
  public dataSource: any;

  // Loader
  public loadPays = false;
  public loadClient = false;
  public loadStatutsLivraison = false;
  public loadActivites = false;

  constructor(private translate: TranslateService,
              private clientSvc: SousCompteSsoService,
              private statutSvc: StatutService,
              private toastr: ToastrService,
              private activiteSvc: ActiviteService,
              private paysSvc: PaysService,
              private filterSvc: FilterService) {
  }

  ngOnInit() {
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.loadClient = true;
      this.loadStatutsLivraison = true;
      this.loadPays = true;
      this.loadActivites = true;
      this.onLoadSousComptes();
      this.onLoadStatuts();
      this.onLoadPays();
      this.onLoadActivites();
    }));
    this.onInitFilter();
  }

  onInitFilter() {
    this.loadClient = true;
    this.loadStatutsLivraison = true;
    this.loadPays = true;
    this.loadActivites = true;
    FormfilterHelper.hydrateForm(this.displayInput, this.filterForm);
    this.hiddenInput = FormfilterHelper.extractHiddenInput(this.displayInput);
    this.onLoadSousComptes();
    this.onLoadStatuts();
    this.onLoadPays();
    this.onLoadActivites();
    this.getValueLocalstorage();
    this.onLoad.emit(this.filterForm.getRawValue());
  }

  /**
   * Chargement des sous-comptes
   */
  onLoadSousComptes() {
    this.subscriptions.push(this.clientSvc.getSousComptes().subscribe(value => {
      this.clients = value;
      this.loadClient = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des sous-comptes n'est pas disponible", {application: this.translate.instant('Mes livraisons')}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadClient = false;
      }
    ));
  }

  /**
   * Chargement des statuts
   */
  onLoadStatuts() {
    this.subscriptions.push(this.statutSvc.getStatutsAvis(StatutService.LIVRAISON).subscribe(value => {
        this.statutsLivraison = value;
        this.loadStatutsLivraison = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des statuts n'est pas disponible", { application: this.translate.instant('Mes livraisons')}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadStatutsLivraison = false;
      }
    ));
  }

  /**
   * Chargement des pays
   */
  onLoadPays() {
    this.subscriptions.push(this.paysSvc.getPays().subscribe(
      value => {
        this.pays = value;
        this.loadPays = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des pays n'est pas disponible", {application: this.translate.instant('Mes livraisons')}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadPays = false;
      }
    ));
  }

  /**
   * Chargement des activités
   */
  onLoadActivites() {
    this.subscriptions.push(this.activiteSvc.getActivites(ActiviteService.LIVRAISON).subscribe(value => {
        this.activites = value;
        this.loadActivites = false;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant("La liste des activités n'est pas disponible", {application: this.translate.instant("Mes livraisons")}),
          this.translate.instant('Désolé'),
          {
            timeOut: 20000,
            progressBar: true
          }
        );
        this.loadActivites = false;
      }
    ));
  }

  /**
   * On récupère les valeurs du localstorage
   */
  getValueLocalstorage() {
    this.filterForm.get('search').setValue(localStorage.getItem('livraisonSearchFilter'));
    this.filterSvc.onCheckValueLocalStorage(this.filterForm, 'livraisonClientFilter', 'client');
    this.filterSvc.onCheckValueLocalStorage(this.filterForm, 'livraisonActiviteFilter', 'activites');
    this.filterSvc.onCheckValueLocalStorage(this.filterForm, 'livraisonPaysFilter', 'pays');
    this.filterSvc.onCheckValueLocalStorage(this.filterForm, 'livraisonStatutFilter', 'statutsLivraison');
    this.filterSvc.convertDateLocalstorageForUrl(this.filterForm, 'minDate', 'livraisonMinDateFilter');
    this.filterSvc.convertDateLocalstorageForUrl(this.filterForm, 'maxDate', 'livraisonMaxDateFilter');
    this.ShowDateLocalstorageInput('livraisonMinDateFilter', 'livraisonMaxDateFilter');
  }

  /**
   *   on affiche la date enregistrée dans le localstorage, dans l'input
   */
  ShowDateLocalstorageInput(minDateFilterName, maxDateFilterName) {
    if (localStorage.getItem(minDateFilterName) != null && localStorage.getItem(maxDateFilterName) == null) {
      if (!this.minDate && !this.maxDate) {
        this.minDate = this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem(minDateFilterName));
        this.maxDate = null;
      }
    } else if (localStorage.getItem(minDateFilterName) != null && localStorage.getItem(maxDateFilterName) != null) {
      if (!this.minDate && !this.maxDate) {
        this.minDate = this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem(minDateFilterName));
        this.maxDate = this.filterSvc.convertDateLocalstorageForShowInput(localStorage.getItem(maxDateFilterName));
      }
    }
  }

  onSubmit() {
    // récupère les infos du filtre
    this.filterSvc.ckeckInputIsNull(this.filterForm, this.inputMinDate, 'minDate');
    this.filterSvc.ckeckInputIsNull(this.filterForm, this.inputMaxDate, 'maxDate');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, 'livraisonSearchFilter', 'search');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, 'livraisonClientFilter', 'client');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, 'livraisonActiviteFilter', 'activites');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, 'livraisonPaysFilter', 'pays');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, 'livraisonStatutFilter', 'statutsLivraison');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, 'livraisonMinDateFilter', 'minDate');
    this.filterSvc.onSaveInLocalstorage(this.filterForm, 'livraisonMaxDateFilter', 'maxDate');
    this.submitFilter.emit(this.filterForm.getRawValue());
  }

  // On efface tous les champs du filter
  reset(): void {
    this.filterForm.reset();
    this.inputMinDate.reset();
    this.inputMaxDate.reset();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
