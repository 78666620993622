<div class="row m-0 pt-3 pb-2">
  <div class="col-xl-4 col-lg-6 col-md-4 col-sm-4 col-6 p-0">
    <label class="col-lg-12 col-md-12 col-sm-12">{{'Volume total' | translate }}
      <div class="input-group">
        <input type="text" class="input form-control form-control-filter" [value]="commande?.volume_total" readonly>
        <span class="input-group-append ">
          <span class="input-group-text input-group-border-left"> m³ </span>
        </span>
      </div>
    </label>
  </div>
  <div class="col-xl-4 col-lg-6 col-md-4 col-sm-4 col-6 p-0">
    <label class="col-lg-12 col-md-12 col-sm-12">{{'Poids total' | translate }}
      <div class="input-group">
        <input type="text" class="input form-control form-control-filter" [value]="commande?.poids_total" readonly>
        <span class="input-group-append">
             <span class="input-group-text input-group-border-left"> kg </span>
          </span>
      </div>
    </label>
  </div>
  <div class="col-xl-4 col-lg-6 col-md-4 col-sm-4 col-6 p-0">
    <label class="col-lg-12 col-md-12 col-sm-12">{{'Colis' | translate }}
      <div class="input-group">
        <input type="text" class="input form-control form-control-filter" [value]="commande?.nb_colis" readonly>
      </div>
    </label>
  </div>
</div>
