<div *ngIf="loading" class="spinner"></div>
<div *ngIf="!loading">
  <div class="box">
    <div *ngFor="let timeline of data; let index = index">
      <div>
        <div class="line timeline-statut">
          <div id="line-black"></div>

          <!-- reception plateforme -->
          <div *ngIf="timeline?.type === 'DISTRIBUTION' && timeline.flux === 'ARRIVAGE'"
               class="marker marker-bottom marker-bottom-1  {{((!timeline.etat && !!data[index +1]['etat']) ? 'OK' : timeline.etat) | statutTimeline}}">
            <i *ngIf="(!timeline.etat && !!data[index +1]['etat']) || !timeline.etat"
               class="icon-bottom-color icon-bottom-pos icon-bottom-size fas fa-cubes"></i>
            <i *ngIf="timeline.etat === 'OK'"
               placement="top"
               container="body"
               ngbTooltip="{{timeline.date_evenement}} {{timeline.dernier_evenement}}"
               class="icon-bottom-color icon-bottom-pos icon-bottom-size fas fa-cubes"></i>
            <i *ngIf="timeline.etat === 'NK'"
               placement="top"
               container="body"
               ngbTooltip="{{timeline.date_evenement}} {{timeline.dernier_evenement}}"
               class="icon-bottom-color icon-bottom-pos icon-bottom-size fas fa-exclamation"></i>
            <i *ngIf="timeline.etat === 'AT'"
               class="icon-bottom-color icon-bottom-pos icon-bottom-size fas fa-cubes"></i>
            <div class="statut reception-hub color-adresse">{{'Réception' | translate }}</div>
            <div *ngIf="timeline.etat === 'AT'"
                 placement="top"
                 container="body"
                 ngbTooltip="{{timeline.date_evenement}} {{timeline.dernier_evenement}}"
                 class="circle-loader circle-loader-pos">
            </div>
          </div>

          <!-- icon plateforme -->
          <div *ngIf="timeline.type === 'DISTRIBUTION'" class="marker marker-bottom-hub">
            <div class="adresse pt-3 pb-1 text-truncate">
              <div>{{timeline.libelle  | uppercase}}</div>
              <div>{{'Agence régionale' | translate }}</div>
            </div>
            <i class="icon-top-color icon-bottom-pos-icon icon-top-size fas fa-building"></i>
          </div>

          <!-- depart plateforme -->
          <div *ngIf="timeline.type === 'DISTRIBUTION' && timeline.flux === 'DEPART'"
               class="marker marker-bottom marker-bottom-2 {{((!timeline.etat && data[index -1]['etat'] === 'OK') ? 'AT' : timeline.etat) | statutTimeline}}">
            <i *ngIf="!timeline.etat && data[index -1]['etat'] !== 'OK'"
               class="icon-bottom-color icon-bottom-pos-truck icon-bottom-size-truck fas fa-truck-loading"></i>
            <i *ngIf="timeline.etat === 'OK'"
               placement="top"
               container="body"
               ngbTooltip="{{timeline.date_evenement}} {{timeline.dernier_evenement}}"
               class="icon-bottom-color icon-bottom-pos-truck icon-bottom-size-truck fas fa-truck-loading"></i>
            <i *ngIf="timeline.etat === 'NK'"
               placement="top"
               container="body"
               ngbTooltip="{{timeline.date_evenement}} {{timeline.dernier_evenement}}"
               class="icon-bottom-color icon-bottom-pos icon-bottom-size fas fa-exclamation"></i>
            <i *ngIf="(!timeline.etat && data[index -1]['etat'] === 'OK') || timeline.etat === 'AT'"
               class="icon-bottom-color icon-bottom-pos icon-bottom-size fas fa-truck-loading"></i>
            <div class="statut chargement-hub color-adresse">{{'Chargement' | translate }}</div>
            <div *ngIf="(!timeline.etat && data[index -1]['etat'] === 'OK')"
                 class="circle-loader circle-loader-pos">
            </div>
            <div *ngIf="timeline.etat === 'AT'"
                 placement="top"
                 container="body"
                 ngbTooltip="{{timeline.date_evenement}} {{timeline.dernier_evenement}}"
                 class="circle-loader circle-loader-pos">
            </div>
          </div>

          <!-- camion -->
          <div
            *ngIf="(timeline.type === 'DISTRIBUTION' && timeline.flux === 'DEPART' && timeline.etat === 'OK') && (data[index +1]['etat'] === 'AT' || !data[index +1]['etat'])"
            class="marker marker-bottom-3">
            <i placement="top" container="body" ngbTooltip="en cours de tranferts - Dépot national"
               class="truck  icon-bottom-size-moving-truck fas fa-truck-moving animate-truck"></i>
          </div>

          <!-- reception hub -->
          <div *ngIf="timeline.type === 'HUB' && timeline.flux === 'ARRIVAGE'"
               class="marker marker-bottom marker-bottom-4 {{((!timeline.etat && data[index -1]['etat'] === 'OK') ? 'AT' : timeline.etat) | statutTimeline}}">
            <i *ngIf="!timeline.etat && data[index -1]['etat'] !== 'OK'"
               class="icon-bottom-color icon-bottom-pos icon-bottom-size fas fa-cubes"></i>
            <i *ngIf="timeline.etat === 'OK'"
               placement="top"
               container="body"
               ngbTooltip="{{timeline.date_evenement}} {{timeline.dernier_evenement}}"
               class="icon-bottom-color icon-bottom-pos icon-bottom-size fas fa-cubes"></i>
            <i *ngIf="timeline.etat === 'NK'"
               placement="top"
               container="body"
               ngbTooltip="{{timeline.date_evenement}} {{timeline.dernier_evenement}}"
               class="icon-bottom-color icon-bottom-pos icon-bottom-size fas fa-exclamation"></i>
            <i *ngIf="(!timeline.etat && data[index -1]['etat'] === 'OK') || timeline.etat === 'AT'"
               class="icon-bottom-color icon-bottom-pos icon-bottom-size fas fa-cubes"></i>
            <div class="statut reception-pf color-adresse">{{'Réception' | translate }}</div>
            <div *ngIf="(!timeline.etat && data[index -1]['etat'] === 'OK')"
                 class="circle-loader circle-loader-pos">
            </div>
            <div *ngIf="timeline.etat === 'AT'"
                 placement="top"
                 container="body"
                 ngbTooltip="{{timeline.date_evenement}} {{timeline.dernier_evenement}}"
                 class="circle-loader circle-loader-pos">
            </div>
          </div>

          <!-- icon hub -->
          <div *ngIf="timeline.type === 'HUB'"
               class="marker marker-bottom-pf">
            <div class="adresse pt-3 pb-1 text-truncate">
              <div>{{timeline.libelle  | uppercase}}</div>
              <div>{{'Dépot national' | translate }}</div>
            </div>
            <i class="icon-top-color icon-bottom-pos-icon icon-top-size fas fa-building"></i>
          </div>

          <!-- départ hub -->
          <div *ngIf="timeline.type === 'HUB' && timeline.flux === 'DEPART'"
               class="marker marker-bottom marker-bottom-5 {{((!timeline.etat && data[index -1]['etat'] === 'OK') ? 'AT' : timeline.etat) | statutTimeline}}">
            <i *ngIf="!timeline.etat && data[index -1]['etat'] !== 'OK'"
               class="icon-bottom-color icon-bottom-pos-truck icon-bottom-size-truck fas fa-truck-loading"></i>
            <i *ngIf="timeline.etat === 'OK'"
               placement="top"
               container="body"
               ngbTooltip="{{timeline.date_evenement}} {{timeline.dernier_evenement}}"
               class="icon-bottom-color icon-bottom-pos-truck icon-bottom-size-truck fas fa-truck-loading"></i>
            <i *ngIf="timeline.etat === 'NK'"
               placement="top"
               container="body"
               ngbTooltip="{{timeline.date_evenement}} {{timeline.dernier_evenement}}"
               class="icon-bottom-color icon-bottom-pos icon-bottom-size fas fa-exclamation"></i>
            <i *ngIf="(!timeline.etat && data[index -1]['etat'] === 'OK') || timeline.etat === 'AT'"
               class="icon-bottom-color icon-bottom-pos icon-bottom-size fas fa-truck-loading"></i>
            <div
              class="statut chargement-pf color-adresse">{{'Chargement' | translate }}</div>
            <div *ngIf="(!timeline.etat && data[index -1]['etat'] === 'OK')"
                 class="circle-loader circle-loader-pos">
            </div>
            <div *ngIf="timeline.etat === 'AT'"
                 placement="top"
                 container="body"
                 ngbTooltip="{{timeline.date_evenement}} {{timeline.dernier_evenement}}"
                 class="circle-loader circle-loader-pos">
            </div>
          </div>

          <!-- camion -->
          <div
            *ngIf="(timeline.type === 'HUB' && timeline.flux === 'DEPART' && timeline.etat === 'OK') && (data[index +1]['etat'] === 'AT' || !data[index +1]['etat'])"
            class="marker marker-bottom-6">
            <i class="truck icon-bottom-size-moving-truck fas fa-truck-moving animate-truck-1"></i>
          </div>

          <!-- livraison -->
          <div *ngIf="timeline.type === 'DESTINATAIRE' && timeline.flux === 'LIVRAISON'"
               class="marker marker-bottom marker-bottom-7 {{((!timeline.etat && data[index -1]['etat'] === 'OK') ? 'AT' : timeline.etat) | statutTimeline}}">
            <div class="adresse adresse-home py-3 text-truncate">{{timeline.libelle  | uppercase}}</div>
            <i *ngIf="!timeline.etat && data[index -1]['etat'] !== 'OK'"
               class="icon-bottom-color icon-bottom-pos-home icon-bottom-size fas fa-home"></i>
            <i *ngIf="timeline.etat === 'OK'"
               placement="top"
               container="body"
               ngbTooltip="{{timeline.date_evenement}} {{timeline.dernier_evenement}}"
               class="icon-bottom-color icon-bottom-pos-home icon-bottom-size fas fa-home"></i>
            <i *ngIf="timeline.etat === 'NK'"
               placement="top"
               container="body"
               ngbTooltip="{{timeline.date_evenement}} {{timeline.dernier_evenement}}"
               class="icon-bottom-color icon-bottom-pos-home icon-bottom-size fas fa-exclamation"></i>
            <i *ngIf="(!timeline.etat && data[index -1]['etat'] === 'OK') || timeline.etat === 'AT'"
               class="icon-bottom-color icon-bottom-pos-home icon-bottom-size fas fa-home"></i>
            <div class="statut livraison color-adresse">{{'Livraison' | translate }}</div>
            <div *ngIf="(!timeline.etat && data[index -1]['etat'] === 'OK')"
                 class="circle-loader circle-loader-pos circle-loader-pos-livraison">
            </div>
            <div *ngIf="timeline.etat === 'AT'"
                 placement="top"
                 container="body"
                 ngbTooltip="{{timeline.date_evenement}} {{timeline.dernier_evenement}}"
                 class="circle-loader circle-loader-pos circle-loader-pos-livraison">
            </div>
          </div>
        </div>
      </div>
<!--      <div class="p-btn" *ngIf="timeline.flux === 'LIVRAISON' && timeline.etat === 'OK'">-->
<!--        <div class="offset-1 col-10 offset-sm-3 col-sm-6">-->
<!--          <button *ngIf="showSignatureLivraisonBtn" class="btn btn-radius btn-agediss btn-block text-truncate shadow"-->
<!--                  (click)="selectSignatureLivraison(this.commande.numero_avis)">{{'button.preuve_de_livraion' | translate }}</button>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</div>
