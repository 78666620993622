import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {PieceJointeService} from '../../../../shared/services/api/piece-jointe.service';
import {FileService} from '../../../../shared/services/file.service';
import {PieceJointeGed} from '../../../../shared/models/api/ged/piece-jointe-ged.model';
import {PieceJointeData} from '../../../../shared/models/api/piece-jointe-data.model';
import {GedService} from "../../../../shared/services/api/ged/ged.service";

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit, OnDestroy {

  @Input() piecesJointesGed: PieceJointeGed[] = null;
  @Input() pieceJointeGed: PieceJointeGed = null;
  @Input() pieceJointeData: PieceJointeData = null;
  @Input() width = '500px';
  @Input() height = '625px';
  @Input() urlPreview: string | void;
  @Input() showImage = false;
  @Input() loadingPage = false;
  @Input() currentType: number =  null;

  @Output() canceled: EventEmitter<void> = new EventEmitter();

  public loadingDownloadBtn = false;
  public subscriptions: Subscription[] = [];

  constructor(private pieceJointeSvc: PieceJointeService,
              private gedSvc: GedService) {
  }

  ngOnInit() {
  }

  download(fileId: any) {
    this.loadingDownloadBtn = true;
    this.subscriptions.push(this.pieceJointeSvc.downloadFile(fileId).subscribe((res) => {
        this.loadingDownloadBtn = false;

        FileService.downloadDataToFile(res);
      },
      (error) => {
        this.loadingDownloadBtn = false;
      }
    ));
  }

  downloadGed(id: string, type: string) {
    this.loadingDownloadBtn = true;
    this.subscriptions.push(this.gedSvc.downloadFileGed(id, type).subscribe((res) => {
        this.loadingDownloadBtn = false;

        FileService.downloadDataToFile(res);
      },
      () => {
        this.loadingDownloadBtn = false;
      }
    ));
  }

  openLink(url){
    window.open(url, '_blank');
  }

  cancel() {
    this.canceled.emit();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
