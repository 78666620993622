<table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" multiTemplateDataRows matSort
       matSortActive="created_at" matSortDirection="desc"
       class="table mat-responsive table-hover table-striped-n3 table-bordered mat-elevation-z8 text-center">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Id' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="align-middle">
            {{element.id}} </td>
    </ng-container>
    <ng-container matColumnDef="nom">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Nom' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="align-middle text-truncate" style="max-width: 150px">
            {{element?.nom | titlecase}} </td>
    </ng-container>
    <ng-container matColumnDef="extension">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Extension' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="align-middle">
            {{element.images[0].extension }} </td>
    </ng-container>
    <ng-container matColumnDef="type_document">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Type' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="align-middle">
            {{element.type_document | titlecase}} </td>
    </ng-container>
    <ng-container matColumnDef="categorie">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Catégorie' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="align-middle">
            {{element.categorie | titlecase}} </td>
    </ng-container>
    <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Date' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="align-middle">
            {{element?.date}} </td>
    </ng-container>
    <ng-container matColumnDef="type_image">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Type d\'image' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="align-middle">
            {{element?.type_image | titlecase }} </td>
    </ng-container>
    <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element, let i = index" class="with-img align-middle text-nowrap p-0">
            <button *ngIf="showDownloadFileBtn && element.downloadable && this.innerWidth > 430"
                    ngbTooltip="{{'Télécharger le document' | translate }}"
                    placement="top"
                    container="body"
                    class="btn btn-agediss btn-sm btn-rounded mr-1"
                    (click)="download(element)">
                <i class="fa fa-download"></i>
            </button>
            <button *ngIf="showViewFileBtn && element.watchable && this.innerWidth > 430 && element.images[0].extension !== 'xlsx'"
                    ngbTooltip="{{'Visualisation des documents' | translate }}"
                    placement="top"
                    container="body"
                    class="btn btn-agediss btn-sm btn-rounded ml-1 mr-1"
                    (click)="selectFile(element)"><i class="fa fa-eye"></i>
            </button>
            <button *ngIf="showDeleteFileBtn && element.deletable && this.innerWidth > 430"
                    [ladda]="element.pending"
                    data-style="zoom-in"
                    ngbTooltip="{{'Supprimer le document' | translate }}"
                    placement="top"
                    container="body"
                    class="btn btn-agediss btn-sm btn-rounded ml-1 mr-1"
                    (click)="delete(element.id)">
                <i class="fa fa-trash"></i>
            </button>
            <button *ngIf="showInfosBtn && (((isKpi || isVideo) && this.innerWidth < 900) || ((!isKpi && !isVideo) && this.innerWidth < 1200))"
                    ngbTooltip="{{'Informations complémentaires' | translate }}"
                    placement="left"
                    container="body"
                    class="btn btn-agediss btn-sm btn-rounded ml-1"
                    (click)="expandedElement = expandedElement === element ? null : element">
                <i class="fas fa-angle-double-down"></i>
            </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="noData">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
            innerHTML="{{'Aucun document' | translate }}"></td>
    </ng-container>
    <ng-container matColumnDef="loading">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length"
            innerHTML="{{'Chargement en cours' | translate }} <i class='fas fa-spin fa-circle-notch'></i>"></td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
        <td class="table-td p-0" mat-cell *matCellDef="let element; let i = index"
            [attr.colspan]="displayedColumns.length">

            <div class="table-detail"
                 *ngIf="this.innerWidth < 430"
                 [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="table-description">
                    <strong>{{'Options' | translate }} : </strong>
                    <span>
                        <button *ngIf="showDownloadFileBtn && element.downloadable"
                             ngbTooltip="{{'Télécharger le document' | translate }}"
                             placement="top"
                             container="body"
                             class="btn btn-sm btn-agediss"
                             (click)="download(element)">
                        <i class="fa fa-download"></i>
                        </button>
                        <button *ngIf="showViewFileBtn && element.watchable && element.images[0].extension !== 'xlsx'"
                              ngbTooltip="{{'Visualisation des documents' | translate }}"
                              placement="top"
                              container="body"
                              class="btn btn-sm btn-agediss ml-1"
                              (click)="selectFile(element)"><i class="fa fa-eye"></i>
                        </button>
                        <button *ngIf="showDeleteFileBtn && element.deletable"
                                [ladda]="element.pending"
                                data-style="zoom-in"
                                ngbTooltip="{{'Supprimer le document' | translate }}"
                                placement="top"
                                container="body"
                                class="btn btn-agediss btn-sm btn-rounded ml-1 mr-1"
                                (click)="delete(element.id)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </span>
                </div>
            </div>
            <div class="table-detail"
                 *ngIf="(!isKpi && !isVideo) && this.innerWidth < 600"
                 [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="table-description">
                    <strong>{{'Type' | translate }} : </strong><span>{{element.type_document | titlecase}}</span>
                </div>
            </div>
            <div class="table-detail"
                 *ngIf="(isKpi || isVideo) && this.innerWidth < 900"
                 [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="table-description">
                    <strong>{{'Catégorie' | translate }} : </strong><span>{{element.categorie | titlecase}}</span>
                </div>
            </div>
            <div class="table-detail"
                 *ngIf="(isKpi || isVideo) ? this.innerWidth < 600 : this.innerWidth < 700"
                 [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="table-description">
                    <strong>{{'Date' | translate }} : </strong><span>{{element?.date}}</span>
                </div>
            </div>
            <div class="table-detail"
                 *ngIf="(isKpi || isVideo) ? this.innerWidth < 700 : this.innerWidth < 1200"
                 [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="table-description">
                    <strong>{{'Type d\'image' | translate }}
                        : </strong><span>{{element?.type_image | titlecase }}</span>
                </div>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row style="height: 15px;" *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" [hidden]="loading"
        class="table-row overflow-scroll"
        [class.expanded-row]="expandedElement === element">
    </tr>
    <tr mat-footer-row *matFooterRowDef="['noData']"
        [hidden]="dataSource && dataSource.data.length > 0 || loading"></tr>
    <tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loading"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
</table>

<mat-paginator [length]="dataSource.data.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
               showFirstLastButtons></mat-paginator>
