<div class="row m-0">
  <div class="col-md-12 col-lg-12 col-xl-12">
    <div class="row m-0 py-2">
      <div class="col-12 p-0 shadow">
        <div class="card card-1">
          <div class="body-1 bg-light-orange text-center">
            <div *ngIf="loadingCharts" class="spinner-border text-light m-spinner-pie-chart"></div>
            <div *ngIf="!loadingCharts" class="charts">
              <h1 class="pt-2">{{"Nombre de commandes par statuts" | translate }}</h1>
              <ngx-charts-pie-chart *ngIf="showCharts"
                                    [view]="view"
                                    [scheme]="colorScheme"
                                    [results]="single"
                                    [gradient]="gradient"
                                    [legend]="showLegend"
                                    [legendTitle]="legendTitle"
                                    [labels]="showLabels"
                                    [legendPosition]="legendPosition"
                                    [doughnut]="isDoughnut">
              </ngx-charts-pie-chart>
              <div class="charts" *ngIf="!showCharts">
                <h5 class="text-white"
                    style="padding: 150px 0">{{'Aucune donnée disponible' | translate }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
