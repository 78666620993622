<div *ngIf="loadingPage" class="spinner"></div>
<div *ngIf="!loadingPage">
  <div class="row m-0 timeline-panel">
    <div class="col-md-12 col-lg-12 m-0">
      <div *ngIf="noTimeline" class="text-center rounded bg-white shadow py-3">{{'Aucune timeline de disponible' | translate }}</div>
      <div *ngIf="!noTimeline"
           class="timeline-component scrollbar scrollbar-black bordered-black square thin overflow-scroll text-left rounded bg-white shadow">
        <!-- début timeline livraison -->
        <app-timeline-hub
          *ngIf="((commande.activite_nom | activiteLivraisonPipe))
        && !!commande.plateforme_transit_code && ((!commande.reception_hub_ok && !commande.depart_hub_ok && !commande.reception_plateforme_ok && !commande.depart_plateforme_ok && !commande.livraison_ok)
         || (!commande.depart_hub_ok && !!commande.reception_hub_ok) || (!!commande.depart_hub_ok))"
          [data]="timelines" [commande]="commande" [loading]="loadingTimeline"
          [showSignatureLivraisonBtn]="true"
          (onSelectInfosTimeline)="openModalGeolocalisation(modal_geolocalisation, $event )"
          (onSelectSignatureLivraison)="openModalSignatureLivraison(modal_signature_livraion, $event )">
        </app-timeline-hub>
        <app-timeline-plateforme
          *ngIf="((commande.activite_nom | activiteLivraisonPipe))
          && !commande.plateforme_transit_code && ((!commande.reception_hub_ok && !commande.depart_hub_ok && !commande.reception_plateforme_ok && !commande.depart_plateforme_ok && !commande.livraison_ok)
          || (!commande.reception_hub_ok && !commande.depart_hub_ok && !!commande.reception_plateforme_ok)
          || (!commande.reception_hub_ok && !commande.depart_hub_ok && !commande.reception_plateforme_ok && !!commande.depart_plateforme_ok))"
          [data]="timelines" [commande]="commande" [loading]="loadingTimeline" [showSignatureLivraisonBtn]="true"
          (onSelectInfosTimeline)="openModalGeolocalisation(modal_geolocalisation, $event )"
          (onSelectSignatureLivraison)="openModalSignatureLivraison(modal_signature_livraion, $event )">
        </app-timeline-plateforme>
        <!-- fin timeline livraison -->

        <!-- debut timeline reprise -->
        <app-timeline-livraison
          *ngIf="(commande.activite_nom | activiteReprisePipe)
          && ((!commande.reception_plateforme_ok && !commande.depart_plateforme_ok && !commande.livraison_ok)
           || (!!commande.reception_plateforme_ok || (!commande.reception_plateforme_ok && !!commande.depart_plateforme_ok)))"
          [data]="timelines" [commande]="commande" [loading]="loadingTimeline" [showSignatureLivraisonBtn]="true"
          (onSelectInfosTimeline)="openModalGeolocalisation(modal_geolocalisation, $event )"
          (onSelectSignatureLivraison)="openModalSignatureLivraison(modal_signature_livraion, $event )">
        </app-timeline-livraison>
        <!-- fin timeline reprise -->

        <!-- début timeline retour -->
        <app-timeline-reverse-hub
          *ngIf="((commande.activite_nom | activiteRetourPipe))
        && !!commande.plateforme_transit_code && ((!commande.reception_plateforme_ok && !commande.depart_plateforme_ok && !commande.reception_hub_ok && !commande.depart_hub_ok && !commande.livraison_ok)
         || (!commande.depart_plateforme_ok && !!commande.reception_plateforme_ok) || (!!commande.plateforme_transit_code && !!commande.depart_plateforme_ok))"
          [data]="timelines" [commande]="commande" [loading]="loadingTimeline"
          [showSignatureLivraisonBtn]="true">
        </app-timeline-reverse-hub>
        <app-timeline-reverse-plateforme
          *ngIf="((commande.activite_nom | activiteRetourPipe))
          && !commande.plateforme_transit_code && ((!commande.reception_plateforme_ok && !commande.depart_plateforme_ok && !commande.reception_hub_ok && !commande.depart_plateforme_ok && !commande.livraison_ok)
           || (!!commande.reception_plateforme_ok))"
          [data]="timelines" [commande]="commande" [loading]="loadingTimeline" [showSignatureLivraisonBtn]="true">
        </app-timeline-reverse-plateforme>
        <!-- fin timeline retour -->
      </div>
    </div>
  </div>
  <div class="row m-0 padding-tab">
    <div class="col-md-12 col-lg-6 pt-4 m-0">
      <panel variants="danger" title="{{'Destinataire' | translate}}" noButton="true"
             bodyClass="no-padding panel"
             class="text-center bg-primary shadow">
        <div class="scrollbar scrollbar-black bordered-black square thin overflow-scroll text-left"
             style="min-height: 140px">
          <app-destinataire [commande]="commande"></app-destinataire>
        </div>
      </panel>
    </div>
    <div class="col-md-12 col-lg-6 pt-4 m-0">
      <panel variants="danger" title="{{'Plateforme' | translate}}" noButton="true"
             bodyClass="no-padding panel"
             class="text-center">
        <div class="scrollbar scrollbar-black bordered-black square thin overflow-scroll text-left"
             style="min-height: 140px">
          <app-plateforme [commande]="commande"></app-plateforme>
        </div>
      </panel>
    </div>
  </div>
  <div class="row m-0 padding-tab">
    <div class="col-lg-6 col-md-12 pt-4 m-0">
      <panel variants="danger" title="{{'Articles' | translate}}" noButton="false" class="text-center"
             bodyClass="no-padding panel">
        <div class="panel-info-article scrollbar scrollbar-black bordered-black square thin overflow-scroll">
          <app-information [commande]="commande"></app-information>
        </div>
        <div class="panel-article scrollbar scrollbar-black bordered-black square thin overflow-scroll">
          <app-table-article
            [data]="articles"
            [loading]="loadingTableArticle"
            [showViewBtn]="false"
            [showViewBtnArticle]="view.hasDroit('COMMANDE_ARTICLE_SHOW')"
            [showInfosBtn]="true"
            (selectArticle)="openModalShowArticle(modal_visualisation_articles_livraison, $event)">
          </app-table-article>
        </div>
      </panel>
    </div>
    <div class="col-lg-6 col-md-12 pt-4 m-0">
      <panel variants="danger" title="{{'Prestations annexes demandées' | translate}}" noButton="true" class="text-center"
             bodyClass="no-padding panel">
        <div class="panel-prestation scrollbar scrollbar-black bordered-black square thin overflow-scroll">
          <div *ngIf="noPrestation" class="py-2">{{'Aucune prestation' | translate }}</div>
          <app-liste-prestation *ngIf="!noPrestation" [data]="prestations"
                                [loading]="loadingTablePrestation"></app-liste-prestation>
        </div>
      </panel>
    </div>
  </div>
  <div class="row m-0 padding-tab">
    <div class="col-12 py-4 m-0">
      <panel variants="danger" title="{{'Instructions complémentaires' | translate}}" noButton="true"
             bodyClass="no-padding panel"
             class="text-center">
        <div class="scrollbar scrollbar-black bordered-black square thin overflow-scroll text-left p-0"
             style="min-height: 210px">
          <app-instruction-complementaire [commande]="commande"></app-instruction-complementaire>
        </div>
      </panel>
    </div>
  </div>
</div>


<!--modal Visualistation Article-->
<ng-template #modal_visualisation_articles_livraison let-modal>
  <app-visualisation-article-livraison [article]="article"
                                       (canceled)="modal.close($event)"></app-visualisation-article-livraison>
</ng-template>
<!-- fin de modal Visualistation Article-->

<!-- modal Signature Livraison -->
<ng-template #modal_signature_livraion let-modal>
  <app-signature-livraison [commandeId]="commande.numero_avis" [codeSociete]="commande.code_societe"
                           [numeroClient]="commande.numero_client"
                           [showViewBlBtn]="true"
                           (canceled)="modal.close($event)"></app-signature-livraison>
</ng-template>
<!-- fin de modal Signature Livraison -->

<!-- modal Géolocalisation -->
<ng-template #modal_geolocalisation let-modal>
  <app-geolocalisation  [dataTimeline]="timeline"
                        [preuveDeLivraions]="true"
                        [commandeId]="commande.numero_avis"
                        [codeSociete]="commande.code_societe"
                        [numeroClient]="commande.numero_client"
                        [libelle]="timeline.dernier_evenement"
                        [latitude]="timeline.latitude"
                        [longitude]="timeline.longitude" (canceled)="modal.close($event)"></app-geolocalisation>
</ng-template>
<!-- fin modal Géolocalisation -->
