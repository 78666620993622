import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilterMultiCritereModel} from '../../shared/models/filters/filterMultiCritere.model';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CommandeService} from "../../shared/services/api/commande.service";
import {PageEvent} from "@angular/material/paginator";
import {PaginationService} from "../../shared/services/pagination.service";
import {SuiviLivraisonData} from "../../shared/models/api/suivi-livraison-data.model";
import {View} from "../../shared/models/view.model";
import {AuthenticationService} from "../../shared/services/authentication.service";
import {Router} from "@angular/router";


@Component({
  selector: 'app-suivi-de-livraison',
  templateUrl: './suivi-de-livraison.component.html',
  styleUrls: ['./suivi-de-livraison.component.scss']
})
export class SuiviDeLivraisonPage implements OnInit, OnDestroy {

  private filter: FilterMultiCritereModel = new FilterMultiCritereModel();
  public suiviLivraison: SuiviLivraisonData;
  public pageEvent: PageEvent;
  public view: View;

  //tableau
  public suiviLivraisonData: SuiviLivraisonData[];
  public subscriptions: Subscription[] = [];
  public pageSizeOption = [5, 10, 20];

  // number
  public refresh: number;
  public pageSize = 10;
  public nbData: number = 0;

  // loading
  public loadingNbCommandes: boolean;
  public loadingTableCommande = false;

  constructor(private translate: TranslateService,
              private paginationSvc: PaginationService,
              private authSvc: AuthenticationService,
              private router: Router,
              private commandeSvc: CommandeService) {
    this.authSvc.currentView.subscribe(x => this.view = x);
  }

  ngOnInit() {
    if (this.view.hasDroit('COMMANDE_MENU_SUIVI-LIVRAISON')) {
      this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
        this.loadDataCommandesToday();
      }))
      this.refresh = setInterval(() => {
        this.loadDataCommandesToday()
      }, 300000);
    } else {
      this.router.navigate(['/', 'home']);
    }

  }

  onSubmit(filter = null) {
    //récupère les infos du filtre
    if (filter) {
      this.filter = filter;
    }
    this.loadDataCommandesToday();
  }

  onLoadFilter(filter = null) {
    //récupère les infos du filtre
    if (filter) {
      this.filter = {...filter, ...this.filter};
    }
    this.loadDataCommandesToday();
  }

  /**
   * On récupère toutes les commandes liès au compte client
   */
  loadDataCommandesToday() {
    this.loadingTableCommande = true;
    delete this.filter.limit;
    delete this.filter.offset;
    this.suiviLivraisonData = [];
    this.subscriptions.push(this.commandeSvc.getLivraisonsTodayDelivery(this.filter).subscribe((commandes) => {
        this.suiviLivraisonData = commandes;
        this.suiviLivraisonData.forEach((value, index) => {
          let progessStatut = this.statut(value);
          this.suiviLivraisonData[index]['progressBar'] = progessStatut['progressBar'];
          this.suiviLivraisonData[index]['color'] = progessStatut['color'];
          this.suiviLivraisonData[index]['statut'] = progessStatut['statut'];
          this.suiviLivraisonData[index]['date'] = progessStatut['date'];
        });
        this.loadingTableCommande = false;
      },
      error => {
        this.loadingTableCommande = false;
      }));
  }

  statut(value) {
    let tab = [];
    if ((value.activite_nom !== 'RE') && (!value.depart_plateforme_ok || value.depart_plateforme_ok === 'AT') && !value.livraison_ok) {
      tab['progressBar'] = 10 + '%';
      tab['color'] = 'bg-secondary';
      tab['statut'] = this.translate.instant('En attente de livraison - Agence régionale');
      tab['date'] = '';
    } else if ((value.activite_nom !== 'RE') && (value.depart_plateforme_ok === 'NK')) {
      tab['progressBar'] = 60 + '%';
      tab['color'] = 'bg-danger';
      tab['statut'] = this.translate.instant('Anomalie lors du chargement - Agence régionale');
      tab['date'] = value.chargement_date_evenement;
    } else if ((value.activite_nom !== 'RE') && (value.depart_plateforme_ok === 'OK') && (!value.livraison_ok)) {
      tab['progressBar'] = 60 + '%';
      tab['color'] = 'bg-info';
      tab['statut'] = this.translate.instant('Livraison en cours');
      tab['date'] = value.chargement_date_evenement;
    } else if ((value.activite_nom !== 'RE') && (value.livraison_ok === 'NK')) {
      tab['progressBar'] = 100 + '%';
      tab['color'] = 'bg-danger';
      tab['statut'] = this.translate.instant('Anomalie lors de la livraison');
      tab['date'] = value.livraison_date_evenement;
    } else if ((value.activite_nom !== 'RE') && (value.depart_plateforme_ok === 'OK' || value.depart_plateforme_ok === 'AT') && value.livraison_ok === 'AT') {
      tab['progressBar'] = 100 + '%';
      tab['color'] = 'bg-warning';
      tab['statut'] = this.translate.instant('Avis de passage');
      tab['date'] = value.livraison_date_evenement;
    } else if ((value.activite_nom !== 'RE') && (value.livraison_ok === 'OK')) {
      tab['progressBar'] = 100 + '%';
      tab['color'] = 'bg-green';
      tab['statut'] = this.translate.instant('Livré');
      tab['date'] = value.livraison_date_evenement;
    } else if ((value.activite_nom == 'RE') && (!value.livraison_ok || value.livraison_ok === 'AT')) {
      tab['color'] = 'bg-secondary';
      tab['statut'] = this.translate.instant('En attente de reprise');
      tab['date'] = value.livraison_date_evenement;
    } else if ((value.activite_nom === 'RE') && (value.livraison_ok === 'OK')) {
      tab['color'] = 'bg-green';
      tab['statut'] = this.translate.instant('Reprise effectuée');
      tab['date'] = value.livraison_date_evenement;
    } else if ((value.activite_nom === 'RE') && (value.livraison_ok === 'NK')) {
      tab['color'] = 'bg-danger';
      tab['statut'] = this.translate.instant('Anomalie lors de la reprise');
      tab['date'] = value.livraison_date_evenement;
    }
    return tab;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
