import {Component, Input, OnInit} from '@angular/core';
import {Prestation} from '../../../../shared/models/api/prestation.model';


@Component({
  selector: 'app-liste-prestation',
  templateUrl: './liste-prestation.component.html',
  styleUrls: ['./liste-prestation.component.scss']
})
export class ListePrestationComponent implements OnInit {

  @Input() data: Prestation[];
  @Input() loading: boolean = false;

  constructor() {
  }

  ngOnInit() {
  }

}
