import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthenticationService} from '../../../../shared/services/authentication.service';
import {View} from '../../../../shared/models/view.model';
import {ConversationGrc} from '../../../../shared/models/api/conversation-grc.model';
import Swal from 'sweetalert2';
import {GrcService} from '../../../../shared/services/api/grc.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer} from "@angular/platform-browser";
import {FileItem, FileUploader} from "ng2-file-upload";
import {environment} from "../../../../../environments/environment";
import {PieceJointeGed} from "../../../../shared/models/api/ged/piece-jointe-ged.model";
import {GrcData} from "../../../../shared/models/api/grc-data.model";
import {UserData} from "../../../../shared/models/user-data.model";
import {convertBytes, urlWithProtocol} from "../../../../shared/utils/util";

@Component({
  selector: 'app-visualisation-demande-grc',
  templateUrl: './visualisation-demande-grc.component.html',
  styleUrls: ['./visualisation-demande-grc.component.scss']
})
export class VisualisationDemandeGrcComponent implements OnInit {

  @Input() grcData: GrcData;
  @Input() conversationGrcs: ConversationGrc[];
  @Input() grcNumeroAvis: number;
  @Input() grcId: number;
  @Input() idDoc: number;
  @Input() conversationGrcId: number;
  @Input() loading: Boolean = false;

  @Output() selectShowDocument: EventEmitter<{id: number}> = new EventEmitter<{id: number}>();
  @Output() canceled: EventEmitter<void> = new EventEmitter();
  @Output() submited: EventEmitter<GrcData> = new EventEmitter();
  @Output() uploaded = new EventEmitter<boolean>();

  public loadingSubmitBtn: Boolean = false;
  public loadingArchiveBtn: Boolean = false;
  public pending: Boolean = true;
  public formSubmit: Boolean;
  public laddaSentButton: Boolean = false;
  public disabledButton: Boolean = false;
  public showFileUploader: Boolean = false;
  public pieceJointe: PieceJointeGed = null;

  public defaultExtension: string[] = ['.pdf', '.jpg'];
  public subscriptions: Subscription[] = [];

  public URL: string = null;
  public uploader: FileUploader;
  public msg: ConversationGrc = new ConversationGrc();
  public activModal: NgbModalRef;
  public currentUser: UserData;
  public view: View;

  public reponseDemandeGrcForm: FormGroup;

  constructor(private translate: TranslateService,
              private grcSvc: GrcService,
              private sanitizer: DomSanitizer,
              private authSvc: AuthenticationService,
              private modalSvc: NgbModal,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.routeApiUploader();
    this.handleEventUploader();
    this.authSvc.currentUser.subscribe(x => this.currentUser = x);
    this.authSvc.currentView.subscribe(x => this.view = x);
    this.initForm();
  }

  initForm() {
    this.reponseDemandeGrcForm = new FormGroup({
      username: new FormControl(this.currentUser.username),
      message: new FormControl('', Validators.required),
    });
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  toggle() {
    this.showFileUploader = !this.showFileUploader;
  }

  archiveMessage() {
    this.loadingArchiveBtn = true;
    Swal.fire({
      title: this.translate.instant('Attention !'),
      text: this.translate.instant("Êtes-vous sûr de vouloir archiver la conversation ?"),
      showCancelButton: true,
      cancelButtonText: this.translate.instant('NON'),
      confirmButtonText: this.translate.instant('OUI'),
    }).then((result) => {
      if (result.value) {
        this.subscriptions.push(this.grcSvc.archiveMessage(this.grcData.numero_avis, this.grcData).subscribe(
          grc => {
            this.loadingArchiveBtn = false;
            this.toastr.success(
              this.translate.instant("Une conversation a été archivée"),
              this.translate.instant("Informations"),
              {progressBar: true}
            );
            this.submited.emit(grc);
          },
        ));
      }
    });
  }

  /**
   * On affiche tous les documents en lien avec le litige
   * @param modalName
   * @param pieceJointe
   */
  openModalShowDocuments(modalName, pieceJointe: PieceJointeGed) {
    this.pieceJointe = pieceJointe;
    this.activModal = this.modalSvc.open(modalName, {
      size: 'lg', keyboard: false
    });
  }

  handleEventUploader() {
    // Apres avoir ajouter le fichier dans la queue de l'uploader
    this.uploader.onAfterAddingFile = (item: FileItem | any) => {

      const name = item.file.name;
      const split = name.split('.');
      item.formData.extention = '.' + split[split.length - 1];
      item.formData.name = name.replace(item.formData.extention, '');

      // controle l'extension du fichier (pdf, jpg)
      if (this.defaultExtension.indexOf(item.formData.extention) < 0) {
        Swal.fire({
          title: this.translate.instant('Attention !'),
          text: this.translate.instant('L\'extension {{ extension }} n\'est pas acceptée', {extension: item.formData.extention}) + '. ' + this.translate.instant('Liste des extensions acceptées: {{ liste }}', {liste: this.defaultExtension}),
          type: 'warning'
        }).then(() => {
          item.remove();
        });
      }
      // controle la taille du fichier
      if (item.file.size > environment.size_max_file) {
        Swal.fire({
          title: this.translate.instant('Attention !'),
          text: this.translate.instant('Le fichier {{ name }} est trop volumineux', {name: item.formData.name}) + '. ' + this.translate.instant('Il ne doit pas être supérieur à {{ size }}', {size: convertBytes(environment.size_max_file)}),
          type: 'warning'
        }).then(() => {
          item.remove();
        });
      }
    };

    // Avant l'uploade vers le serveur
    this.uploader.onBeforeUploadItem = (fileItem: FileItem | any) => {

      this.laddaSentButton = true;
      this.disabledButton = true;
      fileItem.isError = true;
      fileItem.file.name = fileItem.formData.name + fileItem.formData.extention;
      fileItem.withCredentials = false;
      return false;
    };

    this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
      if (fileItem.formData.name) {
        form.append('filename', fileItem.formData.name);
      }
    };

    this.uploader.onCompleteAll = () => {
      if (this.uploader.queue.length === 0) {
        this.uploaded.emit(true);
        this.submited.emit(JSON.parse(this.uploader.options.additionalParameter.grc));
        this.loadingSubmitBtn = false;
        this.formSubmit = false;
        this.toastr.success(
          this.translate.instant("Nouvelle demande GRC enregistrée"),
          this.translate.instant("Informations"),
          {progressBar: true}
        );
      }
      this.laddaSentButton = false;
    };

    // uploader reussi
    this.uploader.onSuccessItem = ((item: FileItem): any => {
      this.removeFileFromQueue(item.alias);
    });

    // erreur dans n'importe quels evenement ci-dessus.
    this.uploader.onErrorItem = ((item: FileItem, response: string): any => {
      item.progress = 0;
      const defaultMsg = this.translate.instant("Le fichier {{ value }} a rencontré un problème lors de l'envoi, merci de rééssayer ultérieurement", {value: item.file.name});
      try {
        const rep = JSON.parse(response);
        if (rep.code) {
          switch (rep.code) {
            case 2101 :
              Swal.fire({
                title: this.translate.instant('Attention !'),
                text: this.translate.instant("Vous devez choisir un type pour le fichier {{ value }}", {value: item.formData.name}),
                type: 'warning'
              }).then(() => {
                this.cancel();
              });
              break;
            case 2700 :
              Swal.fire({
                title: this.translate.instant('Attention !'),
                text: this.translate.instant("Vous devez choisir un type pour le fichier {{ value }}", {value: item.formData.name}),
                type: 'warning'
              }).then(() => {
                this.cancel();
              });
              break;
            default:
              Swal.fire({
                title: this.translate.instant('Désolé'),
                text: defaultMsg,
                type: 'error'
              }).then(() => {
                this.cancel();
              });
          }
        } else {
          Swal.fire({
            title: this.translate.instant('Désolé'),
            text: defaultMsg,
            type: 'error'
          }).then(() => {
            this.cancel();
          });
        }
      } catch (e) {
        Swal.fire({
          title: this.translate.instant('Désolé'),
          text: defaultMsg,
          type: 'error'
        }).then(() => {
          this.cancel();
        });
      }
      this.disabledButton = false;
      this.laddaSentButton = false;

      item.isCancel = false;
      item.isReady = false;
      item.isUploaded = false;
    });
  }

  removeFileFromQueue(label: string) {
    for (let i = 0; i < this.uploader.queue.length; i++) {
      if (this.uploader.queue[i].alias === label) {
        this.uploader.queue[i].remove();
        return;
      }
    }
  }

  routeApiUploader() {
    if (this.grcData.numero_avis != undefined || this.grcData.numero_avis != null) {
      this.URL = urlWithProtocol(environment.url_api_espace_client) + '/demandes-grc/' + this.grcData.numero_avis + '/upload';
    }
    if (!this.grcData.numero_avis) {
      throw new Error('Impossible de définir l\'url pour uploader les pièces jointes.');
    }
    this.uploader = new FileUploader({url: this.URL});
    this.authSvc.getToken().subscribe(token => {
      this.uploader.authToken = 'Bearer ' + token.token;
    });
  }

  sentAllFile(grc) {
    this.uploader.options.additionalParameter = {
      'grc': JSON.stringify(grc)
    };
    this.uploader.uploadAll()
  }

  submitForm() {
    this.formSubmit = true;
    if (this.reponseDemandeGrcForm.invalid) {
      return;
    }
    this.loadingSubmitBtn = true;
    const sub = this.grcSvc.reponseDemandeGrc(this.grcData.numero_avis, this.grcData.id, this.reponseDemandeGrcForm.value).subscribe(
      grc => {
        if(this.uploader.queue.length > 0) {
          this.sentAllFile(grc)
        } else {
          this.submited.emit(grc);
          this.loadingSubmitBtn = false;
          this.formSubmit = false;
        }
        sub.unsubscribe();
      },
      () => {
        this.loadingSubmitBtn = false;
        this.formSubmit = false;
        Swal.fire({
            title: this.translate.instant('Désolé'),
            text: this.translate.instant('Une erreur est survenue'),
            showCancelButton: false
          }
        ).then(() => {
          sub.unsubscribe();
          this.cancel();
        });
      }
    );
  }

  cancel() {
    this.canceled.emit();
  }

}
